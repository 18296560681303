<template>
    <div v-if="course">
        <b-modal title="Заполните анкету" :hide-footer="true" v-if="project" id="profileFields">
            <questionnarie :project="project"/>
        </b-modal>
        <div style="margin-bottom: 30px;">
            <router-link class="btn btn-sm btn-danger text-white btn-block" :to="`/admin/messages?sel=u5&theme=Вопрос по курсу: ${course.name}`">
                <i class="fa fa-comments"></i>
                Чат с поддержкой
            </router-link>
          <a href="mailto:info@universarium.org" class="btn btn-sm btn-default btn-block">
            <i class="fa fa-envelope-o"></i>
            info@universarium.org
          </a>
          <a href="tel:+7 (495) 249-01-36" class="btn btn-sm btn-default btn-block">
            <i class="fa fa-phone"></i>
            +7 (495) 249-01-36
          </a>
        </div>
        <b-btn v-if="!isEnroll(course) && course.id !== 383"
               @click="enroll"
               class="btn btn-primary btn-block">
            <i class="fa fa-sign-in"></i>
            Записаться на курс
        </b-btn>

        <b-btn style="margin-top: 30px;" @click="enroll" type="button" variant="default" v-if="!isEnroll(course) && course.cost > 0 && course.id !== 383" class="btn-block">
            Стоимость курса: {{ course.cost }}руб.
        </b-btn>

        <div style="margin-bottom: 30px;" v-if="isEnroll(course) && course.cost === 0">
            <h3 class="text-center">Вы записались на этот курс</h3>
            <b-btn @click="unEnroll" class="btn btn-block btn-enroll"><i class="fa fa-sign-out"></i> Покинуть курс</b-btn>
        </div>
    </div>
</template>

<script>
    import Actions from "../User/Actions";
    import TYPES from "../User/actionsTypes";
    import Questionnarie from "./Questionnarie";
    import bus from "../../bus";
    export default {
        name: "EnrollCourse",
        components: {Questionnarie},
        props: ['value'],
        extends: Actions,
        data() {
            return {
                course: this.value,
                project: null
            }
        },
        mounted() {

            bus.$on('enroll', () => {
                this.enroll(false);
            });

            this.axios.get('projects', {
                params: {
                    courses: [this.course.id]
                }
            }).then(res => {
                if(res.data.length) {
                    this.project = res.data[0];
                }
            })
            if(localStorage.getItem('has-enroll')) {
                this.enroll(false)
                localStorage.removeItem('has-enroll')
            }
        },
        methods: {
            enroll(withMsg = true) {

                if(withMsg && this.project && this.project.profileFields && this.project.profileFields.length) {
                    this.$bvModal.show('profileFields');
                    return;
                }

                this.axios.post(`enrollments/${this.course.id}`, {
                    'isActive': true
                }).then(res => {
                    if (res.data.paymentLink) {
                        window.location = res.data.paymentLink;
                        return;
                    }

                    if (res.status === 201) {
                        this.axios.get(`courses/${this.course.id}`).then(res => {
                            this.course = res.data
                            if (withMsg) {
                                this.$toasted.global.appSuccess();
                            }
                            this.$emit('input', this.course);
                            bus.$emit('enrollment', this.course);
                            this.$bvModal.hide('profileFields');
                            this.addAction(TYPES.COURSE_ENROLL_TYPE, this.course.name)
                        })
                    }
                });
            },
            unEnroll() {
                this.axios.post(`enrollments/${this.course.id}`, {
                    'isActive': false
                }).then(res => {
                    if(res.status === 201) {
                        this.axios.get(`courses/${this.course.id}`).then(res => {
                            this.course = res.data;
                            this.$emit('input', this.course);
                            bus.$emit('unenroll', this.course);
                            if(this.$router.currentRoute.path !== '/course/' + this.course.id) {
                                this.$router.push('/admin/course/' + this.course.id).catch(() => {});
                            }
                            this.addAction(TYPES.COURSE_UN_ENROLL_TYPE, this.course.name)
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    h3 {
        margin-bottom: 10px;
    }
    .btn-enroll {
        background: none;
        color: #676a6c;
        border-color: #676a6c;
    }
</style>
