<template>
    <ibox>
        <ibox-title>
            Варианты ответа:
        </ibox-title>
        <ibox-content>
            <table>
                <thead>
                <th></th>
                <th style="text-align: center">Правильный<br/>вариант?</th>
                <th style="text-align: center" v-if="task.options && task.options.hasWeight">Вес,<br/>% от 100</th>
                <th></th>
                <th></th>
                </thead>
                <tbody>
                <tr v-for="(v, key) in task.choice" :key="key">

                    <td class="choose-entity" style="font-size: 120%;">
                        <strong>{{ key + 1 }}. </strong><div v-html="v"></div>
                        <valid-summernote
                                v-if="choiceEditMode[key]"
                                v-model="task.choice[key]">
                        </valid-summernote>
                    </td>

                    <td class="right-answer" style="text-align: center;">
                        <input type="checkbox"
                               :id="'right_' + id + '_' + key"
                               :name="'right_' + id"
                               :value="key"
                               v-model="rightAnswers"/>
                    </td>

                    <td v-if="task.options && task.options.hasWeight">
                        <input type="number"
                               class="form-control"
                               style="width: 80px"
                               :id="'weight_' + id + '_' + key"
                               v-model="task.weights[key]"/>
                    </td>

                    <td class="edit-entity">
                        <button class="btn"
                                :class="{'btn-light': !choiceEditMode[key], 'btn-success': choiceEditMode[key]}"
                                style="font-size: 80%"
                                type="button"
                                title="Редактировать вариант ответа"
                                @click="toggleChoice(key)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>

                    <td class="delete-entity">
                        <button type="button" class="btn btn-light" style="font-size: 80%"
                                title="Удалить вариант ответа"
                                @click="deleteChoice(key)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
                <tr class="constructor-task-add">
                    <td colspan="3" style="padding: 5px;">
                        <valid-summernote v-on:keyup.ctrl.enter="addChoice()"
                                      v-model="newEntity"></valid-summernote> &nbsp;
                        <button type="button" class="btn btn-success btn-sm pull-right" @click="addChoice()"><i class="fa fa-plus"></i> Добавить вариант ответа</button>
                    </td>
                </tr>
                <tr class="constructor-task-options">
                    <td colspan="3">
                        <form class="form-inline">
                            <div class="form-group">
                                <label style="font-size: 110%; color: #666;"
                                       :for="'constructor_restrictmax_' + id"
                                       :id="'constructor_restrictmax_' + id" class="control-label">Дать возможность выбрать не более: &nbsp;</label>

                                <input :id="'constructor_restrictmax_' + id"
                                       class="form-control"
                                       type="number"
                                       style="width: 100px;"
                                       title="restrictMax"
                                       v-model="task.options.restrictMax"
                                       @change="giveTask()"
                                />
                                <span>&nbsp; ответов</span>
                            </div>
                        </form>
                    </td>
                </tr>
                <tr class="constructor-task-options">
                    <td colspan="3">
                        <label :id="'constructor_hasWeight_' + id"></label>
                        <p-check :id="'constructor_hasWeight_' + id"
                                 color="info"
                                 v-model="task.options.hasWeight"
                                 v-on:change="giveTask()">
                            <span style="font-size: 110%;">Правильные ответы должны иметь вес</span>
                        </p-check>
                    </td>
                </tr>
                </tbody>
            </table>
        </ibox-content>
    </ibox>
</template>

<script>
    import Ibox from "../../Ibox/Ibox";
    import IboxContent from "../../Ibox/IboxContent";
    import ConstructorBase from "../ConstructorBase";
    import IboxTitle from "../../Ibox/IboxTitle";
    import AnswerStructure from "../model/AnswerStructure";
    import ValidSummernote from "../../Validation/ValidSummernote";
    export default {
        extends: ConstructorBase,
        name: "ConstructorMultipleAnswer",
        components: {ValidSummernote, IboxTitle, IboxContent, Ibox},
        data() {
            return {
                rightAnswers: []
            }
        },
        mounted () {
            this.rightAnswers = (this.answerValue && this.answerValue.key && Array.isArray(this.answerValue.key)) ? this.answerValue.key : []
        },
        watch: {
            rightAnswers() {
                if (Array.isArray(this.rightAnswers)) {

                    let answerStructure = new AnswerStructure()
                    answerStructure.key = this.rightAnswers
                    this.answer = answerStructure
                    this.giveAnswer()
                }
            }
        }
    }
</script>

<style scoped>

</style>
