<template>
  <body class="gray-bg">


  <div class="middle-box text-center animated fadeInDown">
    <h1>403</h1>
    <h3 class="font-bold">Доступ запрещен</h3>

    <div class="error-desc">
      Для доступа к этой странице необходимо сменить роль учетной записи: <br/><a href="/login" class="btn btn-primary m-t">Войти</a>
    </div>
  </div>

  </body>
</template>

<script>
export default {
name: "AccessDenied"
}
</script>

<style scoped>

</style>
