<template>
  <ibox v-if="course">
    <ibox-content>
      <div v-if="course.institution" class="pull-right">
        <div class="d-table">
          <div class="d-table-row">
            <div class="d-table-cell text-center">
              <p style="margin-bottom: 5px;"><span class="badge badge-info">{{ course.institution.shortName }}</span></p>
            </div>
          </div>
          <div class="d-table-row">
            <div v-if="course.institution.logo" class="d-table-cell text-center">
              <img alt="image" :src="course.institution.logo.thumbs.smaller"><br/>
            </div>
          </div>
        </div>
      </div>
      <h1 class="course-title">{{course.name}}</h1>
      <div class="course-short-desc text-muted">
        <p v-html="course.shortDescription"></p>
        <div v-if="course.modules.length && !course.enroll" class="panel panel-primary">
          <div class="panel-heading">Структура курса</div>
          <div class="panel-body">
            <div class="modules-container table-responsive" id="moduleList">
              <table class="table table-condensed table-responsive">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Дата старта</th>
                  <th v-if="course.startDateTime">Окончание</th>
                  <th v-if="course.startDateTime" colspan="2"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(module, index) in course.modules" v-bind:key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ module.name }}</td>
                  <td v-if="course.startDateTime">{{ module.startDateTime|formatDate }}</td>
                  <td v-if="course.startDateTime">{{ module.endDateTime|formatDate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr class="hr-line-dashed"/>
      <h2>Авторы курса</h2>
      <b-row v-if="course.courseTutors">
        <div v-for="(user, index) in course.courseTutors" :key="index" class="contact-box col-md-6">
          <a class="row" href="javascript:void(0)">
            <div class="col-md-4">
              <div v-if="user.tutor.photo" class="text-center">
                <div class="lecturer-avatar">
                  <div :style="{backgroundImage: 'url(' + srcThumb(user.tutor.photo) + ')'}"></div>
                </div>
                <!--<div class="m-t-xs font-bold" v-html="user.tutor.education"></div>-->
              </div>
              <div v-else class="text-center">
                <div class="lecturer-avatar">
                  <div style="background-color: #bbb;"></div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <h3><strong>{{user.tutor.fullname}}</strong></h3>
              <span v-html="user.tutor.degree"></span>
            </div>
          </a>
          <br/>
          <router-link v-if="$auth.user().company && $auth.user().company.subsystemSettings && $auth.user().company.subsystemSettings.isTutorConsult" class="btn btn-sm btn-primary text-white" :to="`/admin/messages?sel=u${user.tutor.userId}&theme=Вопрос по курсу: ${course.name}`">
            <i class="fa fa-envelope-o"></i>
            Консультация
          </router-link>
        </div>
      </b-row>
      <hr class="hr-line-dashed"/>
      <b-row class="course-data-stat">
        <b-col>
          <i class="fa fa-th"></i> <strong>Модулей: </strong>{{course.modules.length}}
        </b-col>
        <b-col v-if="false">
          <i class="fa fa-clock-o"></i> <strong>Время: </strong>{{ course.duration }} {{ course.duration|pluralize('ru', ['час','часа','часов']) }}
        </b-col>
        <b-col>
          <i class="fa fa-users"></i> <strong>На курсе: </strong>{{course.enrollCount}}
        </b-col>
      </b-row>
      <div class="clearfix"></div>
      <div class="course-promo">
        <Video v-if="course.promo" :title="course.name" :media="course.promo"/>
        <img style="max-width: 100%;" v-else :src="srcThumb(course.cover, 'big')" />
      </div>
    </ibox-content>
  </ibox>
</template>

<script>
import Video from "../Video";
import IboxContent from "../Ibox/IboxContent";
import Ibox from "../Ibox/Ibox";
export default {
  name: "CoursePreview",
  components: {Ibox, IboxContent, Video},
  data() {
    return {
      users: null
    }
  },
  props: ['course'],
  computed: {
    tutors() {
      return this.users ? this.users.slice(0,3) : null
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-box {
  border: none;
}
.course-data-stat {
  margin: 10px 0 -10px;
}
.lecturer-avatar {
  display: inline-block;
  vertical-align: top;
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
  > div {
    background-position: center center;
    background-size: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
}
</style>
