<template>
    <div v-if="courses" class="wrapper wrapper-content animated fadeInRight">
      <b-modal v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" id="add-inside-course" :hide-footer="true" title="Добавить новый курс">
        <CourseForm />
      </b-modal>
      <bread-crumbs title="Список курсов" :items="[{title: 'Главная', url: '/#/'}]" />
      <div class="wrapper wrapper-content">
        <ibox>
          <ibox-title>
            <b-row>
              <b-col>
                <div class="input-group">
                  <input type="text" v-model="courseName" placeholder="Введите название курса" class="input form-control">
                  <span class="input-group-append">
                    <button type="button" class="btn btn btn-info btn-sm"> <i class="fa fa-search"></i> Найти</button>
                  </span>
                </div>
              </b-col>
              <b-col class="text-right">
                <b-btn v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" v-b-modal.add-inside-course class="btn btn-primary">Добавить новый курс</b-btn>
              </b-col>
            </b-row>
          </ibox-title>
          <ibox-content>
            <b-table :items="courses" :fields="courseFields">
              <template v-slot:cell(startDateTime)="data">
                <template v-if="data.item.startDateTime">
                  {{ data.item.startDateTime | formatDate }}
                </template>
              </template>
              <template v-slot:cell(endDateTime)="data">
                <template v-if="data.item.endDateTime">
                  {{ data.item.endDateTime | formatDate }}
                </template>
              </template>
              <template v-slot:cell(institution)="data">
                <img v-if="data.item.institution && data.item.institution.logo" :src="data.item.institution.logo.thumbs.smaller"/>
                <span v-else-if="data.item.institution">
                  {{ data.item.institution.shortName }}
                </span>
              </template>
              <template v-slot:cell(actions)="data">
                <b-btn-group>
                  <router-link class="btn btn-primary" :to="`/admin/course/${data.item.id}/edit`">
                    <i class="fa fa-edit"></i>
                  </router-link>
                  <router-link class="btn btn-warning" :to="`/admin/course/${data.item.id}`">
                    <i class="fa fa-eye"></i>
                  </router-link>
                  <b-btn @click="cloneCourse(data.item.id)" class="btn btn-info">
                    <i class="fa fa-copy"></i>
                  </b-btn>
                  <b-btn @click="deleteCourse(data.item.id)" class="btn btn-danger">
                    <i class="fa fa-trash"></i>
                  </b-btn>
                </b-btn-group>
              </template>
            </b-table>
          </ibox-content>
          <ibox-footer v-if="!courseName.length && pagination.total">
            <b-pagination
                          v-model="currentPage"
                          :total-rows="pagination.total"
                          :per-page="pagination.limit"
                          aria-controls="stats-table"
                          @input="loadCourses"
                          prev-text="Назад"
                          next-text="Вперед"
            ></b-pagination>
          </ibox-footer>
        </ibox>
      </div>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import BreadCrumbs from "../BreadCrumbs";
    import IboxFooter from "../Ibox/IboxFooter";
    import CourseForm from "./CourseForm.vue";
    export default {
        name: "CourseListTable",
      components: {CourseForm, IboxFooter, BreadCrumbs, IboxContent, IboxTitle, Ibox},
      data() {
            return {
              pagination: {
                limit: 30,
                offset: 0,
                total: 0
              },
              currentPage: 1,
              courseFields: [
                {
                  key: 'id',
                  label: 'Идентификатор курса'
                },
                {
                  key: 'name',
                  label: 'Название курса'
                },
                {
                  key: 'institution',
                  label: 'Организация'
                },
                {
                  key: 'startDateTime',
                  label: 'Дата старта'
                },
                {
                  key: 'endDateTime',
                  label: 'Дата завершения'
                },
                {
                  key: 'actions',
                  label: 'Действия'
                },
              ],
              courseName: '',
              courses: null
            }
        },
        mounted() {
            this.loadCourses()
        },
        methods: {
          cloneCourse(courseId) {
            this.axios.post('courses-clone', {course: courseId}).then(res => {
              if (res.status === 200) {
                this.loadCourses()
              }
            });
          },
          deleteCourse(courseId) {
            let confirmDelete = confirm('Вы уверены? Курс восстановить будет нельзя!')
            if(confirmDelete) {
              let confirmDelete = confirm('Вы ТОЧНО уверены? Данные будут потеряны навсегда!')
              if(confirmDelete) {
                this.axios.delete('courses/' + courseId).then(() => {
                  this.loadCourses()
                });
              }
            }
          },
          loadCourses() {
            this.axios.get('courses-list', {
              params: {
                offset: (this.currentPage-1)*this.pagination.limit,
                limit: this.pagination.limit,
                'order[id]': 'desc'
              }
            }).then(res => {
              this.courses = res.data.data;
              this.pagination = res.data.pagination;
            });
          }
        },
        watch: {
          courseName(val) {
            if (val && val.length > 3) {
              this.axios.get('courses-list', {
                params: {
                  offset: (this.currentPage-1)*this.pagination.limit,
                  hideLoader: true,
                  'filters[name]': val,
                  'filterType': 'like',
                  limit: this.pagination.limit
                }
              }).then(res => {
                this.courses = res.data.data;
                this.pagination = res.data.pagination;
              });
            }
          }
        },
    }
</script>

<style scoped lang="scss">
    .product-desc {
        height: 150px;
    }
    .product-imitation {
        min-height: 180px;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        span {
            color: #fff;
            z-index: 100;
        }
        .color-overlay {
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .6;
            position: absolute;
        }
    }
    .product-price {
        top: -33px;
        &.finished {
            background-color: #007bff;
        }
        &.started {
            background-color: #dc3545;
        }
        &.open {
            background-color: #17a2b8;
        }
        &.soon {
            background-color: #E6A23C;
        }
        &.finished {
            background-color: #67C23A;
        }
        &.closed {
            background-color: #909399;
        }
    }
    .course-none {
        border-radius: 20px;
        font-size: 16px;
        padding: 6px;
        line-height: 22px;
        height: 33px;
        width: 33px;
        color: #fff;
        display: inline-block;
        text-align: center;
    }
</style>
