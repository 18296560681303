<template>
  <div class="animated fadeIn">
    <template v-if="stat">
      <ibox>
        <ibox-title>
          Рейтинг пользователей
        </ibox-title>
        <ibox-content>
          <b-table striped hover bordered responsive :fields="tableFields" :items="stat">
            <template v-slot:cell(avgMark)="data">
              <b-progress :variant="getVariant(data.item.avgMark * 100)" animated show-progress v-if="data.item.avgMark" :max="100">
                <b-progress-bar :value="data.item.avgMark * 100" :label="`${(data.item.avgMark * 100).toFixed(0)}%`"></b-progress-bar>
              </b-progress>
            </template>
          </b-table>
          <ibox-footer>
            <b-pagination v-if="pagination"
                          v-model="currentPage"
                          :total-rows="pagination.total"
                          :per-page="pagination.limit"
                          aria-controls="stats-table"
                          @input="getData()"
                          prev-text="Назад"
                          next-text="Вперед"
            ></b-pagination>
          </ibox-footer>
        </ibox-content>
      </ibox>
    </template>
    <template v-else>
      <ibox></ibox>
      <ibox-title :hide-tools="true" class="text-center">
        <span class="text-muted">Для получения данных необходимо выбрать проект и нажать &laquo;Применить&raquo;</span>
      </ibox-title>
    </template>
  </div>
</template>

<script>
import bus from "../../bus";
import IboxTitle from "../Ibox/IboxTitle";
import IboxContent from "../Ibox/IboxContent";
import Ibox from "../Ibox/Ibox";
import IboxFooter from "../Ibox/IboxFooter";

export default {
name: "UsersRating",
  components: {IboxFooter, IboxContent, IboxTitle, Ibox},
  props: ['filters'],
  data() {
    return {
      stat: null,
      pagination: {
        limit: 30,
        offset: 0
      },
      currentPage: 1,
      tableFields: [
        {
          key: 'fullName',
          label: 'ФИО'
        },
        {
          key: 'orgName',
          label: 'Организация'
        },
        {
          key: 'regionName',
          label: 'Регион'
        },
        {
          key: 'districtName',
          label: 'Район'
        },
        {
          key: 'avgMark',
          label: 'Средний результат пользователя'
        }
      ],
    }
  },
  methods: {
    getVariant(mark) {
      if(mark <= 50) {
        return 'danger';
      }
      if(mark <= 80) {
        return 'warning';
      }
      return 'primary';
    },
    getData() {
      this.filters.limit = this.pagination ? this.pagination.limit : null;
      this.filters.offset = this.pagination ? (this.currentPage * this.pagination.limit) - this.pagination.limit : null;
      this.axios.post(`/analytics/users`, this.filters).then(res => {
        this.stat = res.data.data;
        this.pagination = res.data.pagination;
      })
    }
  },
  mounted() {
    bus.$on('filter-changed', () => {
      this.getData();
    });
    bus.$on('filter-cleared', () => {
      this.stat = null
    });
  }
}
</script>

<style scoped>

</style>
