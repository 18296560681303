<template>
    <span v-if="test" class="animated fadeInRight">
        <BreadCrumbs :title="test.name" :items="[{title: 'Главная', url: '/#/'}, {title: 'Список тестов', url: '/admin/test'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-content">
                    <test-form
                            :test-preset="test"
                            v-bind:submitting="submitting"
                            v-on:submitting="handleSubmitting($event)"
                            v-on:submitted="handleSubmitResult($event)"
                    ></test-form>
                </div>
                <div class="ibox-footer">
                    <div>
                        <b-button variant="primary" size="lg" @click="handleSubmitting(true)">Сохранить</b-button>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import TestForm from "./TestForm";
    export default {
        name: "TestEdit",
        components: {TestForm, BreadCrumbs},
        data() {
            return {
                test: null,
                submitting: false,
                courses: []
            }
        },
        mounted() {
            this.axios
                .get(`tests/${this.$route.params.id}`)
                .then(res => {
                    const test = res.data;

                    test.questions = test.questions.map(question => {
                        return {
                            ...question,
                            codifiers: question.codifiers.map(c => c.id)
                        }
                    });

                    this.test = {
                        ...test,
                        course: test.course ? test.course.id : null,
                        module: test.module ? test.module.id : null,
                    };
                });
        },
        methods: {
            handleSubmitting(status) {
                this.submitting = status;
            },
            handleSubmitResult() {
                this.submitting = false;
            },
        }
    }
</script>

<style scoped>

</style>
