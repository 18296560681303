<template>
    <ibox>
        <ibox-title>Варианты ответа:</ibox-title>
        <ibox-content>
            <table class="table table-bordered">
                <thead>
                <th>Описание варианта</th>
                <th class="text-center">Правильный вариант?</th>
                <th class="text-center">Действия</th>
                </thead>
                <tbody>
                <tr v-for="(v, key) in task.choice" :key="key">
                    <td class="choose-entity" style="font-size: 120%;">
                        <span v-html="v"></span>
                        <valid-summernote
                                v-if="choiceEditMode[key]"
                                v-model="task.choice[key]">
                        </valid-summernote>
                    </td>
                    <td class="right-answer" style="text-align: center;">
                        <input type="radio" :id="'right_' + id + '_' + key" :name="'right_' + id"
                               :value="key"
                               v-model="rightAnswer"/>
                    </td>
                    <td class="text-center">
                        <b-btn-group>
                            <button class="btn"
                                    :class="{'btn-outline-primary': choiceEditMode[key], 'btn-success': !choiceEditMode[key]}"
                                    style="font-size: 80%"
                                    type="button"
                                    title="Редактировать вариант ответа"
                                    @click="toggleChoice(key)">
                                <i class="fa fa-edit"></i>
                            </button>
                            <button class="btn btn-danger" style="font-size: 80%"
                                    title="Удалить вариант ответа"
                                    type="button"
                                    @click="deleteChoice(key)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </b-btn-group>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="ibox-footer">
                <h4>Новый вариант ответа:</h4>
                <valid-form :submit="addChoice" :button="{style: 'pull-right', text: 'Добавить'}">
                    <valid-summernote v-on:keyup.ctrl.enter="addChoice()" v-model="newEntity"></valid-summernote>
                </valid-form>
            </div>
        </ibox-content>
    </ibox>
</template>

<script>
    import AnswerStructure from "../model/AnswerStructure";
    import ValidSummernote from "../../Validation/ValidSummernote";
    import ConstructorBase from "../ConstructorBase";
    import Ibox from "../../Ibox/Ibox";
    import IboxContent from "../../Ibox/IboxContent";
    import IboxTitle from "../../Ibox/IboxTitle";
    import ValidForm from "../../Validation/ValidForm";
    export default {
        name: "ConstructorOnceAnswer",
        extends: ConstructorBase,
        components: {ValidForm, IboxTitle, IboxContent, Ibox, ValidSummernote},
        mounted () {
            this.rightAnswer = this.answerValue.key[0]
        },
        watch: {
            rightAnswer() {
                if (this.rightAnswer !== null) {
                    let answerStructure = new AnswerStructure()
                    answerStructure.key = [ this.rightAnswer ]
                    this.answer = answerStructure
                    this.giveAnswer()
                }
            }
        }
    }
</script>

<style scoped>

</style>
