<template>
    <div v-if="courseList" class="wrapper wrapper-content animated fadeInRight">
        <div v-if="courseList.length > 0" class="row">
            <div v-for="(course, k) in coursesFinals" v-bind:key="k" :class="gridSystem">
                <div class="ibox">
                    <div class="ibox-content product-box">
                        <div class="product-imitation" :style="{'background-image': course.cover && course.cover.thumbs ? 'url(' + course.cover.thumbs.big + ')' : ''}">
                            <div class="color-overlay"></div>
                            <span>{{course.name}}</span>
                        </div>
                        <div class="product-desc">
                            <div class="pull-right small m-t-xs">
                              <i class="fa fa-users"></i> {{course.enrollCount}}
                            </div>
                            <span :class="'product-price ' + course.status">
                                {{course.statusRu}}
                            </span>
                            <div class="small m-t-xs">
                                {{ course.shortDescription | truncate(100, '...') }}
                            </div>
                            <div v-if="course.status === 'started'" class="small m-t-xs">
                              <i class="fa fa-clock-o"></i> {{ course.startDateTime|formatDate }} - {{ course.endDateTime|formatDate }}
                            </div>
                        </div>
                        <div class="ibox-footer">
                            <b-btn-group class="centered-btn-group">
                                <router-link :to="`course/${course.id}`" class="btn btn-primary"><span class="md-hidden">Смотреть</span> <i class="fa fa-long-arrow-right"></i></router-link>
                                <router-link v-if="$auth.check(['ROLE_SUPER_ADMIN'])" :to="`course/${course.id}/edit`" class="btn btn-info">Изменить <i class="fa fa-edit"></i> </router-link>
                                <router-link v-else-if="$auth.check(['ROLE_ADMIN', 'ROLE_METHODIST']) && $auth.user().company && course.institution && $auth.user().company.id === course.institution.id" :to="`course/${course.id}/edit`" class="btn btn-info">Изменить <i class="fa fa-edit"></i> </router-link>
                                <router-link v-else-if="$auth.check(['ROLE_ADMIN']) && $auth.user().company.id === 1" :to="`course/${course.id}/edit`" class="btn btn-info">Изменить <i class="fa fa-edit"></i> </router-link>
                            </b-btn-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="courseList.length === 0" class="row">
            <b-col md="12">
                <div class="ibox" v-if="emptyText">
                    <div class="ibox-content">
                        <span class="text-muted">
                            <span class="course-none bg-secondary"><i class="fa fa-graduation-cap"></i></span> {{emptyText}}
                        </span>
                    </div>
                </div>
                <div class="ibox" v-else-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST', 'ROLE_CUSTOMER'])">
                    <div class="ibox-content">
                        <span class="text-muted">
                            <span class="course-none bg-secondary"><i class="fa fa-graduation-cap"></i></span>  Вы пока не создали ни одного курса
                        </span>
                    </div>
                </div>
                <div class="ibox" v-else>
                    <div class="ibox-content">
                        <span class="text-muted">
                            Вы пока не записались ни на один курс
                        </span>
                    </div>
                    <div class="ibox-footer">
                        <router-link to="/admin/catalog" class="btn btn-primary btn-block">Перейти в каталог</router-link>
                    </div>
                </div>
            </b-col>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import _ from 'lodash';
    export default {
        name: "CourseList",
        props:['filters', 'grid', 'emptyText'],
        data() {
            return {
                courses: [],
                gridSystem: 'col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6'
            }
        },
        mounted() {

            let loader = Vue.$loading.show(this.loaderStyle());
            this.axios.get('courses-list', {
                params: {
                    'filters[isActive]': true,
                    'filters[isClosed]': false
                }
            }).then(async (res) => {
                this.courses = res.data.data
                loader.hide()
            });
            this.gridSystem  = this.grid ? this.grid : this.gridSystem;
        },
        methods: {
            searchByTitle() {
                return this.orderedCourses.filter(course => {
                    if(!course.name || !course.shortDescription) {
                      return false;
                    }
                    return course.name.toLowerCase().indexOf(this.filters.title.toLowerCase()) > -1 || course.shortDescription.toLowerCase().indexOf(this.filters.title.toLowerCase()) > -1;
                })
            },
            searchByInstitute() {
                return this.orderedCourses.filter(course => {
                    if(course.institution) {
                        return this.filters.institutions.indexOf(course.institution.id) > -1
                    }
                    return false;
                })
            },
            searchByCategory() {
                return this.orderedCourses.filter(course => {
                    if(course.category) {
                        return this.filters.categories.indexOf(course.category.id) > -1
                    }
                    return false;
                })
            },
            searchByTitleAndCodifier() {
                return this.orderedCourses.filter(course => {
                    return course.codifiers.filter(cod => {
                        return this.filters.codifiers.indexOf(cod.id) > -1 && this.searchByTitle()
                    }).length
                })
            }
        },
        computed: {
            hasFilters() {
              return this.filters.title || this.filters.institutions.length || this.filters.categories.length;
            },
            coursesFinals() {
              if(this.hasFilters) {
                return this.courseList;
              }
              return this.orderedCourses;
            },
            orderedCourses: function () {
              return _.orderBy(this.courses, ['cost', 'status', 'endDateTime'], ['asc','desc', 'asc']).filter(course => {
                return course.isActive;
              })
            },
            courseList: function() {
                if(typeof this.filters !== 'undefined' && this.filters !== null) {
                    if(this.filters.title && this.filters.codifiers.length === 0) {
                        return this.searchByTitle()
                    }
                    if(!this.filters.title && this.filters.institutions.length > 0) {
                        return this.searchByInstitute()
                    }
                    if(!this.filters.title && this.filters.categories.length > 0) {
                        return this.searchByCategory()
                    }
                    if(this.filters.title && this.filters.codifiers.length > 0) {
                        return this.searchByTitleAndCodifier()
                    }
                }
                return this.courses;
            }
        }
    }
</script>

<style scoped lang="scss">
    .product-desc {
        height: 150px;
    }
    .product-imitation {
        min-height: 180px;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        span {
            color: #fff;
            z-index: 100;
        }
        .color-overlay {
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .6;
            position: absolute;
        }
    }
    .product-price {
        top: -33px;
        &.finished {
            background-color: #007bff;
        }
        &.started {
            background-color: #dc3545;
        }
        &.open {
            background-color: #17a2b8;
        }
        &.soon {
            background-color: #E6A23C;
        }
        &.finished {
            background-color: #67C23A;
        }
        &.closed {
            background-color: #909399;
        }
    }
    .course-none {
        border-radius: 20px;
        font-size: 16px;
        padding: 6px;
        line-height: 22px;
        height: 33px;
        width: 33px;
        color: #fff;
        display: inline-block;
        text-align: center;
    }
</style>
