<template>
    <div class="setings-item">
        <span>
            {{title}}
        </span>
        <div class="switch">
            <div class="onoffswitch">
                <input type="checkbox" @change="$emit('input', $event.target.checked)" :checked="value" name="collapsemenu" :id="vid" class="onoffswitch-checkbox">
                <label class="onoffswitch-label" :for="vid">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ValidSwitcher",
        props: ['vid', 'title', 'value']
    }
</script>

<style scoped>

</style>
