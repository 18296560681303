<template>
  <div class="gray-bg" style="min-height: 100%;">
    <div class="passwordBox animated fadeInDown">
      <div class="row">
        <div class="col-md-12">
          <div class="ibox-content">
            <h2 class="font-bold">Смена пароля</h2>
            <p>
              Вы запросили восставление пароля. Пожалуйста введите новый пароль
            </p>

            <div class="row">
              <div class="col-lg-12">
                <b-form class="m-t" @submit.prevent="handleSubmit()">
                  <b-form-group class="m-t">
                    <b-input placeholder="Пароль"
                             v-model="form.password"
                             required/>
                  </b-form-group>

                  <button type="submit" class="btn btn-primary block full-width m-b" :disabled="submitting || form.password.length === 0">Сохранить</button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: "ForceChangePassword",
      data() {
        return {
          submitting: false,
          form: {
            password: ''
          },
        }
      },
      methods: {
        handleSubmit() {
          if (this.submitting) {
            return;
          }

          this.submitting = true;

          this.axios.post('change-password', {...this.form})
            .then(res => {
              if (res.status === 200) {
                this.$bvModal.msgBoxOk('Пароль успешно изменен', {
                  title: 'Изменение пароля',
                  hideHeaderClose: false,
                  centered: true
                });

                window.location.href = '/me'
              }
            })
            .finally(() => this.submitting = false)
        },
        checkForm() {
          return true;
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        }
      }
    }
</script>

<style scoped>
  .not-allowed {
    cursor: not-allowed !important;
  }
</style>
