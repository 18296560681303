<template>
    <span>
        <BreadCrumbs :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" title="Ролевая модель"/>
        <div class="wrapper-content wrapper">
        <div class="ibox">
            <div class="ibox-title">
                Ролевая модель
            </div>
            <div class="ibox-content">
                <table class="table table-bordered table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th>Роль</b-th>
                            <b-th>Курс</b-th>
                            <b-th>Модуль</b-th>
                            <b-th>Тест</b-th>
                            <b-th>Элементы модуля</b-th>
                            <b-th>Компетенции</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td class="align-middle"><b-badge variant="primary" size="lg">Методист</b-badge></b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.course.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.course.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.course.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.course.delete"/>
                                       </td>
                                    </tr>
                                </table>
                            </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.module.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.module.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.module.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.module.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.delete"/>
                                       </td>
                                    </tr>
                                    <tr>
                                      <td>
                                          <label>Редактирование веса вопроса</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.updateType"/>
                                      </td>
                                      <td>
                                          <label>Редактирование типа теста</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.updateQType"/>
                                      </td>
                                        <td>
                                          <label>Редактирование компетенций в вопросе</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.test.updateCodifier"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.element.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.element.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.element.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.element.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.codifier.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.codifier.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.codifier.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher v-model="acl.ROLE_METHODIST.codifier.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="align-middle"><b-badge variant="warning" size="lg">Преподаватель</b-badge></b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.course.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.course.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.course.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.course.delete"/>
                                       </td>
                                    </tr>
                                </table>
                            </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.module.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.module.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.module.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.module.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.element.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.element.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.element.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.element.delete"/>
                                       </td>
                                    </tr>
                                    <tr>
                                      <td>
                                          <label>Редактирование веса вопроса</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.updateType"/>
                                      </td>
                                      <td>
                                          <label>Редактирование типа теста</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.updateQType"/>
                                      </td>
                                        <td>
                                          <label>Редактирование компетенций в вопросе</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.updateCodifier"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.codifier.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.codifier.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.codifier.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.codifier.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#f8ac59" v-model="acl.ROLE_TUTOR.test.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="align-middle"><b-badge variant="success" size="lg">Студент</b-badge></b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.course.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.course.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.course.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.course.delete"/>
                                       </td>
                                    </tr>
                                </table>
                            </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.module.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.module.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.module.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.module.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.codifier.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.codifier.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.codifier.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.codifier.delete"/>
                                       </td>
                                    </tr>
                                    <tr>
                                      <td>
                                          <label>Редактирование веса вопроса</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.updateType"/>
                                      </td>
                                      <td>
                                          <label>Редактирование типа теста</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.updateQType"/>
                                      </td>
                                        <td>
                                          <label>Редактирование компетенций в вопросе</label>
                                          <Switcher color="#1c84c6  " v-model="acl.ROLE_USER.test.updateCodifier"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.element.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.element.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.element.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.element.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                            <b-td>
                                <table class="table table-borderless">
                                    <tr>
                                      <td>
                                          <label>Создание</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.create"/>
                                      </td>
                                      <td>
                                          <label>Чтение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.read"/>
                                      </td>
                                        <td>
                                          <label>Изменение</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.update"/>
                                       </td>
                                       <td>
                                          <label>Удаление</label>
                                          <Switcher color="#1c84c6" v-model="acl.ROLE_USER.test.delete"/>
                                       </td>
                                    </tr>
                                </table>
                        </b-td>
                        </b-tr>
                    </b-tbody>
                </table>
            </div>
        </div>
    </div>
    </span>
</template>

<script>
    import ACL from "./defaultAcl";
    import BreadCrumbs from "../BreadCrumbs";
    import Switcher from "../Switcher";
    export default {
        name: "Acl",
        data() {
            return {
                acl: null
            }
        },
        components: {Switcher, BreadCrumbs},
        mounted() {
            let company = this.$auth.user().company;
            if (!company.acl || !company.acl.length) {
                this.acl = ACL;
                this.updateAcl();
            } else {
                this.acl = company.acl
            }
        },
        methods: {
            updateAcl() {
                this.axios.put(`institutions/${this.$auth.user().company.id}`, {
                    acl: this.acl
                }).then(res => {
                    this.$auth.user().company = res.data;
                })
            }
        },
        watch: {
            acl: {
                handler: function () {
                    this.updateAcl();
                },
                deep: true
            }
        }
    }
</script>

<style scoped></style>
