<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <bread-crumbs title="Попытки сдачи тестов" :items="[{title: 'Главная', url: '/#/'}]" />
    <div class="wrapper wrapper-content">
      <div class="ibox">
        <div class="ibox-title">
          <h4>Результаты тестов</h4>
          <div class="row">
            <div class="col-md-4">
              <b-form-group>
                <input type="text" v-model="search" placeholder="Введите Email или ФИО" class="input form-control">
              </b-form-group>
            </div>
            <div class="col-md-4">
              <valid-select
                  v-if="filters"
                  v-model="searchCourse"
                  rules="required"
                  placeholder="Курс"
                  :multiple="false"
                  track-by="id"
                  :custom-label="(opt) => opt.name"
                  :options="filters.map(filter => filter.course)"
              />
            </div>
            <div class="col-md-4">
              <valid-select
                  v-if="searchCourse && initSubSelect"
                  v-model="searchModule"
                  id="selectModule"
                  rules="required"
                  :multiple="false"
                  track-by="id"
                  :custom-label="(opt) => opt.name"
                  :options="searchCourse.modules"
              />
            </div>
          </div>
        </div>
        <div class="ibox-content">
          <b-table responsive bordered :items="items" :fields="fields">
            <template v-slot:cell(action)="data">
              <b-btn size="sm" variant="danger" @click="onDelete(data.item.id)">Удалить</b-btn>
            </template>
          </b-table>
        </div>
        <div class="ibox-footer">
          <b-pagination v-if="pagination"
                        v-model="currentPage"
                        :total-rows="pagination.total"
                        :per-page="pagination.limit"
                        aria-controls="stats-table"
                        @input="load()"
                        prev-text="Назад"
                        next-text="Вперед"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../components/BreadCrumbs.vue";
import ValidSelect from "../components/Validation/ValidSelect.vue";

export default {
  name: "AttemptsList",
  components: {ValidSelect, BreadCrumbs},
  data() {
    return {
      results: [],
      tests: [],
      items: [],
      pagination: null,
      filters: null,
      search: null,
      searchModule: null,
      initSubSelect: true,
      searchCourse: null,
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'user.fullname',
          label: 'ФИО'
        },
        {
          key: 'user.username',
          label: 'Email'
        },
        {
          key: 'test',
          label: 'Тест'
        },
        {
          key: 'course',
          label: 'Курс'
        },
        {
          key: 'module',
          label: 'Модуль'
        },
        {
          key: 'bestResult',
          label: 'Лучший результат'
        },
        {
          key: 'lastResult',
          label: 'Последний результат'
        },
        {
          key: 'action',
          label: 'Действия'
        }
      ],
      currentPage: 1
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      let res = await this.axios.get('tests/results', {
        params: {
          fullname : this.search,
          course: this.searchCourse ? this.searchCourse.id : null,
          module: this.searchModule ? this.searchModule.id : null,
          limit: this.pagination ? this.pagination.limit : null,
          offset: this.pagination ? (this.currentPage * this.pagination.limit) - this.pagination.limit : null
        }
      })
      this.items = res.data.data;
      this.pagination = res.data.pagination;
      this.filters = res.data.filters;
    },
    onDelete(id) {
      this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить попытки? Это дествие необратимо.', {
        title: 'Удаление попыток',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(res => {
            if(res) {
              this.axios.delete('tests/results/' + id).then(() => {
                this.load()
              });
            }
          })
    },
    findResults(query) {
      if(query !== '') {
        this.isRegionsLoading = true;
        this.axios.get(`tests/results`, {
          params: {
            fullname : query,
            course: this.searchCourse ? this.searchCourse.id : null,
            module: this.searchModule ? this.searchModule.id : null,
          }
        }).then(res => {
          this.items = res.data.data;
          this.pagination = res.data.pagination;
          this.filters = res.data.filters;
        });
      } else {
        this.load()
      }
    },
  },
  watch: {
    search(val, oldVal) {
      if(val !== oldVal) {
        this.findResults(val)
      }
    },
    searchCourse: {
      handler() {
        this.initSubSelect = false
        this.searchModule = null
        setTimeout(() => {
          this.initSubSelect = true
          this.load()
        }, 100)
      },
      deep: true
    },
    searchModule: {
      handler() {
        this.load()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>