<template>
    <div class="table-responsive">
        <table class="table question-table">
            <tbody>
            <tr v-for="(value, key) in task.choice" :key="key">
                <td>
                    <p-radio
                            class="p-default p-round p-bigger p-smooth"
                            style="font-size: 200%"
                            color="primary"
                            :id="'one-answer_' + id + '_' + key"
                            :value="key"
                            @input="onChange"
                            v-model="givenAnswer.key[0]">
                    </p-radio>
                </td>
                <td>
                    <div v-html="value" @click="toggle(key)" style="cursor: pointer"></div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";
    export default {
        name: "QuestionOneAnswer",
        extends: QuestionBase,
        methods: {
            toggle (key) {
                this.givenAnswer.key = []
                this.givenAnswer.key.push(key)
            }
        }
    }
</script>

<style scoped>

</style>
