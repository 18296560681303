<template>
  <div class="gray-bg" style="min-height: 100%;">
    <div class="passwordBox animated fadeInDown">
      <div>
        <div style="background: #1ab394; padding: 10px; border-radius: 10px; width: 120px; height: 120px; margin: 0 auto 20px;">
          <img width="100px" src="logo.png"/>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="row">

        <div class="col-md-12">
          <div class="ibox-content">
            <h2 class="font-bold">Забыли пароль?</h2>
            <p>
              Вы получите письмо с инструкциями для смены пароля.
            </p>

            <div class="row">

              <div class="col-lg-12">
                <b-form class="m-t" @submit.prevent="handleSubmit()">
                  <b-form-group class="m-t">
                    <b-input placeholder="Email"
                             v-model="form.email"
                             required/>
                  </b-form-group>

                  <button type="submit" class="btn btn-primary block full-width m-b" :disabled="submitting">Отправить</button>

                  <p class="text-muted text-center"><small>Вспомнили пароль?</small></p>
                  <router-link class="btn btn-sm btn-white btn-block" to="/admin/login">Войти</router-link>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Actions from "../components/User/Actions";

    export default {
      name: "RestorePassword",
      extends: Actions,
      data() {
        return {
          submitting: false,
          form: {
            email: ''
          },
        }
      },
      methods: {
        handleSubmit() {
          if (this.submitting) {
            return;
          }

          this.submitting = true;

          this.axios.post('restore-password', {...this.form})
          .then(() => {
            this.$bvModal.msgBoxOk('Для завершения процедуры восстановления пароля нажмите на ссылку в письме, отправленную на ваш почтовый ящик: ' + this.form.email, {
              title: 'Пожалуйста, проверьте свой почтовый ящик',
              hideHeaderClose: false,
              centered: true
            });

            this.form = {};
          })
          .finally(() => this.submitting = false)
        },
        checkForm() {
          return true;
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        }
      }
    }
</script>

<style scoped>
  .not-allowed {
    cursor: not-allowed !important;
  }
</style>
