<template>
    <div class="animated fadeIn text-center" v-if="testTrackId">
        <template v-for="subtest in test.subtests">
            <template v-for="(specification, specIndex) in subtest.specifications">
                <template v-if="showQuestionHandlers[specification.id]">
                    <span v-for="question in specification.questions" :key="question.id">
                        <a class="btn" style="margin: 0 2px 2px 0;" :class="{
                                  'btn-light': sv[specification.id],
                                  'btn-default':   questionStatuses.get(question.id) === QuestionStatusEnum.wait,
                                  'btn-info':    questionStatuses.get(question.id) === QuestionStatusEnum.pending,
                                  'btn-primary text-white': questionStatuses.get(question.id) === QuestionStatusEnum.answered,
                                  'btn-warning': questionStatuses.get(question.id) === QuestionStatusEnum.postponed,
                                  'btn-success text-white': questionStatuses.get(question.id) === QuestionStatusEnum.correct,
                                  'btn-danger':  questionStatuses.get(question.id) === QuestionStatusEnum.wrong
                                }"
                           @click="goToQuestion(subtest, specIndex, specification, question)">{{ specification.orderCode }}
                        </a>
                      </span>
                </template>
            </template>
        </template>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import { QuestionStatus } from './model/Question'
    export default {
        name: "TestGoto",
        props: {
            testTrackId: {
                type: String
            },
            test: null,
            showQuestionHandlers: null,
            questionStatuses: null,
            sv: null,
            partialDisplay: {
                type: Boolean
            }
        },
        data() {
            return {
                QuestionStatusEnum: QuestionStatus
            }
        },
        methods: {
            showEverything () {
                this.$emit('show-every-question')
            },
            goToQuestion (subtest, specIndex, spec, question) {
                this.$emit('goto-question', { subtest, specIndex, spec, question })
                console.log('goto', { subtest, specIndex, spec, question })
            }
        }
    }
</script>

<style>
    .page-link.bg-default {
        color: #323232;
    }
    .pagination {
        display: -ms-flexbox;
        flex-wrap: wrap;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 0.25rem;
    }
</style>
