<template>
    <div class="animated fadeIn">
        <bread-crumbs :title="`Нейро-диагностика слушателей`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    Данные диагностики
                </ibox-title>
                <ibox-content v-if="results">
                    <b-table striped hover bordered responsive :fields="fields" :items="results">
                        <template v-slot:cell(student.photo)="data">
                            <img v-if="data.item.student.photo" alt="image" :src="data.item.student.photo.thumbs.smaller">
                        </template>
                        <template v-slot:cell(startTime)="data">
                            {{data.item.startTime | formatDate}}
                        </template>
                        <template v-slot:cell(endTime)="data">
                            {{data.item.endTime | formatDate}}
                        </template>
                        <template v-slot:cell(stressAvg)="data">
                            <neuro-result-calc :avg="data.item.stressAvg" :same-avg="data.item.sameStressAvg" />
                        </template>
                        <template v-slot:cell(passionAvg)="data">
                            <neuro-result-calc :avg="data.item.passionAvg" :same-avg="data.item.samePassionAvg" />
                        </template>
                        <template v-slot:cell(cognitiveAvg)="data">
                            <neuro-result-calc :avg="data.item.cognitiveAvg" :same-avg="data.item.sameCognitiveAvg" />
                        </template>
                        <template v-slot:cell(concentrationAvg)="data">
                            <neuro-result-calc :avg="data.item.concentrationAvg" :same-avg="data.item.sameConcentrationAvg" />
                        </template>
                    </b-table>
                </ibox-content>
                <ibox-footer>
                    <b-pagination v-if="pagination"
                                  v-model="currentPage"
                                  :total-rows="pagination.total"
                                  :per-page="pagination.limit"
                                  aria-controls="stats-table"
                                  @input="getData()"
                                  prev-text="Назад"
                                  next-text="Вперед"
                    ></b-pagination>
                </ibox-footer>
            </ibox>
        </div>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import BreadCrumbs from "../BreadCrumbs";
    import NeuroResultCalc from "./NeuroResultCalc";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "NeuroList",
        components: {IboxFooter, NeuroResultCalc, BreadCrumbs, IboxContent, IboxTitle, Ibox},
        data() {
            return {
                results: null,
                pagination: null,
                currentPage: 1,
                fields:  [
                    {
                        key: 'student.photo',
                        label: 'Фото',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'student.fullname',
                        label: 'Студент',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'testName',
                        label: 'Тест',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'result',
                        label: 'Результат',
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'startTime',
                        label: 'Время старта',
                        sortable: true,
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'timeCount',
                        label: 'Времени потрачено, с',
                        sortable: true,
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'stressAvg',
                        label: 'Средняя оценка стресса/отклонение',
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'passionAvg',
                        label: 'Средняя оценка увлеченности/отклонение',
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'cognitiveAvg',
                        label: 'Средняя оценка когнитивной нагрузки/отклонение',
                        tdClass: 'text-center align-middle'
                    },
                    {
                        key: 'concentrationAvg',
                        label: 'Средняя оценка концентрации/отклонение',
                        tdClass: 'text-center align-middle'
                    }
                ]
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                this.axios.get('sdpk-results', {
                    params: {
                        offset: (this.currentPage-1) * 30
                    }
                }).then(res=> {
                    this.results = res.data.data;
                    this.pagination = res.data.pagination;
                })
            },
        }
    }
</script>

<style scoped>
    .table > tbody > tr > td {
        vertical-align: middle;
    }
</style>
