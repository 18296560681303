<template>
  <carousel :per-page="1"
            :per-page-custom="[[800, 1], [1000, 2], [1100, 3], [1400, 4]]"
            :navigation-enabled="myWindow.innerWidth > 500"
            :pagination-enabled="false"
            :center-mode="true"
            :loop="true"
            :navigation-next-label="`<img src='/img/landing/slide-right.png'/>`"
            :navigation-prev-label="`<img src='/img/landing/slide-left.png'/>`">
    <slide v-for="(category, key) in categories" :key="key" class="text-center">
      <router-link :to="`/admin/courses?category=${category.id}`">
        <edu-icon :icon="category.icon" />
        <div class="d-table">
          <div class="d-table-row">
            <div class="d-table-cell">
              {{category.title}}
            </div>
          </div>
        </div>
        <span class="edu-slide-more">
            Подробнее
        </span>
      </router-link>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import EduIcon from "../EduIcon";
export default {
  name: "CategoryList",
  props: ['categories', 'myWindow'],
  components: {
    Carousel, Slide, EduIcon
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/home";
</style>