var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.project && _vm.userProject)?_c('div',{staticClass:"animate fadeIn"},[_c('bread-crumbs',{attrs:{"title":"Назначение тестирования","items":[
          {title: 'Главная', url: '/#/'},
          {title: 'Проекты', url: '/admin/projects'},
          {title: _vm.project.title, url: `/projects/${_vm.project.id}/edit`},
          {title: 'Пользователи проекта', url: `/projects/${_vm.project.id}/project-users`},
        ]}}),_c('div',{staticClass:"wrapper wrapper-content"},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"ibox"},[_c('div',{staticClass:"ibox-title"},[_c('h5',[_vm._v("Назначение тестирования "+_vm._s(_vm.userProject.fullname))])]),(_vm.tests)?_c('div',{staticClass:"ibox-content"},[_c('multiselect',{attrs:{"options":_vm.tests,"multiple":true,"track-by":"id","custom-label":(opt) => opt.name,"openDirection":"bottom","placeholder":"Выберите тест"},model:{value:(_vm.userProject.tests),callback:function ($$v) {_vm.$set(_vm.userProject, "tests", $$v)},expression:"userProject.tests"}})],1):_vm._e(),_vm._m(0)])])])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ibox-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Сохранить")])])
}]

export { render, staticRenderFns }