<template>
    <AddProject v-if="project" :project="project" />
</template>

<script>
    import AddProject from "./AddProject";
    export default {
        name: "EditProject",
        components: {AddProject},
        data() {
            return {
                project: null
            }
        },
        mounted() {
            this.axios.get(`projects/${this.$route.params.id}`).then(res => {
                this.project = res.data;
            })
        }
    }
</script>

<style scoped>

</style>
