<template>
    <div class="animated fadeIn" v-if="test">
        <bread-crumbs title="Прохождение теста" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Моя диагностика', url: '/admin/diagnostic'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-content>
                  <template v-if="testLoadingProgress < 100">
                    <h4>Загрузка теста:</h4>
                    <b-progress :value="testLoadingProgress" variant="primary" :max="100" show-progress animated label=""></b-progress>
                  </template>
                  <b-row v-else>
                        <b-col :md="testIsActive ? 3 : 12">
                            <div id="stopTestModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
                                 class="modal fade text-left">
                                <div role="document" class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 id="exampleModalLabel" class="modal-title">Завершить тест?</h4>
                                            <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Вы действительно хотите завершить тест?<br/><br/>Внимание! При подтверждении тест будет завершен, даже если
                                                у вас остались отложенные или неотвеченные вопросы. (Счетчик отложенных и оставшихся неотвеченных вопросы
                                                приведен слева на панели теста)</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" data-dismiss="modal" class="btn btn-secondary">Отмена</button>
                                            <button type="button" data-dismiss="modal" class="btn btn-primary" @click="finish()">Завершить тест</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul id="test-panel" v-if="testIsActive" class="list-group">
                                <li class="bg-danger text-center list-group-item">
                                    <div id="timer-panel" style="font-size: 2vw;">{{ timerPanelString }}</div>
                                </li>
                                <li class="bg-default text-danger list-group-item text-center">
                                    <a  data-toggle="modal" class="btn bg-danger btn-sm text-white"  data-target="#stopTestModal" id="stopTest" title="Завершить тест">
                                        <span class="fa fa-stop"></span>&nbsp;<span class="description">Завершить тест</span>
                                    </a>
                                </li>
                                <li class="list-group-item">
                                    <a
                                       title="Показать отложенные вопросы (помеченные кнопкой &quot;Ответить позже&quot;)"
                                       @click="showOnlyPostponed()"
                                       class="q-warning">
                                        <b-badge variant="warning">{{ questionsPostponed }}</b-badge>&nbsp;
                                        <span class="description">Отложено вопросов</span>
                                    </a>
                                </li>
                                <li class="list-group-item">
                                    <a
                                       title="Показать список вопросов, на которые Вы еще не ответили (для ответа необходимо нажать кнопку &quot;Готово&quot; под вопросом)"
                                       @click="showOnlyWait()"
                                       class="q-danger">
                                        <b-badge variant="primary">
                                            {{ questionsNotAnswered }}
                                        </b-badge>&nbsp;
                                        <span class="description">Не&nbsp;завершено</span>
                                    </a>
                                </li>
                                <li class="list-group-item">
                                    <a
                                       title="Показать все вопросы"
                                       @click="showEverything()"
                                       class="q-all">
                                        <b-badge variant="secondary">
                                            {{ questionsTotal }}
                                        </b-badge>&nbsp;
                                        <span class="description">Всего вопросов</span>
                                    </a>
                                </li>
                            </ul>
                            <ibox v-if="testIsActive">
                                <ibox-content>
                                    <test-goto
                                            :testTrackId="testTrackId"
                                            :test="test"
                                            :questionStatuses="questionStatuses"
                                            :partialDisplay="partialDisplay"
                                            :sv="sv"
                                            :showQuestionHandlers="showQuestionHandlers"
                                            @show-every-question="showEverything"
                                            @goto-question="goToQuestion"
                                    >
                                    </test-goto>
                                </ibox-content>
                            </ibox>
                        </b-col>
                        <b-col :md="testIsActive ? 9 : 12">
                            <div v-if="test.id"
                                 class="content-inner content-inner-test" style="padding-bottom: 0 !important;">
                                <!-- Page Header-->
                                <header class="page-header" v-if="!testIsActive">
                                    <div class="container-fluid">
                                        <h2 class="no-margin-bottom">{{ test.code }}. {{ test.name }}</h2>
                                    </div>
                                </header>

                                <section id="test-notic" v-if="!testIsActive">
                                    <div class="container-fluid">
                                        <div>
                                            <div>
                                                <div class="card">
                                                    <div class="card-body" v-if="!test.previousRunDate">
                                                        <p v-html="test.description"></p>
                                                        <p v-if="!test.timeText">
                                                            Тестирование ограничено по времени.
                                                            Вам дается <strong>{{ totalTime }} минут на полное прохождение</strong> <strong>теста.</strong>
                                                            Опыт показывает, что этого времени достаточно для того, чтобы спокойно и не торопясь пройти
                                                            тестирование.
                                                        </p>
                                                        <template v-else>
                                                            {{test.timeText.replace("%totalTime%", totalTime)}}
                                                        </template>
                                                        <p v-if="additionalTime > 0">
                                                            <template v-if="!test.timeTextAddon">
                                                                Тем не менее после этого будет добавлено еще {{ additionalTime }} минут дополнительного времени на
                                                                случай, если вам не хватило основного времени для завершения теста.
                                                            </template>
                                                            <template v-else>
                                                                {{test.timeTextAddon.replace("%additionalTime%", additionalTime)}}
                                                            </template>
                                                        </p>
                                                        <template>
                                                            <span v-html="test.text"></span>
                                                        </template>
                                                        <hr/>
                                                        <div class="text-center">
                                                            <button id="showTestIntroductionVideo"
                                                                    @click="startTest"
                                                                    class="btn _btn-circle btn-primary _btn-lg">
                                                                <i class="fa fa-play"></i> Начать тестирование
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body" v-else>
                                                        <h4>(этот тест уже пройден<template v-if="test.previousRunDate"> {{ formatDate(test.previousRunDate) }}</template><template v-if="test.previousRunMark || test.previousRunMark === 0.0"> с оценкой {{ Math.round(test.previousRunMark * 1000) / 10 }}%</template>)</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="test" id="test" v-if="testIsActive">
                                    <div class="container-fluid">
                                        <div v-for="(subtest, skey) in test.subtests" :key="skey">
                                            <div v-for="(specification, specIndex) in subtest.specifications" :key="specIndex">
                                                <div v-if="Array.isArray(specification.questions) && specification.questions.length > 0">
                                                    <div v-if="sv[specification.id]">
                                                        <div class="ibox"
                                                             style="margin-bottom: 0 !important;"
                                                             v-for="(question, qkey) in specification.questions.slice(0, 1)" :key="qkey">
                                                            <div class="ibox-title">
                                                                <a
                                                                   class="more">
                                                                    <h3>
                                                                        <!--
                                                                        enum QuestionStatus :  wait = 1,  pending,  answered, postponed, correct,  wrong
                                                                        -->
                                                                        <span>
                                                                            Вопрос № {{ specification.orderCode }}
                                                                        </span>
                                                                        <span v-if="question.headerText">
                                                                            {{ question.headerText }} {{ question.loh }}
                                                                        </span>
                                                                    </h3>
                                                                </a>
                                                                <div class="pull-right ml-auto">
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.wait" class="badge bg-warning">в процессе</span>
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.pending" class="badge bg-primary">отправка ответа</span>
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.answered"
                                                                          class="badge bg-primary">завершён</span>
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.postponed"
                                                                          class="badge bg-warning">отложен</span>
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.correct"
                                                                          class="badge bg-success">правильный ответ</span>
                                                                    <span v-if="questionStatuses.get(question.id) === QuestionStatusEnum.wrong" class="badge bg-danger">неправильный ответ</span>
                                                                </div>
                                                            </div>
                                                            <div class="ibox-content" :id="'q' + specification.orderCode">
                                                                <div v-if="debugMode"
                                                                     style="color: gray; font-family: monospace; position: absolute; right: 5px; background-color: white; z-index: 10000; padding: 5px; border: solid 1px darkgray;">
                                                                    {{ question.answer }}
                                                                    <br/>
                                                                    {{ question.givenAnswer }}
                                                                    <br/>
                                                                    <span v-if="specification.questionType.code === 9">
                        {{ question.task.options }}
                      </span>
                                                                </div>
                                                                <h4 v-if="question.description" v-html="question.description"></h4>
                                                                <!--
                                                                <p v-if="question.task.options && question.task.options.restrictMax">
                                                                  Максимальное количество верных вариантов данном вопросе: {{ question.task.options.restrictMax }}
                                                                </p>
                                                                -->

                                                                <div v-if="specification.questionType && specification.questionType.code">
                                                                    <question-one-answer
                                                                            v-if="specification.questionType.code === 1 || specification.questionType.code.toString().charAt(0) === '1'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-one-answer>

                                                                    <question-multiple-answer
                                                                            v-if="specification.questionType.code === 2 || specification.questionType.code.toString().charAt(0) === '2'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-multiple-answer>

                                                                    <question-match
                                                                            v-if="specification.questionType.code === 3 || specification.questionType.code.toString().charAt(0) === '3'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-match>

                                                                    <question-match-radio
                                                                            v-if="specification.questionType.code === 7 || specification.questionType.code.toString().charAt(0) === '7'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-match-radio>

                                                                    <question-match-any
                                                                            v-if="specification.questionType.code === 8 || specification.questionType.code.toString().charAt(0) === '8'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-match-any>

                                                                    <question-pool
                                                                        v-if="specification.questionType.code === 9 || specification.questionType.code.toString().charAt(0) === '9'"
                                                                        :id="question.id"
                                                                        :task="question.task"
                                                                        v-model="question.givenAnswer"
                                                                    ></question-pool>

                                                                  <question-open
                                                                      v-if="specification.questionType.code === 5 || specification.questionType.code.toString().charAt(0) === '5'"
                                                                      :id="question.id"
                                                                      :task="question.task"
                                                                      v-model="question.givenAnswer"
                                                                  ></question-open>

                                                                  <question-match-checkbox
                                                                      v-if="specification.questionType.code === 6 || specification.questionType.code.toString().charAt(0) === '6'"
                                                                      :id="question.id"
                                                                      :task="question.task"
                                                                      v-model="question.givenAnswer"
                                                                  ></question-match-checkbox>

                                                                    <!--<question-sequence
                                                                            v-if="specification.questionType.code === 4 || specification.questionType.code.toString().charAt(0) === '4'"
                                                                            :id="question.id"
                                                                            :task="question.task"
                                                                            v-model="question.givenAnswer"
                                                                    ></question-sequence
-->
                                                                </div>
                                                            </div>
                                                            <div :id="'specification_card_' + specIndex" class="ibox-footer">
                                                                <div class="col-sm-12 text-center">
                                                                    <button
                                                                            v-if="!question.task || !question.task.options || !question.task.options.restrictMax || !question.givenAnswer || !question.givenAnswer.key || !Array.isArray(question.givenAnswer.key) || question.givenAnswer.key.length <= +question.task.options.restrictMax"
                                                                            class="btn _btn-circle btn-primary result-final"
                                                                            @click="answer(subtest, specIndex, specification, question)">
                                                                        <i class="fa fa-check"></i> Готово
                                                                    </button> &nbsp;
                                                                    <button class="btn _btn-circle btn-warning result-later"
                                                                            @click="postpone(subtest, specIndex, specification, question)">
                                                                        <i class="fa fa-pause"></i> Ответить позже
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card" v-if="showFinishButton" style="margin-top: 20px;">
                                            <div class="card-header">
                                                <h3>Внимание!</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="form-group row">
                                                    <div class="col-sm-12">
                                                        <div>
                                                            <br/>
                                                            Сейчас вы можете завершить тест и увидеть результаты,
                                                            либо вернуться к каким-либо предыдущим вопросам (используя нижнюю шкалу с номерами вопросов) для проверки ответов или внесения изменений в ответы.
                                                            <br/>
                                                            <br/>
                                                            Даже если вы уже нажали в вопросе кнопку «Готово», вы можете изменить свой ответ, указав новый ответ и снова нажав кнопку «Готово».
                                                            <br/>
                                                        </div>
                                                        <br/>
                                                        <div class="text-center">
                                                            <a
                                                               class="btn btn-lg btn-primary"
                                                               data-toggle="modal"
                                                               data-target="#stopTestModal">
                                                                <i class="fa fa-stop"></i> Завершить тест
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </b-col>
                    </b-row>
                </ibox-content>
            </ibox>
            <test-attempt v-if="!testIsActive" :test-id="$route.params.id"/>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import TestData from "./model/TestData";
    import QuestionStatus from './model/QuestionStatus';
    import moment from "moment";
    import AnswerStructure from "./model/AnswerStructure";
    import QuestionOneAnswer from "./questions/QuestionOneAnswer";
    import TestGoto from "./TestGoto";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import BreadCrumbs from "../BreadCrumbs";
    import QuestionMultipleAnswer from "./questions/QuestionMultipleAnswer";
    import QuestionMatch from "./questions/QuestionMatch";
    import QuestionMatchRadio from "./questions/QuestionMatchRadio";
    import TestAttempt from "../Test/TestAttempt";
    import QuestionMatchAny from "./questions/QuestionMatchAny";
    import QuestionPool from "./questions/QuestionPool";
    import QuestionOpen from "./questions/QuestionOpen";
    import QuestionMatchCheckbox from "./questions/QuestionMatchCheckbox";
    export default {
        name: "Test",
        components: {
          QuestionMatchCheckbox,
          QuestionOpen,
          QuestionPool,
          QuestionMatchAny,
            TestAttempt,
            QuestionMatchRadio,
            QuestionMatch, QuestionMultipleAnswer, BreadCrumbs, IboxContent, Ibox, TestGoto, QuestionOneAnswer},
        props: ['id', 'isMenuVisible'],
        data() {
            return {
                pageTitle: 'Тестирование',
                test: new TestData(),
                testIsActive: false,
                testPanelSizeMini: false,
                secondsRemaining: 0,
                timerPanelString: '00:00:00',
                testLoadingProgress: 0,
                questionStatuses: null,
                QuestionStatusEnum: QuestionStatus,
                questionsNotAnswered: 0,
                questionsPostponed: 0,
                questionsTotal: 0,
                totalTime: 0,
                additionalTime: 0,
                timerRound: 1,
                testTrackId: null,
                setIntervalId: null,
                showFinishButton: false,
                partialDisplay: false,
                debugMode: false,
                showVideoPanel: false,
                startTestError: '',
                sv: {},
                showQuestionHandlers: {}
            }
        },
        mounted () {
            this.loadTest(this.$route.params.id)
            this.questionStatuses = new Map('', QuestionStatus)
        },
        methods: {
            loadTest (id) {
                const self = this
                setInterval(() => {
                    if (self.testLoadingProgress < 90) {
                        self.testLoadingProgress += 5
                    }
                }, 500)
                this.axios.get(`sdpk/get/Test/id/${id}/test.full`)
                    .then(
                        r => {
                          this.axios.get(`sdpk/test-track-result/${id}`).then(res => {
                            let givenAnswers = [];
                            if(res.data.trackId) {
                              self.testTrackId = res.data.trackId
                              self.timerRound = 120
                              self.secondsRemaining = res.data.totalTime
                              self.startTimer()
                              self.showStartTest()
                              givenAnswers = res.data.answers;
                            }
                            self.test = r.data
                            self.test.subtests = this.getRandomQuestions(self.test.subtests)
                            self.test.subtests.forEach(st => {
                              st.specifications = st.specifications.sort((a, b) =>
                                  (a.orderCode < b.orderCode)
                                      ?
                                      -1
                                      :
                                      ((a.orderCode > b.orderCode) ? 1 : 0)
                              )
                              st.specifications.forEach((spec, specIndex) => {
                                Vue.set(self.sv, spec.id, specIndex === 0)
                                Vue.set(self.showQuestionHandlers, spec.id, true)
                                self.questionsTotal += spec.questions.length
                                self.questionsNotAnswered = self.questionsTotal
                                spec.questions.forEach(q => {
                                  if(givenAnswers[q.id]) {
                                    self.questionStatuses.set(q.id, QuestionStatus.answered)
                                    Vue.set(q, 'givenAnswer', givenAnswers[q.id])
                                  } else {
                                    self.questionStatuses.set(q.id, QuestionStatus.wait)
                                    Vue.set(q, 'givenAnswer', new AnswerStructure())
                                  }
                                })
                              })
                            })
                            if(!res.data.trackId) {
                              self.setTime()
                            }
                            self.testLoadingProgress = 100
                          })
                        }
                    )
            },

            getRandomQuestions (subtests) {
                subtests.forEach((subtest, subtestIndex) => {
                    subtest.specifications.forEach((spec, specIndex) => {
                        let count = spec.questions.length
                        subtests[subtestIndex].specifications[specIndex].questions = [spec.questions[Math.floor((Math.random() * count) + 1) - 1]]
                    })
                })
                return subtests
            },

            answer (st, specIndex, spec, question) {
                Vue.set(this.sv, spec.id, false)
                this.toggleNext(st, specIndex, spec)
                this.setQuestionStatus(question, QuestionStatus.answered)
                if (this.testTrackId) {
                    this.axios.post('sdpk/test/answer/' + this.testTrackId, question)
                        .then(r => {
                            Vue.set(question, 'givenAnswerId', r.data.id)
                        })
                        .catch(e => console.error('answer fail', e))
                }
            },

            toggleNext (subtest, specIndex, spec) {
                console.log(spec)
                let nextSpec = subtest.specifications[specIndex + 1]
                if (nextSpec) {
                    if (this.showQuestionHandlers[nextSpec.id]) {
                        Vue.set(this.sv, nextSpec.id, true)
                    } else {
                        this.toggleNext(subtest, specIndex + 1, nextSpec)
                    }
                }
                window.scrollTo(0, 0)
                this.showFinishButton = (specIndex >= subtest.specifications.length - 1)
            },

            goToQuestion (gotoParams) {
                console.log(this.sv)
                for (let key in this.sv) {
                    if (this.sv[key]) {
                        this.sv[key] = false
                    }
                }
                Vue.set(this.sv, gotoParams.spec.id, true)
                window.scrollTo(0, 0)
                this.showFinishButton = (gotoParams.specIndex >= gotoParams.subtest.specifications.length - 1)
            },

            postpone (st, specIndex, spec, question) {
                Vue.set(this.sv, spec.id, false)
                this.toggleNext(st, specIndex, spec)
                this.setQuestionStatus(question, QuestionStatus.postponed)
            },

            showOnlyWithStatus (status) {
                const self = this
                let execOnlyOnceToExposeFirst = true
                this.test.subtests.forEach(st => st.specifications.forEach(spec => {
                    Vue.set(self.showQuestionHandlers, spec.id, false)
                    Vue.set(self.sv, spec.id, false)
                    spec.questions.forEach(q => {
                        if (self.questionStatuses.get(q.id) === status) {
                            Vue.set(self.showQuestionHandlers, spec.id, true)
                            if (execOnlyOnceToExposeFirst) {
                                Vue.set(self.sv, spec.id, true)
                                execOnlyOnceToExposeFirst = false
                            }
                        }
                    })
                }))
                this.partialDisplay = true
            },

            showOnlyPostponed () {
                this.showOnlyWithStatus(QuestionStatus.postponed)
            },

            showOnlyWait () {
                this.showOnlyWithStatus(QuestionStatus.wait)
            },

            showEverything () {
                const self = this
                this.test.subtests.forEach(st => st.specifications.forEach(spec => {
                    Vue.set(self.showQuestionHandlers, spec.id, true)
                }))
                this.partialDisplay = false
            },

            finish () {
                let self = this
                let answeredQuestions = []
                for (let i = 0; i < self.test.subtests.length; i++) {
                    let st = self.test.subtests[i]
                    for (let specIndex = 0; specIndex < st.specifications.length; specIndex++) {
                        let spec = st.specifications[specIndex]
                        Vue.set(self.sv, spec.id, false)
                        Array.prototype.push.apply(answeredQuestions, spec.questions.filter(q => self.questionStatuses.get(q.id) === QuestionStatus.answered))
                    }
                }
                for (let a = 0; a < answeredQuestions.length; a++) {
                    delete answeredQuestions[a].headerText
                    delete answeredQuestions[a].task
                    delete answeredQuestions[a].answer
                    delete answeredQuestions[a].description
                }
                this.$emit('test-finished')
                this.axios.post('sdpk/test/finish/' + this.testTrackId, answeredQuestions)
                    .then(r => {
                        self.$router.push('/admin/test-result/' + r.data.id)
                    })
                    .catch(e => console.error('finish answer fail', e))
                if (this.setIntervalId) {
                    clearInterval(this.setIntervalId)
                }
            },

            breakTestTrack () {
                if(!this.testTrackId) {
                  return false;
                }
                this.axios.post('sdpk/track/break/' + this.testTrackId, { reason: 'beforeunload' })
                    .then(console.log)
                    .catch(console.error)
                setTimeout(() => {
                    this.axios.post('sdpk/track/continue/' + this.testTrackId, { reason: 'tried to break' })
                }, 5000)
                return true
            },

            updateCounts () {
                this.questionsNotAnswered = this.questionsTotal
                this.questionsPostponed = 0
                this.questionStatuses.forEach(qs => {
                    if (qs === QuestionStatus.postponed) {
                        this.questionsPostponed++
                    } else if (qs === QuestionStatus.answered) {
                        this.questionsNotAnswered--
                    }
                })
            },

            setQuestionStatus (question, status) {
                question.status = status

                let qs = new Map(this.questionStatuses)
                qs.set(question.id, question.status)
                this.questionStatuses = qs
                this.updateCounts()
            },

            setTime () {
                for (let i = 0; i < this.test.subtests.length; i++) {
                    if (this.test.subtests[i].durationMinutes) {
                        this.totalTime += this.test.subtests[i].durationMinutes
                    }
                }
                for (let i = 0; i < this.test.subtests.length; i++) {
                    if (this.test.subtests[i].addedMinutes) {
                        this.additionalTime += this.test.subtests[i].addedMinutes
                    }
                }
                this.secondsRemaining = this.totalTime * 60
            },

            startTest () {
                const self = this
                this.showVideoPanel = false
                this.axios.get('sdpk/track/create/' + this.test.id)
                    .then(r => {
                        self.testTrackId = r.data.id
                        self.showStartTest()
                        self.startTimer()
                    })
            },

            showTestIntroductionVideo () {
                this.showVideoPanel = true
                // this.videoState = 1
                this.videoState = 2
                if (!this.test.videoStartIsActive) {
                    this.playerOptions1.sources = {}
                }
                if (this.test.videoStart) {
                    this.playerOptions1.sources[0].src = this.test.videoStart
                    this.playerOptions1.tracks = null
                }
                if (this.test.videoIntro) {
                    this.playerOptions2.sources[0].src = this.test.videoIntro
                    this.playerOptions2.tracks = null
                }

                if (!this.test.videoIntroIsActive) {
                    this.playerOptions2.sources = {}
                }
            },

            onPlayer1Ended () {
                this.videoState = 11
                setTimeout(() => this.videoState = 2, 3000)
            },

            hideVideoPanel () {
                this.showVideoPanel = false
            },

            showStartTest () {
                this.testIsActive = true
                if (!this.isMenuVisible) {
                    this.testPanelSizeMini = true
                }
                this.$emit('test-started', { testCode: this.test.code, testName: this.test.name })
            },

            startTimer () {
                const self = this
                let hour = 0
                let min = 0
                let sec = 0
                let count = this.secondsRemaining
                this.setIntervalId = setInterval(() => {
                    if (count === -1) {
                        clearInterval(self.setIntervalId)
                        if (self.timerRound === 1) {
                            if (self.additionalTime && confirm('Ваше время истекло!\nХотите использовать дополнительное время?')) {
                                self.timerRound--
                                self.secondsRemaining = this.additionalTime * 60
                                self.startTimer()
                            } else {
                                self.finish()
                            }
                        } else {
                            alert('Ваше дополнительное время истекло!')
                            self.finish()
                        }
                    } else {
                        hour = Math.floor(count / 3600)
                        min = Math.floor((count - hour * 3600) / 60)
                        sec = count - hour * 3600 - min * 60
                        self.secondsRemaining = count
                        self.timerPanelString = ((hour < 10 ? '0' : '') + hour + ':' + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec)
                        count--
                    }
                }, 1000)
            },
            toggleDebugMode () {
                this.debugMode = !this.debugMode
            },
            formatDate (d) {
                return moment(d).format('D.MM.YYYY HH:mm')
            }
        }
    }
</script>

<style scoped>
    .list-group {
        margin-bottom: 20px;
    }
    .list-group-item {
        border-radius: 0;
    }
</style>
