<template>
    <div v-if="course.enroll && course.enroll.points >= 0" class="animated fadeInDown">
        <ibox>
            <ibox-title>
                <div class="points-block">
                    Баллы: {{ course.enroll.points }} из {{ course.points }}
                </div>
                Структура курса и мой прогресс
            </ibox-title>
            <ibox-content>
                <div v-for="(module, key) in course.modules" :key="key" :visible="!key ? true : false">
                    <b-row>
                        <b-col>
                            <h3 style="padding-top: 10px;" v-b-toggle="`accordion-${module.id}`" :class="!module.isLocked ? 'text-greened' : ''">
                                <i v-if="module.isLocked" class="fa fa-lock"></i>
                                <i v-else class="fa fa-play"></i>
                                {{module.name}}
                            </h3>
                        </b-col>
                        <b-col md="2">
                            <progress-bar-mini v-if="!module.isLocked" title="Завершено на: " :max="module.points ? module.points : 10" :point="module.moduleResult && module.moduleResult.points ? module.moduleResult.points : 0" />
                        </b-col>
                        <b-col md="2" class="text-right">
                            <span v-if="module.isLocked">
                                Начнется {{ module.startDateTime | formatDate }}
                            </span>
                            <span v-else-if="module.isCurrent">
                                Закончится {{ module.endDateTime | formatDate }}
                            </span>
                            <span v-else>
                                Закончился {{ module.endDateTime | formatDate }}
                            </span>
                            <router-link v-if="!module.isLocked && visible[key]" style="position: absolute; right: 15px; bottom: -40px; z-index: 1000;" class="btn btn-primary" :to="`/admin/course/${course.id}/module/${key+1}`">
                                <i class="fa fa-play-circle"></i> К модулю
                            </router-link>
                        </b-col>
                    </b-row>
                    <div class="element-block">
                        <b-collapse v-model="visible[key]" :id="`accordion-${module.id}`" v-if="!module.isLocked" :visible="!key" accordion="my-accordion">
                            <b-row>
                                <b-col>
                                    <div  v-for="(element, elKey) in module.moduleElements" :key="element.id">
                                        <h4>
                                            <router-link class="element-link" :to="`/admin/course/${course.id}/module/${key + 1}/element/${elKey + 1}`">
                                                <i :class="'fa fa-' + element.type"></i> {{element.name}}
                                                <b-badge class="element-status" v-if="element.elementResult" :variant="getVariant(element.elementResult.status)">{{element.elementResult.statusRu}}</b-badge>
                                            </router-link>
                                        </h4>
                                        <hr v-if="module.moduleElements.length - 1 > elKey" class="hr-line-dashed"/>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </div>
                    <hr/>
                </div>
            </ibox-content>
        </ibox>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";
    import ProgressBarMini from "../Stat/ProgressBarMini";
    export default {
        name: "CourseProgress",
        components: {ProgressBarMini, IboxTitle, IboxContent, Ibox},
        props: ['course'],
        data() {
            return {
                visible: {0: true}
            }
        }
    }
</script>

<style scoped>
    .points-block {
        float: right;
        margin-right: -45px;
    }
    .text-greened {
        color: #1ab394;
        cursor: pointer;
        margin: 0;
    }
    .element-link {
        color: inherit;
    }
    .element-block {
        margin-top: 10px;
        margin-left: 18px;
    }
    .element-status {
        margin-left: 20px;
    }
</style>
