<template>
    <div v-if="moduleData" class="animated fadeIn">
        <b-modal title="Выберите тип элемента" size="lg" :id="'add-element-' + moduleData.id" :hide-footer="true">
            <AddElement :course="course" :module="moduleData"></AddElement>
        </b-modal>
        <b-modal title="Редактировать элемент" size="lg" :id="'edit-element-' + moduleData.id" :hide-footer="true">
            <edit-element v-if="editElement" :course="course" :module="moduleData" :element="editElement"/>
        </b-modal>
        <b-modal title="Добавление результата теста"
                 :hide-footer="true"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 size="xl"
                 :id="'test-results-modal-' + moduleData.id"
                 @close="currentElementType = null">
            <test-user-list v-if="currentElementType === 'test'" :course-id="course.id" :module-element-id="currentElement.id" :test-id="currentElement.test.id"></test-user-list>
        </b-modal>
        <b-modal title="Добавление посещаемости лекции"
                 :hide-footer="true"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 size="xl"
                 :id="'video-results-modal-' + moduleData.id"
                 @close="currentElementType = null"
                 >
            <video-element-user-list v-if="currentElementType === 'video' && currentElement" :course-id="course.id" :element-id="currentElement.id" />
        </b-modal>
        <div class="wrapper wrapper-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ibox ">
                        <div class="ibox-content">
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <div class="file-manager">
                                        <valid-input name="название" label="Название" v-model="moduleData.name" :rules="{ required: true }"/>
                                        <valid-summernote name="Описание" label="Описание модуля" v-model="moduleData.description" />
                                        <b-row>
                                            <b-col>
                                              <label class="col-sm-12 col-form-label">Преподаватель</label>
                                                <multiselect
                                                        v-model="module.tutors"
                                                        :options="tutors.map(tutor => tutor.tutor ? tutor.tutor : tutor)"
                                                        :multiple="true"
                                                        track-by="id"
                                                        :custom-label="getLabel"
                                                        openDirection="bottom"
                                                        placeholder="Выберите преподавателя"
                                                ></multiselect>
                                            </b-col>
                                            <b-col>
                                                <valid-date-range :rules="{}" v-model="moduleData.range" label="Даты обучения"/>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="2">
                                                <valid-checkbox name="Оффлайн" :vid="'isOfflineModule-'+moduleData.id" v-model="moduleData.isOffline"/>
                                            </b-col>
                                            <b-col>
                                                <b-btn @click="deleteModule()" class="pull-right btn-block" variant="danger"><i class="fa fa-trash"></i> Удалить модуль?</b-btn>
                                            </b-col>
                                            <b-col v-if="module.isOffline">
                                                <module-schedule :module="module" :full-mode="true" style-class="btn btn-warning btn-block" />
                                            </b-col>
                                            <b-col md="4">
                                                <b-button type="submit" variant="primary" class="btn-block">
                                                    <i class="fa fa-save"></i> Сохранить
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 animated fadeInRight">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ibox">
                                <div class="ibox-title">
                                    Элементы модуля
                                    <div class="ibox-tools">
                                        <a v-b-tooltip title="Добавить" @click="addElement">
                                            <i class="fa fa-plus"></i> Добавить
                                        </a>
                                    </div>
                                </div>
                                <div class="ibox-content" style="padding: 0;">
                                    <table style="margin: 0" class="table table-striped table-hover">
                                        <b-thead>
                                            <b-tr>
                                                <b-th>
                                                    Тип
                                                </b-th>
                                                <b-th>
                                                    Название
                                                </b-th>
                                                <b-th>
                                                    Длительность
                                                </b-th>
                                                <b-th>
                                                    Уровень сложности
                                                </b-th>
                                                <b-th>
                                                    Действия
                                                </b-th>
                                            </b-tr>
                                        </b-thead>
                                        <draggable @change="onMoveCallback" element="tbody" v-model="moduleData.moduleElements">
                                            <b-tr style="cursor: grabbing" v-for="element in moduleData.moduleElements" :key="element.id">
                                                <b-td class="align-middle">
                                                    <i :class="'fa fa-' + element.type"></i>
                                                </b-td>
                                                <b-td class="align-middle">
                                                    <b-row>
                                                        <b-col>
                                                            {{element.name}}
                                                        </b-col>
                                                        <b-col>
                                                            <EncoderProcess :media="element.file" v-if="element.type === 'film'" />
                                                        </b-col>
                                                    </b-row>
                                                </b-td>
                                                <b-td class="align-middle">{{element.duration.toFixed(2)}}</b-td>
                                                <b-td class="align-middle">{{element.levelRu}}</b-td>
                                                <b-td class="align-middle">
                                                    <b-btn-group>
                                                        <b-btn @click="deleteElement(element)" class="btn btn-danger" v-b-tooltip title="Удалить" variant="sm">
                                                            <i class="fa fa-close"></i>
                                                        </b-btn>
                                                        <b-btn v-if="element.type !== 'list'" class="btn btn-success" @click="onEditElement(element)" v-b-tooltip title="Редактировать" variant="sm">
                                                            <i class="fa fa-edit"></i>
                                                        </b-btn>
                                                        <router-link v-else-if="element.test" class="btn btn-success btn-sm" :to="`/admin/test/${element.test.id}/edit`"><i class="fa fa-edit"></i></router-link>
                                                        <b-btn size="sm" v-b-tooltip title="Внести результаты студента" variant="warning" v-if="element.test && moduleData.isOffline" @click="showTestUserListModal(element)">
                                                            <i class="fa fa-user-circle-o"></i>
                                                        </b-btn>
                                                        <b-btn size="sm" v-b-tooltip title="Скачать тест" variant="info" v-if="element.test && moduleData.isOffline" @click="printTest(element.test.id)">
                                                            <i class="fa fa-print"></i>
                                                        </b-btn>
                                                        <b-btn size="sm" v-b-tooltip title="Внести посещаемость" variant="warning" v-if="element.type === 'film' && moduleData.isOffline" @click="showVideoUserListModal(element)">
                                                            <i class="fa fa-user-circle-o"></i>
                                                        </b-btn>
                                                    </b-btn-group>
                                                </b-td>
                                            </b-tr>
                                        </draggable>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from "../../bus";
    import draggable from 'vuedraggable';
    import AddElement from "../../AddElement";
    import EditElement from "./EditElement";
    import ValidInput from "../Validation/ValidInput";
    import ValidSummernote from "../Validation/ValidSummernote";
    import ValidDateRange from "../Validation/ValidDateRange";
    import EncoderProcess from "../EncoderProcess";
    import TestUserList from "../Test/TestUserList";
    import ValidCheckbox from "../Validation/ValidCheckbox";
    import VideoElementUserList from "./VideoElementUserList";
    import ModuleSchedule from "../Schedule/ModuleSchedule";
    export default {
        name: "ModuleCreate",
        components: {
            ModuleSchedule,
            VideoElementUserList,
            ValidCheckbox,
            TestUserList,
            EncoderProcess, ValidDateRange, ValidSummernote, ValidInput, EditElement, AddElement, draggable},
        props: ['module', 'tutors', 'course'],
        data() {
            return {
                moduleData: null,
                editElement: null,
                currentElement: null,
                currentElementType: null
            }
        },
        mounted() {
            this.moduleData = Object.assign({}, this.module);
            if(this.moduleData.range) {
                this.moduleData.startDateTime = this.moduleData.range[0];
                this.moduleData.endDateTime = this.moduleData.range[1];
            }

            bus.$on('add-module-element', element => {
                if(element.type !== 'list') {
                    this.$bvModal.hide('add-element-' + this.moduleData.id);
                }
                if(!this.moduleData.moduleElements) {
                    this.moduleData.moduleElements = []
                }
                this.moduleData.moduleElements.push(element)
            })
        },
        methods: {
            getLabel(opt) {
              return opt.tutor ? opt.tutor.fullname : opt.fullname
            },
            addElement() {
                this.$bvModal.show('add-element-' + this.moduleData.id)
            },
            deleteElement(element) {
                this.axios.delete(`module_elements/${element.id}`).then(() => {
                    this.moduleData.moduleElements.splice(this.moduleData.moduleElements.indexOf(element), 1)
                })
            },
            onSubmit() {
                if(this.moduleData.range) {
                    this.moduleData.startDateTime = this.moduleData.range[0];
                    this.moduleData.endDateTime = this.moduleData.range[1];
                }
              let module = Object.assign({}, this.moduleData);
               module.tutors = this.module.tutors.map((tutor) => {
                 return {
                   id: tutor.id
                 }
               })
                this.axios.put(`modules/${this.moduleData.id}`, module).then(() => {
                    this.$toasted.global.appSuccess()
                })
            },
            printTest(testId) {
                window.open(`${this.axios.defaults.baseURL}pdf/test/${testId}?access_token=${this.$auth.token()}`, 'Download');
            },
            onEditElement(element) {
                this.editElement = element;
                this.$bvModal.show('edit-element-' + this.moduleData.id)
            },
            onMoveCallback() {
                this.moduleData.moduleElements = this.moduleData.moduleElements.map((element, key) => {
                    element.position = key + 1
                    return element;
                })
                this.onSubmit();
            },
            showTestUserListModal(element) {
                this.currentElement = element;
                this.currentElementType = 'test';
                this.$bvModal.show(`test-results-modal-${this.moduleData.id}`);
            },
            showVideoUserListModal(element) {
                this.currentElement = element;
                this.currentElementType = 'video';
                this.$bvModal.show(`video-results-modal-${this.moduleData.id}`);
            },
            deleteModule() {
                this.$bvModal.msgBoxConfirm('Удаление модуля «' + this.moduleData.name + '»', {
                    title: 'Вы уверены, что хотите удалить модуль? Это дествие необратимо.',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(res => {
                        if(res) {
                            this.axios.delete('/modules/'+this.moduleData.id)
                                .then(() => {
                                    this.$toasted.global.appSuccess('Модуль успешно удален!')
                                    bus.$emit('module-delete', this.moduleData);
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.$toasted.global.appError('Ошибка! В модуле уже есть студенты!')
                                })
                        }
                    })
            },
        }
    }
</script>

<style scoped>

</style>
