<template>
    <div class="ibox">
        <ibox-title>Обсуждение элемента</ibox-title>
        <div class="ibox-content">
            <channel-view :channel="chatChannelKey" :hide-title="true" />
        </div>
    </div>
</template>

<script>
    import IboxTitle from "../Ibox/IboxTitle";
    import ChannelView from "../Profile/ChannelView";
    export default {
        name: "ModuleElementChatbox",
        components: {ChannelView, IboxTitle},
        props: ['elementId'],
        computed: {
            chatChannelKey() {
                return `cme${this.elementId}`
            }
        }
    }
</script>

<style scoped>

</style>
