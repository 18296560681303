<template>
    <div v-if="events" class="ibox">
        <ibox-title>Расписание</ibox-title>
        <div class="ibox-content">
            <FullCalendar
                    defaultView="dayGridMonth"
                    :plugins="calendarPlugins"
                    @dateClick="handleDateClick"
                    locale="ru"
                    :title-format="{ year: 'numeric', month: 'long'}"
                    :button-text="{today: 'Сегодня', 'prev': 'Назад', 'next': 'Вперед'}"
                    :header="{center: '', left: 'title', right:  'today prev,next'}"
                    :weekends="true"
                    :events="events"
            />
        </div>
    </div>
</template>

<script>
    import 'fullcalendar/dist/fullcalendar.css';
    import FullCalendar from '@fullcalendar/vue'
    import IboxTitle from "../Ibox/IboxTitle";
    import dayGridPlugin from '@fullcalendar/daygrid';
    export default {
        name: "CourseCalendar",
        components: {IboxTitle, FullCalendar},
        props: ['course'],
        data() {
            return {
                calendarPlugins: [ dayGridPlugin ],
                events: []
            }
        },
        mounted() {
            this.course.modules.forEach(module => {
                this.events.push({ title: module.name, start: module.startDateTime, end: module.endDateTime, color: module.isOffline ? '#3788D8' : '#1ab394' })
            })
        },
        methods: {
            handleDateClick(arg) {
                console.log(arg)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/core/main.css';
    .fc-toolbar h2 {
        font-size: 16px!important;
        &::first-letter {
            text-transform: uppercase;
        }
    }
    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 10px;
    }
    .fc-button .fc-button-primary {
        background: #1ab394;
    }
</style>
