<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <bread-crumbs title="Новости портала" :items="[{title: 'Главная', url: '/#/'}]" />
    <b-modal v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" id="add-news" :hide-footer="true" title="Добавить новость">
      <form @submit.prevent="submit()">
        <valid-input v-model="form.title" label="Название" name="название" rules="required|min:3"/>
        <valid-input v-model="form.link" label="Ссылка" name="url" rules="required|min:3"/>
        <b-btn variant="success" type="submit">Сохранить</b-btn>
      </form>
    </b-modal>
    <div class="wrapper wrapper-content">
      <div class="ibox">
        <div class="ibox-title">
          <div class="d-flex justify-content-between">
            <h4>Новости</h4>
            <b-btn v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" v-b-modal.add-news class="btn btn-primary mb-2">Добавить новость</b-btn>
          </div>
          <div class="ibox-content">
            <b-table responsive bordered :items="items" :fields="fields">
              <template v-slot:cell(action)="data">
                <b-btn size="sm" variant="danger" @click="data.item.id">Удалить</b-btn>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../components/BreadCrumbs.vue";
import ValidInput from "../components/Validation/ValidInput.vue";

export default {
  name: "NewsList",
  components: {ValidInput, BreadCrumbs},
  data() {
    return {
      results: [],
      form: {},
      items: [],
      pagination: null,
      filters: null,
      search: null,
      searchModule: null,
      initSubSelect: true,
      searchCourse: null,
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'title',
          label: 'Название'
        },
        {
          key: 'link',
          label: 'Ссылка'
        }
        ,
        {
          key: 'action',
          label: 'Действия'
        }
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    submit() {
      this.axios.post('news', this.form).then(() => {
        this.form = {}
        this.$bvModal.hide(`add-news`);
        this.load()
      })
    },
    async load() {
      let res = await this.axios.get('news')
      this.items = res.data;
    },
  },
}
</script>

<style scoped>

</style>