<template>
  <div class="animated fadeInRight">
    <BreadCrumbs title="Профиль" :items="[{title: 'Главная', url: '/#/'}]" />
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="row m-b-lg m-t-lg">
        <div class="col-md-6">
          <div class="profile-image">
            <FileUpload v-model="photo">
              <img v-if="photo" :src="photo.thumbs.normal" class="rounded-circle circle-border m-b-md" alt="profile">
              <img v-else :src="'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==\n'" class="rounded-circle circle-border m-b-md" alt="profile">
            </FileUpload>
          </div>
          <div class="profile-info">
              <div>
                <h2 class="no-margins">
                  {{ $auth.user().fullname }}
                  <a href="#" @click="showSettings = !showSettings">
                    <i v-if="!showSettings" class="fa fa-edit"></i>
                    <i v-else class="fa fa-close"></i>
                  </a>
                </h2>
                <h4>{{ $auth.user().roleRu }}</h4>
                <p class="small font-bold">
                  <span><i class="fa fa-circle text-navy"></i> На сайте</span>
                </p>
              </div>
          </div>
        </div>
        <div class="col-md-3">
          <table v-if="stat" class="table small m-b-xs">
            <tbody>
            <tr>
              <td>
                <strong>{{stat.courses}}</strong> {{stat.courses|pluralize('ru', ['Курс пройден', 'Курса пройдено', 'Курсов пройдено'])}}
              </td>
              <td>
                <strong>{{stat.tests}}</strong> {{stat.tests|pluralize('ru', ['Тест сдан', 'Теста сдано', 'Тестов сдано'])}}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{stat.videos}}</strong> {{stat.videos|pluralize('ru', ['Видео просмотрено', 'Видео просмотрено', 'Видео просмотрено'])}}
              </td>
              <td>
                <strong>{{stat.modules}}</strong> {{stat.modules|pluralize('ru', ['Модуль пройден', 'Модуля пройдено', 'Модулей пройдено'])}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="$auth.user().rank" class="col-md-3">
          <small>Место в рейтинге:</small>
          <h2 class="no-margins">{{$auth.user().rank}}</h2>
          <sparkline/>
        </div>
      </div>
      <b-modal v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" id="add-course" :hide-footer="true" title="Добавить новый курс">
        <CourseForm></CourseForm>
      </b-modal>
      <div v-if="!showSettings" class="row">
        <div class="col-xl-3">
          <h3 class="about">Обо мне</h3>
          <div class="ibox">
            <div v-if="$auth.check('ROLE_ADMIN') || $auth.user().companies.length" class="ibox-content">
              <h3>Состою в организациях:</h3>
              <div v-if="$auth.check('ROLE_ADMIN') && !$auth.user().companies.length" class="user-friends">
                <a v-b-tooltip title="МИИГАиК" href="#"><img alt="image" class="rounded-circle" src="/logo-rounded.png"></a>
              </div>
              <div v-else class="user-friends">
                <a v-for="company in $auth.user().companies" :key="company.id" @click="changeCompany(company)" :class="$auth.user().company.id === company.id ? 'current' : null">
                  <img alt="image" class="rounded-circle" v-b-tooltip :title="company.name" :src="srcThumb(company.logo, 'small')">
                </a>
              </div>
              <hr class="hr-line-dashed"/>
            </div>
            <div v-if="certificates.length > 0">
              <h3>Выданные сертификаты</h3>
              <ul class="list-unstyled file-list">
                <li v-for="(cert, key) in certificates" :key="key">
                  <a target="_blank" :href="'https://api.universitet-sng.ru/cert/' + cert.id">
                  <i class="fa fa-certificate"></i> {{ cert.name }}</a>
                </li>
              </ul>
            </div>

            <div v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_CUSTOMER']) && $auth.user().company" class="ibox-content">
              <router-link class="btn btn-primary btn-block" :to="`institution/${$auth.user().company.id}`">
                <i class="fa fa-building"></i> Редактировать организацию
              </router-link>
              <hr class="hr-line-dashed"/>
              <router-link class="btn btn-primary btn-block" :to="`/admin/acl`">
                <i class="fa fa-universal-access"></i> Ролевая модель
              </router-link>
            </div>
          </div>
          <h3 style="margin-bottom: 20px;">Активность</h3>
          <ActionsProfile/>
        </div>
        <div class="col-xl-9">
          <h3 style="margin-bottom: 0;">Мои курсы</h3>
          <CourseInProfileList/>
        </div>
      </div>
      <Settings :subscribe="subscribe" v-else/>
    </div>
  </div>
</template>
<script>
    import BreadCrumbs from "./BreadCrumbs";
    import FileUpload from "./FileUpload";
    import Settings from "./User/Settings";
    import Actions from "./User/Actions";
    import bus from "../bus";
    import CourseForm from "./Course/CourseForm";
    import CourseInProfileList from "./Course/CourseInProfileList";
    import ActionsProfile from "./User/ActionsProfile";
    import Sparkline from "./Inspinia/Sparkline";
    export default {
      name: "Profile",
      extends: Actions,
      components: {
        Sparkline,
        ActionsProfile,
        CourseInProfileList, CourseForm, Settings, FileUpload, BreadCrumbs},
      data() {
          return {
            photo: this.$auth.user().photo,
            showSettings: !!this.$route.query.settings,
            subscribe: this.$auth.user().postUserSubscribe,
            certificates: [],
            stat: null
          }
        },
      mounted() {
        this.axios.get('course-stat').then(res => {
          this.stat = res.data;
        });
        bus.$on('settings-save', () => {
          this.showSettings = false
        });
        if(!this.subscribe) {
          this.subscribe = {
            subscriber: this.$auth.user().id,
            isNews: true,
            isCourseNews: true,
            isChatMessaging: true
          }
          this.axios.post(`post_user_subscribes`, this.subscribe).then(res => {
            this.subscribe = res.data;
          });
        }

        this.axios
                .get('certificates/my')
                .then(res => {
                  this.certificates = res.data;
                });
      },
      methods: {
        updateSubscribes() {
          if(this.subscribe.id) {
            this.axios.put(`post_user_subscribes/${this.subscribe.id}`, this.subscribe);
          }
        },
        changeCompany(company) {
          this.axios.put('users/' + this.$auth.user().id , {currentInstitution: company.id}).then(res => {
            this.$auth.user(res.data);
            this.$toasted.global.appSuccess('Компания успешно изменена на ' + company.shortName)
            bus.$emit('company:change')
          })
        }
      },
      watch: {
        photo: function (newVal, oldVal) {
          if(newVal !== oldVal) {
            this.axios.put('users/' + this.$auth.user().id , {photo: newVal}).then(res => {
              this.$auth.user().photo = res.data.photo;
              this.$toasted.global.appSuccess('Фото профиля успешно изменено')
            })
          }
        },
        subscribe: {
          handler() {
            this.updateSubscribes()
          },
          deep: true
        }
      }
    }
</script>

<style lang="scss">
  .about {
    margin: 20px 0 20px 5px;
  }
  .no-margins {
    a > i {
      text-decoration: none;
      color: #676a6c;
    }
  }
  .current {
    display: inline-block;
    border: 5px solid #18a689;
    text-align: center;
    margin-right: 5px;
    border-radius: 50%!important;
    img {
      margin: 0;
    }
  }
</style>
