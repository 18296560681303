<template>
    <el-upload
            :show-file-list="false"
            :action="uploadAction"
            :multiple="hasMultiple"
            :accept="acceptData"
            :http-request="uploadFiles"
            :file-list="fileList"
            :headers="{Authorization: 'Bearer ' + this.$auth.token()}"
            :on-success="handleUploadSuccess">
        <el-progress v-if="uploadPercentage > 0 && uploadPercentage < 100" :percentage="uploadPercentage"></el-progress>
        <slot></slot>
    </el-upload>
</template>

<script>
    export default {
        name: "FileUpload",
        props: ['value', 'accept', 'actionForUpload', 'disableAccept', 'multiple', 'enableHttp'],
        data() {
            return {
                fileList: [],
                uploadPercentage: 0,
                uploadAction: typeof this.actionForUpload !== 'undefined' && this.actionForUpload !== null ? this.actionForUpload : this.axios.defaults.baseURL + 'media_objects',
                hasMultiple: this.multiple || false,
                acceptData: this.disableAccept ? '' : (!this.accept
                    ? "image/jpeg,image/gif,image/png"
                    : this.accept)
            }
        },
        methods: {
            handleUploadSuccess(res) {
                this.$emit('input', res)
            },
            uploadFiles(file) {
                console.log(file);
                //Create new formData object
                const fd = new FormData();

                //append the file you want to upload
                fd.append("file", file.file);

                //send call the api to upload files using axios or any other means
                this.axios.post(this.uploadAction, fd,
                    {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
                            }
                            .bind(this)
                }).then(res => {
                    this.handleUploadSuccess(res.data);
                });
            },
        }
    }
</script>

<style scoped>
</style>
