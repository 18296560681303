<template>
    <div v-if="course" class="animated fadeInRight">
        <div class="wrapper">
            <div class="row">
                <div class="col-md-12">

                    <div class="ibox">
                        <div class="ibox-title">
                            <span class="float-right">(<strong>{{ module.moduleElements.length }}</strong>) {{ module.moduleElements.length|pluralize('ru', ['элемент', 'элемента', 'элементов']) }}</span>
                            <h5>Элементы модуля</h5>
                        </div>
                        <div v-for="(element, key) in module.moduleElements" :key="key" class="ibox-content">
                            <div class="table-responsive">
                                <table class="table shoping-cart-table">
                                    <tbody>
                                        <tr>
                                            <td width="90">
                                                <div class="text-muted" style="font-size: 60px;" :class="'fa fa-' + element.type"></div>
                                            </td>
                                            <td class="desc">
                                                <h3>
                                                    <router-link :to="`/admin/course/${course.id}/module/${module.position}/element/${key + 1}`" class="text-navy">
                                                        {{element.name}}
                                                    </router-link>
                                                </h3>
                                                <p v-html="element.description"></p>
                                                <dl v-if="false" class="m-b-none">
                                                    <dt v-if="element.type !== 'list'">
                                                        <i class="fa fa-clock-o"></i> На изучение требуется {{element.duration}} {{ element.duration | pluralize('ru', ['час', 'часа', 'часов']) }}
                                                    </dt>
                                                    <dt v-else>
                                                        <i v-if="element.test" class="fa fa-clock-o"></i> На прохождение требуется {{element.test.duration}} {{ element.duration | pluralize('ru', ['минута', 'минуты', 'минут']) }}
                                                    </dt>
                                                </dl>

                                                <div class="m-t-sm">
                                                    <router-link :to="`/admin/course/${course.id}/module/${module.position}/element/${key  + 1}`" class="btn btn-sm btn-primary"><i :class="'fa fa-' + element.type"></i> Приступить</router-link>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <b-badge class="pull-right" v-if="element.elementResult" :variant="getVariant(element.elementResult.status)">{{element.elementResult.statusRu}}</b-badge>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from "../../bus";
    export default {
        name: "Module",
        data() {
            return {
                elements: null,
                module: null,
                index: null,
                elementIndex: 0,
                course: null,
                options:{
                    height:'100%'
                }
            }
        },
        mounted() {
            this.load()
            if (!this.$auth.check() || !this.course.enroll) {
              this.$router.push(`/admin/course/${this.$parent.course.id}`).catch(() => {});
              return;
            }
        },
        methods: {
            load() {
                this.index = this.$route.params.index;
                this.elementIndex = 0;
                let index = this.index - 1;
                this.course = this.$parent.course;
                this.elements = this.course.modules[index].moduleElements;
                this.module = this.course.modules[index];
                this.$parent.items = [
                    {title: 'Главная', url: '/#/'},
                    {title: 'Каталог курсов', url: '/admin/catalog'},
                    {title:  'Курс &laquo;' + this.course.name + '&raquo;', url: '/admin/course/' +this.course.id}
                ];
                this.$parent.title = `Модуль ${index+1}. ` + this.module.name;
                let event = new Event("route-change");
                document.dispatchEvent(event)
            },
            setElement(index) {
                this.elementIndex = index;
                bus.$emit('pause');
            }
        },
        watch: {
            '$route.params.index': function () {
                this.load()
            }
        },
    }
</script>

<style scoped>

</style>
