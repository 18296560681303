<template>
    <span class="animated fadeIn">
        <div v-if="sameAvg && avg">
            <b-badge :variant="getVariant()">
                {{avg}}/{{Math.abs(sameAvg - avg).toFixed(2)}}
            </b-badge>
        </div>
        <div v-else>
            <b-badge variant="default">
                Данных нет
            </b-badge>
        </div>
    </span>
</template>

<script>
    export default {
        name: "NeuroResultCalc",
        props: ['avg', 'sameAvg'],
        methods: {
            getVariant() {
                let calc = 0;
                if(!this.sameAvg) {
                    return 'default';
                }
                calc = 100 * Math.abs(this.sameAvg - this.avg)/this.sameAvg;
                if(calc <= 5) {
                    return 'primary';
                } else if(calc > 5 && calc <= 10) {
                    return 'warning';
                }else if(calc > 10){
                    return 'danger';
                }

                return 'default';

            }
        }
    }
</script>

<style scoped>

</style>
