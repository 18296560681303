import TestCategory from './TestCategory';
export default class Test {
    constructor() {
        this.id = '';
        this.systemId = '';
        this.code = '';
        this.name = '';
        this.ready = true;
        this.runOnlyOnce = null;
        this.cannotRunAnyMore = null;
        this.previousRunDate = null;
        this.previousRunMark = null;
        this.description = '';
        this.timeText = '';
        this.timeTextAddon = '';
        this.videoIntro = null;
        this.videoStart = null;
        this.videoIntroIsActive = true;
        this.videoStartIsActive = true;
        this.text = '';
        this.redZonePercent = null;
        this.yellowZonePercent = 50;
        this.greenZonePercent = 80;
        this.category = new TestCategory();
        this.type = {};
        this.subtests = [];
        this.subjects = [];
    }
}
