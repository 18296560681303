<script>
import axios from 'axios';
export default {
  name: "Mask",
  mounted() {
    let instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    });
    instance.post('token/refresh', {
      refresh_token: this.$route.params.token
    }).then(res => {
      let response = res.data;
      localStorage.setItem('refresh_token', response.refresh_token);
      localStorage.setItem('default_auth_token', response.token);
      window.location.href = '/admin/profile';
    })
  }
}
</script>

<style scoped>

</style>
