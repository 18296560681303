<template>
  <div>Loading..</div>
</template>

<script>
    import Cookies from "js-cookie";

    const LinkType = {
        restorePassword: 'restore_password',
        confirmEmail: 'confirm_email',
    };

    export default {
        name: "LinkHandler",
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            this.handleToken(token);
        },
        methods: {
            handleToken(token) {
                this.axios
                    .post(`link`, {token})
                    .then(res => {
                        let {status, data} = res;
                        if (status !== 200) {
                            // this.fallbackRedirect();
                            return;
                        }

                        if (data.authData && data.authData.token) {
                            const tokenData = data.authData;
                            this.$auth.token('default_auth_token', tokenData.token);
                            this.$auth.token('refresh_token', tokenData.refresh_token);

                            // nice vue-auth guard
                            Cookies.set('rememberMe', true, {expires: 30});
                        }

                        if (data.type === LinkType.restorePassword) {
                            window.location = `${location.protocol}//${location.host}/admin/profile/change-password`;
                            return;
                        }

                        window.location = data.redirectUri || `${location.protocol}//${location.host}/profile`;
                    })
                    .catch(() => this.fallbackRedirect())
            },
            fallbackRedirect() {
                this.$router.push('/admin/');
            }
        }
    }
</script>
