<template>
    <div class="ibox">
        <div class="ibox-content">
            <b-row v-if="showBtn">
                <b-col md="8">
                    <h3>Поделись с друзьями</h3>
                    <yandex-share :services="['vkontakte','facebook','twitter','collections']" />
                </b-col>
                <b-col v-if="courseData.id !== 383" md="4">
                    <b-btn @click="hasEnroll()" style="margin-top: 20px;" class="btn btn-primary btn-block">Записаться на курс</b-btn>
                    <b-btn v-if="courseData && courseData.cost" style="margin-top: 10px;" @click="hasEnroll()" type="button" variant="warning"  class="btn-block">
                        Стоимость курса: {{ courseData.cost }}руб.
                    </b-btn>
                </b-col>
            </b-row>
            <div v-else>
                <h3>Поделись с друзьями</h3>
                <yandex-share :services="['vkontakte','facebook','twitter','collections']" />
            </div>
        </div>
    </div>
</template>

<script>
    import YandexShare from '@cookieseater/vue-yandex-share';
    export default {
        name: "LikeButtons",
        components: {YandexShare},
        props: ['showBtn', 'courseData'],
        methods: {
            hasEnroll() {
                localStorage.setItem('has-enroll', this.$route.params.id);
                this.$router.push('/admin/login').catch(() => {});
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-bottom: 20px;
    }
</style>
