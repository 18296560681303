<template>
    <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(onSubmit)">
            <b-form-group label="Логотип">
                <AvatarUpload></AvatarUpload>
            </b-form-group>
            <validation-provider name="Название" :rules="{ required: true, min: 10 }" v-slot="validationContext">
                <b-form-group
                        label="Название"
                >
                    <b-form-input
                            id="name-input"
                            v-model="course.name"
                            :state="valid(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <ValidSummernote name="описание" label="Описание" v-model="course.description" />
            <ValidCheckbox hint="Курс будет доступен только сотрудникам компании" v-model="course.isClosed" vid="isClosed" name="Скрытый курс" />
            <hr class="hr-line-dashed"/>
            <b-button variant="primary" type="submit">Сохранить</b-button>
        </b-form>
    </validation-observer>
</template>

<script>
    import bus from '../../bus';
    import AvatarUpload from "../AvatarUpload";
    import ValidCheckbox from "../Validation/ValidCheckbox";
    import ValidSummernote from "../Validation/ValidSummernote";
    export default {
        name: "CourseForm",
        components: {ValidSummernote, ValidCheckbox, AvatarUpload},
        data() {
            return {
                imageUrl: null,
                course: {}
            }
        },
        mounted() {
            bus.$on('avatar-upload', res => {
                this.course.cover = res
            })
        },
        methods: {
            onSubmit() {
                this.axios
                    .post('courses', this.course)
                    .then(res => {
                        if(res.status === 201) {
                            this.$router.push('course/' + res.data.id + '/edit').catch(() => {});
                        }
                    })
            }
        }
    }
</script>

<style>
    .upload-video .el-upload {
        display: block;
    }
</style>
