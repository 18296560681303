<template>
    <div v-if="user && logs" class="animated fadeIn">
        <BreadCrumbs :title="`Статистика пользователя ${user.fullname}`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Список пользователей', url: '/admin/users-stats'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    <b-row>
                        <b-col lg="4">
                            <multiselect
                                    v-model="course"
                                    :multiple="true"
                                    :searchable="true"
                                    placeholder="Выберите курс"
                                    :options="courses.map(c => c.id)"
                                    :custom-label="prepareCourses"
                            ></multiselect>
                        </b-col>
                    </b-row>
                </ibox-title>
                <ibox-content>
                    <div class="clients-list">
                        <b-table striped hover bordered :fields="fields" :items="logs">
                            <template v-slot:cell(point)="data">
                                <b-badge :variant="data.item.point > 0 ? 'primary' : 'danger'" v-if="data.item.point"><span v-if="data.item.point > 0">+</span>{{data.item.point}}</b-badge>
                            </template>
                            <template v-slot:cell(course)="data">
                                <span v-if="data.item.course">{{data.item.course.name}}</span>
                                <span v-else-if="data.item.module && data.item.module.course">{{data.item.module.course.name}}</span>
                                <span v-else-if="data.item.moduleElement && data.item.moduleElement.module && data.item.moduleElement.module.course">{{data.item.moduleElement.module.course.name}}</span>
                            </template>
                            <template v-slot:cell(module)="data">
                                <span v-if="data.item.module">{{data.item.module.name}}</span>
                                <span v-else-if="data.item.moduleElement && data.item.moduleElement.module">{{data.item.moduleElement.module.name}}</span>
                            </template>
                            <template v-slot:cell(moduleElement)="data">
                                <span v-if="data.item.moduleElement"><i :class="`fa fa-${data.item.moduleElement.type}`"></i> {{data.item.moduleElement.name}}</span>
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <span>{{ data.item.createdAt|formatDate }}</span>
                            </template>
                            <template v-slot:cell(test)="data">
                                <span v-if="data.item.test">{{data.item.test.name}}</span>
                            </template>
                        </b-table>
                    </div>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";

    export default {
        name: "UserStat",
        components: {IboxTitle, IboxContent, Ibox, BreadCrumbs},
        data() {
            return {
                filter: "",
                stat: null,
                fields: [
                    {
                        key: 'point',
                        label: 'Баллы'
                    },
                    {
                        key: 'comment',
                        label: 'Описание'
                    },
                    {
                        key: 'course',
                        label: 'Курс'
                    },
                    {
                        key: 'module',
                        label: 'Модуль'
                    },
                    {
                        key: 'createdAt',
                        label: 'Дата/время'
                    },
                    {
                        key: 'moduleElement',
                        label: 'Элемент модуля'
                    }
                ],
                user: null,
                logs: null,
                courses: [],
                course: null
            }
        },
        mounted() {
            let userId = this.$route.params.id;
            this.axios.get(`courses`, {
                params: {
                    isActive: 0
                }
            }).then(res => {
                this.courses = res.data
            });
            this.axios.get(`users/${userId}`).then(res => {
                this.user = res.data;
                this.loadStat()
            });
        },
        methods: {
            prepareCourses(opt) {
                const course = this.courses.find(x => x.id == opt);
                return course ? course.name : undefined;
            },
            loadStat(course = []) {
                if(!course.length) {
                    this.axios.get(`course_user_logs`, {params: {'user.id': this.user.id}}).then(res => {
                        this.logs = res.data;
                    })
                } else {
                    this.axios.get(`courses/${course}/course_user_logs`, {params: {'user.id': this.user.id}}).then(res => {
                        this.logs = res.data;
                    })
                }
            }
        },
        watch: {
            course(course) {
                this.loadStat(course)
            }
        }
    }
</script>

<style scoped>

</style>
