<template>
    <div class="ibox no-margin" v-if="project">
        <div class="ibox-content">
            <validation-observer ref="observer" v-slot="{ passes }">
                <form @submit.stop.prevent="onSubmit(passes)">
                  <template v-if="project.profileFields">
                        <div class="form-group" v-for="(field, key) in project.profileFields" :key="key">
                            <template v-if="field.type === 'текст'">
                                <label>{{ field.name }}</label>
                                <valid-input rules="required" v-model="user.userProject.profile[field.id]"/>
                            </template>
                            <template v-if="field.type === 'большой текст'">
                                <label>{{ field.name }}</label>
                                <textarea required v-model="user.userProject.profile[field.id]" :label="field.name" />
                            </template>
                            <template v-if="field.type === 'целое число'">
                              <label>{{ field.name }}</label>
                              <valid-input rules="required|integer|min_value:1" v-model="user.userProject.profile[field.id]"/>
                            </template>
                            <template v-if="field.type === 'дата'">
                              <label>{{ field.name }}</label>
                              <valid-date rules="required" v-model="user.userProject.profile[field.id]"/>
                            </template>
                            <template v-if="field.type === 'дробное число'">
                              <label>{{ field.name }}</label>
                              <valid-input rules="required|decimal|min_value:0.1" v-model="user.userProject.profile[field.id]"/>
                            </template>
                            <template v-if="field.type === 'единственный выбор'">
                                <label>{{ field.name }}</label>
                                <b-form-radio v-for="(choice, key) in field.profileFieldChoices" v-model="user.userProject.profile[field.id]" :key="key">{{ choice.name }}</b-form-radio>
                            </template>
                            <template v-if="field.type === 'множественный выбор'">
                                <label>{{ field.name }}</label>
                                <b-form-checkbox v-for="(choice, key) in field.profileFieldChoices" v-model="user.userProject.profile[field.id]" :key="key">{{ choice.name }}</b-form-checkbox>
                            </template>
                            <template v-if="field.type === 'город и регион'">
                                <b-form-group
                                        label="Регион"
                                        label-for="type-input"
                                        invalid-feedback="Регион обязательно!"
                                >
                                    <multiselect
                                            id="region"
                                            v-model="region"
                                            required
                                            :multiple="false"
                                            :searchable="true"
                                            :disabled="!regions.length"
                                            :options="regions"
                                            openDirection="bottom"
                                            :loading="isRegionsLoading"
                                            @select="loadRegionCities($event, field.id)"
                                            placeholder="Введите регион"
                                            track-by="id"
                                            :custom-label="opt => opt.regionNameRu"
                                    >
                                        <template slot="noOptions">Регион не найден</template>
                                    </multiselect>
                                </b-form-group>
                                <b-form-group label="Город">
                                    <multiselect
                                            id="city"
                                            v-model="city"
                                            :disabled="!region"
                                            :multiple="false"
                                            :searchable="true"
                                            :options="cities"
                                            openDirection="bottom"
                                            :loading="isRegionsLoading"
                                            @select="checkCity($event, field.id)"
                                            placeholder="Введите город"
                                            track-by="id"
                                            :custom-label="(opt) => opt.name"
                                    >
                                        <template slot="noOptions">Город не найден</template>
                                    </multiselect>
                                </b-form-group>
                            </template>
                        </div>
                    </template>
                  <template v-if="project.countries && project.countries.length > 1">
                    <b-form-group
                        label="Страна"
                        label-for="type-input"
                        invalid-feedback="Страна обязательна!"
                    >
                      <multiselect
                          v-model="user.userProject.profile['country']"
                          :multiple="false"
                          :options="project.countries"
                          openDirection="bottom"
                          placeholder="Выберите страну"
                          :custom-label="opt => opt.name"
                      >
                        <template slot="noOptions">Страна не найдена</template>
                      </multiselect>
                    </b-form-group>
                  </template>
                  <template v-if="project.regions && project.regions.length > 1">
                    <b-form-group
                        label="Регион"
                        label-for="type-input"
                        invalid-feedback="Регион обязателен!"
                    >
                      <multiselect
                          v-model="user.userProject.profile['region']"
                          :multiple="false"
                          :options="project.regions"
                          openDirection="bottom"
                          placeholder="Выберите страну"
                          :custom-label="opt => opt.name"
                      >
                      </multiselect>
                    </b-form-group>
                  </template>
                  <template v-if="project.districts && project.districts.length > 1">
                    <b-form-group
                        label="Район"
                        label-for="type-input"
                        invalid-feedback="Район обязателен!"
                    >
                      <multiselect
                          v-model="user.userProject.profile['district']"
                          :multiple="false"
                          :options="project.districts"
                          openDirection="bottom"
                          placeholder="Выберите район"
                          :custom-label="opt => opt.name"
                      >
                      </multiselect>
                    </b-form-group>
                  </template>
                  <template v-if="project.organizations && project.organizations.length > 1">
                    <b-form-group
                        label="Организация"
                        label-for="type-input"
                        invalid-feedback="Организация обязателен!"
                    >
                      <multiselect
                          v-model="user.userProject.profile['organization']"
                          :multiple="false"
                          :options="project.organizations"
                          openDirection="bottom"
                          placeholder="Выберите организацию"
                          :custom-label="opt => opt.name"
                      >
                      </multiselect>
                    </b-form-group>
                  </template>
                    <div class="clearfix">
                        <b-btn class="pull-right" variant="primary" type="submit"><i :class="'fa fa-user-plus'"></i>Отправить</b-btn>
                    </div>
                </form>
            </validation-observer>
        </div>
    </div>
</template>

<script>
    import ValidInput from "../Validation/ValidInput";
    import bus from "../../bus";
    import ValidDate from "../Validation/ValidDate";
    export default {
        name: "Questionnarie",
        components: {ValidDate, ValidInput},
        props: ['project'],
        data() {
            return {
                isRegionsLoading: false,
                region: null,
                city: null,
                cities: [],
                regions: [],
                user: {
                    userInstitution: {},
                    userProject: {
                        profile: {},
                    }
                }
            }
        },
      mounted() {
        this.findRegions()
      },
      methods: {
            onSubmit() {
                this.axios.post('user_projects', {
                    profile: this.user.userProject.profile,
                    project: this.project.id
                }).then(() => {
                    bus.$emit('enroll');
                });
            },
            findRegions() {
              this.isRegionsLoading = true;
              this.axios.get(`region-list`).then(res => {
                this.isRegionsLoading = false;
                this.regions = res.data.data;
              });
            },
            loadRegionCities(region) {
                this.city = null;
                this.axios.get(`city-list?region=${region.id}&hideLoader=true`)
                    .then(res => {
                        this.cities = res.data.data
                    });
            },
            checkCity(city, fieldId) {
                this.user.userProject.profile[fieldId] = city;
            },
        }
    }
</script>

<style scoped>
    .no-margin {
        margin: -20px -30px -30px -30px;
    }
    .ibox-content {
        border: none;
    }
</style>
