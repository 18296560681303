<template>
    <div class="wrapper wrapper-content  animated fadeInRight article">
        <div class="row justify-content-md-center">
            <div class="col-lg-10">
                <div class="ibox">
                    <div class="ibox-content">
                        <div class="text-center article-title">
                            <h1>
                                {{ document.title }}
                            </h1>
                        </div>
                        <span v-html="document.text"></span>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Document",
        data() {
            return {
                document: null
            }
        },
        mounted() {
            let id = 4;
            switch (this.$route.params.alias) {
                case 'politika-konfidencialnosti-obrabotki-personalnyh-dannyh':
                    id = 1;
                    break;
                case 'politika-i-usloviya-vozvratov':
                    id = 2;
                    break;
                case 'informaciya-i-rekvizity-kompanii':
                    id = 3;
                    break;
                case 'o-proekte':
                    id = 5;
                    break;
                case 'postavschikam-kontenta':
                    id = 6;
                    break;
                case 'rabotodatelyam':
                    id = 7;
                    break;
                case 'sposoby-oplaty':
                    id = 8;
                    break;
            }
            this.axios.get(`documents/${id}`).then(res => {
                this.document = res.data;
            })
        }
    }
</script>

<style scoped>

</style>
