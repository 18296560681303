<template>
    <b-table v-if="bookLists" striped hover bordered  :fields="fields" :items="bookLists">
        <template v-slot:cell(title)="data">
            <div class="text-left">
              <a v-if="data.item.link" :href="data.item.link" target="_blank">{{ data.item.title }}</a>
              <span v-else >{{ data.item.title }}</span>
            </div>
        </template>
        <template v-slot:cell(link)="data">
            <a :href="data.item.link" target="_blank"><b-badge variant="info">Подробнее <i class="fa fa-mail-forward"></i></b-badge></a>
        </template>
    </b-table>
</template>

<script>
    export default {
        name: "BookList",
        props: ['bookLists'],
        data() {
            return {
                fields: [
                    {
                        key: 'title',
                        label: 'Название'
                    },
                    {
                        key: 'author',
                        label: 'Автор'
                    },
                    {
                        key: 'type',
                        label: 'Тип'
                    },
                    {
                        key: 'link',
                        label: 'Ссылка'
                    }
                ]
            }
        }
    }
</script>

<style scoped>

</style>
