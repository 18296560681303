<template>
    <div id="sparkline"></div>
</template>

<script>
    import $ from 'jquery'
    import {} from 'jquery-sparkline'
    export default {
        name: "Sparkline",
        mounted() {
            $("#sparkline").sparkline([34, 43, 43, 35, 44, 32, 44, 48], {
                type: 'line',
                width: '100%',
                height: '50',
                lineColor: '#1ab394',
                fillColor: "transparent"
            });
        }
    }
</script>

<style scoped>

</style>
