<template>
    <form ref="form" @submit.stop.prevent="handleSubmit(true)">
        <div class="">
            <b-form-group
                    label="Название"
                    label-for="name-input"
                    invalid-feedback="Name is required"
            >
                <b-form-input
                        id="name-input"
                        v-model="codifier.title"
                        required
                ></b-form-input>
            </b-form-group>
            <valid-input v-model.number="codifier.hardPoint" label="Балл за сложный ЭО" name="сложный балл" rules="required|decimal|min_value:0.1|max_value:1"/>
            <valid-input v-model.number="codifier.mediumPoint" label="Балл за средний ЭО" name="средний балл" rules="required|decimal|min_value:0.1|max_value:1"/>
            <valid-input v-model.number="codifier.simplePoint" label="Балл за простой ЭО" name="простой балл" rules="required|decimal|min_value:0.1|max_value:1"/>
            <validation-provider name="Описание" :rules="{ required: true }" v-slot="validationContext">
                <div class="form-group">
                    <label class="col-form-label">Описание:</label>
                    <div>
                        <Summernote placeholder="Введите описание компетенции" v-model="codifier.description" :state="valid(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </div>
                </div>
            </validation-provider>

            <b-form-group
                    v-if="courses.length"
                    label="Курсы"
                    label-for="type-input"
                    invalid-feedback="Courses is required"
            >
                <multiselect
                        v-model="codifier.courses"
                        :options="courses"
                        :multiple="true"
                        track-by="id"
                        label="name"
                        openDirection="bottom"
                ></multiselect>
            </b-form-group>
        </div>
    </form>
</template>

<script>
    import Summernote from "../Summernote";
    import Multiselect from 'vue-multiselect'
    import ValidInput from "../Validation/ValidInput";
    export default {
        name: "CodifierForm",
        components: {ValidInput, Summernote, Multiselect},
        props: {
            type: String,
            submitting: Boolean,
            codifier: {
                type: Object,
                default () {
                    return {
                        courses: [],
                        modules: [],
                        tests: []
                    }
                }
            }
        },
        mounted() {
            this.axios.get(`codifiers/${this.codifier.id}/courses`).then(res => {
                this.courses = res.data;
            })
        },
        data() {
            return {
                isCoursesLoading: false,
                courses: [],
                modules: [],
                tests: [],
            }
        },
        watch: {
            submitting(val) {
                if (val) {
                    this.handleSubmit();
                }
            }
        },
        methods: {
            checkFormValidity() {
                return this.$refs.form.checkValidity()
            },
            numberFormatter(value) {
                return Number(value);
            },
            handleSubmit(emitSubmitting = false) {
                if (emitSubmitting) {
                    this.$emit('submitting', true);
                }

                if (!this.checkFormValidity()) {
                    this.$emit('submitting', false);
                    return;
                }

                let promise = null;
                if (this.codifier.id) {
                    promise = this.axios.put(`codifiers/${this.codifier.id}`, this.codifier).then(r => {
                        if (r.status >= 400) {
                            throw new Error();
                        }
                        this.$toasted.global.appSuccess();
                    });
                } else {
                    promise = this.axios.post('codifiers', this.codifier).then(r => {
                        if (r.status === 400) {
                            throw new Error();
                        }
                        this.$toasted.global.appSuccess();
                    });
                }

                promise
                    .then(() => this.$emit('submitted', true))
                    .catch(() => this.$emit('submitted', false));
            },
            asyncFind (query) {
                this.isCoursesLoading = true;
                this.axios.get(`courses/?name=${query}`).then(res => {
                    this.isCoursesLoading = false;
                    this.courses = res.data;
                });
            },
            loadCourseModules(courseId) {
                if (!courseId) {
                    this.modules = [];
                    return;
                }
                this.axios.get(`modules?course=${courseId}`).then(res => this.modules = res.data);
            },
        }
    }
</script>

<style scoped>

</style>
