<template>
  <div class="gray-bg" style="min-height: 100%;">
    <div class="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <div>
          <div style="background: #1ab394; padding: 10px; border-radius: 10px; width: 120px; height: 120px; margin: 0 auto 20px;">
            <img width="100px" src="logo.png"/>
          </div>
          <div class="clearfix"></div>
        </div>
        <h3>Регистрация</h3>
        <p>Открытая система электронного образования.</p>
        <b-form class="m-t" role="form" @submit.prevent="handleSubmit()">
          <b-form-group>
            <b-input placeholder="Фамилия" v-model="form.lastname" required/>
          </b-form-group>
          <b-form-group>
            <b-input placeholder="Имя"
                     v-model="form.firstname"
                     required/>
          </b-form-group>
          <b-form-group>
            <b-input placeholder="Отчество" v-model="form.middlename"/>
          </b-form-group>

          <b-form-group>
            <b-input type="email" placeholder="Email" v-model="form.email" required/>
          </b-form-group>


          <b-form-group>
            <b-input placeholder="Пароль" v-model="form.password" required/>
          </b-form-group>

          <div class="form-group">
            <b-form-checkbox
                    :checked="true"
                    :disabled="true"
            >
              Я согласен с условиями <a href="https://universarium.org/docs/politika-konfidencialnosti-obrabotki-personalnyh-dannyh" target="_blank">пользовательского соглашения.</a>
            </b-form-checkbox>
          </div>
          <div v-if="alreadyExists">Пользователь уже сущестует! Попробуйте войти</div>
          <b-button type="submit" size="sm" variant="primary" class="block full-width m-b" :disabled="submitting">Отправить</b-button>

          <p class="text-muted text-center"><small>Уже есть аккаунт?</small></p>
          <router-link class="btn btn-sm btn-white btn-block" to="/admin/login">Войти</router-link>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
    import Actions from "../components/User/Actions";

    export default {
      name: "Registration",
      extends: Actions,
      data() {
        return {
          submitting: false,
          needConfirm: false,
          alreadyExists: false,
          form: {},
        }
      },
      methods: {
        handleSubmit() {
          if (this.submitting) {
            return;
          }

          this.alreadyExists = false;
          this.submitting = true;

          if(this.$route.query.redirect) {
            this.form.isKids = true
          }

          this.axios.post('registration', {...this.form})
          .then(res => {
            let {status} = res;

            if (status === 400) {
              throw new Error();
            }

            this.$bvModal.msgBoxOk('Для завершения процедуры регистрации нажмите на ссылку в письме, отправленную на ваш почтовый ящик: ' + this.form.email, {
              title: 'Пожалуйста, проверьте свой почтовый ящик',
              hideHeaderClose: false,
              centered: true
            });

            this.form = {};
          })
          .catch(() => this.alreadyExists = true)
          .finally(() => this.submitting = false)
        },
        checkForm() {
          return true;
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        }
      }
    }
</script>

<style scoped>
  .not-allowed {
    cursor: not-allowed !important;
  }
</style>
