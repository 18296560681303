<template>
  <div class="table-responsive">
    <table class="table table-condensed">
      <thead>
      <th>№</th>
      <th v-for="(column, key) in task.columns" :key="key">{{ column }}</th>
      </thead>
      <tbody>
      <tr v-for="(row, r) in task.cells" :key="r">
        <td>{{ r + 1 }}</td>
        <td v-for="(column, k) in task.columns" :key="k">
          <div v-if="row[k] && row[k].isStaticText" v-html="row[k].text"></div>
          <div v-if="row[k] && row[k].isStaticText && Array.isArray(givenAnswer.grid) && Array.isArray(givenAnswer.grid[r])"
               style="display: none">
            {{ givenAnswer.grid[r][k] = '' }}
          </div>
          <div v-if="row[k] && !row[k].isStaticText">
            <input v-if="givenAnswer && Array.isArray(givenAnswer.grid) && Array.isArray(givenAnswer.grid[r])"
                   type="text"
                   class="form-control"
                   :title="column"
                   v-model="givenAnswer.grid[r][k]"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";
    export default {
        name: "QuestionMatchAny",
        extends: QuestionBase,
        mounted () {
          if (this.task && Array.isArray(this.task.cells) && this.givenAnswer && Array.isArray(this.givenAnswer.grid) && this.givenAnswer.grid.length === 0) {
            for (let i = 0; i < this.task.cells.length; i++) {
              this.givenAnswer.grid.push([])
            }
          }
        }
    }
</script>

<style scoped>

</style>
