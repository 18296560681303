<template>
    <div v-if="result" class="animated fadeIn">
        <ibox>
            <ibox-title>
                Результат
            </ibox-title>
            <ibox-content>
                <b-table class="text-center" :items="[result]" :fields="[{key: 'mark', label: 'Результат'},{key: 'passed', label: 'Пройден?'},{key: 'resultDay', label: 'Дата/время прохождения'},{key: 'resultTime', label: 'Затраченное время, мин.'}]">
                    <template v-slot:cell(mark)="data">
                        <div class="text-center">
                            <b-badge v-if="toFixed(data.item.mark) === 0" variant="danger">0</b-badge>
                            <b-progress v-else :value="data.item.mark ? toFixed(data.item.mark) : 0" :variant="variant(toFixed(data.item.mark))" :max="100" show-progress animated></b-progress>
                        </div>
                    </template>
                    <template v-slot:cell(passed)="data">
                        <div class="text-center">
                            <b-badge :variant="data.item.passed ? 'primary' : 'danger'">{{data.item.passed ? 'Да' : 'Нет'}}</b-badge>
                        </div>
                    </template>
                    <template v-slot:cell(resultDay)="data">
                        <div class="text-center">
                            <b-badge :variant="data.item.passed ? 'primary' : 'danger'">{{data.item.passed ? 'Да' : 'Нет'}}</b-badge>
                        </div>
                    </template>
                </b-table>
            </ibox-content>
            <ibox-footer>
                <router-link class="btn btn-warning" :to="`/admin/test-result/${result.id}`"><i class="fa fa-bar-chart"></i> Подробнее</router-link>
            </ibox-footer>
        </ibox>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";
    import TestResult from "../TestConstructor/TestResult";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "TestAttempt",
        extends: TestResult,
        components: {IboxFooter, IboxTitle, IboxContent, Ibox},
        props: ['testId'],
        data() {
            return {
                result: null
            }
        },
        mounted() {
            this.axios.get(`sdpk/test/result/by-test/${this.testId}`).then(res => {
                this.result = res.data
            })
        }
    }
</script>

<style scoped>

</style>
