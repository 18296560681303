<template>
    <b-row>
        <b-col lg="4">
            <div class="input-group search-course">
                <input type="text" v-model="filters.title" @input="searchTitle" placeholder="Введите название курса" class="input form-control" />
                <span class="input-group-append">
                    <button type="button" class="btn btn btn-primary"> <i class="fa fa-search"></i></button>
                </span>
            </div>
        </b-col>
        <b-col lg="4">
            <multiselect
                    v-model="filters.categories"
                    :multiple="true"
                    :searchable="true"
                    placeholder="Выберите категорию"
                    :options="categories.map(c => c.id)"
                    :custom-label="prepareCategories"
                    @input="onChangeCategory"
            ></multiselect>
        </b-col>
        <b-col lg="4">
          <multiselect
              v-model="filters.institutions"
              :multiple="true"
              :searchable="true"
              placeholder="Выберите организацию"
              :options="institutions.map(c => c.id)"
              :custom-label="prepareInstitutes"
              @input="onChangeInstitute"
          ></multiselect>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "CourseFilter",
        props: ['value'],
        data() {
            return {
                codifiers: [],
                institutions: [],
                categories: [],
                isCodifiersLoading: false,
                filters: {
                    title: null,
                    codifiers: [],
                    institutions: [],
                    categories: [],
                }
            }
        },
        mounted() {
            this.axios.get('codifiers').then(res => {
                this.codifiers = res.data;
            })
            this.axios.get('categories').then(res => {
                this.categories = res.data;
            })
            this.axios.get('institutions', {params: {'isPublic': true}}).then(res => {
                this.institutions = res.data;
            })
            if(this.$route.query.q) {
                this.filters.title = this.$route.query.q;
                this.$emit('input', this.filters)
            }
            if(this.$route.query.category) {
                this.filters.categories = [parseInt(this.$route.query.category)];
                this.$emit('input', this.filters)
            }
        },
        methods: {
            searchTitle() {
                this.$emit('input', this.filters)
            },
            onChangeCodifier() {
                this.$emit('input', this.filters)
            },
            onChangeInstitute() {
                this.$emit('input', this.filters)
            },
            onChangeCategory() {
                this.$emit('input', this.filters)
            },
            prepareCodifiers(opt) {
                const codifier = this.codifiers.find(x => x.id == opt);
                return codifier ? codifier.title : undefined;
            },
            prepareInstitutes(opt) {
                const institute = this.institutions.find(x => x.id == opt);
                return institute ? institute.shortName : undefined;
            },
            prepareCategories(opt) {
                const category = this.categories.find(x => x.id == opt);
                return category ? category.title : undefined;
            }
        }
    }
</script>

<style scoped>

</style>
