<template>
    <div v-if="project" class="animate fadeIn">
        <bread-crumbs title="Пользователи проекта" :items="[
            {title: 'Главная', url: '/#/'},
            {title: 'Проекты', url: '/admin/projects'},
            {title: project.title, url: `/projects/${project.id}/edit`},
          ]" />
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    <div class="ibox-tools">
                      <router-link :to="`/admin/projects/${project.id}/project-users-add`" class="btn btn-primary btn-xs">Добавить пользователя в проект</router-link>
                    </div>
                    <h5>Пользователи проекта</h5>
                </div>
                <div class="ibox-content">
                    <b-card>
                        <b-table :fields="fields" :items="users">
                          <template v-slot:cell(subjects)="data">
                            <span class="badge badge-info" v-for="(subject, key) in data.item.subjects" :key="key">
                              {{subject.name}}
                            </span>
                          </template>
                          <template v-slot:cell(actions)="data">
                            <b-button-group>
                              <router-link class="btn btn-primary btn-sm" :to="`/admin/projects/${project.id}/project-users/${data.item.id}`">
                                <i class="fa fa-play"></i>
                              </router-link>
                              <router-link class="btn btn-warning btn-sm" :to="`/admin/projects/${project.id}/project-users/${data.item.id}/edit`">
                                <i class="fa fa-edit"></i>
                              </router-link>
                              <router-link class="btn btn-info btn-sm" :to="`/admin/projects/${project.id}/project-user/${data.item.id}/stats`">
                                <i class="fa fa-pie-chart"></i>
                              </router-link>
                            </b-button-group>
                          </template>
                        </b-table>
                    </b-card>
                </div>
                <div class="ibox-footer">
                  <b-pagination v-if="pagination"
                                v-model="currentPage"
                                :total-rows="pagination.total"
                                :per-page="pagination.limit"
                                aria-controls="stats-table"
                                @input="load()"
                                prev-text="Назад"
                                next-text="Вперед"
                  ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    export default {
        name: "ProjectUserList",
        components: {BreadCrumbs},
        data() {
            return {
                currentPage: 1,
                project: null,
                users: [],
                pagination: null,
                fields: [
                  {
                    key: 'user.fullname',
                    label: 'ФИО'
                  },
                  {
                    key: 'region.name',
                    label: 'Регион'
                  },
                  {
                    key: 'district.name',
                    label: 'Район'
                  },
                  {
                    key: 'organization.name',
                    label: 'Организация'
                  },
                  {
                    key: 'subjects',
                    label: 'Предметы'
                  },
                  {
                    key: 'actions',
                    label: 'Действия'
                  }
                ],
            }
        },
        mounted() {
          this.load();
        },
        methods: {
          load() {
            this.axios
                .get(`project-short/${this.$route.params.id}`)
                .then(res => {
                  this.project = res.data
                  this.axios.get('user_projects', {
                    params: {
                      'order[user][lastName]': 'asc',
                      'order[user][middleName]': 'asc',
                      'order[user][firstName]': 'asc',
                      'filters[project]': this.$route.params.id,
                      limit: this.pagination ? this.pagination.limit : null,
                      offset: this.pagination ? (this.currentPage * this.pagination.limit) - this.pagination.limit : null
                    }
                  }).then(res => {
                    this.users = res.data.data;
                    this.pagination = res.data.pagination;
                  })
                })
          }
        }
    }
</script>

<style scoped>

</style>
