<template>
    <test-passage :test-id="parseInt($route.params.id)"/>
</template>

<script>
    import TestPassage from "./TestPassage";
    export default {
        name: "TestStart",
        components: {TestPassage}
    }
</script>

<style scoped>

</style>
