import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login'
import Profile from "../components/Profile";
import DashboardLayout from "../components/layouts/DashboardLayout";
import InstitutionEdit from "../components/InstitutionEdit";
import TestEdit from "../components/Test/TestEdit";
import Course from "../components/Course/Course";
import CourseEdit from "../components/Course/CourseEdit";
import Catalog from "../components/Catalog";
import InsideLayout from "../components/layouts/InsideLayout";
import CodifierList from "../components/Codifier/CodifierList";
import CodifierEdit from "../components/Codifier/CodifierEdit";
import Module from "../components/Module/Module";
import Home from "../components/Home";
import InstitutionList from "../components/Institution/InstitutionList";
import Acl from "../components/User/Acl";
import CourseInfo from "../components/Course/CourseInfo";
import LoginConnect from "../pages/LoginConnect";
import UserList from "../components/User/UserList";
import Messages from "../components/Profile/Messages";
import UserStat from "../components/User/UserStat";
import TestCategoryList from "../components/Test/TestCategoryList";
import TestList from "../components/Test/TestList";
import TestConstructor from "../components/TestConstructor/TestConstructor";
import ActionsList from "../components/User/ActionsList";
import CourseStat from "../components/Course/CourseStat";
import TestResult from "../components/TestConstructor/TestResult";
import CourseStatsDetail from "../components/Course/CourseStatsDetail";
import ElementData from "../components/Module/ElementData";
import TestStart from "../components/Test/TestStart";
import Test from "../components/TestConstructor/Test";
import CodifierStat from "../components/Codifier/CodifierStat";
import ProjectList from "../components/Project/ProjectList";
import AddProject from "../components/Project/AddProject";
import Diagnostic from "../components/Project/Diagnostic";
import EditProject from "../components/Project/EditProject";
import ProjectLanding from "../pages/ProjectLanding";
import Registration from "../pages/Registration";
import RestorePassword from "../pages/RestorePassword";
import LinkHandler from "../pages/LinkHandler";
import ForceChangePassword from "../components/Profile/ForceChangePassword";
import VueAnalytics from 'vue-analytics'
import CourseFree from "../components/Course/CourseFree";
import Neuro from "../components/Stat/Neuro";
import MailCreate from "../components/Mail/MailCreate";
import MailList from "../components/Mail/MailList";
import NeuroList from "../components/Stat/NeuroList";
import SelfCourseStat from "../components/User/SelfCourseStat";
import StatConstructorContainer from "../components/Stat/StatConstructorContainer";
import Trajectory from "../components/Course/Trajectory";
import HomeworkList from "../components/Homework/HomeworkList";
import HomeworkCheck from "../components/Homework/HomeworkCheck";
import DocumentForm from "../components/Document/DocumentForm";
import Document from "../components/Document/Document";
import AccessDenied from "../pages/AccessDenied";
import ProjectUserList from "../components/Project/ProjectUserList";
import ProjectUser from "../components/Project/ProjectUser";
import MyTests from "../components/Profile/MyTests";
import AddProjectUser from "../components/Project/AddProjectUser";
import ProjectUserEdit from "../components/Project/ProjectUserEdit";
import Anal from "../components/Anal/Anal";
import Member from "../components/Anal/Member";
import OrganizationsRating from "../components/Anal/OrganizationsRating";
import UsersRating from "../components/Anal/UsersRating";
import CodifiersRating from "../components/Anal/CodifiersRating";
import Mask from "../components/User/Mask";
import MyCourseList from "../components/Course/MyCourseList";
import ProjectUserStat from "../components/Project/ProjectUserStat";
import CourseListTable from "../components/Course/CourseListTable";
import CertOld from "../pages/CertOld";
import AttemptsList from "../pages/AttemptsList.vue";
import NewsList from "../pages/NewsList.vue";

Vue.use(VueAnalytics, {
    id: 'UA-40339159-1'
})

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/admin/',
            name: 'Home',
            component: Home,
            meta: {
                auth: {roles: 'ROLE_SUPER_ADMIN', redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/admin/',
            name: 'Inside',
            component: InsideLayout,
            children: [
                {
                    path: 'landing/:id',
                    component: ProjectLanding,
                },
                {
                    path: '/admin/docs/:alias',
                    component: Document,
                },
                {
                    path: '/admin/courses',
                    name: 'courses',
                    component: Catalog
                },
                // old for redirect
                {
                    path: 'courses/:id',
                    name: 'noneAuthCourse',
                    component: CourseFree,
                    children: [
                        {
                            path: '',
                            component: CourseInfo
                        }
                    ]
                },

            ],
            meta: {
                auth: {roles: 'ROLE_SUPER_ADMIN', redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
            }
        },
        {
            path: '/admin/',
            name: 'Dashboard',
            component: DashboardLayout,
            children: [
                {
                    path: 'profile',
                    component: Profile,
                    meta: {auth: true, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'mask/:token',
                    component: Mask,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'profile/change-password',
                    component: ForceChangePassword
                },
                {
                    path: 'analytic',
                    component: Anal,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST']}, redirect: {path: '/admin/login' }},
                    children: [
                        {
                            path: 'members',
                            name: 'analytic-member',
                            component: Member
                        },
                        {
                            path: 'organizations',
                            name: 'organizations',
                            component: OrganizationsRating
                        },
                        {
                            path: 'users',
                            name: 'users',
                            component: UsersRating
                        },
                        {
                            path: 'codifiers',
                            name: 'analytic-codifiers',
                            component: CodifiersRating
                        }
                    ],
                },
                {
                    path: 'messages',
                    component: Messages,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_METHODIST']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'homeworks',
                    component: HomeworkList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_TUTOR']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: '/admin/homeworks/:id',
                    component: HomeworkCheck
                },
                {
                    path: 'institution/:id',
                    component: InstitutionEdit,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'course/:id/edit',
                    component: CourseEdit,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER']}, redirect: {path: '/admin/login' }},
                    children: [
                        {
                            path: 'module/:index',
                            component: CourseEdit,
                            meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                        },
                        {
                            path: 'students',
                            component: CourseEdit,
                            meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                        }
                    ]
                },
                {
                    path: 'course/:id',
                    component: Course,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER']}, redirect: {path: '/admin/login' }},
                    children: [
                        {
                            path: '',
                            name: 'courseInfo',
                            component: CourseInfo
                        },
                        {
                            path: 'module/:index',
                            name: 'module',
                            component: Module
                        },
                        {
                            path: 'module/:index/element/:eIndex',
                            component: ElementData,
                            name: 'element'
                        }
                    ],
                    // meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    component: TestCategoryList,
                    path: 'test-categories',
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    component: TestList,
                    path: 'test-categories/:id',
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    component: TestConstructor,
                    path: 'constructor/:id',
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'acl',
                    component: Acl,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'test/:id',
                    component: TestStart,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'test/:id/edit',
                    component: TestEdit,
                    meta: {
                        auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                    }
                },
                {
                    path: 'tests-construct/:id',
                    component: Test,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'codifier',
                    component: CodifierList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'codifier/:id',
                    component: CodifierEdit,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                },
                {
                    path: 'institutions',
                    component: InstitutionList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'users',
                    component: UserList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'users-stats/:id',
                    component: UserStat,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'courses-points/:id',
                    component: SelfCourseStat,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'actions',
                    component: ActionsList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'stats',
                    component: CourseStat,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_METHODIST']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'stats/:id',
                    component: CourseStatsDetail,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_METHODIST']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: 'test-stat/:id',
                    component: TestResult,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}, redirect: {path: '/admin/login' }}
                },
                {
                    path: '/admin/codifiers-stats',
                    name: 'CodyStat',
                    component: CodifierStat
                },
                {
                    path: '/admin/catalog',
                    component: Catalog,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}}
                },
                {
                    path: '/admin/my-courses',
                    component: MyCourseList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN']}}
                },
                {
                    path: '/admin/courses-list',
                    component: CourseListTable,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}}
                },
                {
                    path: '/admin/attempts-list',
                    component: AttemptsList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}}
                },
                {
                    path: '/admin/news',
                    component: NewsList,
                    meta: {auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}}
                },
                {
                    path: '/admin/projects',
                    name: 'project-list',
                    component: ProjectList
                },
                {
                    path: '/admin/projects/add',
                    name: 'addProject',
                    component: AddProject
                },
                {
                    path: '/admin/projects/:id/project-users',
                    name: 'project-users',
                    component: ProjectUserList
                },
                {
                    path: '/admin/projects/:id/project-users-add',
                    name: 'project-users-add',
                    component: AddProjectUser
                },
                {
                    path: '/admin/projects/:projectId/project-users/:id',
                    name: 'project-users-tests',
                    component: ProjectUser
                },
                {
                    path: '/admin/projects/:projectId/project-user/:id/stats',
                    name: 'project-users-stats',
                    component: ProjectUserStat
                },
                {
                    path: '/admin/projects/:projectId/project-users/:id/edit',
                    name: 'project-users-edit',
                    component: ProjectUserEdit
                },
                {
                    path: '/admin/projects/:id/edit',
                    name: 'addProject',
                    component: EditProject,
                    meta: {
                        auth: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_CUSTOMER'], redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                    }
                },
                {
                    path: '/admin/diagnostic-more',
                    name: 'diagnostic-more',
                    component: Diagnostic
                },
                {
                    path: '/admin/diagnostic',
                    name: 'diagnostic',
                    component: MyTests
                },
                {
                    path: '/admin/mails/create',
                    name: 'new-mail',
                    component: MailCreate
                },
                {
                    path: '/admin/mails',
                    name: 'mailList',
                    component: MailList
                },
                {
                    path: '/admin/documents/:id',
                    name: 'documentEdit',
                    component: DocumentForm,
                    meta: {
                        auth: {roles: 'ROLE_SUPER_ADMIN', redirect: {path: '/admin/login' }, forbiddenRedirect: '/403'}
                    }
                },
                {
                    path: '/admin/stat-constructor',
                    name: 'stat-constructor',
                    component: StatConstructorContainer
                },
                {
                    path: '/admin/neuro-stats/:id',
                    component: Neuro
                },
                {
                    path: '/admin/stat-test-result',
                    component: NeuroList
                },
                {
                    path: '/admin/test-result/:id',
                    component: TestResult
                },
                {
                    path: '/admin/trajectory/:course/:points',
                    name: 'trajectory',
                    component: Trajectory
                },
            ]
        },
        {
          path: '/admin/login',
          name: 'login',
          component: Login
        },
        {
          path: '/registration',
          name: 'registration',
          component: Registration
        },
        {
          path: '/admin/forgot-password',
          name: 'forgot-password',
          component: RestorePassword
        },
        {
          path: '/admin/link',
          name: 'link-handler',
          component: LinkHandler
        },
        {
            path: '/admin/cert/:id',
            name: 'CertOld',
            component: CertOld
        },
        {
          path: '/admin/login/connect/:network',
          component: LoginConnect
        },
        {
            path: '/admin/403',
            name: 'AccessDenied',
            component: AccessDenied
        }
    ],
    linkActiveClass: 'active',
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})


let event = new Event('route-change');

router.beforeEach((to, from, next) => {
    document.dispatchEvent(event);
    if(from.path === '/') {
        let body = document.body;
        body.removeAttribute('class')
        body.removeAttribute('id')
    }
    Vue.$ga.page(router)
    next()
})

export default router
