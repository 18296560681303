<template>
    <div id="vertical-timeline" class="vertical-container light-timeline no-margins">
        <div v-for="(action, key) in actions" :key="key" class="vertical-timeline-block">
            <actions-enter :action="action" v-if="action.type === types.ENTER_TYPE" />
            <actions-subscribe :action="action" v-if="action.type === types.COURSE_ENROLL_TYPE" />
            <actions-un-subscribe :action="action" v-if="action.type === types.COURSE_UN_ENROLL_TYPE" />
            <actions-watch :action="action" v-if="action.type ==='video'" />
        </div>
        <div class="vertical-timeline-block text-center last-time-block">
            <router-link to="/admin/actions" class="btn btn-primary more-activity" variant="primary">Подробнее</router-link>
        </div>
    </div>
</template>

<script>
    import ActionsEnter from "./actions/ActionsEnter";
    import ActionsWatch from "./actions/ActionsWatch";
    import Actions from "./Actions";
    import ActionsSubscribe from "./actions/ActionsSubscribe";
    import ActionsUnSubscribe from "./actions/ActionsItUnSubscribe";
    import TYPES from "./actionsTypes";
    export default {
        name: "ActionsProfile",
        extends: Actions,
        components: {ActionsUnSubscribe, ActionsSubscribe, ActionsEnter, ActionsWatch},
        data() {
            return {
                types: TYPES
            }
        },
        mounted() {
            this.getActions()
        }
    }
</script>

<style scoped>
    .vertical-container {
        width: 100%;
    }
    .last-time-block {
        margin-bottom: 2em;
    }
</style>
