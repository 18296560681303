<template>
    <div class="animated fadeIn">
        <b-modal
                id="add-test"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Добавление новой компетенции"
                @ok="handleOk"
                v-bind:busy="modalSubmitting"
                ok-title="Отправить"
                cancel-title="Отмена"
        >
            <codifier-form
                    v-bind:submitting="modalSubmitting"
                    v-on:submitting="handleSubmitting($event)"
                    v-on:submitted="handleSubmitResult($event)"
            ></codifier-form>
        </b-modal>
        <BreadCrumbs title="Каталог компетенций" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    <div class="ibox-tools">
                        <a v-b-tooltip title="Добавить" v-b-modal.add-test>
                            <i class="fa fa-plus"></i> Добавить
                        </a>
                    </div>
                </div>
                <div class="ibox-content">
                    <b-table striped hover bordered :fields="fields" :items="codifiers">
                        <template v-slot:cell(source)="data">
                            <i v-if="data.item.source === 'system'" v-b-tooltip title="Система" class="fa fa-key"></i>
                            <i v-else class="fa fa-users"  v-b-tooltip title="Пользователи"></i>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button-group>
                                <router-link v-if="$auth.check('ROLE_SUPER_ADMIN')" v-b-tooltip title="Редактировать" :to="'/admin/codifier/' + data.item.id" class="btn btn-sm btn-info"><i class="fa fa-edit"></i></router-link>
                            </b-button-group>
                        </template>
                        <template v-slot:cell(hardPoint)="data">
                            {{data.item.hardPoint ? data.item.hardPoint : 0.40}}
                        </template>
                        <template v-slot:cell(mediumPoint)="data">
                            {{data.item.mediumPoint ? data.item.mediumPoint : 0.60}}
                        </template>
                        <template v-slot:cell(simplePoint)="data">
                            {{data.item.simplePoint ? data.item.simplePoint : 0.80}}
                        </template>
                    </b-table>
                </div>
                <ibox-footer>
                  <b-pagination v-if="pagination"
                                v-model="currentPage"
                                :total-rows="pagination.total"
                                :per-page="pagination.limit"
                                aria-controls="stats-table"
                                @input="load()"
                                prev-text="Назад"
                                next-text="Вперед"
                  ></b-pagination>
                </ibox-footer>
            </div>
        </div>
    </div>
</template>

<script>
    import CodifierForm from "./CodifierForm";
    import BreadCrumbs from "../BreadCrumbs";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "CodifierList",
        components: {IboxFooter, BreadCrumbs, CodifierForm},
        data() {
            return {
                form: {},
                modalSubmitting: false,
                pagination: null,
                currentPage: 1,
                codifiers: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true
                    },
                    {
                        key: 'title',
                        label: 'Название',
                        sortable: true
                    },
                    {
                        key: 'source',
                        label: 'Источник',
                        sortable: true
                    },
                    {
                        key: 'hardPoint',
                        label: 'Балл за сложный ЭО',
                        sortable: true
                    },
                    {
                        key: 'mediumPoint',
                        label: 'Балл за средний ЭО',
                        sortable: true
                    },
                    {
                        key: 'simplePoint',
                        label: 'Балл за простой ЭО',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load(false);
        },
        methods: {
            load(showMsg) {
                this.axios.get('codifiers', {
                  params: {
                    offset: (this.currentPage-1)*30,
                  }
                }).then(res => {
                    this.codifiers = res.data.data;
                    this.pagination = res.data.pagination;
                    if (showMsg) {
                        this.$toasted.global.appSuccess();
                    }
                });
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.modalSubmitting = true;
            },
            handleSubmitting(status) {
                this.modalSubmitting = status;
            },
            handleSubmitResult(success) {
                if (success) {
                    this.$nextTick(() => {
                        this.$bvModal.hide('add-test')
                    });
                    this.load(false);
                }
                this.modalSubmitting = false;
            },
        }
    }
</script>

<style scoped>
    .mr-30 {
        margin-bottom: 10px;
        margin-right: 0;
    }
</style>
