import Pusher from 'pusher-js';
import {AppConfig} from "../global";

export default {
    install(Vue) {
        Vue.prototype.$pusherService = new Pusher(
            AppConfig.pusherAppKey,
            {
                cluster: 'eu',
                authorizer: ({name}) => ({
                    authorize: async (socket, callback) => {
                        try {
                            let {data} = await Vue.axios.post('chats/pusher-auth', {channel: name, hideLoader: true, socket});
                            callback(false, {auth: data.auth})
                        } catch (e) {
                            callback(true);
                        }
                    }
                })
            }
        )
    }
}
