<template>
    <div v-if="data" class="animated fadeIn">
        <bread-crumbs :title="`Списки рассылок`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title :hide-tools="true">
                    <div class="ibox-tools">
                        <small>
                            <router-link to="/admin/mails/create" v-b-tooltip title="Добавить">
                                <i class="fa fa-plus"></i> Добавить
                            </router-link>
                        </small>
                    </div>
                    Списки рассылок
                </ibox-title>
                <ibox-content v-if="data">
                    <b-table striped hover bordered responsive :fields="fields" :items="data">
                        <template v-slot:cell(status)="data">
                            {{data.item.status}}
                        </template>
                        <template v-slot:cell(action)="data">
                            <b-btn size="sm" variant="danger" @click="onDelete(data.item.id)">Удалить</b-btn>
                        </template>
                    </b-table>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    export default {
        name: "MailList",
        components: {IboxContent, IboxTitle, Ibox, BreadCrumbs},
        data() {
            return {
                fields: [
                    {
                        key: 'subject',
                        label: 'Тема письма'
                    },
                    {
                        key: 'startDate',
                        label: 'Дата отправки'
                    },
                    {
                        key: 'status',
                        label: 'Статус'
                    },
                    {
                        key: 'action',
                        label: 'Действия'
                    },
                ],
                data: null
            }
        },
        methods: {
            load() {
                this.axios.get('maillists').then(res => {
                    this.data = res.data;
                })
            },
            onDelete(id) {
                this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить рассылку? Это дествие необратимо.', {
                    title: 'Удаление рассылки',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(res => {
                        if(res) {
                            this.axios.delete('maillists/' + id).then(() => {
                                this.load()
                            });
                        }
                    })
            }
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
