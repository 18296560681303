<template>
    <div v-if="user" class="animated fadeIn">
        <bread-crumbs :title="`Нейро-диагностика слушателя ${user.fullname}`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Список слушателей', url: '/admin/users-stats'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    Сводные данные
                </ibox-title>
                <ibox-content v-if="totalData">
                    <neuro-chart :data="totalData"/>
                    <hr class="hr-line-dashed"/>
                    <b-table striped hover bordered responsive :fields="totalFields" :items="totalData">
                        <template v-slot:cell(diff)="data">
                            {{ Math.abs(data.item.avg - data.item.user_avg).toFixed(2) }}
                        </template>
                    </b-table>
                </ibox-content>
            </ibox>
            <ibox>
                <ibox-title>
                    Результаты диагностики
                </ibox-title>
                <ibox-content>
                    <b-table striped hover bordered responsive :fields="resultFields" :items="resultData">
                        <template v-slot:cell(startTime)="data">
                            {{data.item.startTime | formatDate}}
                        </template>
                        <template v-slot:cell(endTime)="data">
                            {{data.item.endTime | formatDate}}
                        </template>
                        <template v-slot:cell(stressAvg)="data">
                            <neuro-result-calc :avg="data.item.stressAvg" :same-avg="data.item.sameStressAvg" />
                        </template>
                        <template v-slot:cell(passionAvg)="data">
                            <neuro-result-calc :avg="data.item.passionAvg" :same-avg="data.item.samePassionAvg" />
                        </template>
                        <template v-slot:cell(cognitiveAvg)="data">
                            <neuro-result-calc :avg="data.item.cognitiveAvg" :same-avg="data.item.sameCognitiveAvg" />
                        </template>
                        <template v-slot:cell(concentrationAvg)="data">
                            <neuro-result-calc :avg="data.item.concentrationAvg" :same-avg="data.item.sameConcentrationAvg" />
                        </template>
                    </b-table>
                </ibox-content>
            </ibox>
            <ibox>
                <ibox-title>
                    Общая статистика
                </ibox-title>
                <ibox-content>
                    <b-table striped hover bordered responsive :fields="fields" :items="neuroData">
                        <template v-slot:cell(moduleElement)="data">
                            <span v-if="data.item.testResult">
                                {{ data.item.testResult.testName }}
                            </span>
                            <span v-else>
                                Видео-лекция &laquo;Тайм-менеджмент&raquo;
                            </span>
                        </template>
                        <template v-slot:cell(dateTime)="data">
                            {{data.item.dateTime | formatDate}}
                        </template>
                    </b-table>
                </ibox-content>
                <ibox-footer>
                    <b-pagination v-if="pagination"
                                  v-model="currentPage"
                                  :total-rows="pagination.total"
                                  :per-page="pagination.limit"
                                  aria-controls="stats-table"
                                  @input="getData()"
                                  prev-text="Назад"
                                  next-text="Вперед"
                    ></b-pagination>
                </ibox-footer>
            </ibox>
        </div>
    </div>
</template>

<script>
    import IboxContent from "../Ibox/IboxContent";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import BreadCrumbs from "../BreadCrumbs";
    import IboxFooter from "../Ibox/IboxFooter";
    import NeuroChart from "./NeuroChart";
    import NeuroResultCalc from "./NeuroResultCalc";
    export default {
        name: "Neuro",
        components: {NeuroResultCalc, NeuroChart, IboxFooter, BreadCrumbs, IboxTitle, Ibox, IboxContent},
        data() {
            return {
                neuroData: null,
                user: null,
                pagination: null,
                currentPage: 1,
                totalFields: [
                    {
                        key: 'type',
                        label: 'Параметр'
                    },
                    {
                        key: 'avg',
                        label: 'Общая средняя оценка'
                    },
                    {
                        key: 'user_avg',
                        label: 'Средняя оценка пользователя'
                    },
                    {
                        key: 'diff',
                        label: 'Отклонение'
                    }
                ],
                fields: [
                    {
                        key: 'type',
                        label: 'Параметр',
                        sortable: true
                    },
                    {
                        key: 'moduleElement',
                        label: 'Элемент обучения',
                        sortable: true
                    },
                    {
                        key: 'mark',
                        label: 'Оценка',
                        sortable: true
                    },
                    {
                        key: 'dateTime',
                        label: 'Время',
                        sortable: true
                    }
                ],
                resultFields: [
                    {
                        key: 'testName',
                        label: 'Тест'
                    },
                    {
                        key: 'result',
                        label: 'Результат'
                    },
                    {
                        key: 'startTime',
                        label: 'Время старта',
                        sortable: true
                    },
                    {
                        key: 'timeCount',
                        label: 'Времени потрачено, с',
                        sortable: true
                    },
                    {
                        key: 'stressAvg',
                        label: 'Средняя оценка стресса/отклонение'
                    },
                    {
                        key: 'passionAvg',
                        label: 'Средняя оценка увлеченности/отклонение'
                    },
                    {
                        key: 'cognitiveAvg',
                        label: 'Средняя оценка когнитивной нагрузки/отклонение'
                    },
                    {
                        key: 'concentrationAvg',
                        label: 'Средняя оценка концентрации/отклонение'
                    }
                ],
                totalData: null,
                resultData: null
            }
        },
        methods: {
            getTotal() {
                this.axios.get(`neuro-total`, {
                    params: {
                        user: this.$route.params.id
                    }
                }).then(res => {
                    this.totalData = res.data;
                })
            },
            getData() {
                this.axios.get(`neuro-data`, {
                    params: {
                        user: this.$route.params.id,
                        offset: (this.currentPage-1) * 30
                    }
                }).then(res => {
                    this.neuroData = res.data.data;
                    this.pagination = res.data.pagination;
                })
            },
            getTestResults() {
                this.axios.get(`sdpk-result/${this.$route.params.id}`).then(res => {
                    this.resultData = res.data;
                })
            }
        },
        mounted() {
            this.axios.get(`users/${this.$route.params.id}`).then(res => {
                this.user = res.data;
                this.getData();
                this.getTotal();
                this.getTestResults();
            })
        }
    }
</script>

<style scoped>

</style>
