<template>
    <span v-if="data">
        <div class="question mb-3 table-responsive" v-html="data.question.name"></div>
        <div class="answers">
            <div v-if="data.question.type === TYPES.simple">
                <b-form-radio-group
                        v-model="selected"
                        :name="`q${data.question.id}`"
                        :disabled="readOnly"
                        @change="handleRadioChange">
                    <div v-for="(answer, index) in data.answers" :key="index">
                        <b-form-radio
                            :value="answer.id"
                            v-bind:class="{'correct-answer': correctAnswers === answer.id}"
                        >
                            {{ answer.name }}
                        </b-form-radio>
                    </div>
                </b-form-radio-group>
            </div>
            <div v-else-if="data.question.type === TYPES.multiple">
                <b-form-checkbox-group
                        v-model="selected"
                        :name="`q${data.question.id}`"
                        :disabled="readOnly"
                        @change="handleCheckboxChange">
                    <div v-for="(answer, index) in data.answers" :key="index">
                        <b-form-checkbox
                            :value="answer.id"
                            v-bind:class="{'correct-answer': correctAnswers.includes(answer.id)}"
                        >
                            {{ answer.name }}
                        </b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </div>
            <div v-else-if="data.question.type === TYPES.typed">
                <valid-textarea
                        @change="handleTextAreaChange"
                        :disabled="readOnly"
                        :value="selected"
                />
            </div>
            <div v-else-if="data.question.type === TYPES.matchRadio">
                <question-match-radio
                    :id="data.question.id"
                    :task="data.question.match.task"
                    v-model="data.match"
                ></question-match-radio>
            </div>
        </div>
    </span>
</template>

<script>
    import {TYPE_ANSWER} from "./TestFormQuestion";
    import ValidTextarea from "../Validation/ValidTextarea";
    import QuestionMatchRadio from "../TestConstructor/questions/QuestionMatchRadio";
    export default {
        name: "TestPassageProcessQuestion",
        components: {QuestionMatchRadio, ValidTextarea},
        props: {
            data: Object,
            readOnly: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                TYPES: TYPE_ANSWER,
                selected: [],
                correctAnswers: [],
            }
        },
        mounted() {
            switch (this.data.question.type) {
                case TYPE_ANSWER.typed:
                case TYPE_ANSWER.simple:
                    this.selected = this.data.selected[0] || null;
                    this.correctAnswers = this.data.correctAnswers ? this.data.correctAnswers[0] : [];
                    break;
                case TYPE_ANSWER.multiple:
                    this.selected = this.data.selected;
                    this.correctAnswers = this.data.correctAnswers || [];
                    break;
            }
        },
        methods: {
            handleRadioChange(value) {
                const event = {question: this.data.question.id, values: [value], match: null};
                this.$emit('update-answer', event);
            },
            handleCheckboxChange(values) {
                const event = {question: this.data.question.id, values: values, match: null};
                this.$emit('update-answer', event);
            },
            handleMatchChange(match) {
              const event = {question: this.data.question.id, values: [], match: match};
              this.$emit('update-answer', event);
            },
            handleTextAreaChange(value) {
                const event = {question: this.data.question.id, values: [value], match: null};
                this.$emit('update-answer', event);
            }
        },
      computed: {
          match() {
            return this.data.match;
          }
      },
      watch: {
        match: {
          deep: true,
          handler(val) {
            console.log(val)
            this.handleMatchChange(val)
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .question {
      font-size: 16px;
      font-weight: bold;
      table {
        max-width: 100%!important;
        min-width: 0!important;
        display: table;
      }
      img {
        max-width: 100%!important;
      }
    }
    .answers {
        font-size: 14px;
    }
    .correct-answer {
        background-color: #00f889;
    }
</style>
