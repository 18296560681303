<template>
    <div class="animated fadeIn">
        <BreadCrumbs title="Каталог тестов" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    <div class="ibox-tools">
                        <a v-b-tooltip title="Добавить" v-b-modal.add-category>
                            <i class="fa fa-plus"></i> Добавить
                        </a>
                    </div>
                </div>
                <div class="ibox-content">
                    <b-row>
                        <b-col v-for="cat in categories" :key="cat.id" md="3">
                            <router-link class="without-hover" :to="`/admin/test-categories/${cat.id}`" variant="primary">
                                <ibox class="product-box">
                                    <ibox-title :hide-tools="true">
                                        <div style="font-size: 12px; font-weight: bold;" class="pull-right">
                                            <b-badge style="position: absolute; top: -10px; right: -10px;" variant="danger"><i class="fa fa-outdent"></i> {{cat.testsСount}} {{ cat.testsСount|pluralize('ru', ['тест', 'теста', 'тестов']) }}</b-badge>
                                        </div>
                                        <h4>{{ cat.name }}</h4>
                                    </ibox-title>
                                    <ibox-content>
                                        <div style="font-size: 16px;" v-html="cat.description"></div>
                                    </ibox-content>
                                </ibox>
                            </router-link>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <b-modal
                id="add-category"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Добавление новой категории"
                @ok="onSubmit"
                ok-title="Сохранить"
                cancel-title="Отмена"
        >
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <valid-input name="название" label="Название" v-model="formData.name" :rules="{required: true, min: 3}" />
                    <valid-summernote name="описание" label="Описание" v-model="formData.description" :rules="{required: true, min: 3}" />
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import ValidInput from "../Validation/ValidInput";
    import ValidSummernote from "../Validation/ValidSummernote";
    import IboxContent from "../Ibox/IboxContent";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    export default {
        name: "TestCategoryList",
        components: {IboxTitle, Ibox, IboxContent, ValidSummernote, ValidInput, BreadCrumbs},
        data() {
            return {
                form: {},
                modalSubmitting: false,
                selectedTestId: null,
                categories: [],
                category: null,
                formData: {},
                test:[],
                fields: [
                    {
                        key: 'name',
                        label: 'Название',
                        sortable: true
                    },
                    {
                        key: 'description',
                        label: 'Описание',
                        sortable: false
                    },
                    {
                        key: 'testsСount',
                        label: 'Количество тестов',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load(false);
        },
        methods: {
            load(showMsg) {
                this.axios.get('sdpk/get/TestCategory/all').then(res => {
                    this.categories = res.data;
                    if (showMsg) {
                        this.$toasted.global.appSuccess();
                    }
                });
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.modalSubmitting = true;
            },
            handleSubmitting(status) {
                this.modalSubmitting = status;
            },
            handleSubmitResult(success) {
                if (success) {
                    this.$nextTick(() => {
                        this.$bvModal.hide('add-test')
                    });
                    this.load(false);
                }
                this.modalSubmitting = false;
            },
            onSubmit() {
                this.axios.post('sdpk/save/TestCategory/new', this.formData).then(() => {
                    this.load();
                })
            }
        }
    }
</script>

<style scoped>
    .mr-30 {
        margin-bottom: 10px;
        margin-right: 0;
    }
    .without-hover {
        color: inherit;
    }
</style>
