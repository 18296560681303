<template>
    <div class="animated fadeIn" v-if="course">
        <bread-crumbs :title="`Статистика по курсу ${course.name}`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Статистика', url: '/admin/stats'}]" />
        <div class="wrapper-content wrapper">
            <div class="row">
                <div class="col-md-4"></div>
            </div>
            <div v-if="stats" class="row">
                <div class="col-lg-8">
                    <div class="ibox ">
                        <div class="ibox-content">
                            <div>
                                <span class="float-right text-right">
                                    <small>Среднее число новых пользователей в день:</small>
                                    <br/>
                                    {{Math.ceil(course.enrollCount/12)}}
                                </span>
                                <h3 class="font-bold no-margins">
                                    Информация по прогрессу пользователей
                                </h3>
                                <small style="opacity: 0;">По датам обучения</small>
                            </div>

                            <div class="m-t-sm">

                                <div class="row">
                                    <div class="col-md-8">
                                        <div>
                                            <!--<line-chart :chart-data="data"></line-chart>-->
                                            <canvas id="lineChart" height="114"></canvas>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="stat-list m-t-lg">
                                            <li>
                                                <h2 class="no-margins">{{ course.enrollCount }}</h2>
                                                <small>Пользователей на  курсе</small>
                                            </li>
                                            <li>
                                                <h2 class="no-margins ">{{ course.pointProgress }} <small>из</small> {{course.points * course.enrollCount}}</h2>
                                                <small>Баллов набрано</small>
                                                <div class="progress progress-mini">
                                                    <div class="progress-bar" :style="'width: ' + course.progress + '%;'"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div class="m-t-md">
                                <small class="float-right">
                                    <i class="fa fa-clock-o"> </i>
                                    Обновлено {{ ISODateString() }}
                                </small>
                                <small>
                                    Данные обновляются<strong> 1 раз в день</strong>.
                                </small>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ibox ">
                        <div class="ibox-title">
                            <h5>Активность пользователей</h5>
                        </div>
                        <div class="ibox-content">
                            <div class="row">
                                <div class="col-4">
                                    <small class="stats-label">Просмотры видео</small>
                                    <h4>{{ stats.countStat.film }}</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Новых</small>
                                    <h4 v-if="stats.yesterday">{{ calPercent(stats.countStat.film, stats.yesterday.countStat.film) }} %</h4>
                                    <h4 v-else>0%</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Вчера</small>
                                    <h4 v-if="stats.yesterday">{{ stats.yesterday.countStat.film }}</h4>
                                    <h4 v-else>0</h4>
                                </div>
                            </div>
                        </div>
                        <div class="ibox-content">
                            <div class="row">
                                <div class="col-4">
                                    <small class="stats-label">Сдано тестов</small>
                                    <h4>{{ stats.countStat.list }}</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Новых</small>
                                    <h4 v-if="stats.yesterday">{{ calPercent(stats.countStat.list, stats.yesterday.countStat.list) }} %</h4>
                                    <h4 v-else>0%</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Вчера</small>
                                    <h4 v-if="stats.yesterday">{{ stats.yesterday.countStat.list }}</h4>
                                    <h4 v-else>0</h4>
                                </div>
                            </div>
                        </div>
                        <div class="ibox-content">
                            <div class="row">
                                <div class="col-4">
                                    <small class="stats-label">Скачано файлов</small>
                                    <h4>{{ stats.countStat['file-pdf-o'] }}</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Новых</small>
                                    <h4 v-if="stats.yesterday">{{ calPercent(stats.countStat['file-pdf-o'], stats.yesterday.countStat['file-pdf-o']) }} %</h4>
                                    <h4 v-else>0%</h4>
                                </div>
                                <div class="col-4">
                                    <small class="stats-label">Вчера</small>
                                    <h4 v-if="stats.yesterday">{{ stats.yesterday.countStat['file-pdf-o'] }}</h4>
                                    <h4 v-else>0</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="stats" class="row">

                <div class="col-lg-12">
                    <div class="ibox ">
                        <div class="ibox-title">
                            <h5 v-if="!currentUser">Слушатели </h5>
                            <span v-else>
                                <h5 class="d-inline-block">
                                    <a style="text-decoration: underline;" @click="currentUser = null">Слушатели</a>
                                </h5> / {{ currentUser.fullname }}
                            </span>
                        </div>
                        <div v-if="!currentUser" class="ibox-content">
                            <div class="row">
                                <b-col class="text-right">
                                  <div class="form-inline">
                                    <multiselect
                                        v-model="checkedModule"
                                        :multiple="false"
                                        :searchable="true"
                                        :options="course.modules"
                                        placeholder="Выберите модуль"
                                        :custom-label="opt => opt.name"
                                        openDirection="bottom"
                                        @select="checkModule($event)"
                                    ></multiselect>
                                  </div>
                                </b-col>
                                <b-col class="text-right">
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-sm" @input="getUsers($event.target.value)" placeholder="Введите ФИО">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-success" type="button">Найти</button>
                                        </div>
                                        <div class="input-group-append">
                                          <button class="btn btn-sm btn-primary" type="button" @click="download()">
                                            <i class="fa fa-file-excel-o"></i> Скачать
                                          </button>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-primary" type="button" @click="downloadStat()">
                                                <i class="fa fa-file-excel-o"></i> Скачать ведомость
                                            </button>
                                        </div>
                                    </div>
                                </b-col>
                            </div>
                            <hr class="hr-line-dashed"/>
                            <div class="table-responsive">
                                <b-table striped hover :fields="fields" :responsive="true" @sort-changed="sortChanged" :items="enrolls">
                                    <template v-slot:cell(lastAction)="data">
                                        <last-element :action="data.item.user.lastAction"/>
                                    </template>
                                    <template v-slot:cell(progress)="data">
                                        <div style="margin-left: 10px;" class="stat-percent font-bold text-navy">{{data.item.progress}}% <i class="fa fa-bolt"></i></div>
                                        <div class="progress progress-mini">
                                            <div class="progress-bar" :style="'width:' + data.item.progress + '%;'"></div>
                                        </div>
                                    </template>
                                    <template v-slot:cell(points)="data">

                                        <b-btn @click="currentUser = data.item.user" variant="success" size="sm">
                                            <i class="fa fa-line-chart"></i> {{data.item.points}} {{data.item.points|pluralize('ru', ['балл', 'балла', 'баллов'])}}
                                        </b-btn>
                                    </template>
                                  <template v-slot:cell(moduleData[videoCount])="data">
                                    {{data.item.moduleData.videoCount}}
                                  </template>
                                  <template v-slot:cell(moduleData[hwCount])="data">
                                    {{data.item.moduleData.hwCount}}
                                  </template>
                                  <template v-slot:cell(moduleData[testCount])="data">
                                    {{data.item.moduleData.testCount}}
                                  </template>
                                  <template v-slot:cell(moduleData[points])="data">
                                    {{data.item.moduleData.points}}
                                  </template>
                                </b-table>
                                <b-pagination v-if="pagination"
                                              v-model="currentPage"
                                              :total-rows="pagination.total"
                                              :per-page="pagination.limit"
                                              aria-controls="stats-table"
                                              @input="getUsers()"
                                              prev-text="Назад"
                                              next-text="Вперед"
                                ></b-pagination>
                            </div>
                        </div>
                        <user-stat-table v-else :pagination="pagination" :course="course.id" :user="currentUser" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import Chart from 'chart.js';
    import {} from 'flot';
    import BreadCrumbs from "../BreadCrumbs";
    import UserStatTable from "../User/UserStatTable";
    import {TYPES, TYPES_LIST} from "../User/actionsTypes";
    import LastElement from "../Stat/LastElement";
    import Vue from "vue";
    export default {
        name: "CourseStatsDetail",
        components: {LastElement, UserStatTable, BreadCrumbs},
        data() {
            return {
                exportData: null,
                course: null,
                loadingAllData: true,
                enrolls: [],
                currentUser: null,
                pagination: null,
                currentPage: 1,
                filter: '',
                stats: null,
                isActive: false,
                checkedModule: null,
                fields: [
                    {
                        key: 'id',
                        label: '#'
                    },
                    {
                        key: 'user.fullname',
                        label: 'ФИО'
                    },
                    {
                        key: 'user.username',
                        label: 'Email'
                    },
                    {
                      key: 'projectForm[7].name',
                      label: 'Город'
                    },
                    {
                      key: 'projectForm[2]',
                      label: 'Возраст'
                    },
                    {
                      key: 'projectForm[5]',
                      label: 'Номер телефона'
                    },
                    {
                      key: 'projectForm[6]',
                      label: 'Место работы'
                    },
                    {
                        key: 'progress',
                        sortable: true,
                        label: 'Процент завершения'
                    },
                    {
                      key: 'points',
                      sortable: true,
                      label: 'Успеваемость'
                    },
                    {
                        key: 'lastAction',
                        label: 'Последний эелемент'
                    }
                ]
            }
        },
        mounted() {
            let loader = Vue.$loading.show(this.loaderStyle());
            this.axios.get(`courses/${this.$route.params.id}`).then(res => {
                this.course = res.data;
                this.getUsers()
                this.axios.get(`courses/${this.$route.params.id}/stat`).then(res => {
                    this.stats = res.data;
                    let self = this;
                    $(document).ready(function() {
                        let lineData = {
                            labels: self.stats.enrollStat.labels,
                            datasets: [
                                {
                                    label: "Хронология записи на курс",
                                    backgroundColor: "rgba(26,179,148,0.5)",
                                    borderColor: "rgba(26,179,148,0.7)",
                                    pointBackgroundColor: "rgba(26,179,148,1)",
                                    pointBorderColor: "#fff",
                                    data: self.stats.enrollStat.countsData
                                },
                                {
                                    label: "Баллы по курсу",
                                    backgroundColor: "rgba(220,220,220,0.5)",
                                    borderColor: "rgba(220,220,220,1)",
                                    pointBackgroundColor: "rgba(220,220,220,1)",
                                    pointBorderColor: "#fff",
                                    data: []
                                }
                            ]
                        };

                        loader.hide();

                        let lineOptions = {
                            responsive: true
                        };
                        let ctx = document.getElementById("lineChart").getContext("2d");
                        new Chart(ctx, {type: 'line', data: lineData, options:lineOptions});
                    })
                });
                let params = {
                  course: this.course.id,
                  'order[points]': 'desc',
                  'forExport': true
                };
                this.axios.get(`/enrollments`, {
                  params: params
                }).then(res => {
                  this.exportData  = res.data.data;
                  this.loadingAllData = false;
                });
            })
        },
        methods: {
          checkModule(checkedModule) {
            let params = {
              course: this.course.id,
              withModuleResult: checkedModule.id,
              'order[points]': 'desc'
            };
            this.axios.get(`/enrollments`, {
              params: params
            }).then(res => {
              this.enrolls = res.data.data;
              this.exportData = res.data.data;
              this.pagination = res.data.pagination;
              this.fields = [
                {
                  key: 'id',
                  label: '#'
                },
                {
                  key: 'user.fullname',
                  label: 'ФИО'
                },
                {
                  key: 'moduleData[testCount]',
                  label: 'Количество сданных тестов'
                },
                {
                  key: 'moduleData[videoCount]',
                  label: 'Количество просмотренных видео'
                },
                {
                  key: 'moduleData[hwCount]',
                  label: 'Количество сданных ДЗ'
                },
                {
                  key: 'user.username',
                  label: 'Email'
                },
                {
                  key: 'projectForm[7].name',
                  label: 'Город'
                },
                {
                  key: 'projectForm[2]',
                  label: 'Возраст'
                },
                {
                  key: 'projectForm[5]',
                  label: 'Номер телефона'
                },
                {
                  key: 'projectForm[6]',
                  label: 'Место работы'
                },
                {
                  key: 'moduleData[points]',
                  sortable: true,
                  label: 'Баллов за модуль'
                },
                {
                  key: 'progress',
                  sortable: true,
                  label: 'Процент завершения'
                },
                {
                  key: 'points',
                  sortable: true,
                  label: 'Всего баллов'
                }
              ];
            });
          },
          getData() {
            let data = [];
              if(!this.exportData) {
                return null;
              }

              console.log(this.exportData)

              this.exportData.forEach(enroll => {

                  let element = {
                    'id': enroll.user.id,
                    'ФИО': enroll.user.fullname,
                    'Email': enroll.user.email,
                    'Город': enroll.projectForm && typeof enroll.projectForm[1] !== 'undefined' ? enroll.projectForm[1].name : enroll.projectForm && typeof enroll.projectForm[7] !== 'undefined' ? enroll.projectForm[7].name : null,
                    'Возраст': enroll.projectForm ? enroll.projectForm[2] : null,
                    'Номер телефона': enroll.projectForm ? enroll.projectForm[5] : null,
                    'Место работы': enroll.projectForm ? enroll.projectForm[6] : null,
                    'Видео просмотрено': enroll.moduleData ? enroll.moduleData['videoCount'] : null,
                    'Тестов сдано': enroll.moduleData ? enroll.moduleData['testCount'] : null,
                    'ДЗ работы': enroll.moduleData ? enroll.moduleData['hwCount'] : null,
                    'Баллов за модуль': enroll.moduleData && typeof enroll.moduleData['points'] !== 'undefined' ? enroll.moduleData['points'].toFixed(0) : null,
                    'Прогресс': enroll.progress.toFixed(0),
                    'Успеваемость': enroll.points.toFixed(0),
                    'Активен на курсе': enroll.isActive ? 'Да' : 'Нет',
                    'Есть сертификат': enroll.certificate ? 'Да' : 'Нет',
                  };
                Object.keys(element).forEach(key => element[key] === null && delete element[key])
                data.push(element);
              })
              return data;
          },
          download() {
            this.axios.get('/courses/' + this.course.id + '/download', {
              responseType: 'blob'
            }).then((response) => {
              // Let's create a link in the document that we'll
              // programmatically 'click'.
              const link = document.createElement('a');

              // Tell the browser to associate the response data to
              // the URL of the link we created above.
              link.href = window.URL.createObjectURL(
                  new Blob([response.data])
              );

              // Tell the browser to download, not render, the file.
              link.setAttribute('download',   `Статистика ${this.course.id}.xlsx`);

              // Place the link in the DOM.
              document.body.appendChild(link);

              // Make the magic happen!
              link.click();
            }); // Please catch me!
          },
          downloadStat() {
              window.open(`${this.axios.defaults.baseURL}pdf/course_bill/${this.course.id}?access_token=${this.$auth.token()}`, 'Download');
          },
          sortChanged(ctx) {
              this.getUsers(null, ctx.sortDesc)
              return false;
          },
          getUsers(query = null, order = null) {
              let params = query && query.length > 3 ? {
                  userSearch: query,
                  course: this.course.id,
                  isActive: this.isActive,
                  withModuleResult: this.checkedModule ? this.checkedModule.id : null,
                  'order[points]': order === false ? 'asc' : 'desc'
              } : {
                  isActive: this.isActive,
                  course: this.course.id,
                  withModuleResult: this.checkedModule ? this.checkedModule.id : null,
                  offset: (this.currentPage-1)*30,
                  'order[points]': order === false ? 'asc' : 'desc'
              };
              this.axios.get(`/enrollments`, {
                  params: params
              }).then(res => {
                  this.enrolls = res.data.data;
                  this.pagination = res.data.pagination;
              });
          },
          pad(n){
              return n < 10 ? '0'+n : n
          },
          calPercent(oldPoints, newPoints) {
              if(oldPoints === 0) {
                  return 0;
              }
              return Math.floor((1 - newPoints/oldPoints)*100);
          },
          ISODateString() {
              let d = new Date();
              return this.pad(d.getUTCDate()) + '.' +
                  this.pad(d.getUTCMonth()+1) + '.' +
                  d.getUTCFullYear() + ' в 06:00'
          },
          getClassByType(type) {
              let styleClass;
              switch (type) {
                  case TYPES.ENTER_TYPE:
                      styleClass = 'label-primary';
                      break;
                  case TYPES.VIDEO_TYPE:
                      styleClass = 'label-warning';
                      break;
                  case TYPES.COURSE_ENROLL_TYPE:
                      styleClass = 'label-success';
                      break;
                  case TYPES.COURSE_UN_ENROLL_TYPE:
                      styleClass = 'label-danger';
                      break;
                  default:
                      styleClass = 'label-default';
                      break;
              }
              return styleClass;
          },
          getTitleByType(type) {
              return TYPES_LIST[type];
          }
        }
    }
</script>

<style scoped>

</style>
