<template>
  <div v-if="project && userProject" class="animate fadeIn">
    <bread-crumbs :title="`Результаты пользователя ${userProject.user.fullname}`" :items="[
            {title: 'Главная', url: '/#/'},
            {title: 'Проекты', url: '/admin/projects'},
            {title: project.title, url: `/projects/${project.id}/edit`},
            {title: 'Пользователи проекта', url: `/projects/${project.id}/project-users`},
          ]" />
    <div class="wrapper wrapper-content">
      <div v-if="stats" class="row">
        <div class="col-md-6">
          <ibox>
            <ibox-title>
              Результаты тестирования
            </ibox-title>
            <ibox-content v-if="stats.testResult.length">
              <b-table :items="stats.testResult" :fields="testResultFields">
                <template v-slot:cell(resultDay)="data">
                  {{data.item.resultDay | formatDate}}
                </template>
                <template v-slot:cell(mark)="data">
                  <b-progress :value="data.item.mark ? toFixed(data.item.mark) : 0" :variant="variant(toFixed(data.item.mark))" :max="100" show-progress animated></b-progress>
                </template>
              </b-table>
            </ibox-content>
            <ibox-content v-else>
              <p class="text-muted">В рамках проекта тестирование пользователя не проводилось</p>
            </ibox-content>
          </ibox>
        </div>
        <div class="col-md-6">
          <ibox>
            <ibox-title>
              Результаты обучения
            </ibox-title>
            <ibox-content v-if="stats.courseResult.length">
              <b-table :items="stats.courseResult" :fields="courseResultFields">
                <template v-slot:cell(createdAt)="data">
                  {{data.item.createdAt | formatDate}}
                </template>
                <template v-slot:cell(progress)="data">
                  <b-progress :value="data.item.result" :variant="variant(toResult(data.item.result,data.item.maxResult))" :max="data.item.maxResult" show-progress animated></b-progress>
                </template>
              </b-table>
            </ibox-content>
            <ibox-content v-else>
              <p class="text-muted">В рамках проекта обучение пользователя не проводилось</p>
            </ibox-content>
          </ibox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs";
import Ibox from "../Ibox/Ibox";
import IboxContent from "../Ibox/IboxContent";
import IboxTitle from "../Ibox/IboxTitle";
export default {
  name: "ProjectUserStat",
  components: {IboxTitle, IboxContent, Ibox, BreadCrumbs},
  data() {
    return {
      stats: null,
      project: null,
      userProject: null,
      courseResultFields: [
        {
          key: 'title',
          label: 'Название курса'
        },
        {
          key: 'createdAt',
          label: 'Дата начала обучения'
        },
        {
          key: 'progress',
          label: 'Прогресс'
        }
      ],
      testResultFields: [
        {
          key: 'test.code',
          label: 'Код теста'
        },
        {
          key: 'test.name',
          label: 'Название теста'
        },
        {
          key: 'resultTime',
          label: 'Затрачено времени, с'
        },
        {
          key: 'resultDay',
          label: 'Дата, время сдачи'
        },
        {
          key: 'mark',
          label: 'Результат'
        }
      ],
    }
  },
  mounted() {
    this.axios.get(`projects/${this.$route.params.projectId}`, {
      params: {
        hideLoader: false
      }
    }).then(res => {
      this.project = res.data;
      this.axios.get(`user_projects/${this.$route.params.id}`).then(res => {
        this.userProject = res.data;
      })
      this.axios.get(`user_projects/${this.$route.params.id}/stats`).then(res => {
        this.stats = res.data;
      })
    });
  },
  methods: {
    toFixed(val) {
      return parseFloat(val).toFixed(2)*100;
    },
    toResult(val, maxVal) {
      return parseFloat(val/maxVal).toFixed(2)*100;
    },
    variant(val) {
      if(val < 40) {
        return 'danger';
      }
      if(val < 60) {
        return 'warning';
      }
      return 'primary';
    }
  }
}
</script>

<style scoped>

</style>
