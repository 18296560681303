import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins
export default {
    extends: Bar,
    props: ['datasets', 'vid', 'options'],
    mixins: [reactiveProp],
    data() {
        return {
            optionsData: this.options ? this.options : {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.optionsData)
    }
}
