<template>
    <div class="animated fadeIn">
        <bread-crumbs title="Конструктор отчетов" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>Конструктор отчетов</ibox-title>
                <ibox-content>
                    <div v-for="(constructor, index) in constructors" :key="index">
                        <hr v-if="index" class="hr-line-dashed"/>
                        <stat-constructor :id="index" :title="constructor.title"></stat-constructor>
                    </div>
                </ibox-content>
                <ibox-footer>
                    <b-btn variant="primary" @click="addArray">
                        <i class="fa fa-plus-circle"></i> Добавить массив данных
                    </b-btn>
                    <template>
                        |
                        Массивы в сравнении:
                        <span v-for="(constructor, index) in constructors" :key="index">
                            <template v-if="index > 0">
                                <b-btn @click="onDelete(index)" class="m-right-10" :key="index" size="xs" variant="success">{{constructor.title}} <i class="fa fa-close"></i></b-btn>
                            </template>
                            <template v-else>
                                <b-btn class="m-right-10" :disabled="true" :key="index" size="xs" variant="success">{{constructor.title}}</b-btn>
                            </template>
                        </span>
                    </template>
                </ibox-footer>
            </ibox>
        </div>
    </div>
</template>

<script>
    import StatConstructor from "./StatConstructor";
    import IboxContent from "../Ibox/IboxContent";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import BreadCrumbs from "../BreadCrumbs";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "StatConstructorContainer",
        components: {IboxFooter, BreadCrumbs, IboxTitle, Ibox, IboxContent, StatConstructor},
        data() {
            return {
                constructors: [{
                    title: 'Массив 1'
                }]
            }
        },
        methods: {
            addArray() {
                this.constructors.push({
                    title: `Массив ${this.constructors.length + 1}`
                })
            },
            onDelete(index) {
                this.constructors.splice(index, 1);
            }
        }
    }
</script>

<style scoped>
    .m-right-10 {
        margin-right: 10px;
    }
    .m-right-10:first-child {
        margin-left: 10px;
    }
</style>
