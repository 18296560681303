<template>
  <div v-if="project && userProject" class="animate fadeIn">
    <bread-crumbs title="Назначение тестирования" :items="[
            {title: 'Главная', url: '/#/'},
            {title: 'Проекты', url: '/admin/projects'},
            {title: project.title, url: `/projects/${project.id}/edit`},
            {title: 'Пользователи проекта', url: `/projects/${project.id}/project-users`},
          ]" />
    <div class="wrapper wrapper-content">
      <form ref="form" @submit.stop.prevent="handleSubmit()">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Назначение тестирования {{userProject.fullname}}</h5>
          </div>
          <div v-if="tests" class="ibox-content">
              <multiselect
                  v-model="userProject.tests"
                  :options="tests"
                  :multiple="true"
                  track-by="id"
                  :custom-label="(opt) => opt.name"
                  openDirection="bottom"
                  placeholder="Выберите тест"
              ></multiselect>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-primary" type="submit">Сохранить</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs";
export default {
  name: "ProjectUser",
  components: {BreadCrumbs},
  data() {
    return {
      project: null,
      userProject: null,
      tests: null
    }
  },
  mounted() {
    this.axios.get(`projects/${this.$route.params.projectId}`).then(res => {
      this.project = res.data;
      this.axios.get(`user_projects/${this.$route.params.id}`).then(res => {
        this.userProject = res.data;
        if(!this.project) {
          return;
        }
        let projectTests = [...this.project.enter, ...this.project.exit];
        if(projectTests.length) {
          this.tests = [];
          projectTests.forEach(category => {
            this.axios
                .get('sdpk/get/Test/headers/' + category.id)
                .then(res => {
                  this.tests = [...res.data];
                })
          })
        }
      })
    })
  },
  methods: {
    handleSubmit() {
      this.axios.put(`user_projects/${this.$route.params.id}`, this.userProject).then(res => {
        if(res.status === 200) {
          this.$toasted.global.appSuccess();
        } else {
          this.$toasted.global.appError('Ошибка');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
