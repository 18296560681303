<template>
    <b-modal v-if="institute"
            id="add-access"
            ref="modal"
            button-size="sm"
            title="Предоставление доступа"
            :hide-footer="true"
    >
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(onSubmit)">
                <b-form-group label="Фото пользователя">
                    <AvatarUpload/>
                </b-form-group>
                <ValidInput label="Логин (email)" name="логин" :rules="{ required: true, email:true }" v-model="addUserForm.username"/>
                <ValidInput label="Пароль" name="пароль" :rules="{ required: true, min: 6 }" v-model="addUserForm.password"/>
                <ValidInput label="Имя" name="имя" :rules="{ required: true, min: 3 }" v-model="addUserForm.firstName"/>
                <ValidInput label="Отчество" name="отчество" :rules="{ required: true, min: 3 }" v-model="addUserForm.middleName" />
                <ValidInput label="Фамилия" name="фамилия" v-model="addUserForm.lastName" :rules="{ required: true, min: 3 }"/>
                <ValidSelect label="Роль" name="роль" :options="roles" :rules="{required: true}" v-model="userInstitutionForm.roles" label-title="name" open-direction="top" track-by="role"/>
                <b-button type="submit" size="sm" variant="primary">Сохранить</b-button>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import bus from '../bus.js'
    import ROLES from "./User/roles";
    import AvatarUpload from "./AvatarUpload";
    import ValidSelect from "./Validation/ValidSelect";
    import ValidInput from "./Validation/ValidInput";
    export default {
        name: "AddUser",
        components: {ValidInput, ValidSelect, AvatarUpload},
        props: ['institute'],
        data() {
            return {
                addUserForm: {},
                userInstitutionForm: {
                    institution: '/institutions/' + this.institute.id,
                    role: null,
                    user: null
                },
                roles: []
            }
        },
        mounted() {
            Object.keys(ROLES).forEach(roleKey => {
                this.roles.push({name: ROLES[roleKey], role: roleKey});
            })
            bus.$on('avatar-upload', res => {
                this.addUserForm.photo = res
            })
        },
        methods: {
            onSubmit() {
                this.axios.post('users', this.addUserForm)
                    .then(res => {
                        if(res.status === 201) {
                            this.userInstitutionForm.user = '/users/' + res.data.id;
                            this.userInstitutionForm.roles = this.userInstitutionForm.roles.map(role => role.role);
                            this.axios.post('user_institutions', this.userInstitutionForm).then(() => {
                                this.$bvModal.hide('add-access');
                                bus.$emit('user-added')
                                this.addUserForm = {}
                                this.userInstitutionForm = {
                                    institution: '/institutions/' + this.institute.id,
                                        role: null,
                                        user: null
                                }
                            })
                        }
                    })
                ;
            }
        }
    }
</script>

<style scoped>

</style>
