<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <carousel :per-page="1"
              :per-page-custom="[[800, 1], [800, 1], [1000, 2], [1100, 3], [1400, 4]]"
              :navigation-enabled="myWindow.innerWidth > 500"
              :pagination-enabled="false"
              :center-mode="true"
              :loop="true"
              :navigation-next-label="`<img src='/img/landing/slide-right.png'/>`"
              :navigation-prev-label="`<img src='/img/landing/slide-left.png'/>`">
      <slide  v-for="(course, index) in courses[$i18n.locale]" :key="index" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="ibox">
          <div class="ibox-content product-box">
            <div class="product-imitation" :style="{'background-image': 'url(' + course.cover + ')'}">
              <div class="color-overlay"></div>
              <span>{{course.name}}</span>
            </div>
            <div class="product-desc">
            <span class="product-price bg-warning">
                Скоро
            </span>
              <small class="badge badge-info">
                <i class="fa fa-building"></i> РАН
              </small>
              <div class="small m-t-xs">
                {{ course.shortDescription | truncate(100, '...') }}
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "SoonList",
  props: ['myWindow'],
  data() {
    return {
      courses: {
        ru: [
          {
            "name": "Сенсоры для экологического мониторинга: от идеи до реализации",
            "shortDescription": "Курс предназначен для студентов последних курсов магистратуры или специалитета по физическим наукам.",
            "cover": "/soon/1.jpg",
          },
          {
            "name": "Лазерно-плазменные источники корпускулярного и электромагнитного излучения",
            "shortDescription": "Курс предназначен для магистрантов, аспирантов, специализирующихся в области теоретической физики и лазерной физики. Для успешного освоения курса требуются общие знания в области классической электродинамики, электродинамики материальных сред, лазерной физики.",
            "cover": "/soon/2.jpg",
          },
          {
            "name": "Климат Земли и его современные изменения",
            "shortDescription": "Курс предназначен для аспирантов, молодых ученых и студентов старших курсов высших учебных заведений (в первую очередь в области наук о Земле)",
            "cover": "/soon/3.jpg",
          },
          {
            "name": "Кибербезопасность инфокоммуникаций",
            "shortDescription": "Курс предназначен для бакалавров, магистрантов, аспирантов по специальностям основы высшей математики, дискретной математики, телекоммуникаций, информационной безопасности.",
            "cover": "/soon/4.jpg",
          },
          {
            "name": "Квантово-кинетическая теория фотолюминесценции",
            "shortDescription": "Курс предназначен для студентов магистратуры или специалитета по физическим наукам, прошедших или проходящих курсы теоретической физики.",
            "cover": "/soon/5.jpg",
          },
          {
            "name": "Квантовая механика с нуля",
            "shortDescription": "Курс предназначен для студентов бакалавриата по физике, магистрантов, аспирантов и молодых ученых других специальностей",
            "cover": "/soon/6.jpg",
          },
          {
            "name": "Истоки, основные понятия, концепции и вопросы философии образования»",
            "shortDescription": "Курс предназначен для студентов старших курсов бакалавриата и магистрантов первого года обучения психологических, педагогических, социологических и философских факультетов...",
            "cover": "/soon/7.jpg",
          },
          {
            "name": "Исследования фотосинтеза: от поглощения кванта света до появления урожая",
            "shortDescription": "Курс предназначен для бакалавров, магистрантов, аспирантов по специальности биология, биохимия, биофизика, молекулярная биология.",
            "cover": "/soon/8.jpg",
          },
          {
            "name": "Исследование биологических макромолекул: от клетки к структуре",
            "shortDescription": "Курс предназначен для бакалавров, магистрантов, аспирантов кафедры молекулярно-биологической направленности.",
            "cover": "/soon/9.jpg",
          },
          {
            "name": "Загадки мерцающих частиц",
            "shortDescription": "Курс предназначен для бакалавров, магистрантов, аспирантов и молодых ученых естественно-научных специальностей.",
            "cover": "/soon/10.jpg",
          },
        ],
        en: [
          {
            "name": "Sensors for ecological monitoring: from idea to production",
            "shortDescription": "The course is designed for magister degree in physical science, PhD students, young scientists. ",
            "cover": "/soon/1.jpg",
          },
          {
            "name": "Laser-plasma sources of corpuscular and electromagnetic radiation",
            "shortDescription": "The course «Laser-plasma sources of corpuscular and electromagnetic radiation» is devoted to the study of various inelastic processes in non-equilibrium relativistic laser plasma that occurs under the action of ultra-intense laser radiation on various targets.\n" +
                "Relativistic laser plasma is the unique object that allows to simulate and investigate in the laboratory the extreme states of matter and processes corresponding to the problems of controlled thermonuclear fusion, nuclear physics and astrophysics.\n",
            "cover": "/soon/2.jpg",
          },
          {
            "name": "Earth’s climate and its current changes",
            "shortDescription": "The course is intended for young scientists, graduate and senior students, primarily in the field of geosciences",
            "cover": "/soon/3.jpg",
          },
          {
            "name": "Cybersecurity of infocommunications",
            "shortDescription": "The purpose of the course is to familiarize students with promising scientific directions in the field of cybersecurity, their fundamental foundations.",
            "cover": "/soon/4.jpg",
          },
          {
            "name": "Quantum-kinetic theory of photoluminescence",
            "shortDescription": "Course for students, graduate students and young researchers studying courses of theoretical physics \"Electrodynamics\", \"Quantum Mechanics\", \"Statistical Physics\".",
            "cover": "/soon/5.jpg",
          },
          {
            "name": "Quantum mechanics from the scratch",
            "shortDescription": "Course for physics undergraduates, graduate students and young scientists of other specialties.",
            "cover": "/soon/6.jpg",
          },
          {
            "name": "Origins, concepts and questions of the philosophy of education ",
            "shortDescription": "The course is designed for senior undergraduate of study of psychological, pedagogical, sociological or philosophical faculties. ",
            "cover": "/soon/7.jpg",
          },
          {
            "name": "Research of photosynthesis: from the absorption of a light quantum to the crop",
            "shortDescription": "The course is designed for bachelors and masters in biology, biochemistry, biophysics, molecular biology.",
            "cover": "/soon/8.jpg",
          },
          {
            "name": "Исследование биологических макромолекул: от клетки к структуре",
            "shortDescription": "Курс предназначен для бакалавров, магистрантов, аспирантов кафедры молекулярно-биологической направленности.",
            "cover": "/soon/9.jpg",
          },
          {
            "name": "The mystery of blinking nanoparticles",
            "shortDescription": "The purpose of the course is to graduate students and young researchers of natural sciences.",
            "cover": "/soon/10.jpg",
          },
        ],
      }
    }
  },
  components: {Carousel, Slide}
}
</script>

<style lang="scss" scoped>
.product-desc {
  height: 150px;
}
.product-imitation {
  min-height: 180px;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  span {
    color: #fff;
    z-index: 100;
  }
  .color-overlay {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .6;
    position: absolute;
  }
}
.product-price {
  top: -33px;
  &.finished {
    background-color: #007bff;
  }
  &.started {
    background-color: #dc3545;
  }
  &.open {
    background-color: #17a2b8;
  }
  &.soon {
    background-color: #E6A23C;
  }
  &.finished {
    background-color: #67C23A;
  }
  &.closed {
    background-color: #909399;
  }
}
.course-none {
  border-radius: 20px;
  font-size: 16px;
  padding: 6px;
  line-height: 22px;
  height: 33px;
  width: 33px;
  color: #fff;
  display: inline-block;
  text-align: center;
}
</style>