<template>
    <span v-if="institute" class="animated fadeInRight">
        <AddUser :institute="institute"/>
        <SearchUser :institute="institute"/>
        <BreadCrumbs :title="institute.name" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <div class="row">
                <div class="col-md-4">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5>Редактировать организацию &laquo;{{ institute.name }}&raquo;</h5>
                        </div>
                        <div class="ibox-content">
                            <form ref="form" @submit.stop.prevent="handleSubmit">
                                <b-form-group
                                        label="Логотип"
                                        invalid-feedback="Name is required"
                                >
                                    <el-upload
                                            class="avatar-uploader"
                                            :show-file-list="false"
                                            :action="axios.defaults.baseURL + 'media_objects'"
                                            :headers="{Authorization: 'Bearer ' + this.$auth.token()}"
                                            :on-success="handleUploadSuccess">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>

                                </b-form-group>
                                <b-form-group
                                    label="Показать на главной"
                                    label-for="name-input"
                                    invalid-feedback="Mainpage is required"
                                >
                                  <valid-checkbox name="Показать на главной" vid="main" v-model="institute.isMainpage" />
                                </b-form-group>
                                <b-form-group
                                        label="Название"
                                        label-for="name-input"
                                        invalid-feedback="Name is required"
                                >
                                    <b-form-input
                                            id="name-input"
                                            v-model="institute.name"
                                            required
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                        label="Короткое название"
                                        label-for="name-input"
                                        invalid-feedback="Short name is required"
                                >
                                    <b-form-input
                                            id="short-name-input"
                                            v-model="institute.shortName"
                                            required
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                        label="Сайт"
                                        label-for="name-input"
                                        invalid-feedback="Short name is required"
                                >
                                    <b-form-input
                                            id="institute-name-input"
                                            v-model="institute.site"
                                            required
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                        label="Регион"
                                        label-for="type-input"
                                        invalid-feedback="Регион обязательно!"
                                >
                                    <multiselect
                                            v-model="region"
                                            :multiple="false"
                                            :searchable="true"
                                            :options="regions"
                                            openDirection="bottom"
                                            :loading="isRegionsLoading"
                                            @search-change="findRegions"
                                            @select="loadRegionCities"
                                            placeholder="Введите регион"
                                            :custom-label="opt => opt.regionNameRu"
                                    >
                                        <template slot="noOptions">Регион не найден</template>
                                    </multiselect>
                                </b-form-group>
                                <b-form-group label="Город">
                                    <multiselect
                                            :disabled="!region"
                                            v-model="institute.city"
                                            :multiple="false"
                                            :searchable="true"
                                            :options="cities"
                                            openDirection="bottom"
                                            :loading="isRegionsLoading"
                                            placeholder="Введите город"
                                            track-by="id"
                                            :custom-label="(opt) => opt.name"
                                    >
                                            <template slot="noOptions">Город не найден</template>
                                    </multiselect>
                                </b-form-group>
                                <b-button type="submit" size="sm" variant="success"><i class="fa fa-save"></i> Сохранить</b-button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5>Предоставление доступа</h5>
                            <div class="ibox-tools">
                                <a v-b-tooltip title="Добавить" v-b-modal.add-access>
                                    <i class="fa fa-plus"></i> Добавить
                                </a>
                                <a v-b-tooltip title="Выбрать" v-b-modal.search-access>
                                    <i class="fa fa-list"></i> Выбрать
                                </a>
                            </div>
                        </div>
                        <div class="ibox-content">
                            <b-form-group v-if="$auth.check(['ROLE_SUPER_ADMIN']) && institute.subsystemSettings">
                                <label><strong><i class="fa fa-lock"></i> Доступ к подсистемам:</strong></label>
                                <b-row>
                                    <b-col><valid-checkbox name="Консультации с преподавателем" vid="tutor" v-model="institute.subsystemSettings.isTutorConsult" /></b-col>
                                    <b-col><valid-checkbox name="Домашние задания" vid="homework" v-model="institute.subsystemSettings.isHomework" /></b-col>
                                    <b-col><valid-checkbox name="Чаты" vid="chars" v-model="institute.subsystemSettings.isChat" /></b-col>
                                    <b-col><valid-checkbox name="Рассылки" vid="mail" v-model="institute.subsystemSettings.isMail" /></b-col>
                                </b-row>
                                <b-row>
                                    <b-col><valid-checkbox name="Диагностика" vid="diagnost" v-model="institute.subsystemSettings.isDiagnost" /></b-col>
                                    <b-col><valid-checkbox name="Проекты обучения (проекты)" vid="projects" v-model="institute.subsystemSettings.isProject" /></b-col>
                                    <b-col><valid-checkbox name="Компетенции" vid="codifiers" v-model="institute.subsystemSettings.isCodifier" /></b-col>
                                    <b-col><valid-checkbox name="Тесты" vid="tests" v-model="institute.subsystemSettings.isTest" /></b-col>
                                </b-row>
                                <hr class="hr-line-dashed"/>
                                <label><strong><i class="fa fa-lock"></i> Доступ к статистике:</strong></label>
                                <b-row>
                                    <b-col md="4"><valid-checkbox name="Статистика по слушателям" vid="users" v-model="institute.subsystemSettings.isStatUser" /></b-col>
                                    <b-col md="4"><valid-checkbox name="Статистика по курсам" vid="courses" v-model="institute.subsystemSettings.isStatCourse" /></b-col>
                                    <b-col md="4"><valid-checkbox name="Статистика по компетенциям" vid="codifiers" v-model="institute.subsystemSettings.isStatCody" /></b-col>
                                    <b-col md="4"><valid-checkbox name="Конструктор отчетов" vid="constructor" v-model="institute.subsystemSettings.isStatReport" /></b-col>
                                    <b-col md="4"><valid-checkbox name="Нейро-диагностика" vid="neuro" v-model="institute.subsystemSettings.isStatNeuro" /></b-col>
                                </b-row>
                            </b-form-group>
                            <hr class="hr-line-dashed" />
                            <UserInstitutionList v-if="institute" ref="uiList" :inst="institute"></UserInstitutionList>
                            <span v-else>Пользователей в организации нет</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
    import BreadCrumbs from "./BreadCrumbs";
    import AddUser from "./AddUser";
    import UserInstitutionList from "./User/UserInstitutionList";
    import SearchUser from "./SearchUser";
    import ValidCheckbox from "./Validation/ValidCheckbox";
    export default {
        name: "InstitutionEdit.vue",
        components: {ValidCheckbox, SearchUser, UserInstitutionList, AddUser, BreadCrumbs},
        data() {
            return {
                emailState: null,
                institute: null,
                selected: null,
                imageUrl: null,
                cities: [],
                regions: [],
                region: null,
                isRegionsLoading: false
            }
        },
        mounted() {
            this.axios
                .get('/institutions/' + this.$route.params.id)
                .then((res) => {
                    this.institute = res.data
                    this.imageUrl = this.institute.logo ? this.institute.logo.thumbs.normal : null;
                    if(!this.institute.subsystemSettings) {
                        this.institute.subsystemSettings = {};
                    }
                    if(this.institute.city && this.institute.city.region) {
                        this.region = this.institute.city.region;
                    }
                })
        },
        methods: {
            handleUploadSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.institute.logo = res;
            },
            handleSubmit() {
                if (!this.$refs.form.checkValidity()) {
                    return
                }
                this.axios.put('institutions/' + this.institute.id, this.institute).then(res => {
                    if(res.status === 200) {
                        this.institute = res.data;
                        this.$toasted.global.appSuccess();
                    }
                });
            },
            findRegions(query) {
                if(query !== '') {this.isRegionsLoading = true;
                    this.axios.get(`regions/?name=${query}`).then(res => {
                        this.isRegionsLoading = false;
                        this.regions = res.data;
                    });
                }
            },
            loadRegionCities(region) {
                if (!region) {
                    this.cities = [];
                    return;
                }
                this.axios.get(`cities?region=${region.id}`)
                    .then(res => {
                        this.institute.city = null;
                        this.cities = res.data
                    });
            },
        }
    }
</script>

<style scoped>

</style>
