<template>
    <span v-if="codifier" class="animated fadeInRight">
        <BreadCrumbs :title="`Компетенция #${codifier.id}: ${codifier.title}`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Каталог компетенций', url: '/admin/codifier'}]"/>
        <div class="wrapper wrapper-content">
            <codifier-form
                    :codifier="codifier"
                    v-bind:submitting="submitting"
                    v-on:submitting="handleSubmitting($event)"
                    v-on:submitted="handleSubmitResult($event)"
            ></codifier-form>
            <div class="mt-3">
                <b-button variant="primary" @click="handleSubmitting(true)">Сохранить</b-button>
            </div>
        </div>
    </span>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import CodifierForm from "./CodifierForm";
    export default {
        name: "CodifierEdit",
        components: {CodifierForm, BreadCrumbs},
        data() {
            return {
                codifier: null,
                submitting: false,
                courses: []
            }
        },
        mounted() {
            this.axios
                .get(`codifiers/${this.$route.params.id}`)
                .then(res => {
                    this.codifier = {
                        ...res.data
                    };
                });
        },
        methods: {
            handleSubmitting(status) {
                this.submitting = status;
            },
            handleSubmitResult() {
                this.submitting = false;
            },
        }
    }
</script>

<style scoped>

</style>
