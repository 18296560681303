<template>
    <div  class="animate fadeIn">
        <bread-crumbs title="Создать поток" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Проекты обучения', url: '/admin/projects'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-content>
                    <b-tabs card>
                        <b-tab title="Основная информация" active>
                            <b-card-text>
                                <ibox>
                                    <ibox-title>
                                        <h4>Настройки потока обучения</h4>
                                    </ibox-title>
                                    <ibox-content>
                                        <validation-observer ref="observer" v-slot="{ passes }">
                                            <form @submit.stop.prevent="onSubmit(passes)">
                                                <valid-input rules="required" v-model="form.title" name="название" label="Название"></valid-input>
                                                <valid-date-range rules="required" v-model="form.daterange" label="Даты проведения"></valid-date-range>
                                                <valid-select rules="required" v-model="form.institutions" label-title="shortName" track-by="id" name="организации" label="Организации" :options="companies"></valid-select>
                                                <valid-select rules="required" v-model="form.enter" label-title="name" track-by="id" name="входная диагностика" label="Входная диагностика" :options="categories"></valid-select>
                                              <div class="form-group">
                                                <label>Курсы</label>
                                                <multiselect
                                                    v-model="form.courses"
                                                    :multiple="true"
                                                    :searchable="true"
                                                    :options="courses"
                                                    track-by="id"
                                                    placeholder="Начните вводить название курса"
                                                    label="title"
                                                    :custom-label="opt => opt.name"
                                                    :loading="isCodiLoader"
                                                    openDirection="bottom"
                                                    @search-change="searchCourse"
                                                ></multiselect>
                                              </div>
                                                <valid-select rules="required" v-model="form.exit" label-title="name" track-by="id" name="выходная диагностика" label="Выходная диагностика" :options="categories"></valid-select>
                                                <valid-checkbox vid="hard" v-model="form.isHard" name="Жесткая диагностика"></valid-checkbox>
                                                <valid-checkbox v-if="form.id" vid="soft" v-model="form.isClosed" name="Закрытый поток"></valid-checkbox>
                                                <div class="input-group mb-3" v-if="form.isClosed" >
                                                    <input :disabled="true" class="form-control form-control-sm" v-model="form.link"/>
                                                    <div class="input-group-append">
                                                        <router-link tag="a" target="_blank" :to="form.link" class="btn btn-sm btn-primary"><i class="fa fa-chain"></i> Перейти</router-link>
                                                    </div>
                                                </div>
                                                <hr class="hr-line-dashed" />
                                                <b-btn type="submit" variant="primary"><i class="fa fa-save"></i> Сохранить</b-btn>
                                            </form>
                                        </validation-observer>
                                    </ibox-content>
                                </ibox>
                            </b-card-text>
                        </b-tab>
                        <b-tab v-if="project && form.landing" title="Лендинг">
                            <ibox>
                                <ibox-title :hide-tools="true">
                                    <router-link tag="a" target="_blank" class="btn btn-xs btn-success pull-right"  :to="form.link"> <i class="fa fa-eye"></i> Предпросмотр</router-link>
                                    <h4>Настройка стартовой страницы потока обучения</h4>
                                </ibox-title>
                                <ibox-content>
                                    <valid-form :submit="onSubmit">
                                        <valid-select v-model="form.landing.courses" label-title="name" track-by="id" :multiple="true" name="курсы" label="Курсы на странице анкеты" :options="project.courses"></valid-select>
                                        <valid-select v-model="form.landing.testCategories" label-title="name" track-by="id" :multiple="true" name="курсы" label="Диагностика на странице анкеты" :options="project.enter"></valid-select>
                                        <b-form-group>
                                            <label class="col-sm-12 col-form-label">Превью (видео):</label>
                                            <div class="file-box">
                                                <div class="file">
                                                    <el-upload
                                                            :action="axios.defaults.baseURL + 'media_objects'"
                                                            :headers="{Authorization: 'Bearer ' + $auth.token()}"
                                                            :on-success="videoUpload"
                                                            accept="video/mp4"
                                                            class="upload-video"
                                                    >
                                                        <div class="icon">
                                                            <i class="img-fluid fa fa-film"></i>
                                                        </div>
                                                        <div class="file-name">
                                                            Загрузить файл
                                                        </div>
                                                    </el-upload>
                                                    <div v-if="form.landing && form.landing.promo" class="file-name">
                                                        <encoder-process :media="form.landing.promo"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-form-group>
                                        <valid-summernote :rows="500" v-model="form.landing.text"></valid-summernote>
                                      <valid-checkbox vid="landingIsActive" v-model="form.landing.isActive" name="Активна"></valid-checkbox>
                                    </valid-form>
                                </ibox-content>
                            </ibox>
                        </b-tab>
                        <b-tab v-if="project && form.profileFields" title="Входная анкета">
                            <ibox>
                                <ibox-title :hide-tools="true">
                                    <h4>Настройка анкеты</h4>
                                </ibox-title>
                                <ibox-content>
                                    <b-row>
                                        <b-col md="7">
                                            <valid-form :submit="onSubmit">
                                                <div v-for="(field, key) in form.profileFields" :key="key">
                                                    <valid-input rules="required" label="Название поля" v-model="field.name" />
                                                    <valid-select label="Тип поля" v-model="field.type" :multiple="false" :options="types" />
                                                    <template v-if="['множественный выбор', 'единственный выбор'].indexOf(field.type) > -1">
                                                        <label>Варианты ответов</label>
                                                        <b-row>
                                                            <b-col md="3" v-for="(choice, choiceKey) in field.profileFieldChoices" :key="choiceKey">
                                                                <div class="input-group">
                                                                    <input type="text" v-model="choice.name" class="input form-control">
                                                                    <span v-if="choiceKey" class="input-group-append">
                                                                        <button @click="field.profileFieldChoices.splice(choiceKey, 1)" type="button" class="btn btn btn-danger">
                                                                            <i class="fa fa-close"></i>
                                                                        </button>
                                                                     </span>
                                                                </div>
                                                            </b-col>
                                                            <b-col md="3"><b-btn @click="field.profileFieldChoices.push({name: ''})" variant="primary"><i class="fa fa-plus"></i></b-btn></b-col>
                                                        </b-row>
                                                    </template>
                                                    <template v-if="field.type === 'ограниченный список регионов'">
                                                      <label>Выберите страны</label>
                                                      <b-row>
                                                        <valid-select label="Страны" v-model="field.geoCountries" track-by="id" :options="geoCountries" label-title="name" />
                                                      </b-row>
                                                      <b-row>
                                                        <valid-select label="Регионы" v-model="field.geoRegions" />
                                                      </b-row>
                                                      <b-row>
                                                        <valid-select label="Города" v-model="field.geoCities" />
                                                      </b-row>
                                                      <b-row>
                                                        <valid-select label="Районы" v-model="field.geoDistricts" />
                                                      </b-row>
                                                    </template>
                                                    <br/>
                                                    <div class="text-right">
                                                        <b-btn variant="danger" @click="form.profileFields.splice(key, 1)"><i class="fa fa-trash"></i> Удалить</b-btn>
                                                    </div>
                                                    <hr class="hr-line-dashed"/>
                                                </div>
                                                <b-btn variant="primary" @click="form.profileFields.push({name: '',type: 'текст', profileFieldChoices: [{name: ''}]})" size="sm">Добавить вопрос анкеты</b-btn>
                                                <hr class="hr-line-dashed"/>
                                            </valid-form>
                                        </b-col>
                                        <b-col md="5">
                                            <draggable @change="onMoveCallback" v-model="form.profileFields">
                                                <table class="table table-striped table-hover table-bordered">
                                                    <b-thead>
                                                        <b-tr>
                                                            <b-th>Название</b-th>
                                                            <b-th>Тип</b-th>
                                                            <b-th>Поменять позицию</b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <draggable @change="onMoveCallback" element="tbody" v-model="form.profileFields">
                                                        <b-tr style="cursor: grabbing" v-for="(profile, key) in form.profileFields" :key="key">
                                                            <b-td>{{ profile.name }}</b-td>
                                                            <b-td>{{ profile.type }}</b-td>
                                                            <b-td><b-btn variant="primary"><i class="fa fa-arrows"></i></b-btn></b-td>
                                                        </b-tr>
                                                    </draggable>
                                                </table>
                                            </draggable>
                                        </b-col>
                                    </b-row>
                                </ibox-content>
                            </ibox>
                        </b-tab>
                    </b-tabs>
                    <hr/>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import ValidForm from "../Validation/ValidForm";
    import ValidInput from "../Validation/ValidInput";
    import ValidSelect from "../Validation/ValidSelect";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import ValidDateRange from "../Validation/ValidDateRange";
    import EncoderProcess from "../EncoderProcess";
    import ValidSummernote from "../Validation/ValidSummernote";
    import IboxTitle from "../Ibox/IboxTitle";
    import ValidCheckbox from "../Validation/ValidCheckbox";
    import draggable from 'vuedraggable';
    export default {
        name: "AddProject",
        props: ['project'],
        components: {
            ValidCheckbox,
            IboxTitle,
            ValidSummernote,
            EncoderProcess,
            ValidDateRange,
            IboxContent,
            Ibox,
            ValidSelect,
            ValidInput,
            ValidForm,
            BreadCrumbs,
            draggable
        },
        data() {
            return {
                isCodiLoader: false,
                districts: [],
                courses: [],
                categories: [],
                types: ['текст', 'дата', 'множественный выбор', 'единственный выбор', 'большой текст', 'город и регион', 'организации, районы', 'регион', 'организации', 'целое число', 'дробное число'],
                form: {
                    landing: null,
                    profileFields: this.project && this.project.profileFields ? this.project.profileFields : []
                },
                token: Math.random().toString(36).substring(7),
                companies: []
            }
        },
        beforeMount() {
            if(this.project) {
                this.form = this.project;
                if(!this.form.landing) {
                    this.axios.post(`landings`, {
                        project: this.project.id,
                        isActive: false
                    }).then(res => {
                        this.form.landing = res.data;
                    })
                }
                if(!this.form.profileFields) {
                    this.axios.post(`profile_fields`, {
                        project: this.project.id,
                        profileFields: [
                            {
                                name: '',
                                type: 'текст',
                                profileFieldChoices: [{name: ''}]
                            }
                        ]
                    }).then(res => {
                        this.form.profileFields = [res.data];
                    })
                }
                this.form.daterange = [this.form.startDate, this.form.endDate];
                if(!this.form.link) {
                    this.form.link = '/landing/' + this.project.id
                }
            }
        },
        mounted() {
            this.axios.get(`institutions`).then(res => {
                this.companies = res.data;
            });
            this.axios.get('sdpk/get/TestCategory/all').then(res => {
                this.categories = res.data;
            });
        },
        computed: {
            isClosed() {
                return this.form.isClosed;
            }
        },
        watch: {
            isClosed(val) {
                if(val) {
                    this.form.link = '/landing/' + this.project.id + '?token='+this.token
                }
            }
        },
        methods: {
          searchCourse(query) {
            if(query.length > 3 && !this.isCodiLoader) {
              this.isCodiLoader = true
              this.axios.get(`courses/search`, {
                params: {
                  q: query,
                  hideLoader: true
                }
              }).then(res => {
                this.isCodiLoader = false
                if(res.status === 200) {
                  this.courses = res.data;
                }
              });
            }
          },
          onMoveCallback() {
              this.project.profileFields = this.project.profileFields.map((element, key) => {
                  element.position = key + 1
                  return element;
              })

              this.onSubmit()
          },
          videoUpload(res) {
              this.axios.put(`landings/${this.project.landing.id}`, {promo: res}).then((res) => {
                  this.form.landing.promo = res.data.promo;
              })
          },
          onSubmit() {
              if(this.form.daterange) {
                  this.form.startDate = this.form.daterange[0];
                  this.form.endDate = this.form.daterange[1];
              }
              if(!this.project) {
                  this.axios.post(`projects`, this.form).then((res) => {
                      if(res.status === 201) {
                          this.$router.push(`/admin/projects/${res.data.id}/edit`).catch(() => {});
                          this.$toasted.global.appSuccess()
                      }
                  })
              } else {
                  if(this.form.profileFields) {
                      this.form.profileFields = this.form.profileFields.map((element, key) => {
                          element.position = key + 1
                          return element;
                      })
                  }
                  this.axios.put(`projects/${this.project.id}`, this.form).then((res) => {
                      this.form = res.data;
                      this.$toasted.global.appSuccess()
                  })
              }

          },
        },
    }
</script>

<style scoped>

</style>
