<template>
    <div :class="`edu-icon ${icon}`" :style="getPosition()"></div>
</template>

<script>
    export default {
        name: "EduIcon",
        props: {
            icon: {
                type: String,
                default: 'programming'
            }
        },
        methods: {
            getPosition() {
                let position = '40px 0;';
                switch (this.icon) {
                    case 'programming':
                        position = '40px 0;';
                        break;
                    case 'matematika':
                        position = '-260px 0';
                        break;
                    case 'obschaya-himiya':
                        position = '-570px 0;';
                        break;
                }
                return 'background-position: ' + position;
            }
        }
    }
</script>

<style scoped>
    .edu-icon {
        display: inline-block;
        width: 280px;
        height: 180px;
        background: url("/img/landing/edu-icons-sprite.png");
    }
</style>
