<template>
    <div v-if="course && logs" class="animated fadeIn">
        <BreadCrumbs :title="`История набранных баллов по курсу ${course.name}`" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-content>
                    <div class="clients-list">
                        <b-table striped hover bordered :fields="fields" :items="logs">
                            <template v-slot:cell(point)="data">
                                <b-badge :variant="data.item.point > 0 ? 'primary' : 'danger'" v-if="data.item.point"><span v-if="data.item.point > 0">+</span>{{data.item.point}}</b-badge>
                            </template>
                            <template v-slot:cell(course)="data">
                                <span v-if="data.item.course">{{data.item.course.name}}</span>
                                <span v-else-if="data.item.module && data.item.module.course">{{data.item.module.course.name}}</span>
                                <span v-else-if="data.item.moduleElement && data.item.moduleElement.module && data.item.moduleElement.module.course">{{data.item.moduleElement.module.course.name}}</span>
                            </template>
                            <template v-slot:cell(module)="data">
                                <span v-if="data.item.module">{{data.item.module.name}}</span>
                                <span v-else-if="data.item.moduleElement && data.item.moduleElement.module">{{data.item.moduleElement.module.name}}</span>
                            </template>
                            <template v-slot:cell(moduleElement)="data">
                                <span v-if="data.item.moduleElement"><i :class="`fa fa-${data.item.moduleElement.type}`"></i> {{data.item.moduleElement.name}}</span>
                            </template>
                            <template v-slot:cell(createdAt)="data">
                                <span>{{ data.item.createdAt|formatDate }}</span>
                            </template>
                            <template v-slot:cell(test)="data">
                                <span v-if="data.item.test">{{data.item.test.name}}</span>
                            </template>
                        </b-table>
                    </div>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";

    export default {
        name: "SelfCourseStat",
        components: {IboxContent, Ibox, BreadCrumbs},
        data() {
            return {
                fields: [
                    {
                        key: 'point',
                        label: 'Баллы'
                    },
                    {
                        key: 'comment',
                        label: 'Описание'
                    },
                    {
                        key: 'course',
                        label: 'Курс'
                    },
                    {
                        key: 'module',
                        label: 'Модуль'
                    },
                    {
                        key: 'createdAt',
                        label: 'Дата/время'
                    },
                    {
                        key: 'moduleElement',
                        label: 'Элемент модуля'
                    }
                ],
                logs: null,
                course: null
            }
        },
        mounted() {
            let course = this.$route.params.id;
            this.axios.get(`courses/${course}`).then(res => {
                this.course = res.data;
            });
            this.axios.get(`courses/${course}/course_user_logs`, {params: {'user.id': this.$auth.user().id, 'course.id': this.course.id}}).then(res => {
                this.logs = res.data;
            })
        }
    }
</script>

<style scoped>

</style>
