<template>
    <div v-if="element" class="animated fadeIn">
        <pdf :src="`${axios.defaults.baseURL}media_files/${element.file.id}`" />
    </div>
</template>

<script>
    import pdf from 'vue-pdf'
    export default {
        name: "ElementFile",
        props: ['element'],
        components: {pdf}
    }
</script>

<style scoped>
    .pdf-download {
        display: inline-block;
        position: absolute;
        bottom: 0;
        text-align: center;
        right: 0;
    }
</style>
