<template>
    <div>
        <CoursePreview :course="course"/>
        <CourseAbout :course="course"/>
        <course-progress :course="course" v-if="course.enroll" />
        <CourseCalendar v-if="course.status !== 'open'" :course="course"/>
        <course-chatbox v-if="course.enroll && course.isSupport" :course="course"></course-chatbox>
    </div>
</template>

<script>
    import CoursePreview from "./CoursePreview";
    import CourseAbout from "./CourseAbout";
    import CourseCalendar from "./CourseCalendar";
    import CourseChatbox from "./CourseChatbox";
    import CourseProgress from "./CourseProgress";
    import bus from "../../bus";
    export default {
        name: "CourseInfo",
        components: {CourseProgress, CourseChatbox, CourseCalendar, CourseAbout, CoursePreview},
        data() {
            return {
                course: this.$parent.course
            }
        },
      mounted() {
        bus.$on('enrollment', (course) => {
          this.course = course;
        });
        bus.$on('unenroll', (course) => {
          this.course = course;
        });
      }
    }
</script>

<style scoped>

</style>
