<template>
    <div class="project-completion">
        <small>{{title}}{{progress}}%</small>
        <div class="progress progress-mini">
            <div :style="`width: ${progress}%;`" class="progress-bar"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProgressBarMini",
        props: ['max', 'point', 'title'],
        data() {
            return {
                progress: 0
            }
        },
        mounted() {
            this.progress = Math.floor(100 * this.point/this.max);
        }
    }
</script>

<style scoped>

</style>
