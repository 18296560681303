<template>
    <div>
        <video-player
                v-if="media.mimeType === 'video/mp4' || 'video/x-m4v'"
                ref="videoPlayer"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @timeupdate="timeUpdate($event)"
                :title="title">
        </video-player>
    </div>
</template>

<script>
    import bus from "../bus";
    import Actions from "./User/Actions";
    import TYPES from "./User/actionsTypes";
    import 'videojs-resolution-switcher/lib/videojs-resolution-switcher';
    import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css';
    export default {
        name: "Video",
        props: ['media', 'title', 'elementResult'],
        extends: Actions,
        data() {
            return {
                wasUpdate: false,
                playerOptions: {
                    // component options
                    start: 0,
                    playsinline: false,
                    muted: false,
                    language: 'ru',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [],
                    controls: true,
                    plugins: {
                        videoJsResolutionSwitcher: {
                            default: 'high',
                            dynamicLabel: true
                        }
                    },
                    poster: this.media.thumbs['cover']
                }
            }
        },
        created() {
            bus.$on('pause', () => {
                this.player.pause();
            })
            bus.$on('stop', () => {
                this.player.stop();
            })
            bus.$on('play', () => {
                this.player.play();
            })
        },
        mounted() {
            this.player.updateSrc([
                {
                    type: "video/mp4",
                    src: this.media.thumbs['720p'],
                    label: '720'
                },
                {
                    type: "video/mp4",
                    src: this.media.thumbs['480p'],
                    label: '480'
                },
                {
                    type: "video/mp4",
                    src: this.media.thumbs['360p'],
                    label: '360'
                }
            ])
            bus.$on('video:reload', (media) => {
                this.player.updateSrc([
                    {
                        type: "video/mp4",
                        src: media.thumbs['720p'],
                        label: '720'
                    },
                    {
                        type: "video/mp4",
                        src: media.thumbs['480p'],
                        label: '480'
                    },
                    {
                        type: "video/mp4",
                        src: media.thumbs['360p'],
                        label: '360'
                    }
                ])
                this.player.stop();
                this.player.load();
                this.player.play();
            })
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            }
        },
        methods: {
            onPlayerPlay() {
                this.addAction(TYPES.VIDEO_TYPE, this.title);
            },
            onPlayerPause(player) {
                console.log('player pause!', player)
            },
            timeUpdate(player) {
                if(this.elementResult && player.currentTime() >= 5 && !this.wasUpdate) {
                    this.axios.put(`element_results/${this.elementResult.id}`, {status: 'success', hideLoader: true}).then((res) => {
                        this.wasUpdate = true;
                        bus.$emit('element_result:update', res.data);
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '~video.js/dist/video-js.css';
    .vjs_video_3-dimensions {
        min-height: 480px;
    }
    @media (max-width: 768px) {
        .vjs_video_3-dimensions {
            min-height: 280px!important;
        }
    }
    .video-js .vjs-big-play-button,
    .video-js.vjs-custom-skin .vjs-big-play-button,  {
        height: 2em!important;
        width: 2em!important;
        border-radius: 2em;
        line-height: 2em;
    }
    .video-js:hover .vjs-big-play-button, .video-js.vjs-custom-skin .vjs-big-play-button:focus, .video-js.vjs-custom-skin .vjs-big-play-button:active {
        background-color: #1ab394!important;
    }
    .video-js.vjs-custom-skin .vjs-play-progress, .video-js.vjs-custom-skin .vjs-volume-level {
        background-color: #1ab394!important;
    }
</style>
