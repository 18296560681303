<template>
    <div>
        <table>
            <tr v-for="(ch, i) in rightAnswers" :key="i">
                <td>
                    <div>
                        <p><strong>Ответ<span v-if="rightAnswers.length > 1">&nbsp;{{ i + 1 }}</span>:</strong></p>
                        <textarea title="Правильный ответ"
                                  class="form-control"
                                  v-model="rightAnswers[i]"></textarea>
                        <p><i>Можно использовать звёздочку</i> <strong>*</strong> <i> для указания вариантов написания</i></p>
                    </div>
                </td>
                <td style="padding-top: 17px; padding-left: 10px;">
                    <button class="btn btn-light"
                            style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                            tabindex="-1"
                            @click="deleteRightAnswer(i)">
                        <i class="fa fa-trash" style="font-size: 120%;"></i> удалить<br/>строку
                    </button>
                </td>
            </tr>
        </table>

        <br/>
        <button class="btn btn-link" @click="addRightAnswer()">
            <i class="fa fa-trash"></i>
            Добавить правильный ответ
        </button>
    </div>

</template>

<script>
    import ConstructorBase from "../ConstructorBase";
    import AnswerStructure from "../model/AnswerStructure";

    export default {
        name: "ConstructorOpen",
        extends: ConstructorBase,
        date() {
            return {
                rightAnswers: []
            }
        },
        mounted () {
            this.rightAnswers = this.answerValue.key
        },
        methods: {
            addRightAnswer () {
                this.rightAnswers.push('')
                this.task.choice.push('')
                this.giveAnswer()
                this.giveTask()
            },

            deleteRightAnswer (r) {
                this.rightAnswers.splice(r, 1)
                if (Array.isArray(this.task.choice) && r in this.task.choice) {
                    this.task.choice.splice(r, 1)
                }
                this.giveAnswer()
                this.giveTask()
            }
        },
        watch: {
            rightAnswers() {
                if (this.rightAnswers !== null) {
                    let ans = new AnswerStructure()
                    ans.key = this.rightAnswers
                    this.answer = ans
                    this.giveAnswer()
                }
            }
        }
    }
</script>

<style scoped>

</style>
