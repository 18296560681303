<template>
    <a target="_blank" :href="`${axios.defaults.baseURL}pdf/schedule/${course.id}?access_token=${$auth.token()}`" v-b-tooltip :title="title" v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST', 'ROLE_CUSTOMER'])" :class="styleClass">
        <i class="fa fa-print"></i>
        <template v-if="fullMode">
            {{title}}
        </template>
    </a>
</template>

<script>
    export default {
        name: "CourseSchedule",
        props: {
            course: {
                type: Object
            },
            fullMode: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Скачать расписание'
            },
            styleClass: {
                type: String,
                default: 'btn btn-white btn-sm'
            }
        },
    }
</script>

<style scoped>

</style>
