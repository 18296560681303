<template>
    <div v-if="elements && spec" class="animated fadeIn">
            <ibox>
                <ibox-content>
                    <valid-form :submit="onSubmit">
                        <valid-input rules="between:0,1|required" name="вес" label="Удельный вес (коэффициент)" v-model.number="spec.weight" />
                        <valid-tree-select label="Компетенции" name="компетенции" label-title="name" vid="id" :options="elements" v-model="spec.specCodifierIds" :multiple="true" rules="required" />
                        <div class="constructor-editor-question" v-for="(question, key) in spec.questions" :key="key">
                            <valid-summernote name="Описание" label="Описание вопроса" v-model="question.description" />
                            <constructor-once-answer
                                    v-if="spec.questionType.code === 1 || spec.questionType.code.toString().charAt(0) === '1'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                            ></constructor-once-answer>
                            <constructor-multiple-answer
                                    v-if="spec.questionType.code === 2 || spec.questionType.code.toString().charAt(0) === '2'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-multiple-answer>
                            <constructor-match
                                    v-if="spec.questionType.code === 3 || spec.questionType.code.toString().charAt(0) === '3'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-match>
                            <constructor-sequence
                                    v-if="spec.questionType.code === 4 || spec.questionType.code.toString().charAt(0) === '4'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-sequence>
                            <constructor-open
                                    v-if="spec.questionType.code === 5 || spec.questionType.code.toString().charAt(0) === '5'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-open>
                            <constructor-match-checkbox
                                    v-if="spec.questionType.code === 6 || spec.questionType.code.toString().charAt(0) === '6'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-match-checkbox>
                            <constructor-match-radio
                                    v-if="spec.questionType.code === 7 || spec.questionType.code.toString().charAt(0) === '7'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-match-radio>
                            <constructor-match-any
                                    v-if="spec.questionType.code === 8 || spec.questionType.code.toString().charAt(0) === '8'"
                                    :id="question.id"
                                    v-model="question.task"
                                    :answerValue="question.answer"
                                    @answer="question.answer = $event"
                            ></constructor-match-any>
                        </div>
                    </valid-form>
                </ibox-content>
            </ibox>
            <hr class="hr-line-dashed"/>
        </div>
</template>

<script>
    import ValidForm from "../Validation/ValidForm";
    import ValidInput from "../Validation/ValidInput";
    import IboxContent from "../Ibox/IboxContent";
    import ValidTreeSelect from "../Validation/ValidTreeSelect";
    import _ from "lodash";
    import ValidSummernote from "../Validation/ValidSummernote";
    import ConstructorOnceAnswer from "./types/ConstructorOnceAnswer";
    import ConstructorMultipleAnswer from "./types/ConstructorMultipleAnswer";
    import ConstructorMatch  from "./types/ConstructorMatch";
    import ConstructorSequence from "./types/ConstructorSequence";
    import ConstructorOpen from "./types/ConstructorOpen";
    import ConstructorMatchCheckbox from "./ConstructorMatchCheckbox";
    import ConstructorMatchRadio from "./types/ConstructorMatchRadio";
    import ConstructorMatchAny from "./ConstructorMatchAny";
    import Ibox from "../Ibox/Ibox";
    export default {
        name: "TestConstructorEditor",
        components: {
            ConstructorMatchAny,
            ConstructorMatchRadio,
            ConstructorMatchCheckbox,
            ConstructorOpen,
            ConstructorSequence,
            ConstructorMultipleAnswer,
            ConstructorOnceAnswer, ValidSummernote, ValidTreeSelect, IboxContent, Ibox, ValidInput, ValidForm, ConstructorMatch},
        props: ['spec', 'elements', 'subtest'],
        created() {
            this.spec.specCodifierIds = this.specCodifiers;
            this.spec.subtest = this.subtest
        },
        methods: {
            onSubmit() {
                this.axios.post('sdpk/save/Specification/' + this.spec.id, this.spec).then(() => {
                    this.$toasted.global.appSuccess()
                })
            }
        },
        computed: {
            specCodifiers() {
                let codis = []
                this.spec.specCodifiers.forEach(specC => {
                    codis.push(specC.codifierElement.id)
                })
                return _.uniq(codis)
            }
        }
    }
</script>

<style scoped>

</style>
