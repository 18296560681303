<template>
  <div class="table-responsive">
    <table class="question-table">
      <tbody>
      <tr v-for="(value, key) in task.choice" :key="key">
        <td>
          <p-check
              class="p-icon p-bigger p-smooth"
              style="font-size: 200%"
              :id="'pool_' + id + '_' + key"
              :value="key"
              v-model="givenAnswer.key"
              @change="onChangeMultiple(givenAnswer)">
            <i slot="extra" class="icon fa fa-check"></i>
          </p-check>
        </td>
        <td>
          <div
              v-html="value"
              @click="toggle(key)"
              style="cursor: pointer">
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <p style="color: darkred; font-size: 120%; font-weight: bold;" v-if="errorMessage">Внимание! {{ errorMessage }}</p>
  </div>

</template>

<script>
    import QuestionMultipleAnswer from "./QuestionMultipleAnswer";
    export default {
        name: "QuestionPool",
        extends: QuestionMultipleAnswer,
        methods:{
          addRow () {
            let row = []
            let answerRow = []
            for (let i = 0; i < this.task.columns.length; i++) {
              row.push({ isStaticText: true, text: '', text2: '' })
              answerRow.push(false)
            }
            this.task.cells.push(row)
            this.answer.grid.push(answerRow)
          },
          addPool () {
            if (this.task && this.task.options && Array.isArray(this.task.options.pools)) {
              this.task.options.pools.push([])
              this.giveTask()
            }
          }
        }
    }
</script>

<style scoped>

</style>
