<template>
    <div v-if="givenAnswer && givenAnswer.grid.length" class="table-responsive">
        <table class="table table-condensed">
            <thead>
            <th>№</th>
            <th v-for="(column, key) in task.columns" :key="key">{{ column }}</th>
            </thead>
            <tbody>
            <tr v-for="(row, r) in task.cells" :key="r">
                <td width="2%">{{ r + 1 }}</td>
                <td v-for="(column, k) in task.columns" :key="k" width="25%">
                    <p v-html="row[k].text"></p>
                    <div v-if="row[k] && row[k].isStaticText && Array.isArray(givenAnswer.grid) && Array.isArray(givenAnswer.grid[r])"
                         style="display: none">
                        {{ givenAnswer.grid[r][k] = '' }}
                    </div>
                    <div v-if="row[k] && !row[k].isStaticText && task.union">
                        <v-select
                                :title="column"
                                placeholder="выберите вариант ответа"
                                v-model="givenAnswer.grid[r][k]"
                                label="label"
                                index="index"
                                :reduce="item => item.index"
                                :options="task.choice.map((ch, c) => {return {index: c, label: (c + 1) + '. ' + ch}})">
                            <template slot="option" slot-scope="option">
                                <div style="font-size: 75%; white-space: normal;">{{ option.label }}</div>
                            </template>
                        </v-select>
                    </div>
                    <div v-if="row[k] && !row[k].isStaticText && !task.union">
                        <v-select
                                :title="column"
                                v-model="givenAnswer.grid[r][k]"
                                placeholder="выберите вариант ответа"
                                label="label"
                                index="index"
                                :reduce="item => item.index"
                                :options="task.choices[k].map((ch, c) => {return {index: c, label: (c + 1) + '. ' + ch}})">
                            <template slot="option" slot-scope="option">
                                <div style="font-size: 75%; white-space: normal;">{{ option.label }}</div>
                            </template>
                        </v-select>
                    </div>
                    <p v-html="row[k].text2"></p>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import QuestionBase from "./QuestionBase";
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css';
    export default {
        name: "QuestionMatch",
        components: {vSelect},
        extends: QuestionBase,
        mounted () {
            if (this.task && Array.isArray(this.task.cells) && this.givenAnswer && Array.isArray(this.givenAnswer.grid) && this.givenAnswer.grid.length === 0) {
                for (let r = 0; r < this.task.cells.length; r++) {
                    this.givenAnswer.grid.push([])
                    for (let k = 0; k < this.task.columns.length; k++) {
                        this.givenAnswer.grid[r][k] = ''
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
