<template>
  <div v-if="myWindow" class="mainpage-wrapper">
    <div class="navbar-wrapper">
      <nav class="navbar navbar-default navbar-fixed-top navbar-expand-md" role="navigation">
        <div class="container">
          <div class="navbar-header page-scroll">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
              <i class="fa fa-bars"></i>
            </button>
          </div>`
          <div class="collapse navbar-collapse justify-content-end" id="navbar">
            <ul class="nav navbar-nav navbar-right">
              <li><router-link class="nav-link"  to="/admin/courses">{{ $t('home.6201') }}</router-link></li>
              <li><router-link class="nav-link" to="/admin/docs/postavschikam-kontenta">{{ $t('home.1588') }}</router-link></li>
              <li><router-link class="nav-link" to="/admin/docs/o-proekte">{{ $t('home.579') }}</router-link></li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
              <li class="language-switcher">
                <a class="nav-link" href="javascript:void(0)">{{ $i18n.locale }} <i class="fa fa-chevron-down"></i></a>
                <div class="dropdown-content" v-if="$i18n.locale === 'ru'" @click="setLocale('en')">
                  EN
                </div>
                <div class="dropdown-content" v-if="$i18n.locale === 'en'" @click="setLocale('ru')">
                  RU
                </div>
              </li>
              <li v-if="$auth.check()">
                <router-link class="nav-link" to="/admin/profile"><i class="fa fa-user"></i>{{ $t('home.6443') }}</router-link>
              </li>
              <li v-if="$auth.check()"><a href="javascript:void(0)" @click="$auth.logout()" class="nav-link"><i class="fa fa-sign-out"></i>{{ $t('home.1906') }}</a></li>
              <li v-else><router-link class="nav-link" to="/admin/login"><i class="fa fa-sign-in"></i>{{ $t('home.6492') }}</router-link></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div id="inSlider" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <div class="container">
            <router-link class="navbar-brand" to="/admin/">
              <img src="/big-logo.png">
            </router-link>
            <div class="pull-right users-count" v-if="count">
              <h1>{{ $t('home.9972') }}</h1>
              <h2>{{ count }}</h2>
              <p>{{ $t('home.753') }}<br/>{{ $t('home.2647') }}</p>
            </div>
            <div class="carousel-caption">
              <h1>{{ $t('home.8997') }}</h1>
              <h2>{{ $t('home.6953') }}<br/>{{ $t('home.3555') }}</h2>
              <p>
                <router-link class="btn btn-lg btn-primary" to="/admin/courses">{{ $t('home.7660') }}</router-link>
              </p>
            </div>
          </div>
          <!-- Set background for slide in css -->
          <div class="header-back one"></div>
        </div>
      </div>
    </div>
    <section v-if="institutions.length" id="institutions" class="dashed-header-section">
      <h1>{{ $t('home.8516') }}</h1>
      <partner-list :my-window="myWindow" :institutions="institutions" />
    </section>
    <section id="soon" class="dashed-header-section">
      <h1>{{ $t('home.9973') }}</h1>
      <soon-list :my-window="myWindow" />
    </section>
    <section id="directions" class="dashed-header-section">
      <h1>{{ $t('home.2844') }}</h1>
      <category-list :my-window="myWindow" :categories="categories" />
      <div class="text-center">
        <router-link class="btn btn-lg btn-primary" to="/admin/courses">{{ $t('home.3611') }}</router-link>
      </div>
    </section>
    <section id="opportunities" class="dashed-header-section">
      <b-row>
        <b-col lg="6">
          <Video class="main-page-video" :media="media" :title="'Что такое МИИГАиК?'"/>
        </b-col>
        <b-col lg="6">
          <h1 class="text-left">{{ $t('home.4325') }}</h1>
          <p>{{ $t('home.1230') }}</p>
          <p>{{ $t('home.7575') }}</p>
        </b-col>
      </b-row>
    </section>
    <section id="tutors" class="dashed-header-section">
      <h1>{{ $t('home.8523') }}</h1>
      <tutor-list :my-window="myWindow" :tutors="tutors" />
    </section>
    <section id="footer">
      <h1>{{ $t('home.8761') }}</h1>
      <h2>{{ $t('home.4281') }}<br/>{{ $t('home.9653') }}</h2>
      <div class="phone-block">
        <span class="phone-block-prefix">+7</span>
        <the-mask v-model="phone" :mask="['(###) ###-##-##']" />
      </div>
      <div class="text-center">
        <b-btn @click="sendPhone" class="btn btn-lg btn-primary">{{ $t('home.8229') }}</b-btn>
      </div>
      <div class="footed">
        <div class="pull-left">
          <ul class="bottom-link text-white text-left">
            <li><router-link class="text-white" to="/admin/docs/politika-konfidencialnosti-obrabotki-personalnyh-dannyh">{{ $t('home.5091') }}</router-link></li>
            <li><router-link class="text-white" to="/admin/docs/politika-i-usloviya-vozvratov">{{ $t('home.2998') }}</router-link></li>
            <li><router-link class="text-white" to="/admin/docs/informaciya-i-rekvizity-kompanii">{{ $t('home.1577') }}</router-link></li>
            <li><router-link class="text-white" to="/admin/docs/informaciya-o-zaschite-platejnyh-dannyh-pokupatelya">{{ $t('home.8383') }}</router-link></li>
            <li><router-link class="text-white" to="/admin/docs/sposoby-oplaty">{{ $t('home.7323') }}</router-link></li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div class="text-white">
          <div class="pull-right hidden-lg-and-down">
            <ul class="social-link">
              <li><a href="https://twitter.com/universariumorg" class="social social-tw"></a></li>
              <li><a href="https://www.facebook.com/moosuniversarium/" class="social social-fb"></a></li>
              <li><a href="https://vk.com/public64826803" class="social social-vk"></a></li>
              <li><a href="https://www.youtube.com/channel/UC1KZMioKdLFgQARkZVK8Mow" class="social social-youtube"></a></li>
              <li><a href="https://www.instagram.com/universarium/" class="social social-insta"></a></li>
            </ul>
          </div>
          <span class="copyright">{{ $t('home.7985', { '1': new Date().getFullYear() }) }}</span><br/>
          <small>{{ $t('home.4706') }}</small>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import $ from 'jquery';
import tutors from './tutors'
import Video from "./Video";
const WOW = require('wowjs');
import {TheMask} from 'vue-the-mask'
import TutorList from "./Home/TutorList";
import PartnerList from "./Home/PartnerList";
import CategoryList from "./Home/CategoryList";
import SoonList from "./Home/SoonList";
export default {
  name: "Home",
  components: {
    SoonList,
    CategoryList,
    PartnerList,
    TutorList,
    Video,
    TheMask
  },
  data() {
    return {
      phone: null,
      myWindow: null,
      categories: null,
      institutions: [],
      media: {
        mimeType: 'video/mp4',
        thumbs: {
          '360p': "https://universarium.servicecdn.ru/courses/452/mp4/14095_Taym_menedjment___Rolik_720p.mp4",
          '480p': "https://universarium.servicecdn.ru/courses/452/mp4/14095_Taym_menedjment___Rolik_720p.mp4",
          '720p': "https://universarium.servicecdn.ru/courses/452/mp4/14095_Taym_menedjment___Rolik_720p.mp4",
          'cover': "/video-layer.jpg"
        }
      },
      slickOptions: {
        slidesToShow: 3,
        // Any other options that can be got from plugin documentation
      },
      clusterOptions: {
        1: {
          clusterDisableClickZoom: false
        }
      },
      settings: {
        apiKey: '5024622f-fa7d-48eb-aabe-fa1c0d9c4316',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      tutors: tutors.tutors,
      count: null
    }
  },
  mounted() {
    this.axios.get('users-count').then(res => {
      this.count = res.data.count + 2031258;
    })
    this.axios.get(`categories`).then(res => {
      this.categories = res.data;
    })
    this.axios.get(`institutions?isMainpage=true`).then(res => {
      this.institutions = res.data;
    })
    this.myWindow = window;
    this.axios.get('courses', {
      params: {
        isMainpage: true
      }
    })
        .then(res => {
          this.courses = res.data;
          $(document).ready(function () {
            $('body').attr('id', 'page-top').addClass('landing-page no-skin-config');
            $('.mainpage-wrapper').css('display', 'block');

            $('body').scrollspy({
              target: '#navbar',
              offset: 80
            });

            window.dispatchEvent(new Event('resize'));

            // Page scrolling feature
            $('a.page-scroll').bind('click', function(event) {
              var link = $(this);
              $('html, body').stop().animate({
                scrollTop: $(link.attr('href')).offset().top - 50
              }, 500);
              event.preventDefault();
              $("#navbar").collapse('hide');
            });
          });

          (function() {
            var docElem = document.documentElement,
                header = document.querySelector( '.navbar-default' ),
                didScroll = false,
                changeHeaderOn = 200;
            function init() {
              window.addEventListener( 'scroll', function(  ) {
                if( !didScroll ) {
                  didScroll = true;
                  setTimeout( scrollPage, 250 );
                }
              }, false );
            }
            function scrollPage() {
              var sy = scrollY();
              if ( sy >= changeHeaderOn ) {
                $(header).addClass('navbar-scroll')
              }
              else {
                $(header).removeClass('navbar-scroll')
              }
              didScroll = false;
            }
            function scrollY() {
              return window.pageYOffset || docElem.scrollTop;
            }
            init();

          })();

          window.wow = new WOW.WOW();

          // Activate WOW.js plugin for animation on scrol
          window.wow.init();
          window.wow.sync();
        });
  },
  methods: {
    getBg(tutor) {
      return 'background: url('+ tutor.User.photo + ') center center no-repeat;';
    },
    sendPhone() {
      this.$toasted.global.appSuccess('Мы вам скоро перезвоним!');
      this.phone = null;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/home";
.language-switcher {
  position: relative;
}

.language-switcher .nav-link {
  text-transform: uppercase!important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  padding: 12px 16px;
  top: 10px;
  right: -45px;
  color: #323232;
  cursor: pointer;
}
.language-switcher:hover .dropdown-content {
  display: block;
}
.language-switcher:hover i {
  transform: rotate(270deg);
}
</style>
