<template>
    <div class="theme-config">
        <div class="theme-config-box">
            <div class="spin-icon">
                <i class="fa fa-cogs fa-spin"></i>
            </div>
            <div class="skin-settings">
                <div class="title">Настройка интерфейса <br/>
                <small style="text-transform: none;font-weight: 400">
                    Настройте внешний вид для вашей компании
                </small></div>
                <div class="setings-item">
                    <b-form-group>
                        <valid-switcher v-model="viewSettings.showLogo" :vid="'showLogo'" title="Показать логотип организации" />
                        <valid-switcher v-model="viewSettings.switchMenu" :vid="'collapsemenu'" title="Свернутое меню" />
                        <valid-switcher v-model="viewSettings.fixHeader" :vid="'fixednavbar'" title="Зафиксировать хедер" />
                        <valid-switcher v-model="viewSettings.fixFooter" :vid="'fixedfooter'" title="Зафиксировать футер" />
                        <valid-switcher v-model="viewSettings.boxedLayout" :vid="'boxedlayout'" title="Ограничить ширину" />
                    </b-form-group>
                </div>
                <div class="title">Фон</div>
                <div class="setings-item default-skin">
                    <span class="skin-name">
                         <a href="#" @click="setTheme('s-skin-0')" class="s-skin-0">
                             Стандартный
                         </a>
                    </span>
                </div>
                <div class="setings-item blue-skin">
                    <span class="skin-name">
                        <a href="#" @click="setTheme('s-skin-1')" class="s-skin-1">
                            Светло-голубой
                        </a>
                    </span>
                </div>
                <div class="setings-item yellow-skin">
                    <span class="skin-name">
                        <a href="#" @click="setTheme('s-skin-3')" class="s-skin-3">
                            Желтый
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import ValidSwitcher from "./Validation/ValidSwitcher";

    export default {
        name: "ViewConfig",
        components: {ValidSwitcher},
        data() {
            return {
                viewSettings: {}
            }
        },
        mounted() {
            this.init()
            this.viewSettings = this.$auth.user().company.viewSettings ? this.$auth.user().company.viewSettings : {};
        },
        methods: {
            setTheme(theme) {
                this.viewSettings.theme = theme;
            },
            init() {
                let self = this;
              $(function () {
// Enable/disable fixed top navbar
                  $('#fixednavbar').click(function (){
                      if ($('#fixednavbar').is(':checked')){
                          $(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
                          $("body").removeClass('boxed-layout');
                          $("body").addClass('fixed-nav');
                          $('#boxedlayout').prop('checked', false);
                      } else{
                          $(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
                          $("body").removeClass('fixed-nav');
                          $("body").removeClass('fixed-nav-basic');
                          $('#fixednavbar2').prop('checked', false);
                      }
                  });

                  // Enable/disable fixed top navbar
                  $('#fixednavbar2').click(function (){
                      if ($('#fixednavbar2').is(':checked')){
                          $(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
                          $("body").removeClass('boxed-layout');
                          $("body").addClass('fixed-nav').addClass('fixed-nav-basic');
                          $('#boxedlayout').prop('checked', false);
                      } else {
                          $(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
                          $("body").removeClass('fixed-nav').removeClass('fixed-nav-basic');
                          $('#fixednavbar').prop('checked', false);
                      }
                  });

                  // Enable/disable fixed sidebar
                  $('#fixedsidebar').click(function (){
                      if ($('#fixedsidebar').is(':checked')){
                          $("body").addClass('fixed-sidebar');
                          $('.sidebar-collapse').slimScroll({
                              height: '100%',
                              railOpacity: 0.9
                          });
                      } else{
                          $('.sidebar-collapse').slimScroll({destroy: true});
                          $('.sidebar-collapse').attr('style', '');
                          $("body").removeClass('fixed-sidebar');
                      }
                  });

                  // Enable/disable collapse menu
                  $('#collapsemenu').click(function (){
                      if ($('#collapsemenu').is(':checked')){
                          $("body").addClass('mini-navbar');
                          self.SmoothlyMenu();
                      } else{
                          $("body").removeClass('mini-navbar');
                          self.SmoothlyMenu();
                      }
                  });

                  // Enable/disable boxed layout
                  $('#boxedlayout').click(function (){
                      if ($('#boxedlayout').is(':checked')){
                          $("body").addClass('boxed-layout');
                          $('#fixednavbar').prop('checked', false);
                          $('#fixednavbar2').prop('checked', false);
                          $(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
                          $("body").removeClass('fixed-nav');
                          $("body").removeClass('fixed-nav-basic');
                          $(".footer").removeClass('fixed');
                          $('#fixedfooter').prop('checked', false);
                      } else{
                          $("body").removeClass('boxed-layout');
                      }
                  });

                  // Enable/disable fixed footer
                  $('#fixedfooter').click(function (){
                      if ($('#fixedfooter').is(':checked')){
                          $('#boxedlayout').prop('checked', false);
                          $("body").removeClass('boxed-layout');
                          $(".footer").addClass('fixed');
                      } else{
                          $(".footer").removeClass('fixed');
                      }
                  });

                  // SKIN Select
                  $('.spin-icon').click(function (){
                      $(".theme-config-box").toggleClass("show");
                  });

                  // Default skin
                  $('.s-skin-0').click(function (){
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-2");
                      $("body").removeClass("skin-3");
                  });

                  // Blue skin
                  $('.s-skin-1').click(function (){
                      $("body").removeClass("skin-2");
                      $("body").removeClass("skin-3");
                      $("body").addClass("skin-1");
                  });

                  // Inspinia ultra skin
                  $('.s-skin-2').click(function (){
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-3");
                      $("body").addClass("skin-2");
                  });


                  // Yellow skin
                  $('.s-skin-3').click(function (){
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-2");
                      $("body").addClass("skin-3");
                  });

                  console.log(self.viewSettings.theme);

                  if(!self.viewSettings.theme || self.viewSettings.theme === 's-skin-0') {
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-2");
                      $("body").removeClass("skin-3");
                  }

                  if(self.viewSettings.theme === 's-skin-1') {
                      $("body").removeClass("skin-2");
                      $("body").removeClass("skin-3");
                      $("body").addClass("skin-1");
                  }

                  if(self.viewSettings.theme === 's-skin-2') {
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-3");
                      $("body").addClass("skin-2");
                  }

                  if(self.viewSettings.theme === 's-skin-3') {
                      $("body").removeClass("skin-1");
                      $("body").removeClass("skin-2");
                      $("body").addClass("skin-3");
                  }

                  if (self.viewSettings){
                      if (self.viewSettings.switchMenu){
                          $("body").addClass('mini-navbar');
                          self.SmoothlyMenu();
                      }
                      if (self.viewSettings.fixHeader){
                          $('#fixednavbar').prop('checked','checked')
                      }
                      if (self.viewSettings.boxedLayout){
                          $("body").addClass('boxed-layout');
                          $('#fixednavbar').prop('checked', false);
                          $('#fixednavbar2').prop('checked', false);
                          $(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
                          $("body").removeClass('fixed-nav');
                          $("body").removeClass('fixed-nav-basic');
                          $(".footer").removeClass('fixed');
                          $('#fixedfooter').prop('checked', false);
                      }
                      if (self.viewSettings.fixFooter){
                          $('#fixedfooter').prop('checked','checked')
                      }
                      if (self.viewSettings.showLogo){
                          $('#showLogo').prop('checked','checked')
                      }
                  }
              })
            },
            SmoothlyMenu() {
                if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
                    // Hide menu in order to smoothly turn on when maximize menu
                    $('#side-menu').hide();
                    // For smoothly turn on menu
                    setTimeout(
                        function () {
                            $('#side-menu').fadeIn(400);
                        }, 200);
                } else if ($('body').hasClass('fixed-sidebar')) {
                    $('#side-menu').hide();
                    setTimeout(
                        function () {
                            $('#side-menu').fadeIn(400);
                        }, 100);
                } else {
                    // Remove all inline style from jquery fadeIn function to reset menu state
                    $('#side-menu').removeAttr('style');
                }
            }
        },
        watch: {
            viewSettings: {
                handler(val) {
                    if(val !== {}) {
                        this.axios.put(`institutions/${this.$auth.user().company.id}`, {
                            viewSettings: val
                        });
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .setings-item {
        padding: 10px;
    }
</style>
