<template>
  <div v-if="element" class="animated fadeInRight container">
    <div class="ibox product-detail">
      <div class="ibox-content">
        <div class="row">
          <div class="col-lg">
            <div class="product-images slick-initialized slick-slider" role="toolbar">
              <div :class="element.type === 'check' ? '' : 'image-imitation'">
                <Video :title="element.title" v-if="element.type === 'film'" :element-result="element.elementResult" :media="element.file"/>
                <TestPassage v-else-if="element.type === 'list'" :test-id="element.test.id"/>
                <element-file v-else-if="element.type === 'file-pdf-o'" :element="element" />
                <homework-user-check v-else-if="element.type === 'check'" :element="element" />
                <template v-else-if="element.type === 'home'">
                  <ibox-title><i class="fa fa-home"></i> Домашнее задание</ibox-title>
                  <div class="ibox-content">
                    <element-homework
                        v-if="!module.isLocked"
                        :loading="homeworkSaving"
                        :status="element.elementResult.homework.status"
                        :content="element.elementResult.homework.content"
                        :attachments="element.elementResult.attachments"
                        @draft="onHomeworkSave($event, true)"
                        @submit="onHomeworkSave($event, false)"
                    />
                    <div v-else class="hw-blocked">
                      <b-badge>Модуль закончен, домашнее задание недоступно</b-badge>
                    </div>
                  </div>
                </template>
                <template v-else-if="element.type === 'play'">
                  <ibox-title>Вебинар</ibox-title>
                  <div class="ibox-content">
                    <iframe width="100%" height="800"
                            :src="'https://pruffme.com/widget/full/?webinar=' + element.webinar.apiId"
                            frameborder="0" allowfullscreen samesite="none">
                    </iframe>
                  </div>
                </template>
                <book-list v-else-if="element.type === 'book'" :book-lists="element.bookLists" />
              </div>
            </div>
            <div>
              <div class="btn-group group-center">
                <router-link :to="`/admin/course/${$parent.course.id}/module/${$route.params.index}/element/${prevElementIndex}`" class="btn btn-white btn-sm"><i class="fa fa-arrow-circle-left"></i> Назад </router-link>
                <router-link active-class="none" :to="`/admin/course/${$parent.course.id}/module/${$route.params.index}`" class="btn btn-primary btn-sm"><i class="fa fa-list-ul"></i> К модулю</router-link>
                <router-link :to="`/admin/course/${$parent.course.id}/module/${$route.params.index}/element/${nextElementIndex}`" class="btn btn-white btn-sm"><i class="fa fa-arrow-circle-right"></i> Вперед </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg" v-if="element.type !== 'list' && element.type !== 'film' && element.type !== 'book'">
            <ibox v-if="element.type === 'home'">
              <ibox-title :hide-tools="true">
                <div class="pull-right">
                      <span v-if="element.elementResult.points" :class="'label label-' + (element.elementResult.status === 'success' ? 'primary' : element.elementResult.status)">
                        {{element.elementResult.statusRu}}: <strong>{{element.elementResult.points}} {{element.elementResult.points|pluralize('ru', ['балл', 'балла', 'баллов'])}}</strong>
                      </span>
                </div>
                Домашнее задание
              </ibox-title>
              <ibox-content>
                <p>
                  <strong>Задание:</strong>
                  <br/>
                  <span v-html="element.description"></span>
                </p>
              </ibox-content>
              <homework-user-result :element="element" />
            </ibox>
            <ibox v-if="element.type === 'check'">
              <ibox-title :hide-tools="true">
                <div class="pull-right">
                      <span v-if="element.elementResult.points" :class="'label label-' + (element.elementResult.status === 'success' ? 'primary' : element.elementResult.status)">
                        {{element.elementResult.statusRu}}: {{element.elementResult.points}} <strong>{{element.elementResult.points|pluralize('ru', ['балл', 'балла', 'баллов'])}}</strong>
                      </span>
                </div>
                {{ element.name }}
              </ibox-title>
              <ibox-content>
                <p>
                  <strong>Задание:</strong>
                  <br/>
                  <span v-html="element.description"></span>
                </p>
                <p v-if="element.file">
                  <strong>Файл с правильным ответом:</strong>
                  <br/>
                  <a class="btn d-inline-block btn-sm btn-outline-info" target="_blank" download :href="element.file.contentPath"><i class="fa fa-download"></i> Скачать</a>
                </p>
              </ibox-content>
            </ibox>
            <template v-else-if="element.type === 'file-pdf-o' || element.type === 'file'">
              <a @click="setSuccess()" class="pdf-download btn btn-success" target="_blank" download :href="element.file.contentPath"><i class="fa fa-download"></i> Скачать</a>
            </template>
            <template v-else v-html="element.description"></template>
          </div>
        </div>
      </div>
      <div class="ibox-footer">
        <div class="text-right">
          <i class="fa fa-clock-o"></i> Время на изучение {{element.duration}} {{element.duration|pluralize('ru', ['час','часа','часов'])}}
        </div>
      </div>
    </div>
    <module-element-chatbox v-if="$parent.course.isSupport" :element-id="element.id"/>
  </div>
</template>

<script>
import Video from "../Video";
import TestPassage from "../Test/TestPassage";
import ModuleElementChatbox from "./ModuleElementChatbox";
import ElementFile from "./ElementFile";
import bus from "../../bus";
import BookList from "./BookList";
import ElementHomework from "./ElementHomework";
import IboxTitle from "../Ibox/IboxTitle";
import HomeworkUserCheck from "../Homework/HomeworkUserCheck";
import Ibox from "../Ibox/Ibox";
import IboxContent from "../Ibox/IboxContent";
import HomeworkUserResult from "../Homework/HomeworkUserResult";
export default {
  name: "ElementData",
  components: {
    HomeworkUserResult,
    IboxContent,
    Ibox,
    HomeworkUserCheck,
    IboxTitle,
    ElementHomework,
    BookList,
    ElementFile,
    ModuleElementChatbox,
    TestPassage,
    Video
  },
  data() {
    return {
      module: this.$parent.course.modules[this.$route.params.index-1],
      items: [],
      homeworkSaving: false
    }
  },
  mounted() {
    if (!this.$auth.check() || !this.$parent.course.enroll) {
      this.$router.push(`/admin/course/${this.$parent.course.id}`).catch(() => {});
      return;
    }

    bus.$on('element_result:update', (data) => {
      this.element.elementResult = data;
    });
    this.$parent.items = [
      {title: 'Главная', url: '/#/'},
      {title: 'Каталог курсов', url: '/admin/catalog'},
      {title:  this.$parent.course.name, url: '/admin/course/' +this.$parent.course.id},
      {title:  `Модуль ${this.module.position}. ${this.module.name}`, url: '/admin/course/' + this.$parent.course.id + '/module/' + this.module.position},
    ];
    if(this.element) {
      this.$parent.title = this.element.name
    }
  },
  computed: {
    element() {
      return this.$parent.course.modules[this.$route.params.index-1].moduleElements[this.$route.params.eIndex-1];
    },
    nextElementIndex() {
      if(this.$route.params.eIndex < this.module.moduleElements.length) {
        return parseInt(this.$route.params.eIndex) + 1;
      } else {
        return 1;
      }
    },
    prevElementIndex() {
      if(this.$route.params.eIndex - 1 > 0) {
        return parseInt(this.$route.params.eIndex) - 1;
      } else {
        return this.module.moduleElements.length;
      }
    },
  },
  methods: {
    setSuccess() {
      this.axios
          .put(`element_results/${this.element.elementResult.id}?hideLoader=true`, {status: 'success'})
          .then(res => {
            bus.$emit('element_result:update', res.data);
          });
    },
    onHomeworkSave(data, isDraft = false) {
      if (this.homeworkSaving) {
        return;
      }

      this.homeworkSaving = true;
      this.axios
          .post(
              `element_results/${this.element.elementResult.id}`,
              {...data, isDraft}
          )
          .then(res => {
            console.log(this.element.elementResult);
            bus.$emit(
                'element_result:update',
                {
                  ...this.element.elementResult,
                  homework: res.data.homework,
                  attachments: res.data.attachments,
                }
            );
          })
          .finally(() => this.homeworkSaving = false)
    }
  }
}
</script>

<style scoped>
.image-imitation {
  padding: 0;
  min-height: auto;
}
.group-center {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
}
</style>
