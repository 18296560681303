<template>
    <div v-if="course" class="animated fadeInRight">
        <template v-if="!$auth.check()">
            <div class="wrapper wrapper-content row">
                <div class="col-lg-12">
                    <LikeButtons :course-data="course" :show-btn="true"/>
                    <router-view :key="$route.path"/>
                </div>
            </div>
        </template>
        <template v-else>
            <bread-crumbs :title="title" :items="items"/>
            <div class="wrapper wrapper-content">
                <div class="d-flex flex-column">
                    <div class="row">
                        <div :class="$route.name !== 'element' ? 'col-lg-12 col-xl-9' : 'col-lg-12'">
                            <router-view :key="$route.fullPath"></router-view>
                        </div>
                        <div v-if="$route.name !== 'element'" class="col-lg-12 col-xl-3 order-first order-lg-2">
                            <ModuleMenu v-if="isEnroll(course)" :course="course"/>
                            <LikeButtons/>
                            <EnrollCourse v-model="course" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import ModuleMenu from "./ModuleMenu";
    import LikeButtons from "../LikeButtons";
    import EnrollCourse from "./EnrollCourse";
    import Actions from "../User/Actions";
    import BreadCrumbs from "../BreadCrumbs";
    import Vue from "vue";
    export default {
        name: "Course",
        components: {BreadCrumbs, EnrollCourse, LikeButtons, ModuleMenu},
        extends: Actions,
        data() {
            return {
                course: null,
                title: null,
                items: [{title: 'Главная', url: '/#/'}, {title: 'Каталог курсов', url: '/admin/catalog'}]
            }
        },
        mounted() {
            let loader = Vue.$loading.show(this.loaderStyle());
            this.axios
                .get(`courses/${this.$route.params.id}`, {
                    params: {
                        hideLoader: false
                    }
                })
                .then(res => {
                    if(res.status === 200) {
                      this.course = res.data
                      loader.hide()
                      this.title = this.course.name;
                    } else {
                      this.$router.push('/admin/404').catch(() => {});
                    }
                });
        },
        watch: {
            $route(to) {
                if(to.name === 'courseInfo') {
                    this.title = this.course.name;
                    this.items = [{title: 'Главная', url: '/#/'}, {title: 'Каталог курсов', url: '/admin/catalog'}];
                }
            }
        }
    }
</script>

<style lang="scss">
    .course {
        &-title {
            font-size: 27px;
            font-weight: 100;
        }
        &-short-desc {
            line-height: 1.5;
        }
        &-data-stat {
            font-size: 12px;
            justify-content: space-between;
            padding: 0;
            margin: -15px 0 0;
            list-style: none;
            display: flex;
            span {
                display: inline-block;
                position: relative;
                top: 1.2em;
            }
        }
        &-promo {
            margin-top: 25px;
        }
    }
    .course-page {
        .contact-box {
            border: none;
        }
        h2 {
            font-weight: 400;
            font-size: 18px;
            margin: 20px 0;
        }
    }
    .big-badge {
        padding: 5px 7px;
        margin: 2px;
        background: #d8d8d8;
        color: #737373;
    }
</style>
