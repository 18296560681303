<template>
    <div class="animated fadeIn">
        <BreadCrumbs title="Список организаций" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    Список организаций
                    <div class="ibox-tools">
                        <a v-b-tooltip title="Добавить" variant="success" v-b-modal.add-institution>
                            <i class="fa fa-plus"></i> Добавить
                        </a>
                    </div>
                </div>
                <div class="ibox-content">
                    <b-modal
                            id="add-institution"
                            ref="modal"
                            button-size="sm"
                            title="Добавление новой компании"
                            :hide-footer="true"
                            ok-title="Отправить"
                            cancel-title="Отмена"
                    >
                        <validation-observer ref="observer" v-slot="{ passes }">
                            <form ref="form" @submit.stop.prevent="passes(handleSubmit)">
                                <b-form-group
                                        label="Логотип"
                                >
                                    <el-upload
                                            class="avatar-uploader"
                                            :show-file-list="false"
                                            :action="axios.defaults.baseURL + 'media_objects'"
                                            :headers="{Authorization: 'Bearer ' + $auth.token()}"
                                            :on-success="handleUploadSuccess">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>

                                </b-form-group>
                                <valid-input name="название" label="Название" v-model="form.name" rules="required" />
                                <valid-input name="короткое название" label="Короткое название" v-model="form.shortName" rules="required" />
                                <valid-input name="сайт" label="Сайт организации" rules="required" v-model="form.site" />
                                <b-form-group
                                        label="Регион"
                                        label-for="type-input"
                                        invalid-feedback="Регион обязательно!"
                                >
                                    <multiselect
                                            v-model="form.region"
                                            :multiple="false"
                                            :searchable="true"
                                            :options="regions"
                                            openDirection="top"
                                            :loading="isRegionsLoading"
                                            @search-change="findRegions"
                                            @select="loadRegionCities"
                                            placeholder="Введите регион"
                                            :custom-label="opt => opt.regionNameRu"
                                    >
                                        <template slot="noOptions">Регион не найден</template>
                                    </multiselect>
                                </b-form-group>
                                <b-form-group label="Город">
                                    <multiselect
                                            :disabled="!form.region"
                                            v-model="form.city"
                                            :multiple="false"
                                            :searchable="true"
                                            :options="cities"
                                            track-by="id"
                                            openDirection="top"
                                            placeholder="Введите город"
                                            :custom-label="opt => opt.name"
                                    >
                                        <template slot="noOptions">Город не найден</template>
                                    </multiselect>
                                </b-form-group>
                                <hr class="hr-line-dashed"/>
                                <b-btn variant="primary" type="submit" size="md">Отправить</b-btn>
                            </form>
                        </validation-observer>
                    </b-modal>
                    <b-table striped hover bordered :fields="fields" :items="institutes">
                        <template v-slot:cell(logo)="data">
                            <img v-if="data.item.logo" :src="data.item.logo.thumbs.smaller"/>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <b-button-group>
                                <router-link v-b-tooltip title="Редактировать" :to="'/admin/institution/' + data.item.id" class="btn btn-sm btn-info"><i class="fa fa-edit"></i></router-link>
                                <b-button v-b-tooltip title="Удалить" variant="danger" size="sm" @click="confirmDelete(data.item)"><i class="fa fa-trash"></i></b-button>
                            </b-button-group>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import ValidInput from "../Validation/ValidInput";
    export default {
        name: "InstitutionList",
        components: {ValidInput, BreadCrumbs},
        data() {
            return {
                imageUrl: null,
                form: {},
                addCompany: false,
                institutes: [],
                isRegionsLoading: false,
                cities: [],
                regions: [],
                fields: [
                    {
                        key: 'logo',
                        label: 'Логотип'
                    },
                    {
                        key: 'name',
                        label: 'Название',
                        sortable: true
                    },
                    {
                        key: 'shortName',
                        label: 'Короткое название',
                        sortable: false
                    },
                    {
                        key: 'site',
                        label: 'Вебсайт',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load(false);
        },
        methods: {
            findRegions(query) {
                if(query !== '') {this.isRegionsLoading = true;
                    this.axios.get(`regions/?name=${query}`).then(res => {
                        this.isRegionsLoading = false;
                        this.regions = res.data;
                    });
                }
            },
            loadRegionCities(region) {
                if (!region) {
                    this.cities = [];
                    return;
                }
                this.axios.get(`cities?region=${region.id}`)
                    .then(res => {
                        this.cities = res.data
                    });
            },
            load(showMsg) {
                this.axios.get(  'institutions').then(res => {
                    this.institutes = res.data;
                    if(showMsg) {
                        this.$toasted.global.appSuccess();
                    }
                });
            },
            confirmDelete(company) {
                this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить компанию? Это дествие необратимо.', {
                    title: 'Удаление компании «' + company.name + '»',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(res => {
                        if(res) {
                            this.axios.delete('institutions/' + company.id).then(() => {
                                this.load(true)
                            });
                        }
                    })
            },
            checkFormValidity() {
                return this.$refs.form.checkValidity()
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                // Trigger submit handler
                this.handleSubmit()
            },
            handleSubmit() {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return
                }
                // Push the name to submitted names
                this.axios.post('institutions', this.form).then(() => {
                    this.load(true)
                    this.form = {};
                    this.imageUrl = null;
                });
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('add-institution')
                })
            },
            handleUploadSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.form.logo = res;
            }
        }
    }
</script>

<style scoped>
    .mr-30 {
        margin-bottom: 10px;
        margin-right: 0;
    }
</style>
