import { render, staticRenderFns } from "./CodifierEdit.vue?vue&type=template&id=fd334500&scoped=true&"
import script from "./CodifierEdit.vue?vue&type=script&lang=js&"
export * from "./CodifierEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd334500",
  null
  
)

export default component.exports