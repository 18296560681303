<template>
    <div class="animated fadeIn">
        <BreadCrumbs title="Проверка домашнего задания" :items="[{title: 'Главная', url: '/#/'}, {title: 'Домашние задания', url: '/admin/homeworks'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox" v-if="task">
                <div class="ibox-title"><b>{{ task.title }}</b></div>
                <div class="ibox-content" v-html="task.description"></div>
            </div>

            <div class="ibox" v-if="task && task.criterias.length > 0">
                <div class="ibox-title font-weight-bold">Критерии оценки</div>
                <div class="ibox-content">
                    <ul class="list-group">
                        <li class="list-group-item" v-for="(cr, index) of task.criterias" :key="index">
                            <div>{{ cr.title }}</div>
                            <div class="font-weight-bold">Коэффициент: {{ cr.coefficient }}</div>
                            <div class="mt-3" v-html="cr.description"></div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="ibox" v-if="result">
                <div class="ibox-title font-weight-bold">Результат выполнения</div>
                <div class="ibox-content">
                    <div class="mb-3" v-html="result.content"></div>
                    <div v-if="result.attachments.length > 0">
                        <div class="font-weight-bold">Вложения</div>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="(file, index) of result.attachments" :key="index">
                                <a :href="axios.defaults.baseURL + file.path" target="_blank">{{ file.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <h3>Оценка результата проверки</h3>
                    <b-badge v-if="result.status === 'checked'" variant="primary">Результаты уже были отправлены</b-badge>
                    <validation-observer ref="observer" v-slot="{ passes }">
                      <b-form @submit.stop.prevent="passes(saveResult)">
                          <b-row>
                              <b-col sm="12">
                                  <valid-input
                                      :disabled="result.status === 'checked'"
                                       v-model.number="checkResult.mark"
                                       type="number"
                                       label="Оценка"
                                       :step="1"
                                       name="оценка"
                                       :rules="{required: true, numeric: true, min_value: 0, max_value: 5}"
                                  />
                              </b-col>
                              <b-col sm="12">
                                  <valid-textarea :rules="{required: true}" :disabled="result.status === 'checked'" label="Комментарий" name="комментарий" v-model="checkResult.text" />
                              </b-col>
                          </b-row>
                          <b-btn v-if="result.status !== 'checked'"
                                 :disabled="checkSending"
                                 variant="primary"
                                 type="submit"
                          >Отправить результат</b-btn>
                      </b-form>
                      <button type="button" class="btn btn-sm btn-success" v-if="result.status === 'checked'" @click="changeResult">
                        <i class="fa fa-edit"></i> Изменить результат
                      </button>
                    </validation-observer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import ValidInput from "../Validation/ValidInput";
    import ValidTextarea from "../Validation/ValidTextarea";
    export default {
        name: "HomeworkCheck",
        components: {
          ValidTextarea, ValidInput, BreadCrumbs},
        data() {
            return {
              id: null,
              task: null,
              result: null,
              checkSending: false,
              checkResult: {
                  mark: 0,
                  text: ''
              }
            }
        },
        mounted() {
            this.loadByElementResult(this.$route.params.id)
        },
        methods: {
            loadByElementResult(id) {
                this.axios
                    .get('/homeworks/' + id)
                    .then((res) => {
                        this.id = res.data.id;
                        this.task = res.data.task;
                        this.result = res.data.result;
                        this.checkResult = res.data.checkResult;
                    })
            },
            saveResult() {
                if (this.checkSending) {
                    return;
                }

                this.checkSending = true;
                this.axios
                    .post(`/homeworks/${this.id}/result`, this.checkResult)
                    .then(r => {
                        // @todo wtf omg
                        if (r.status > 199 && r.status < 299) {
                            this.$router.push('/admin/homeworks').catch(() => {});
                            return;
                        }
                    })
                    .finally(() => this.checkSending = false)
            },
            changeResult() {
              this.result.status = 'submitted'
            }
        }
    }
</script>
