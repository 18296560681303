const ACL = {
    ROLE_METHODIST: {
        course: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        module: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        element: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        codifier: {
            read: true,
            create: true,
            update: true,
            delete: true
        },
        test: {
            read: true,
            create: true,
            update: true,
            delete: true,
            updateType: true,
            updateQType: true,
            updateCodifier: true
        }
    },
    ROLE_USER: {
        course: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        module: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        element: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        codifier: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        test: {
            read: true,
            create: false,
            update: false,
            delete: false
        }
    },
    ROLE_TUTOR: {
        course: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        module: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        element: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        codifier: {
            read: true,
            create: false,
            update: false,
            delete: false
        },
        test: {
            read: true,
            create: false,
            update: false,
            delete: false,
            updateType: true,
            updateQType: true,
            updateCodifier: true
        }
    }
}
export default ACL
