<template>
    <div v-if="course.description" class="ibox">
        <ibox-title>О курсе</ibox-title>
        <div class="ibox-content">
            <p v-html="course.description"></p>
            <p v-if="codifiers.length"><i class="fa fa-clock"></i> Время, необходимое для изучения курса: {{course.duration}} {{course.duration|pluralize('ru', ['час', 'часа', 'часов'])}}</p>
            <span v-if="codifiers.length">
                <h3>Приобретаемые навыки</h3>
                <span v-for="(codifier, index) in codifiers" :key="index">
                     <b-badge class="big-badge" variant="secondary">{{codifier.title}}</b-badge>
               </span>
            </span>
        </div>
    </div>
</template>

<script>
    import IboxTitle from "../Ibox/IboxTitle";
    export default {
        name: "CourseAbout",
        components: {IboxTitle},
        props: ['course'],
        data() {
            return {
                codifiers: []
            }
        },
        mounted() {
            this.course.modules.forEach(module => {
                module.codifiers.forEach(codifier => {
                    if(this.codifiers.indexOf(codifier) > -1) {
                        this.codifiers.push(codifier);
                    }
                });
            })
        }
    }
</script>

<style scoped>

</style>
