<template>
    <div class="ibox animated fadeInRight">
        <div class="ibox-content">
            <ul v-if="course" class="module-menu">
                <li>
                    <router-link :to="`/admin/course/${course.id}`"><i class="fa fa-info-circle"></i> О курсе</router-link>
                </li>
                <li v-for="(module, index) in course.modules" :key="index">
                    <router-link v-if="!module.isLocked" :to="`/admin/course/${course.id}/module/${index + 1}`">
                        <i class="fa fa-play"></i> Модуль {{index + 1}}. {{module.name}}
                    </router-link>
                    <span v-else>
                        <i class="fa fa-lock"></i> Модуль {{index + 1}}. {{module.name}}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModuleMenu",
        props: ['course']
    }
</script>

<style scoped lang="scss">
    .module-menu {
        margin: -15px -20px;
        padding: 25px 35px 25px 25px;
        list-style: none;
        li {
            padding-bottom: 2px;
            margin-bottom: 5px;
            border-bottom: 1px dashed #e7eaec;
            line-height: 25px;
            display: block;
            a {
                color: inherit;
                font-size: 14px;
                display: inline-block;
                margin: 5px 0;
                &:hover {
                    color: #1ab394;
                }
                &.router-link-exact-active {
                    color: #1ab394;
                    padding-left: 22px;
                    background: inherit;
                    margin-left: -25px;
                    border-left: 3px solid #1ab394;
                }
            }
        }
    }
</style>
