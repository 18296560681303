<template>
    <div v-if="course" class="animated fadeInRight">
        <div class="wrapper wrapper-content row">
            <div class="col-lg-12">
                <LikeButtons :course-data="course" :show-btn="true"/>
                <router-view :key="$route.path"/>
            </div>
        </div>
    </div>
</template>

<script>
    import LikeButtons from "../LikeButtons";
    import Actions from "../User/Actions";
    export default {
        name: "Course",
        components: {LikeButtons},
        extends: Actions,
        data() {
            return {
                course: null
            }
        },
        mounted() {
            this.$router.push(`/admin/course/${this.$route.params.id}`).catch(() => {});

            // if(this.$auth.check()) {
            //     this.$router.push(`/admin/course/${this.$route.params.id}`);
            // }
            // this.axios
            //     .get(`courses/${this.$route.params.id}`)
            //     .then(res => this.course = res.data);
        },
    }
</script>

<style lang="scss">
    .course {
        &-title {
            font-size: 27px;
            font-weight: 100;
        }
        &-short-desc {
            line-height: 1.5;
        }
        &-data-stat {
            font-size: 12px;
            justify-content: space-between;
            padding: 0;
            margin: -15px 0 0;
            list-style: none;
            display: flex;
            span {
                display: inline-block;
                position: relative;
                top: 1.2em;
            }
        }
        &-promo {
            margin-top: 25px;
        }
    }
    .course-page {
        .contact-box {
            border: none;
        }
        h2 {
            font-weight: 400;
            font-size: 18px;
            margin: 20px 0;
        }
    }
    .big-badge {
        padding: 5px 7px;
        margin: 2px;
        background: #d8d8d8;
        color: #737373;
    }
</style>
