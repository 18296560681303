<template>
  <span>Поиск сертификата...</span>
</template>
<script>
export default {
name: "CertOld",
  mounted() {
    window.location.href = process.env.VUE_APP_API_URL + 'cert-old/' + this.$route.params.id
  }
}
</script>

<style scoped>

</style>
