<template>
    <b-list-group v-if="module && course">
        <b-modal
                id="add-test"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Добавление нового теста"
                @ok="handleOk"
                v-bind:busy="modalSubmitting"
                ok-title="Сохранить"
                cancel-title="Отмена"
        >
            <test-form
                    v-bind:submitting="modalSubmitting"
                    :test-preset="prepareTestPreset()"
                    :hideCourse="true"
                    v-on:submitting="handleSubmitting($event)"
                    v-on:submitted="handleSubmitResult($event)"
            ></test-form>
        </b-modal>
        <div role="tablist" v-if="element">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-video variant="default"><i class="fa fa-film"></i> Видеолекция</b-button>
                </b-card-header>
                <b-collapse id="accordion-video" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                                    <FileUpload accept="video/mp4" v-model="element.file" style="width: 100%">
                                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                                            Выберите файл mp4 <i class="fa fa-cloud-upload"></i>
                                        </b-btn>
                                        <div v-else>
                                            <label>Прогресс транскодинга</label>
                                            <EncoderProcess :media="element.file"/>
                                        </div>
                                    </FileUpload>
                                    <hr class="hr-line-dashed"/>
                                    <b-btn variant="success" type="submit">Сохранить</b-btn>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-audio variant="default"><i class="fa fa-music"></i> Аудиофайл</b-button>
                </b-card-header>
                <b-collapse id="accordion-audio" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                                    <FileUpload accept="audio/mp3" v-model="element.file">
                                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                                            Выберите файл mp3 <i class="fa fa-cloud-upload"></i>
                                        </b-btn>
                                        <div v-else>
                                            Файл загружен <i class="fa fa-download"></i>
                                        </div>
                                    </FileUpload>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-test variant="default"><i class="fa fa-list"></i> Тест</b-button>
                </b-card-header>
                <b-collapse id="accordion-test" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block variant="default" v-b-toggle.accordion-pdf><i class="fa fa-file-pdf-o"></i> Презентация</b-button>
                </b-card-header>
                <b-collapse id="accordion-pdf" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                                    <FileUpload accept="application/pdf" v-model="element.file">
                                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                                            Выберите файл pdf <i class="fa fa-cloud-upload"></i>
                                        </b-btn>
                                        <div v-else>
                                            Файл загружен <i class="fa fa-download"></i>
                                        </div>
                                    </FileUpload>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block variant="default" v-b-toggle.accordion-webinar><i class="fa fa-play"></i> Вебинар</b-button>
                </b-card-header>
                <b-collapse id="accordion-webinar" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <b-form-group label="Дата начала">
                                        <ElDatePicker style="z-index: 10000;"
                                                      type="datetime"
                                                      placeholder="Выберите дату и время проведения вебинара" v-model="element.startedAt"/>
                                    </b-form-group>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block variant="default" v-b-toggle.accordion-homework><i class="fa fa-home"></i> Домашнее задание</b-button>
                </b-card-header>
                <b-collapse id="accordion-homework" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer v-if="element.homework" ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                                    <valid-summernote v-model="element.description" label="Описание" name="описание" rules="required|min:3"/>
                                    <valid-select
                                            v-model="element.hwType"
                                            :value="element.hwType"
                                            label="Тип проверки"
                                            rules="required"
                                            placeholder="Тип"
                                            :multiple="false"
                                            :custom-label="homeworkTypeLabel"
                                            :options="homeworkOptions"
                                    />
                                    <valid-select
                                      v-model="element.checkModule"
                                      label="Модуль проверки"
                                      rules="required"
                                      placeholder="Выберите модуль проверки"
                                      :multiple="false"
                                      track-by="id"
                                      :custom-label="(opt) => opt.name"
                                      :options="course.modules.filter(mod => mod.position > module.position)"
                                    />
                                    <label>Файл с правильным ответом</label>
                                    <FileUpload accept=".pdf,.xls,.xlsx" :action-for-upload="null" v-model="element.homework.file">
                                      <b-btn size="lg" class="btn-block" variant="primary">
                                        Выберите файл pdf или excel <i class="fa fa-cloud-upload"></i>
                                      </b-btn>
                                    </FileUpload>
                                    <div v-if="element.homework.file" class="form-group">
                                      <a target="_blank" :href="element.homework.file.contentPath">Скачать <i class="fa fa-download"></i></a>
                                    </div>
                                    <hr class="hr-line-dashed"/>
                                    <h4>Критерии оценки</h4>
                                    <b-row v-for="(criteria, index) in element.homeworkCriterias" :key="index">
                                        <b-col md="8"><ValidInput v-model="criteria.title" placeholder="Критерий"/></b-col>
                                        <b-col md="3"><ValidInput v-model.number="criteria.coefficient" type="number" placeholder="Оценка"/></b-col>
                                        <b-col md="1">
                                            <b-btn v-if="index > 0" size="sm" variant="danger" @click="element.homeworkCriterias.splice(index, 1)"><i class="fa fa-close"></i></b-btn>
                                        </b-col>
                                    </b-row>
                                    <div class="clearfix">
                                        <b-btn size="sm" variant="primary" @click="addHomeworkCriterias()" class="pull-right">Добавить</b-btn>
                                    </div>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block variant="default" v-b-toggle.accordion-book><i class="fa fa-book"></i> Список литературы</b-button>
                </b-card-header>
                <b-collapse id="accordion-book" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            <validation-observer ref="observer" v-slot="{ passes }">
                                <b-form @submit.stop.prevent="passes(onSubmit)">
                                    <valid-summernote v-model="element.description" label="Описание" name="описание"/>
                                    <hr class="hr-line-dashed"/>
                                    <h4>Список литературы</h4>
                                    <span v-for="(book, index) in booklist" :key="index">
                                        <b-row>
                                            <b-col>
                                                <ValidInput v-model="book.title" placeholder="Название"/>
                                            </b-col>
                                            <b-col>
                                                <ValidInput v-model="book.link" placeholder="Ссылка"/>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <ValidInput v-model="book.author" placeholder="Авторы"/>
                                            </b-col>
                                            <b-col>
                                                <valid-select v-model="book.type" placeholder="Тип" :multiple="false" :options="['Статья', 'Пособие', 'Учебник', 'Художественная литература']" />
                                            </b-col>
                                        </b-row>
                                        <b-col md="1">
                                            <b-btn v-if="index > 0" size="sm" variant="danger" @click="booklist.splice(index, 1)"><i class="fa fa-close"></i></b-btn>
                                        </b-col>
                                        <hr class="hr-line-dashed"/>
                                    </span>
                                    <div class="clearfix">
                                        <b-btn size="sm" variant="primary" @click="booklist.push({title: '', author: '', type: 'article', link: ''})" class="pull-right">Добавить</b-btn>
                                    </div>
                                    <hr class="hr-line-dashed"/>
                                    <b-button type="submit" variant="success">Сохранить</b-button>
                                </b-form>
                            </validation-observer>
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </b-list-group>
</template>

<script>
    import TestForm from "./components/Test/TestForm";
    import {DEFAULT_TEST_DATA} from "./components/Test/TestForm";
    import FileUpload from "./components/FileUpload";
    import EncoderProcess from "./components/EncoderProcess";
    import ValidInput from "./components/Validation/ValidInput";
    import bus from "./bus";
    import ValidSummernote from "./components/Validation/ValidSummernote";
    import ValidSelect from "./components/Validation/ValidSelect";
    export default {
        name: "AddElement",
        components: {
            ValidSelect,
            ValidSummernote,
            ValidInput,
            EncoderProcess,
            FileUpload,
            TestForm
        },
        props: ['module', 'course'],
        data() {
            return {
                modalSubmitting: false,
                booklist: [{title: '', author: '', type: 'article', link: ''}],
                element: {
                    file: null,
                    name: '',
                    homework: null,
                    checkModule: null,
                    type: '',
                    module: '/modules/' + this.module.id
                },
                homeworkOptions: [
                    'tutor',
                    'crosspoll',
                ],
                homeworkTypeLabel(type) {
                    const map = {tutor: 'Учитель', crosspoll: 'Учитель+Ученик'};

                    return map[type] || null;
                },
                moduleTypeLabel() {

                }
            }
        },
        mounted() {
            this.$root.$on('bv::toggle::collapse', id => {
                if(id === 'accordion-video') {
                    this.element.type = 'film'
                }
                if(id === 'accordion-audio') {
                    this.element.type = 'music'
                }
              if(id === 'accordion-homework-check') {
                this.element.type = 'check'
              }
                if(id === 'accordion-file') {
                    this.element.type = 'file'
                }
                if(id === 'accordion-test') {
                    this.element.type = 'list'
                }
                if(id === 'accordion-pdf') {
                    this.element.type = 'file-pdf-o'
                }
                if(id === 'accordion-webinar') {
                    this.element.type = 'play'
                }
                if (id === 'accordion-homework') {
                    this.element.type = 'home';
                    this.element.homework = {};
                    this.element.hwType = 'tutor';
                    this.element.homeworkCriterias = [{title: '', description: '', coefficient: 0}];
                } else {
                  this.element.homework = null;
                }
                if(id === 'accordion-book') {
                    this.element.type = 'book'
                }
            })
            bus.$on('add-test', (test) => {
                this.$bvModal.hide(`add-element-${this.module.id}`);
                this.element.test = '/tests-categories/' + test.id
            });
        },
        methods: {
            onSubmit() {

                if (this.element.type === 'book' && this.booklist.length) {
                    this.element.bookLists = this.booklist;
                    this.element.name = 'Список литературы';
                }

                if (this.element.type === 'home') {
                    this.element.homework = {
                        title: this.element.name,
                        description: this.element.description,
                        type: this.element.hwType,
                        homeworkCriterias: this.element.homeworkCriterias
                    };
                }

                this.axios
                    .post('module_elements', this.element)
                    .then(res => {
                        if(res.status === 201) {
                            if(res.data.type === 'list') {
                                this.element = res.data
                                this.$bvModal.show('add-test');
                            } else {
                                this.$bvModal.hide(`add-element-${this.module.id}`);
                            }
                            bus.$emit('add-module-element', res.data);
                        }
                    })
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.modalSubmitting = true;
            },
            handleSubmitting(status) {
                this.modalSubmitting = status;
            },
            handleSubmitResult() {
                this.modalSubmitting = false;
            },
            prepareTestPreset() {
                return {
                    ...DEFAULT_TEST_DATA,
                    name: this.element.name,
                    moduleElements: [this.element.id]
                }
            },
            addHomeworkCriterias() {
                this.element = {
                    ...this.element,
                    homeworkCriterias: [...this.element.homeworkCriterias, {title: '', description: '', coefficient: 0}]
                };
            }
        }
    }
</script>
<style>
    .el-picker-panel {
        z-index: 10000!important;
    }
</style>
