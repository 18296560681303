<template>
  <div v-if="!$auth.check()">
    <inside-layout></inside-layout>
  </div>
  <div v-else id="wrapper">
    <view-config v-if="$auth.user().company && $auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])"/>
    <nav-bar></nav-bar>
    <div id="page-wrapper" class="gray-bg">
      <dashboard-header></dashboard-header>
      <router-view :key="$route.path"/>
      <footer-vue></footer-vue>
    </div>
    <right-bar></right-bar>
  </div>
</template>

<script>
    import RightBar from "../RightBar";
    import FooterVue from "../FooterVue";
    import NavBar from "../NavBar";
    import DashboardHeader from "../DashboardHeader";
    import ViewConfig from "../ViewConfig";
    import InsideLayout from "./InsideLayout";

    export default {
        name: "DashboardLayout",
        components: {InsideLayout, ViewConfig, DashboardHeader, RightBar, FooterVue, NavBar}
    }
</script>

<style scoped>

</style>
