export default class Subtest {
    constructor() {
        this.id = '';
        this.test = '';
        this.name = '';
        this.hidden = false;
        this.durationMinutes = 10;
        this.addedMinutes = 5;
        this.specifications = [];
    }
}
