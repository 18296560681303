<template>
    <div class="table-responsive">
        <table class="question-table">
            <tbody>
            <tr v-for="(value, key) in task.choice" :key="key">
                <td>
                    <p-check
                            class="p-icon p-bigger p-smooth"
                            style="font-size: 200%"
                            :id="'one-answer_' + id + '_' + key"
                            :ref="'one-answer_' + id + '_' + key"
                            :value="key"
                            @change="onChangeMultiple(givenAnswer)"
                            v-model="givenAnswer.key">
                        <i slot="extra" class="icon fa fa-check"></i>
                    </p-check>
                </td>
                <td>
                    <div v-html="value"
                         @click="toggle(key)"
                         style="cursor: pointer">
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <p style="color: darkred; font-size: 150%; font-weight: bold;" v-if="errorMessage">Внимание! {{ errorMessage }}</p>
    </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";
    export default {
        name: "QuestionMultipleAnswer",
        extends: QuestionBase,
        data() {
            return {
                errorMessage: ''
            }
        },
        methods: {
            onChangeMultiple (val, oldVal) {
                if (!Array.isArray(val.key) || val.key.length === 0 || !this.task.options || !this.task.options.restrictMax || val.key.length <= +this.task.options.restrictMax) {
                    this.errorMessage = ''
                    this.givenAnswer.key.sort((a, b) => (a < b) ? -1 : ((a > b) ? 1 : 0))
                    this.onChange(this.givenAnswer, oldVal)
                } else {
                    this.errorMessage = 'Максимальное количество ответов, которое Вы можете выбрать — ' + this.task.options.restrictMax +
                        '. Для того, чтобы корректно ответить на данный вопрос, необходимо сначала снять отметку у одного из выбранных ранее ответов.'
                }
            },
            toggle (key) {
                if (this.givenAnswer.key.indexOf(key) !== -1) {
                    while (this.givenAnswer.key.indexOf(key) !== -1) {
                        this.givenAnswer.key.splice(this.givenAnswer.key.indexOf(key), 1)
                    }
                } else {
                    this.givenAnswer.key.push(key)
                }
                this.givenAnswer.key = this.givenAnswer.key.sort((a, b) => (a < b) ? -1 : ((a > b) ? 1 : 0))
            }
        }
    }
</script>

<style scoped>

</style>
