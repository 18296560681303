<template>
    <valid-form v-if="category" :submit="onSubmit">
        <valid-input label="Название" name="название" v-model="test.name"></valid-input>
        <valid-input v-model="subtest.durationMinutes" label="Длительность теста в минутах" name="длительность теста в минутах"></valid-input>
    </valid-form>
</template>

<script>
    import ValidForm from "../Validation/ValidForm";
    import ValidInput from "../Validation/ValidInput";
    import Test from "./model/TestData";
    import Subtest from "./model/Subtest";
    export default {
        name: "AddTest",
        components: {ValidInput, ValidForm},
        props: ['category'],
        data() {
            return {
                test: new Test(),
                subtest: new Subtest()
            }
        },
        mounted() {
            this.test.code = this.hashId();
            this.test.category = this.category;
        },
        methods: {
            onSubmit() {
                this.axios.post('sdpk/save/Test/new', this.test).then(res => {
                    this.subtest.test = res.data.id;
                    this.axios.post('sdpk/save/Subtest/new', this.subtest).then(() => {
                        this.$router.push('/admin/constructor/' + this.subtest.test);
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>
