<template>
    <div  class="animate fadeIn">
        <b-modal title="Введите тестовый email" id="send-test" :hide-footer="true">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="submitTest(passes)">
                    <valid-input v-model="testMail" />
                    <hr class="hr-line-dashed"/>
                    <b-btn type="submit" variant="primary">Отправить</b-btn>
                </b-form>
            </validation-observer>
        </b-modal>
        <bread-crumbs title="Отправить рассылку" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="submit(passes)">
                    <ibox>
                        <ibox-title>
                            Запланировать рассылку
                        </ibox-title>
                        <ibox-content>
                            <valid-date label="Выберите дату и время рассылки" v-model="form.startDate"/>
                            <valid-input name="заголовок" label="Заголовок" rules="required" v-model="form.subject" />
                            <valid-summernote name="текст сообщения" label="Текст сообщения" rules="required" v-model="form.body" />

                            <valid-select v-model="form.courses" v-if="courses" label-title="name" track-by="id" :multiple="true" name="курсы" label="Подписчики курсов" :options="courses"></valid-select>
                            <valid-select v-model="form.projects" v-if="projects" label-title="name" track-by="id" :multiple="false" name="поток" label="Участники потока" :options="projects"></valid-select>
                            <valid-select v-model="form.regions" v-if="regions" label-title="name" track-by="id" :multiple="false" name="регион" label="Пользователи из регинов" :options="regions"></valid-select>
                            <valid-input name="список email через запятую" label="Список email через запятую" v-model="form.emails" />
                        </ibox-content>
                        <ibox-footer>
                            <b-btn-group>
                                <b-btn variant="primary" type="submit" class="pull-right"><i class="fa fa-times-circle-o"></i> Запланировать</b-btn>
                                <b-btn :disabled="!(form.subject && form.body)" variant="success" v-b-modal.send-test type="button" class="pull-right"><i class="fa fa-mail-reply"></i> Отправить тест</b-btn>
                            </b-btn-group>
                        </ibox-footer>
                    </ibox>
                </b-form>
            </validation-observer>
        </div>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import BreadCrumbs from "../BreadCrumbs";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxFooter from "../Ibox/IboxFooter";
    import IboxContent from "../Ibox/IboxContent";
    import ValidInput from "../Validation/ValidInput";
    import ValidSelect from "../Validation/ValidSelect";
    import ValidSummernote from "../Validation/ValidSummernote";
    import ValidDate from "../Validation/ValidDate";
    export default {
        name: "MailCreate",
        components: {
            ValidDate,
            ValidInput,
            ValidSummernote,
            ValidSelect,
            IboxFooter,
            IboxContent,
            IboxTitle,
            Ibox,
            BreadCrumbs
        },
        data() {
            return {
                form: {},
                courses: null,
                projects: null,
                regions: null,
                cities: null,
                testMail: null
            }
        },
        mounted() {
            this.axios.get(`courses`).then(res => {
                this.courses = res.data;
            })
            this.axios.get(`projects`).then(res => {
                this.projects = res.data;
            })
            this.axios.get(`regions`).then(res => {
                this.regions = res.data;
            })

        },
        methods: {
            submit() {
                let form = Object.assign({}, this.form);
                form.emails = form.emails.split(',');
                this.axios.post('maillists', form).then(() => {
                    this.$router.push('/admin/mails').catch(() => {});
                })
            },
            submitTest() {
                this.axios.post('maillists-test', {
                    subject: this.form.subject,
                    body: this.form.body,
                    email: this.testMail
                }).then(() => {
                    this.$bvModal.hide('send-test');
                    this.$toasted.global.appSuccess('Тест успешно отправлен!');
                })
            }
        }
    }
</script>

<style scoped>

</style>
