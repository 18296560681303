<template>
  <div v-if="!loading" class="w-100 animated fadeInRight">
    <div class="wrapper wrapper-content">
      <div class="ibox chat-view">
        <ibox-title :hide-tools="true">
          <div v-if="!hideTitle" class="h2">
            Сообщения
            <b-button type="button" size="sm" variant="link" @click="$emit('close')">
              <i class="fa fa-reply"></i>
              К списку сообщений
            </b-button>
          </div>
        </ibox-title>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12 ">
              <div ref="containerMessages" class="chat-discussion">
                <div v-for="(msg) in messages"
                     v-bind:key="msg.id"
                     v-bind:class="{ right: isFromMe(msg), left: !isFromMe(msg) }"
                     class="chat-message">
                  <img class="message-avatar" :src="prepareAvatar(msg)" alt="" >
                  <div class="message">
                    <span class="message-author font-bold"> {{ msg.from.fullname }} </span>
                    <span class="message-date"> {{ msg.createdAt|formatDate}} </span>
                    <span class="message-content">{{ msg.content }}</span>
                  </div>
                </div>

                <div v-if="messages.length === 0" class="text-center">Сообщений нет.</div>
              </div>
            </div>
          </div>
          <form class="row" ref="form" @submit.stop.prevent="handleSubmit()" v-if="channelInfo.type !== 'system'">
            <div class="col-lg-12">
              <div class="chat-message-form">
                <div class="form-group">
                <textarea v-model="form.body"
                          class="form-control message-input"
                          name="message"
                          placeholder="Введите текст сообщения"
                ></textarea>
                </div>

                <div v-if="form.body.length > 0" class="mt-1 mb-5">
                  <b-button type="submit" size="sm" variant="primary" :disabled="submitting">
                    <i class="fa fa-reply"></i>
                    Отправить
                  </b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import ValidTextarea from "../Validation/ValidTextarea";
  import IboxTitle from "../Ibox/IboxTitle";
  export default {
    name: "ChannelView",
    components: {IboxTitle},
    props: {
      initialText: String,
      channel: String,
      hideTitle: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loading: true,
        channelRel: true,
        submitting: false,
        form: {
          channel: null,
          body: ''
        },
        channelInfo: [],
        messages: [],
      }
    },
    destroyed() {
      this.destroySubscription();
    },
    mounted() {
      const channel = this.channel;

      if (this.initialText) {
        this.form.body = this.initialText;
      }
      this.loadMessages(channel)

    },
    updated() {
      this.$nextTick(() => {
        // @todo flow for preload prev messages?
        const content = this.$refs.containerMessages;
        content.scrollTop = content.scrollHeight
      });
    },
    methods: {
      loadMessages(channel) {
        this.axios.get(`chats/${channel}`, {params: {hideLoader: true}})
                .then(response => {
                  const channelData = response.data;

                  this.form.channel = channelData.channel.key;

                  this.channelInfo = channelData.channel;
                  this.messages = channelData.messages;

                  this.initSubscription();
                  this.loading = false;

                  // stupid hotfix for mark messages as read
                  this.markChannelAsRead();
                })
                .catch(() => {
                  // this.$router.push('/admin/messages');
                });
      },
      handleSubmit() {
        if (this.submitting) {
          return;
        }

        this.submitting = true;
        this.axios.post('chats', {...this.form, hideLoader: true})
                .then(() => {
                  this.form.body = '';
                })
                .catch(() => {
                })
                .finally(() => this.submitting = false)
      },
      isFromMe(message) {
        const currentUserId = this.$auth.user().id

        return currentUserId === message.from.id;
      },

      prepareAvatar(message) {
        if (!message.from.photo) {
          // default stub
          return '/logo-rounded.png';
        }

        return message.from.photo.thumbs.smaller;
      },

      initSubscription() {
        const channel = this.$pusherService.subscribe(this.pusherChannelName());

        channel.bind('message', message => this.addAsyncMessage(message));

        this.channelRel = channel;
      },
      destroySubscription() {
        this.channelRel.unbind('message');
        // system channel breakpoint
        if (this.channelInfo.type === 'system') {
          return;
        }

        this.$pusherService.unsubscribe(this.pusherChannelName());
      },
      pusherChannelName() {
        return `private-${this.channelInfo.key}`;
      },
      addAsyncMessage(message) {
        this.messages.push(message);
        this.markChannelAsRead();
      },
      markChannelAsRead() {
        this.axios.post('chats/mark-as-read', {key: this.channelInfo.key, hideLoader: true});
      }
    },
    watch: {
      channel(val) {
        this.loadMessages(val)
      }
    }
  }
</script>

<style lang="scss">
  .mail-box {
    background-color: #ffffff;
    border: 1px solid #e7eaec;
    border-top: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  .mail-box-header {
    background-color: #ffffff;
    border: 1px solid #e7eaec;
    border-bottom: 0;
    padding: 30px 20px 20px 20px;
  }
  .mail-box-header h2 {
    margin-top: 0;
  }
  .mailbox-content .tag-list li a {
    background: #ffffff;
  }
  .mail-body {
    border-top: 1px solid #e7eaec;
    padding: 20px;
  }
  .mail-text {
    border-top: 1px solid #e7eaec;
  }
  .mail-text .note-toolbar {
    padding: 10px 15px;
  }
  .mail-body .form-group {
    margin-bottom: 5px;
  }
  .mail-text .note-editor .note-toolbar {
    background-color: #F9F8F8;
  }
  .mail-attachment {
    border-top: 1px solid #e7eaec;
    padding: 20px;
    font-size: 12px;
  }
  .mailbox-content {
    background: none;
    border: none;
    padding: 10px;
  }
</style>
