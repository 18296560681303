<template>
    <div class="animated fadeIn" v-if="courseList">
        <bread-crumbs title="Статистика" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    <course-filter v-model="filters" />
                </ibox-title>
                <ibox-content>
                    <b-row>
                        <b-col md="3" v-for="(course, key) in courseList" :key="key">
                            <router-link :to="`/admin/stats/${course.id}`" class="no-decorate">
                                <div class="ibox">
                                    <div class="ibox-title">
                                        <div class="ibox-tools">
                                            <span class="label float-right" :class="course.status">{{course.statusRu}}</span>
                                        </div>
                                        <h5>{{ course.name | truncate(40, '...') }}</h5>
                                    </div>
                                    <div class="ibox-content">
                                        <h1 class="no-margins">{{ course.enrollCount }}</h1>
                                        <div class="stat-percent font-bold text-navy">{{ course.progress }}% <i class="fa fa-bolt"></i></div>
                                        <small>{{ course.enrollCount|pluralize('ru', ['Учащийся', 'Учащихся', 'Учащихся']) }}</small>
                                    </div>
                                </div>
                            </router-link>
                        </b-col>
                    </b-row>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import  BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";
    import CourseFilter from "./CourseFilter";
    import CourseList from "./CourseList";
    export default {
        name: "CourseStat",
        extends: CourseList,
        components: {CourseFilter, IboxTitle, IboxContent, Ibox, BreadCrumbs}
    }
</script>

<style scoped lang="scss">
    .label {
        color: #fff;
        &.finished {
            background-color: #007bff;
        }
        &.started {
            background-color: #dc3545;
        }
        &.open {
            background-color: #17a2b8;
        }
        &.soon {
            background-color: #E6A23C;
        }
        &.finished {
            background-color: #67C23A;
        }
        &.closed {
            background-color: #909399;
        }
    }
    .no-decorate {
        color: inherit;
    }
</style>
