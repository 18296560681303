<template>
  <div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
      <div class="navbar-header">
        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i class="fa fa-bars"></i> </a>
        <form role="search" method="get" class="navbar-form-custom" action="/catalog">
          <div class="form-group">
            <input type="text" placeholder="Поиск..." class="form-control" name="q" id="top-search">
          </div>
        </form>
      </div>
      <ul v-if="$auth.user().company" class="nav navbar-top-links navbar-right">
        <li :style="!showLogo ? 'padding: 20px' : 'padding-top: 15px;margin-bottom: -10px;'">
          <span v-if="!showLogo" class="m-r-sm text-muted welcome-message">
            Добро пожаловать в МИИГАиК!
          </span>
          <span v-else class="m-r-sm text-muted welcome-message">
            <div class="user-friends">
              <img alt="image" class="rounded-circle" v-b-tooltip :title="$auth.user().company.name" :src="$auth.user().company.logo.thumbs.small">
              {{$auth.user().company.name}}
            </div>
          </span>
        </li>
        <li v-if="!notice.initialLoading">
              <b-dropdown :no-caret="true" menu-class="dropdown-messages" variant="link" toggle-tag="a" toggle-class="count-info">
                  <template v-slot:button-content>
                      <i class="fa fa-envelope"></i>
                      <span class="label label-warning" v-if="notice.unreadMessagesCount > 0">{{ notice.unreadMessagesCount }}</span>
                  </template>

                  <template v-for="msg in notice.unreadMessagesLast">
                      <b-dropdown-item :key="`${msg.id}-item`">
                          <div class="dropdown-messages-box">
                              <router-link :to="`/admin/messages?sel=u${msg.from.id}`" class="dropdown-item float-left">
                                  <img alt="image" class="rounded-circle" :src="prepareAvatar(msg)">
                              </router-link>
                              <div class="media-body">
                                  <!--                      <small class="float-right">46h ago</small>-->
                                  {{ msg.content | truncate(40, '...') }}
                                  <br>
                                  <small class="text-muted">{{ msg.createdAt|formatDate }}</small>
                              </div>
                          </div>
                      </b-dropdown-item>
                      <b-dropdown-divider :key="`${msg.id}-div`"></b-dropdown-divider>
                  </template>

                  <b-dropdown-item>
                      <div class="text-center link-block">
                          <router-link :to="`/admin/messages`" class="dropdown-item">
                              <i class="fa fa-envelope"></i> <strong>К списку сообщений</strong>
                          </router-link>
                      </div>
                  </b-dropdown-item>
              </b-dropdown>
          </li>
        <li v-if="!notice.initialLoading">
              <b-dropdown :no-caret="true" menu-class="dropdown-alerts" variant="link" toggle-tag="a" toggle-class="count-info">
                  <template v-slot:button-content>
                      <i class="fa fa-bell"></i>
                      <span v-if="notice.unreadNotificationCount > 0" class="label label-primary">{{ notice.unreadNotificationCount }}</span>
                  </template>

                  <template v-for="msg in notice.unreadNotificationsLast">
                      <b-dropdown-item :key="`${msg.id}-item`">
                          <div class="dropdown-item">
                              <router-link :to="`/admin/messages?sel=system`">
                                  <i class="fa fa-envelope fa-fw"></i>
                                  {{ msg.content | truncate(40, '...') }}
                                  <span class="float-right text-muted small">{{ msg.createdAt|formatDate }}</span>
                              </router-link>
                          </div>
                      </b-dropdown-item>
                      <b-dropdown-divider :key="`${msg.id}-div`"></b-dropdown-divider>
                  </template>

                  <b-dropdown-item>
                      <div class="text-center link-block">
                          <router-link :to="`/admin/messages?sel=system`" class="dropdown-item">
                              <strong>Еще</strong>
                              <i class="fa fa-angle-right"></i>
                          </router-link>
                      </div>
                  </b-dropdown-item>
              </b-dropdown>
          </li>
        <li v-if="isMask">
          <a @click="unmask" class="text-warning">
            <i class="fa fa-eye-slash"></i> Снять маску
          </a>
        </li>
        <li>
          <a @click="logout">
            <i class="fa fa-sign-out"></i> Выход
          </a>
        </li>
      </ul>
      <ul v-else class="nav navbar-top-links navbar-right">
        <li style="padding: 20px;">
          <span class="m-r-sm text-muted welcome-message">
            Добро пожаловать в МИИГАиК!
          </span>
        </li>
        <template v-if="!notice.initialLoading">
          <li>
              <b-dropdown :no-caret="true" menu-class="dropdown-messages" variant="link" toggle-tag="a" toggle-class="count-info">
                  <template v-slot:button-content>
                      <i class="fa fa-envelope"></i>
                      <span class="label label-warning" v-if="notice.unreadMessagesCount > 0">{{ notice.unreadMessagesCount }}</span>
                  </template>

                  <template v-for="msg in notice.unreadMessagesLast">
                      <b-dropdown-item :key="`${msg.id}-item`">
                          <div class="dropdown-messages-box">
                              <router-link :to="`/admin/messages?sel=u${msg.from.id}`" class="dropdown-item float-left">
                                  <img alt="image" class="rounded-circle" :src="prepareAvatar(msg)">
                              </router-link>
                              <div class="media-body">
                                  <!--                      <small class="float-right">46h ago</small>-->
                                  {{ msg.content | truncate(40, '...') }}
                                  <br>
                                  <small class="text-muted">{{ msg.createdAt|formatDate }}</small>
                              </div>
                          </div>
                      </b-dropdown-item>
                      <b-dropdown-divider :key="`${msg.id}-div`"></b-dropdown-divider>
                  </template>

                  <b-dropdown-item>
                      <div class="text-center link-block">
                          <router-link :to="`/admin/messages`" class="dropdown-item">
                              <i class="fa fa-envelope"></i> <strong>К списку сообщений</strong>
                          </router-link>
                      </div>
                  </b-dropdown-item>
              </b-dropdown>
          </li>
          <li>
              <b-dropdown :no-caret="true" menu-class="dropdown-alerts" variant="link" toggle-tag="a" toggle-class="count-info">
                  <template v-slot:button-content>
                      <i class="fa fa-bell"></i>
                      <span v-if="notice.unreadNotificationCount > 0" class="label label-primary">{{ notice.unreadNotificationCount }}</span>
                  </template>

                  <template v-for="msg in notice.unreadNotificationsLast">
                      <b-dropdown-item :key="`${msg.id}-item`">
                          <div class="dropdown-item">
                              <router-link :to="`/admin/messages?sel=system`">
                                  <i class="fa fa-envelope fa-fw"></i>
                                  {{ msg.content | truncate(40, '...') }}
                                  <span class="float-right text-muted small">{{ msg.createdAt|formatDate }}</span>
                              </router-link>
                          </div>
                      </b-dropdown-item>
                      <b-dropdown-divider :key="`${msg.id}-div`"></b-dropdown-divider>
                  </template>

                  <b-dropdown-item>
                      <div class="text-center link-block">
                          <router-link :to="`/admin/messages?sel=system`" class="dropdown-item">
                              <strong>Еще</strong>
                              <i class="fa fa-angle-right"></i>
                          </router-link>
                      </div>
                  </b-dropdown-item>
              </b-dropdown>
          </li>
      </template>
        <li v-if="isMask">
          <a @click="unmask">
            <i class="fa fa-eye-slash"></i> Снять маску
          </a>
        </li>
        <li>
            <a v-if="this.$auth.user()" @click="logout">
            <i class="fa fa-sign-out"></i> Выход
          </a>
          <router-link v-else to="/admin/login">
              <i class="fa fa-sign-out"></i> Вход
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "DashboardHeader",
    data() {
      return {
        isMask: false,
        notice: {
          channelName: null,
          initialLoading: false,
          unreadMessagesCount: 0,
          unreadMessagesLast: [],
          unreadNotificationCount: 0,
          unreadNotificationsLast: [],
        }
      }
    },
    mounted() {
      this.isMask = localStorage.getItem('admin_refresh_token')
      const hasUser = !!this.$auth.user();
      if (hasUser) {
        this.initNotifications();
      }
    },
    computed: {
      showLogo() {
        return this.$auth.user() && this.$auth.user().company && this.$auth.user().company.viewSettings && this.$auth.user().company.viewSettings.showLogo;
      }
    },
    destroyed() {
      this.destroyNotifications();
    },
    methods: {
      unmask() {
        if(this.isMask) {
          let instance = axios.create({
            baseURL: process.env.VUE_APP_API_URL
          });
          instance.post('token/refresh', {
            refresh_token: this.isMask
          }).then(res => {
            let response = res.data;
            localStorage.removeItem('admin_refresh_token');
            localStorage.setItem('refresh_token', response.refresh_token);
            localStorage.setItem('default_auth_token', response.token);
            window.location.href = '/admin/profile';
          })
        }
      },
      logout() {
        this.$auth.logout({
          makeRequest: false,
          success: function () {
          },
          error: function () {
          },
          redirect: '/admin/login',
        });
      },
        loadNotificationStatus() {
            this.axios.post(`chats/status`, {hideLoader: true})
                .then(res => {
                    let {data} = res;

                    this.notice = {
                        ...this.notice,
                        initialLoading: false,
                        ...data
                    };
                });
        },
      initNotifications() {
        this.notice.channelName = `private-sys${this.$auth.user().id}`;

        this.notice.initialLoading = true; // initial loading
        this.loadNotificationStatus();
        this.initSubscription();
      },
      destroyNotifications() {
        this.destroySubscription();
      },
      initSubscription() {
        const channel = this.$pusherService.subscribe(this.notice.channelName);

        channel.bind('update', () => this.loadNotificationStatus());
      },
      destroySubscription() {
        this.$pusherService.unsubscribe(this.notice.channelName);
      },
      prepareAvatar(message) {
        if (!message.from.photo || !message.from.photo.thumbs) {
          // default stub
          return '/logo-rounded.png';
        }

        return message.from.photo.thumbs.smaller;
      },
    }
  }
</script>

<style>
</style>
