<template>
    <div v-if="document" class="animated fadeIn">
        <bread-crumbs :title="`Редактировать текст ${this.document.title}`" :items="[{title: 'Главная', url: '/'}]"/>
        <div class="wrapper-content wrapper">
            <ibox>
                <ibox-content>
                    <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.stop.prevent="passes(onSubmit)">
                            <valid-input v-model="document.title" label="Название" rules="required"></valid-input>
                            <valid-summernote v-model="document.text" label="Текст" rules="required"></valid-summernote>
                            <b-form-group>
                                <b-btn type="submit" variant="primary">Отправить</b-btn>
                            </b-form-group>
                        </b-form>
                    </validation-observer>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import ValidInput from "../Validation/ValidInput";
    import ValidSummernote from "../Validation/ValidSummernote";
    import IboxContent from "../Ibox/IboxContent";
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    export default {
        name: "DocumentForm",
        components: {Ibox, BreadCrumbs, IboxContent, ValidSummernote, ValidInput},
        data() {
            return {
                document: null
            }
        },
        mounted() {
             this.axios.get(`documents/${this.$route.params.id}`).then(res => {
                 this.document = res.data;
            });
        },
        methods: {
            onSubmit() {
                this.axios.put(`documents/${this.$route.params.id} `, this.document).then(res => {
                    this.document = res.data;
                    this.$toasted.global.appSuccess();
                });
            }
        }
    }
</script>

<style scoped>

</style>
