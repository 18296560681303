<template>
    <validation-provider v-if="options" :name="name" :rules="rules" v-slot="validationContext">
        <b-form-group :label="label">
            <treeselect
                    v-model="selected"
                    :multiple="isMultiple"
                    :options="options"
                    :placeholder="placeholder"
                    :class="validationContext.errors.length ? 'in-valid' : ''"
                    openDirection="bottom"
                    :auto-load-root-options="false"
                    :searchable="false"
            >
            </treeselect>
            <b-form-invalid-feedback :class="validationContext.errors.length ? 'd-block' : ''">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        name: "ValidTreeSelect",
        components: { Treeselect },
        props: ['rules', 'name', 'value', 'label', 'vid', 'options', 'multiple', 'labelTitle', 'placeholder'],
        data() {
            return {
                selected: null,
                isMultiple: typeof this.multiple !== 'undefined' ? this.multiple : true
            }
        },
        mounted() {
            this.selected = this.value
        },
        watch: {
            selected() {
                this.$emit('input', this.selected)
            }
        }
    }
</script>

<style lang="scss">
    .vue-treeselect__control {
        border-radius: 0;
    }
</style>
