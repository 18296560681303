<template>
    <ibox v-if="element" style="padding: 20px;">
        <ibox-content v-if="element.type === 'film'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                    <valid-summernote v-model="element.description" label="Описание" name="Описание"/>
                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                    <valid-select v-if="questions" v-model="element.questions" :multiple="true" :options="questions" label="Выберите вопрос в конце лекции" label-title="name"></valid-select>
                    <label>Выберите уровень сложности</label>
                    <select class="form-control" v-model="element.level">
                        <option selected value="medium">Средний</option>
                        <option value="simple">Простой</option>
                        <option value="hard">Сложный</option>
                    </select>
                    <FileUpload accept="video/mp4" :action="null" v-model="element.file" style="width: 100%">
                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                            Выберите файл mp4 <i class="fa fa-cloud-upload"></i>
                        </b-btn>
                        <div v-else>
                            <label>Прогресс транскодинга</label>
                            <EncoderProcess :media="element.file"/>
                        </div>
                    </FileUpload>
                    <hr class="hr-line-dashed"/>
                    <b-btn variant="success" type="submit">Сохранить</b-btn>
                </b-form>
            </validation-observer>
        </ibox-content>
        <ibox-content v-if="element.type === 'book'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                    <valid-summernote v-model="element.description" label="Описание" name="Описание"/>
                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|numeric|min_value:1"/>
                    <template  v-if="element.type === 'book'">
                        <span v-for="(book, index) in element.bookLists" :key="index">
                            <b-row>
                                <b-col>
                                    <ValidInput v-model="book.title" placeholder="Название"/>
                                </b-col>
                                <b-col>
                                    <ValidInput v-model="book.link" placeholder="Ссылка"/>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <ValidInput v-model="book.author" placeholder="Авторы"/>
                                </b-col>
                                <b-col>
                                    <valid-select placeholder="Тип" v-model="book.type" :multiple="false" :options="['Статья', 'Пособие', 'Учебник', 'Художественная литература']" />
                                </b-col>
                            </b-row>
                            <b-col md="1">
                                <b-btn v-if="index > 0" size="sm" variant="danger" @click="element.bookLists.splice(index, 1)"><i class="fa fa-close"></i></b-btn>
                            </b-col>
                        </span>
                    </template>
                    <hr class="hr-line-dashed"/>
                    <b-btn variant="success" type="submit">Сохранить</b-btn>
                </b-form>
            </validation-observer>
        </ibox-content>
        <ibox-content v-if="element.type === 'music'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                    <valid-summernote v-model="element.description" label="Описание" name="Описание"/>
                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|numeric|min_value:1"/>
                    <FileUpload accept="audio/mp3" v-model="element.file">
                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                            Выберите файл mp3 <i class="fa fa-cloud-upload"></i>
                        </b-btn>
                        <div v-else>
                            Файл загружен <i class="fa fa-download"></i>
                        </div>
                    </FileUpload>
                    <hr class="hr-line-dashed"/>
                    <b-button type="submit" variant="success">Сохранить</b-button>
                </b-form>
            </validation-observer>
        </ibox-content>
        <ibox-content v-if="element.type === 'file-pdf-o'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                    <valid-summernote v-model="element.description" label="Описание" name="Описание"/>
                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|numeric|min_value:1"/>
                    <FileUpload accept=".pdf,.xls,.xlsx" :action-for-upload="null" v-model="element.file">
                        <b-btn v-if="!element.file" size="lg" class="btn-block" variant="primary">
                            Выберите файл pdf или excel <i class="fa fa-cloud-upload"></i>
                        </b-btn>
                        <div v-else>
                            Файл загружен <i class="fa fa-download"></i>
                        </div>
                    </FileUpload>
                    <hr class="hr-line-dashed"/>
                    <b-button type="submit" variant="success">Сохранить</b-button>
                    <b-button type="button" variant="info">Отмена</b-button>
                </b-form>
            </validation-observer>
        </ibox-content>
        <ibox-content v-if="element.type === 'home'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.homework.title" label="Название" name="название" rules="required|min:3"/>
                    <ValidInput v-model.number="element.duration" type="number" label="Время для изучения и подготовки (часы)" name="длительность" rules="required|decimal|min_value:0.1"/>
                    <valid-summernote v-model="element.homework.description" label="Описание" name="описание" rules="required|min:3"/>
                    <valid-select v-model="element.homework.type"
                                  :value="element.homework.type"
                                  label="Тип проверки"
                                  rules="required"
                                  placeholder="Тип"
                                  :multiple="false"
                                  :custom-label="homeworkTypeLabel"
                                  :options="homeworkOptions" />
                    <valid-select
                        v-model="element.checkModule"
                        :value="element.checkModule"
                        label="Модуль проверки"
                        rules="required"
                        placeholder="Выберите модуль проверки"
                        :multiple="false"
                        :custom-label="(opt) => opt.name"
                        :options="course.modules.filter(mod => mod.position > module.position)"
                    />
                    <label>Файл с правильным ответом</label>
                    <FileUpload accept=".pdf,.xls,.xlsx" :action-for-upload="null" v-model="element.homework.file">
                      <b-btn size="lg" class="btn-block" variant="primary">
                        Выберите файл pdf или excel <i class="fa fa-cloud-upload"></i>
                      </b-btn>
                    </FileUpload>
                    <div v-if="element.homework.file" class="form-group">
                      <a target="_blank" :href="element.homework.file.contentPath">Скачать <i class="fa fa-download"></i></a>
                    </div>
                    <hr class="hr-line-dashed"/>
                    <h4>Критерии оценки</h4>
                    <b-row v-for="(criteria, index) in element.homework.homeworkCriterias" :key="index">
                        <b-col md="8"><ValidInput v-model="criteria.title" placeholder="Критерий"/></b-col>
                        <b-col md="3"><ValidInput v-model.number="criteria.coefficient" type="number" placeholder="Оценка"/></b-col>
                        <b-col md="1">
                            <b-btn v-if="index > 0" size="sm" variant="danger" @click="element.homework.homeworkCriterias.splice(index, 1)"><i class="fa fa-close"></i></b-btn>
                        </b-col>
                    </b-row>
                    <div class="clearfix">
                        <b-btn size="sm" variant="primary" @click="addHomeworkCriterias()" class="pull-right">Добавить</b-btn>
                    </div>
                    <hr class="hr-line-dashed"/>
                    <b-button type="submit" variant="success">Сохранить</b-button>
                </b-form>
            </validation-observer>
        </ibox-content>
        <ibox-content v-if="element.type === 'play'" no-body class="mb-1">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onSubmit)">
                    <ValidInput v-model="element.name" label="Название" name="название" rules="required|min:3"/>
                    <b-form-group label="Дата начала">
                        <ElDatePicker style="z-index: 10000;"
                                      type="datetime"
                                      placeholder="Выберите дату и время проведения вебинара" v-model="element.startedAt"/>
                    </b-form-group>
                    <hr class="hr-line-dashed"/>
                    <b-button type="submit" variant="success">Сохранить</b-button>
                </b-form>
            </validation-observer>
        </ibox-content>
    </ibox>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import ValidInput from "../Validation/ValidInput";
    import FileUpload from "../FileUpload";
    import EncoderProcess from "../EncoderProcess";
    import ValidSelect from "../Validation/ValidSelect";
    import ValidSummernote from "../Validation/ValidSummernote";
    export default {
        name: "EditElement",
        components: {ValidSummernote, ValidSelect, EncoderProcess, IboxContent, Ibox, FileUpload, ValidInput},
        props: ['element', 'module', 'course'],
        data() {
            return {
                submitting: false,
                questions: [],
                homeworkOptions: [
                    'tutor',
                    'crosspoll',
                ],
                homeworkTypeLabel(type) {
                    const map = {tutor: 'Учитель', crosspoll: 'Учитель+Ученик'};

                    return map[type] || null;
                }
            }
        },
        mounted() {
            this.axios.get(this.element.module).then(res => {
                let elements = res.data.moduleElements;
                elements.forEach(element => {
                    if(element.type === 'list') {
                        this.axios.get(element.test).then(res => {
                            let questions = res.data.questions;
                            if(questions && questions.length) {
                                questions.forEach(question => {
                                    this.questions.push(question)
                                })
                            }
                        })
                    }
                })
            })
        },
        methods: {
            onSubmit() {
                // facepalm
                if (this.element.type === 'home') {
                    this.element.description = this.element.homework.description;
                    this.element.name = this.element.homework.title;
                }

                this.axios.put(`module_elements/${this.element.id}`, this.element).then(() => {
                    this.$toasted.global.appSuccess()
                })
            },
            handleSubmitting(status) {
                this.submitting = status;
            },
            handleSubmitResult() {
                this.submitting = false;
            },
            addHomeworkCriterias() {
                /*this.element.homework = {
                    ...this.element.homework,
                    homeworkCriterias: [
                        ...this.element.homework.homeworkCriterias,
                        {title: '', description: '', coefficient: 0}
                    ]
                };*/
            }
        }
    }
</script>

<style scoped>
</style>
