<template>
    <div class="animated fadeIn">
        <b-modal id="downloadModal" title="Выберите поля для экспорта" :hide-footer="true">
            <b-row>
                <template v-for="(field, key) in codifiers">
                    <b-col :key="key" md="6">
                        <valid-checkbox :name="field.title" :vid="`${key}`" v-model="possibleFields[field.id]" />
                    </b-col>
                </template>
            </b-row>
            <hr class="hr-line-dashed" />
            <b-btn
                    variant="primary"
                    @click="onDownloadCsv()"
            >
                <i class="fa fa-file-excel-o"></i> Скачать
            </b-btn>
        </b-modal>
        <bread-crumbs title="Статистика по компетенциям" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <stat-filter-codifier v-if="users && codifiers" :users="rows" :codifiers="allCodifiers" @apply="onApply($event)"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-content v-if="filters.showTable">
                    <div v-if="codifiers" class="table-responsive">
                        <table class="table table-responsive table-striped table-bordered table-condensed">
                            <thead>
                            <tr>
                                <th>ФИО/Кодификатор</th>
                                <th v-for="(codifier, key) in codifiers" :key="key">
                                    {{ codifier.title }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, uKey) in rows" :key="uKey">
                                    <td>
                                        {{ row.userFullname }}
                                    </td>
                                    <td style="vertical-align: middle; text-align: center;" v-for="(codifier, key) in codifiers" :key="key">
                                        <div class="colors">
                                            <span :class="getClass(row.codifiers[codifier.id])">{{ row.codifiers[codifier.id] || 0 }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <b-pagination v-if="pagination"
                                      @change="onPaginate($event)"
                                      :value="pagination.currentPage"
                                      :total-rows="pagination.total"
                                      :per-page="pagination.limit"
                                      aria-controls="stats-table"
                                      prev-text="Назад"
                                      next-text="Вперед"
                                      class="mt-2"
                        ></b-pagination>
                    </div>
                </ibox-content>
                <ibox-content v-if="!filters.showTable">
                    <bar-chart v-for="(cody, key) in codifiers" :key="key" :chart-id="'codifiers' + cody.id" :chart-data="getDataSet(cody)" />
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import ValidCheckbox from "../Validation/ValidCheckbox";
    import bus from "../../bus";
    import BarChart from "../Charts/BarChart";
    import moment from "moment";
    import StatFilterCodifier from "../Stat/StatFilterCodifier";
    export default {
        name: "CodifierStat",
        components: {StatFilterCodifier, BarChart, ValidCheckbox, IboxContent, Ibox, BreadCrumbs},
        data() {
            return {
                possibleFields: {},
                filters: {
                    users: [],
                    codifiers: [],
                    institutions: [],
                    showTable: true
                },
                dataSet: {
                    labels: [],
                    datasets: []
                },
                allCodifiers: [],
                codifiers: [],
                institutions: [],
                users: [],
                points: {},
                rows: [],
                pagination: null,
            }
        },
        methods: {
            onPaginate(page) {
                if (!page) {
                    return;
                }

                this.onApply({
                    ...this.filters,
                    limit: this.pagination.limit,
                    offset: (page * this.pagination.limit) - this.pagination.limit
                })
            },
            onDownloadCsv() {
                const fields = [];
                const allFields = this.possibleFields;
                for (let key of Object.keys(allFields)) {
                    if (allFields[key]) {
                        fields.push(key);
                    }
                }

                this.onApply({
                    ...this.filters,
                    exportFields: fields
                });
            },
            onApply(filters) {
                let query = {
                    type: 'user-codifier'
                };

                if (filters.region && filters.region.id) {
                    query.regions = [filters.region.id];
                }

                if (filters.city && filters.city.id) {
                    query.cities = [filters.city.id];
                }

                if (filters.institution && filters.institution.id) {
                    query.company = filters.institution.id;
                }

                if (filters.courses && filters.courses.length > 0) {
                    query.courses = filters.courses.map(c => c.id);
                }

                if (filters.users && filters.users.length > 0) {
                    query.users = filters.users.map(u => u.userId);
                }

                if (filters.codifiers && filters.codifiers.length > 0) {
                    query.codifiers = filters.codifiers.map(c => c.id);
                }

                if (filters.tests && filters.tests.length > 0) {
                    query.tests = filters.tests.map(t => t.id);
                }

                if (filters.age && filters.age.length > 0) {
                    query.ages = filters.age.map(t => t.value);
                }

                if (filters.date && filters.date.length === 2) {
                    query.date = {
                        from: moment(filters.date[0]).format('DD.MM.YYYY HH:mm'),
                        to: moment(filters.date[1]).format('DD.MM.YYYY HH:mm'),
                    };
                }

                if (filters.limit) {
                    query.limit = filters.limit;
                }

                if (filters.offset) {
                    query.offset = filters.offset;
                }

                // prepare visual
                if (Array.isArray(query.codifiers) && query.codifiers.length > 0) {
                    this.codifiers = this.allCodifiers.filter(c => query.codifiers.indexOf(c.id) !== -1);
                } else {
                    this.codifiers = [...this.allCodifiers];
                }

                if (filters.exportFields) {
                    query.exportFields = filters.exportFields;
                }

                this.loadData(query);
            },
            loadData(query) {
                if (!query.exportFields) {
                    this.rows = [];
                    this.points = {};
                }

                this.axios.post('stats/search', query).then(res => {
                    let {data} = res;

                    if (data.data.downloadUrl) {
                        this.$bvModal.hide('downloadModal');
                        window.open(data.data.downloadUrl, '_blank');

                        return;
                    }

                    const rows = data.data;
                    const pagination = data.pagination;

                    this.rows = rows;
                    this.pagination = pagination;

                    const codifiersTitleMap = {};
                    this.codifiers.forEach(c => codifiersTitleMap[c.id] = c.title);
                });
            },
            getDataSet(cody) {
                if(!cody) {
                    return null;
                }

                let points = [];
                let labels = [];

                this.rows.forEach(row => {
                    points.push(this.points[row.userId][cody.id]);
                    labels.push(row.userFullname);
                })

                return {
                    labels: labels,
                    datasets: [
                        {
                            label: cody.title,
                            backgroundColor: '#1ab394',
                            data: points
                        }
                    ]
                }
            },
            prepareCodifiers(opt) {
                const codifier = this.codifiers.find(x => x.id == opt);
                return codifier ? codifier.title : undefined;
            },
            prepareInstitutes(opt) {
                const institute = this.institutions.find(x => x.id == opt);
                return institute ? institute.name : undefined;
            },
            prepareUsers(opt) {
                const user = this.users.find(x => x.id == opt);
                return user ? user.fullname : undefined;
            },
            getClass(point) {
                if(point > 80) {
                    return 'badge badge-primary';
                }
                if(point > 50) {
                    return 'badge badge-warning';
                }
                return 'badge badge-danger';
            }
        },
        mounted() {
            bus.$on('filter:change', (filters) => {
                this.filters = filters;
            });

            this.onApply({});

            if(this.$auth.user().company) {
                this.filters.institutions.push(this.$auth.user().company);
                this.institutions = [this.$auth.user().company];
            } else {
                this.axios.get(`institutions`).then(res => {
                    this.institutions = res.data;
                })
            }
            this.axios.get('codifiers').then(res => {
                this.allCodifiers = res.data;
                this.codifiers = res.data;
            });
            this.axios.get('institutions', {params: {'isPublic': true}}).then(res => {
                this.institutions = res.data;
            })
        }
    }
</script>

<style scoped>

</style>
