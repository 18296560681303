<template>
  <div>Loading..</div>
</template>

<script>
    import {SocialNetwork} from "../global";
    import Cookies from 'js-cookie'

    export default {
      name: "Login",
      data() {
        return {
          email: null,
          password: null,
          submit: false,
        }
      },
      mounted() {
        const network = this.$route.params.network ? this.$route.params.network : 'google';
        this.connect(network);
      },
      methods: {
        connect(network) {
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');

          const allowedSocials = Object.values(SocialNetwork);

          if (!code || !allowedSocials.includes(network)) {
            this.$router.push('/admin/').catch(() => {});
            return;
          }

          this.axios
                  .post(`connect/${network}`, {code})
                  .then(res => {
                    // @todo temp
                    if (res.error) {
                      this.$router.push('/admin/login').catch(() => {});
                      return;
                    }

                    this.$auth.token('default_auth_token', res.token);
                    this.$auth.token('refresh_token', res.refresh_token);

                    // nice vue-auth guard
                    Cookies.set('rememberMe', true, { expires: 30 });
                    window.location = `${location.protocol}//${location.host}/profile`;
                  })
        }
      }
    }
</script>
