<template>
    <div class="top-navigation">
        <div id="wrapper">
            <div id="page-wrapper" class="gray-bg">
                <div class="row border-bottom white-bg">
                    <nav class="navbar navbar-expand-lg navbar-static-top" role="navigation">

                        <router-link to="/admin/" class="navbar-brand">
                            <img style="max-height: 45px;" src="/big-logo.png">
                        </router-link>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="fa fa-reorder"></i>
                        </button>

                        <div class="navbar-collapse collapse" id="navbar">
                          <ul class="nav navbar-nav mr-auto">
                              <li><router-link class="nav-link"  to="/admin/courses">Каталог курсов</router-link></li>
                              <li><router-link class="nav-link" to="/admin/docs/postavschikam-kontenta">Поставщикам контента</router-link></li>
                              <li><router-link class="nav-link" to="/admin/docs/o-proekte">О проекте</router-link></li>
                            </ul>
                            <ul v-if="$auth.check()" class="nav navbar-top-links navbar-right">
                                <li>
                                    <router-link to="/admin/profile">
                                        <i class="fa fa-user"></i> Профиль
                                    </router-link>
                                </li>
                            </ul>
                            <ul v-if="$auth.check()" class="nav navbar-top-links navbar-right">
                                <li>
                                    <a  href="#" @click="$auth.logout()">
                                        <i class="fa fa-sign-out"></i> Выход
                                    </a>
                                </li>
                            </ul>
                            <ul v-else class="nav navbar-top-links navbar-right">
                                <li>
                                    <router-link :to="`/admin/login`">
                                        <i class="fa fa-sign-in"></i> Вход
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="wrapper wrapper-content">
                    <div class="container">
                        <router-view :key="$route.path"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InsideLayout",
        data() {
            return {
                privateChannel: `private-p${this.$auth.user().id}`,
            }
        },
        mounted() {
            console.log(this.privateChannel);
        },
        methods: {

        }
    }
</script>

<style scoped>
    .count-info {
        position: relative;
    }

    .count-info .label {
        line-height: 12px;
        padding: 2px 5px;
        position: absolute;
        right: 6px;
        top: 12px;
    }
</style>
