<template>
  <div class="gray-bg" style="min-height: 100%;">
    <div class="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <h3>Добро пожаловать в МИИГАиК!</h3>
        <form class="m-t" role="form" @submit.prevent="login()">
          <ValidationProvider name="email" rules="email" v-slot="{ errors }">
            <div class="form-group">
              <input type="email" class="form-control" v-model="email" placeholder="Email" required="">
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <input type="password" class="form-control" v-model="password" placeholder="Пароль" required="">
          </div>
          <button type="submit" class="btn btn-primary block full-width m-b" :class="submit ? 'disabled not-allowed' : ''">Вход</button>
          <div class="m-b d-flex">
            <button type="button" class="btn btn-link" @click="connectSocial(NETWORKS.facebook)">
              <span class="fa fa-facebook"></span>
              Facebook
            </button>
            <button type="button" class="btn btn-link" @click="connectSocial(NETWORKS.vk)">
              <span class="fa fa-vk"></span>
              VK
            </button>
            <button type="button" class="btn btn-link" @click="connectSocial(NETWORKS.yandex)">
              <span><strong>Я</strong></span>
              Яндекс
            </button>
            <button type="button" class="btn btn-link" @click="connectSocial(NETWORKS.google)">
              <span class="fa fa-google"></span>
              Google
            </button>
            <button type="button" class="btn btn-link" @click="connectSocial(NETWORKS.ok)">
              <span class="fa fa-odnoklassniki"></span>
              OK
            </button>
          </div>
          <router-link to="/admin/forgot-password" class="text-left"><small>Забыли пароль?</small></router-link>
          <p class="text-muted text-center"><small>Нет аккаунта?</small></p>
          <router-link class="btn btn-sm btn-white btn-block" to="/admin/registration">Зарегистрироваться</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
    import {AppConfig, SocialNetwork} from "../global";
    import Actions from "../components/User/Actions";
    import TYPES from "../components/User/actionsTypes";

    export default {
      name: "Login",
      extends: Actions,
      data() {
        return {
          email: null,
          password: null,
          submit: false,
          NETWORKS: SocialNetwork
        }
      },
      mounted() {
        if(this.$route.query.username && this.$route.query.password) {
          this.email = this.$route.query.username;
          this.password = this.$route.query.password;
          this.login();
        }

        // Хак для рассылок
        if(this.$route.query.utm_source && this.$route.query.utm_source === 'Sendsay') {
          this.$router.push('/admin/profile')
        }

      },
      methods: {
        async login() {
          this.submit = true;
          const { email, password } = this;
          this.$auth.login({
                    data: {username: email, password: password},
                    rememberMe: true,
                    url: 'login_check',
                    redirect: '/admin/profile'
                  })
                  .then((res) => {
                    if(res.status === 200) {
                      this.addAction(TYPES.ENTER_TYPE);
                    } else {
                      this.$toasted.global.appError('Неверный логин или пароль');
                    }
                    this.submit = false;
                  });
        },
        getError(fieldName) {
          return this.errors.first(fieldName);
        },
        connectSocial(network) {
          const redirectUri = `${location.protocol}//${location.host}/login/connect/${network}`;
          switch (network) {
            case  SocialNetwork.yandex:
              window.location = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${AppConfig.yandexAppId}&redirect_uri=${redirectUri}`;
              break;
            case  SocialNetwork.facebook:
              window.location = `https://www.facebook.com/v6.0/dialog/oauth?client_id=${AppConfig.facebookAppId}&redirect_uri=${redirectUri}&scope=email%2Cpublic_profile`;
              break;
            case  SocialNetwork.vk:
              window.location = `https://oauth.vk.com/authorize?client_id=${AppConfig.vkClientId}&redirect_uri=${redirectUri}&response_type=code&scope=email`;
              break;
            case  SocialNetwork.ok:
              window.location = `https://connect.ok.ru/oauth/authorize?client_id=${AppConfig.okClientId}&scope=GET_EMAIL&response_type=code&redirect_uri=${redirectUri}`;
              break;
            case  SocialNetwork.google:
              window.location = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${AppConfig.googleAppId}&response_type=code&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email`;
              break;
          }
        }
      }
    }
</script>

<style scoped>
  .not-allowed {
    cursor: not-allowed !important;
  }
</style>
