<template>
    <div class="animated fadeIn">
        <BreadCrumbs title="Список домашних заданий" :items="[{title: 'Главная', url: '/#/'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    <b-btn-group class="dt-buttons" size="sm" label="Уровень усреднения">
                        <b-btn :class="mode === 'actual' ? 'active' : ''" variant="default" @click="setMode('actual')"><i class="fa fa-pencil"></i> На проверку</b-btn>
                        <b-btn :class="mode === 'my' ? 'active' : ''" variant="default" @click="setMode('my')"><i class="fa fa-check"></i> Проверенные</b-btn>
                    </b-btn-group>
                </div>
                <div class="ibox-content">
                    <b-table striped hover bordered
                             :show-empty="true"
                             empty-text="Список пуст"
                             :fields="fields"
                             :items="homeworks">
                        <template v-slot:cell(actions)="data">
                            <b-button-group v-if="mode === 'actual'">
                                <router-link v-b-tooltip title="Проверить" :to="'/admin/homeworks/' + data.item.id" class="btn btn-sm btn-primary"><i class="fa fa-check"></i> Проверить</router-link>
                            </b-button-group>
                            <b-button-group v-else>
                              <router-link v-b-tooltip title="Исправить" :to="'/admin/homeworks/' + data.item.id" class="btn btn-sm btn-success"><i class="fa fa-edit"></i> Исправить</router-link>
                            </b-button-group>
                        </template>
                    </b-table>
                </div>
                <ibox-footer>
                  <b-pagination v-if="pagination"
                                v-model="currentPage"
                                :total-rows="pagination.total"
                                :per-page="pagination.limit"
                                aria-controls="stats-table"
                                @input="load()"
                                prev-text="Назад"
                                next-text="Вперед"
                  ></b-pagination>
                </ibox-footer>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "HomeworkList",
        components: {IboxFooter, BreadCrumbs},
        data() {
            return {
                pagination: {
                  limit: 30,
                  offset: 0
                },
                currentPage: 1,
                mode: 'actual',
                homeworks: [],
                fields: [
                    {
                        key: 'course',
                        label: 'Курс',
                        sortable: false
                    },
                    {
                        key: 'module',
                        label: 'Модуль',
                        sortable: false
                    },
                    {
                        key: 'student',
                        label: 'Ученик',
                        sortable: false
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load(showMsg = false) {
              this.pagination.offset = this.pagination ? (this.currentPage * this.pagination.limit) - this.pagination.limit : null;
                this.axios.get('homeworks', {
                  params: {
                    mode: this.mode,
                    limit: this.pagination.limit,
                    offset: this.pagination.offset
                  }
                }).then(res => {
                    const hwInfo = res.data.data;
                    this.pagination = res.data.pagination;
                    const preparedInfo = hwInfo.map(info => {
                        return {
                            'id' : info.id,
                            'courseId': info.course.id,
                            'course': `${info.course.name} (${info.course.id})`,
                            'module': `${info.module.name} (${info.module.id})`,
                            'moduleId': info.module.id,
                            'student': `${info.enrollment.userFullname}`
                        };
                    });

                    this.homeworks = preparedInfo;
                    if (showMsg) {
                        this.$toasted.global.appSuccess();
                    }
                });
            },
            setMode(mode) {
                this.mode = mode;
                this.load();
            }
        }
    }
</script>
