<template>
    <ibox>
        <ibox-title>
            {{filterTitle}}
        </ibox-title>
        <ibox-content>
            <b-modal :id="`downloadModal-${this.id}`" title="Выберите поля для экспорта" :hide-footer="true">
                <b-row>
                    <template v-for="(field, key) in Object.keys(possibleFields[speedValue])">
                        <b-col :key="key" md="6">
                            <valid-checkbox :name="$t(`field.${speedValue}.${field}`)" :vid="`${key}`" v-model="possibleFields[speedValue][field]" />
                        </b-col>
                    </template>
                </b-row>
                <hr class="hr-line-dashed" />
                <b-btn
                        variant="primary"
                        @click="onDownloadCsv()"
                >
                    <i class="fa fa-file-excel-o"></i> Скачать
                </b-btn>
            </b-modal>
            <stat-filter @apply="onApply($event)"/>
            <ibox>
                <ibox-title :hide-tools="true">
                    <b-btn-group class="dt-buttons" size="sm" label="Уровень усреднения">
                        <b-btn :class="speedValue === 'user' ? 'active' : ''" variant="default" @click="setPanel('user')"><i class="fa fa-user"></i> Пользователь</b-btn>
                        <b-btn :class="speedValue === 'organization' ? 'active' : ''" variant="default" @click="setPanel('organization')"><i class="fa fa-building"></i> Организация</b-btn>
                        <b-btn :class="speedValue === 'region' ? 'active' : ''" variant="default" @click="setPanel('region')"><i class="fa fa-map"></i> Регион</b-btn>
                    </b-btn-group>
                </ibox-title>
                <ibox-content>
                    <div v-if="speedValue === 'user'">
                        <div v-if="filters.showTable" class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ФИО </th>
                                    <th>Email </th>
                                    <th>Компания</th>
                                    <th>Пройдено тестов</th>
                                    <th>Пройдено курсов</th>
                                    <th>Результат (среднее)</th>
                                    <th>Процент выполнения</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(row, key) in rows" :key="key">
                                    <td>{{ row.userId }}</td>
                                    <td>{{ row.userFullname}}</td>
                                    <td>{{ row.userEmail }}</td>
                                    <td>{{ row.companyId ? row.companyName : '' }}</td>
                                    <td><b-badge variant="default">{{ row.countTests }}</b-badge></td>
                                    <td>
                                        <b-badge variant="default">{{ row.countCourses }}</b-badge>
                                    </td>
                                    <td>
                                        <b-badge :variant="getVariant(row.resultAvg)">{{ row.resultAvg }}</b-badge>
                                    </td>
                                    <td>
                                        <div style="margin-left: 10px;" class="stat-percent font-bold text-navy">{{ row.completeness }}% <i class="fa fa-bolt"></i></div>
                                        <div class="progress progress-mini">
                                            <div class="progress-bar" :style="'width:' + row.completeness + '%;'"></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <b-pagination v-if="pagination"
                                          @change="onPaginate($event)"
                                          :total-rows="pagination.total"
                                          :per-page="pagination.limit"
                                          aria-controls="stats-table"
                                          prev-text="Назад"
                                          next-text="Вперед"
                                          class="mt-2"
                            ></b-pagination>
                        </div>
                        <div v-if="!filters.showTable">
                            <bar-chart chart-id="user" v-if="chartData" :chart-data="userDataSets" />
                        </div>
                    </div>
                    <div v-if="speedValue === 'region'">
                        <div v-if="filters.showTable" class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Регион </th>
                                    <th>Пройдено тестов</th>
                                    <th>Пройдено курсов</th>
                                    <th>Результат (среднее)</th>
                                    <th>Процент выполнения</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(row, key) in rows" :key="key">
                                    <td>{{ row.regionId }}</td>
                                    <td>{{ row.regionName }}</td>
                                    <td><b-badge variant="default">{{ row.countTests }}</b-badge></td>
                                    <td>
                                        <b-badge variant="default">{{ row.countCourses }}</b-badge>
                                    </td>
                                    <td>
                                        <b-badge :variant="getVariant(row.resultAvg)">{{ row.resultAvg }}</b-badge>
                                    </td>
                                    <td>
                                        <div style="margin-left: 10px;" class="stat-percent font-bold text-navy">{{ row.completeness }}% <i class="fa fa-bolt"></i></div>
                                        <div class="progress progress-mini">
                                            <div class="progress-bar" :style="'width:' + row.completeness + '%;'"></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <b-pagination v-if="pagination"
                                          @change="onPaginate($event)"
                                          :total-rows="pagination.total"
                                          :per-page="pagination.limit"
                                          aria-controls="stats-table"
                                          prev-text="Назад"
                                          next-text="Вперед"
                                          class="mt-2"
                            ></b-pagination>
                        </div>
                        <div v-if="!filters.showTable">
                            <bar-chart chart-id="region" v-if="chartData" :chart-data="regionDataSets" />
                        </div>
                    </div>
                    <div v-if="speedValue === 'organization'">
                        <div v-if="filters.showTable" class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Организация </th>
                                    <th>Пройдено тестов</th>
                                    <th>Пройдено курсов</th>
                                    <th>Результат (среднее)</th>
                                    <th>Процент выполнения</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(row, key) in rows" :key="key">
                                    <td>{{ row.companyId }}</td>
                                    <td>{{ row.companyName }}</td>
                                    <td><b-badge variant="default">{{ row.countTests }}</b-badge></td>
                                    <td>
                                        <b-badge variant="default">{{ row.countCourses }}</b-badge>
                                    </td>
                                    <td>
                                        <b-badge :variant="getVariant(row.resultAvg)">{{ row.resultAvg }}</b-badge>
                                    </td>
                                    <td>
                                        <div style="margin-left: 10px;" class="stat-percent font-bold text-navy">{{ row.completeness }}% <i class="fa fa-bolt"></i></div>
                                        <div class="progress progress-mini">
                                            <div class="progress-bar" :style="'width:' + row.completeness + '%;'"></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <b-pagination v-if="pagination"
                                          @change="onPaginate($event)"
                                          :value="pagination.currentPage"
                                          :total-rows="pagination.total"
                                          :per-page="pagination.limit"
                                          aria-controls="stats-table"
                                          prev-text="Назад"
                                          next-text="Вперед"
                                          class="mt-2"
                            ></b-pagination>
                        </div>
                        <div v-if="!filters.showTable">
                            <bar-chart chart-id="organization" v-if="chartData" :chart-data="instDataSets" />
                        </div>
                    </div>
                </ibox-content>
            </ibox>
        </ibox-content>
    </ibox>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import BarChart from "../Charts/BarChart";
    import bus from "../../bus";
    import ValidCheckbox from "../Validation/ValidCheckbox"
    import StatFilter from "./StatFilter";
    import moment from "moment";
    export default {
        name: "StatConstructor",
        components: {StatFilter, ValidCheckbox, BarChart, IboxContent, IboxTitle, Ibox},
        props: ['title', 'id'],
        data() {
            return {
                filters: {
                    showTable: true
                },
                speedValue: 'user',
                possibleFields: {
                    user: {
                        'userFullname': true,
                        'userEmail': true,
                        'company': true,
                        'region': true,
                        'countTests': true,
                        'countCourses': true,
                        'resultAvg': true,
                        'completeness': true
                    },
                    region: {
                        'region': true,
                        'countTests': true,
                        'countCourses': true,
                        'resultAvg': true,
                        'completeness': true
                    },
                    organization: {
                        'organization': true,
                        'countTests': true,
                        'countCourses': true,
                        'resultAvg': true,
                        'completeness': true
                    }
                },
                chartData: {
                    labels: [],
                    data: []
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                rows: [],
                pagination: null,
                filterTitle: 'Фильтры'
            }
        },
        mounted() {
            bus.$on('filter:change', (filters) => {
                this.filters = filters;
            });
            this.filterTitle = this.title;

            this.onApply(this.filters);
        },
        methods: {
            onPaginate(page) {
                if (!page) {
                    return;
                }

                this.onApply({
                    ...this.filters,
                    limit: this.pagination.limit,
                    offset: (page * this.pagination.limit) - this.pagination.limit
                });
            },
            onDownloadCsv() {
                const fields = [];
                const allFields = this.possibleFields[this.speedValue];
                for (let key of Object.keys(allFields)) {
                    if (allFields[key]) {
                        fields.push(key);
                    }
                }

                this.onApply({
                    ...this.filters,
                    exportFields: fields
                });
            },
            onApply(filters) {
                let query = {
                    type: this.speedValue
                };

                if (filters.region && filters.region.id) {
                    query.regions = [filters.region.id];
                }

                if (filters.city && filters.city.id) {
                    query.cities = [filters.city.id];
                }

                if (filters.institution && filters.institution.id) {
                    query.company = filters.institution.id;
                }

                if (filters.courses && filters.courses.length > 0) {
                    query.courses = filters.courses.map(c => c.id);
                }

                if (filters.tests && filters.tests.length > 0) {
                    query.tests = filters.tests.map(t => t.id);
                }

                if (filters.age && filters.age.length > 0) {
                    query.ages = filters.age.map(t => t.value);
                }

                if (filters.date && filters.date.length === 2) {
                    query.date = {
                        from: moment(filters.date[0]).format('DD.MM.YYYY HH:mm'),
                        to: moment(filters.date[1]).format('DD.MM.YYYY HH:mm'),
                    };
                }

                if (filters.limit) {
                    query.limit = filters.limit;
                }

                if (filters.offset) {
                    query.offset = filters.offset;
                }

                if (filters.exportFields) {
                    query.exportFields = filters.exportFields;
                }

                this.loadData(query);
            },
            loadData(query) {
                if (!query.exportFields) {
                    this.rows = [];
                    this.chartData.data = [];
                    this.chartData.labels = [];
                }

                this.axios.post('stats/search', query).then(res => {
                    let {data} = res;
                    if (data.data.downloadUrl) {
                        this.$bvModal.hide('downloadModal');
                        window.open(data.data.downloadUrl, '_blank');

                        return;
                    }

                    const rows = data.data;
                    const pagination = data.pagination;

                    this.rows = rows;
                    this.pagination = pagination;
                });
            },
            setPanel(val) {
                this.speedValue = val;
                bus.$emit('chart:rerender');

                this.onApply(this.filters);
            },
            getVariant(points) {
                if(points < 30) {
                    return 'danger'
                }
                if(points < 70) {
                    return 'warning'
                }

                return 'primary';
            }
        },
        computed: {
            regionDataSets() {
                return {
                    labels: this.chartData.labels,
                    datasets: [
                        {
                            label: 'Средний балл в регионе',
                            backgroundColor: '#007bff',
                            data: this.chartData.data
                        }
                    ]
                }
            },
            instDataSets() {
                return {
                    labels: this.chartData.labels,
                    datasets: [
                        {
                            label: 'Средний балл по организации',
                            backgroundColor: '#f87979',
                            data: this.chartData.data
                        }
                    ]
                }
            },
            userDataSets() {
                return {
                    labels: this.chartData.labels,
                    datasets: [
                        {
                            label: 'Средний балл по пользователю',
                            backgroundColor: '#1ab394',
                            data: this.chartData.data
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>

</style>

<i18n>
    {
        "ru": {
            "field": {
                "user": {
                    "userFullname": "ФИО",
                    "userEmail": "Email",
                    "company": "Компания",
                    "region": "Регион",
                    "countTests": "Тестов сдано",
                    "countCourses": "Курсов пройдено",
                    "resultAvg": "Результат",
                    "completeness": "Процент выполнения"
                },
                "organization": {
                    "organization": "Название",
                    "countTests": "Тестов сдано",
                    "countCourses": "Курсов пройдено",
                    "resultAvg": "Результат",
                    "completeness": "Процент выполнения"
                },
                "region": {
                    "region": "Название",
                    "countTests": "Тестов сдано",
                    "countCourses": "Курсов пройдено",
                    "resultAvg": "Результат",
                    "completeness": "Процент выполнения"
                }
            }
        }
    }
</i18n>
