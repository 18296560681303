import { render, staticRenderFns } from "./TestConstructor.vue?vue&type=template&id=5cfb1600&scoped=true&"
import script from "./TestConstructor.vue?vue&type=script&lang=js&"
export * from "./TestConstructor.vue?vue&type=script&lang=js&"
import style0 from "./TestConstructor.vue?vue&type=style&index=0&id=5cfb1600&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfb1600",
  null
  
)

export default component.exports