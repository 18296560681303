<template>
  <span>
    <template v-if="$auth.ready()">
      <router-view></router-view>
    </template>
    <template v-if="!$auth.ready()"></template>
  </span>
</template>
<script>
    import 'vue-multiselect/dist/vue-multiselect.min.css';
    export default {
      name: 'App',
    }
</script>

<style lang="scss">
  @import "assets/css/bootstrap4.css";
  @import 'assets/css/bootstrap-vue.css';
  @import "assets/css/animate.css";
  @import "assets/css/plugins/summernote/summernote-bs4.css";
  @import "assets/css/plugins/dropzone/basic.css";
  @import "assets/css/plugins/dropzone/dropzone.css";
  @import "assets/css/plugins/jasny/jasny-bootstrap.min.css";
  @import "assets/css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
  @import "assets/css/plugins/codemirror/codemirror.css";

  @import "assets/css/style.css";

  .hot-courses {
    .col-md-3, .col-md-4 {
      margin-bottom: 20px;
      img {
        width: 100%;
      }
      .course-row {
        box-shadow: 0 0 30px rgba(0,0,0,.07);
        height: 100%;
        padding: 16px 16px 16px 16px;
        .list-inline {
          margin-bottom: 16px;
          .btn-primary {
            position: absolute;
            bottom: 16px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }
    margin: 0 0 20px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 200px;
    height: 161px;
    display: block;
  }
  .breadcrumb {
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    border-radius: .25rem;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  h2 {
    font-size: 24px;
  }

  .h1, .h2, .h3, h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto;
  }
  .m-t-xs {
    margin-top: 5px;
  }
  .dropdown-menu {
    border: medium none;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
    display: none;
    float: left;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 1000;
  }
  .dropdown-item {
    width: auto;
  }
  .mr-30 {
    margin: -6px 30px 0 0;
  }
  .centered-btn-group {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .product-detail .ibox-content {
    padding-bottom: 10px;
  }
  .multiselect--active {
    font: inherit;
  }
  .multiselect__tags {
    min-height: 36px;
    border-radius: 0;
    padding: 6px 40px 0 8px;
    .multiselect__placeholder {
      color: #676a6c;
      margin: 0;
      padding: 0;
    }
  }
  .multiselect__input {
    font: inherit;
  }
  .el-upload {
    display: block;
  }
  .green {
    color: #1ab394;
  }
  .note-editable {
    border: 1px solid #f5f5f5;
  }
  .pagination > li > a, .pagination > li > span {
    line-height: 1.9;
  }
  .vertical-date {
    font-size: 12px;
  }
</style>
