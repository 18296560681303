<template>
    <div v-if="status">
        <ibox>
            <ibox-title :hide-tools="true">
                <h1><i class="fa fa-list"></i> {{ status.name[$i18n.locale] }}</h1>
            </ibox-title>
            <ibox-content>
                <div>
                    <div v-if="status.description[$i18n.locale]" class="small text-muted">
                        {{ status.description[$i18n.locale] }}
                    </div>
                </div>
                <b-row>
                    <b-col>
                        <span>Длительность теста: </span>
                        <b>{{ status.duration }} мин.</b>
                    </b-col>
                    <b-col>
                        <span>Количество вопросов: </span>
                        <b>{{ status.questionsCount }}</b>
                    </b-col>
                    <b-col>
                        <span>Использовано попыток: </span>
                        <b>{{ status.attemptsCount }}</b>
                    </b-col>
                    <b-col>
                        <span>Осталось попыток: </span>
                        <b>{{ status.elapsedAttemptsCount }}</b>
                    </b-col>
                </b-row>
                <hr style="margin-top: 0;" class="hr-line-dashed"/>
                <div v-if="status.attempts.length > 0">
                    <table class="table small m-b-xs">
                        <thead>
                        <tr>
                            <th>Завершен в</th>
                            <th>Правильных ответов</th>
                            <th v-if="status.canViewAnswers"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(attempt, index) in status.attempts"  :key="index">
                            <td>
                                {{ attempt.finishedAt|formatDate }}
                            </td>
                            <td>
                                {{ attempt.correctCount }}
                            </td>
                            <td v-if="status.canViewAnswers">
                                <button type="button" class="btn btn-info btn-sm" @click="viewResult(attempt.id)">
                                    <span class="fa fa-eye"></span>
                                    Посмотреть ответы
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="clearfix text-center row">
                    <div class="col">
                        <b-button v-if="status.elapsedAttemptsCount > 0" variant="primary" @click="startTest()"><i class="fa fa-play"></i> Начать тест</b-button>
                    </div>
                    <div v-if="status.canForceFinishTest" class="col">
                        <b-button variant="danger" @click="$bvModal.show('confirm-finish')">Завершить тест</b-button>
                    </div>
                </div>
            </ibox-content>
        </ibox>

        <b-modal
                id="confirm-finish"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Подтвердите завершение теста"
                @ok="endTest()"
                ok-title="Да"
                cancel-title="Нет"
        >
            Вы действительно хотите завершить тест? Все неиспользованные попытки сгорят
        </b-modal>
    </div>
</template>

<script>
    // import Vue from "vue";
    import IboxContent from "../Ibox/IboxContent";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    export default {
        name: "TestPassageStatus",
        components: {IboxTitle, Ibox, IboxContent},
        props: {
            status: Object,
        },
        methods: {
            startTest() {
                this.$emit('process');
            },
            endTest() {
                this.$emit('finish');
            },
            viewResult(attemptId) {
                this.$emit('view-result', attemptId);
            },
        }
    }
</script>

<style scoped>

</style>
