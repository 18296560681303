<template>
    <div class="wrapper wrapper-content animated fadeInUp">
        <b-modal v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" id="add-inside-course" :hide-footer="true" title="Добавить новый курс">
            <CourseForm></CourseForm>
        </b-modal>
        <div class="row">
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Курсы, в которых я принимаю участие:</h5>
                        <div class="ibox-tools">
                            <b-btn v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" v-b-modal.add-inside-course class="btn btn-primary btn-xs">Добавить новый курс</b-btn>
                        </div>
                    </div>
                    <div class="ibox-content">
                        <div class="m-b-sm m-t-sm">

                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button tabindex="-1" class="btn btn-white btn-sm" @click="load()" type="button"><i class="fa fa-refresh"></i> Обновить</button>
                                </div>
                                <input type="text" v-model="filter" class="form-control form-control-sm">

                                <div class="input-group-append">
                                    <button tabindex="-1" class="btn btn-primary btn-sm" type="button">Найти</button>
                                </div>
                            </div>
                        </div>

                        <div class="project-list">
                            <table v-if="courseList" class="table table-hover">
                                <tbody>
                                <tr v-for="(course, k) in courseList" v-bind:key="k">
                                    <td class="project-status">
                                        <span :class="'label label-' + course.status">
                                            {{course.statusRu}}
                                        </span>
                                        <i v-if="course.hasOffline" v-b-tooltip style="margin-left: 10px;" title="Есть оффлайн обучение" class="fa fa-institution"></i>
                                    </td>
                                    <td class="project-title">
                                        <router-link :to="`/admin/course/${course.id}`">{{course.name}}</router-link>
                                        <hr v-if="course.currentModule" class="hr-line-dashed"/>
                                        <small v-if="course.currentModule"><i class="fa fa-clock-o"></i> Модуль 1. {{course.currentModule}}</small>
                                    </td>
                                    <td class="project-status">
                                        <i class="fa fa-users"></i> {{course.enrollCount}}
                                    </td>
                                    <td class="project-people">
                                        <span v-if="course.institution && course.institution.logo">
                                          <img alt="image" class="rounded-circle" :src="course.institution.logo.thumbs.smaller">
                                        </span>
                                    </td>
                                    <td class="project-actions">
                                        <b-btn-group>
                                            <router-link v-b-tooltip title="Редактировать" v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" :to="`/admin/course/${course.id}/edit`" class="btn btn-white btn-sm"><i class="fa fa-pencil"></i> </router-link>
                                            <router-link v-b-tooltip title="Перейти к курсу" :to="`/admin/course/${course.id}`" class="btn btn-white btn-sm"><i class="fa fa-folder"></i> </router-link>
                                            <router-link v-b-tooltip title="Статистика" :to="`/admin/stats/${course.id}`" class="btn btn-white btn-sm"><i class="fa fa-bar-chart"></i> </router-link>
                                        </b-btn-group>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <b-progress v-else :value="courseLoadingProgress" variant="primary" :max="100" show-progress animated label="Загрузка данных о курсах"></b-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CourseForm from "./CourseForm";
    export default {
        name: "CourseInProfileList",
        components: {CourseForm},
        data() {
            return {
                courses: null,
                filter: null,
                courseLoadingProgress: 0
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                  setInterval(() => {
                    if (this.courseLoadingProgress < 95) {
                      this.courseLoadingProgress += 5
                    }
                  },1000)
                  this.axios.get('courses?my=true').then(res => {
                    this.courses = res.data;
                });
            }
        },
        computed: {
            courseList: function() {
                if(this.filter) {
                    return this.courses.filter(course => {
                        return (course.name.toLowerCase().indexOf(this.filter) === 0);
                    })
                }
                return this.courses;
            }
        }
    }
</script>

<style lang="scss">
    .project-status {
        .label {
            color: #fff;
            &.label {
                &-finished {
                    background-color: #007bff!important;
                }
                &-started {
                    background-color: #dc3545!important;
                }
                &-open {
                    background-color: #17a2b8!important;
                }
                &-soon {
                    background-color: #E6A23C!important;
                }
                &-finished {
                    background-color: #67C23A!important;
                }
                &-closed {
                    background-color: #909399!important;
                }
            }
        }
    }
</style>
