<template>
    <div class="animated fadeInRight">
        <b-modal id="edit-user"
                 ref="modal"
                 button-size="sm"
                 title="Предоставление доступа"
                 :hide-footer="true"
        >
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.stop.prevent="passes(onEdit)">
                    <b-form-group label="Фото пользователя">
                        <AvatarUpload/>
                    </b-form-group>
                    <ValidInput label="Логин (email)" name="логин" :rules="{ required: true, email:true }" v-model="editUser.username"/>
                    <ValidInput label="Пароль" name="пароль" :rules="{ required: false, min: 6 }" v-model="editUser.password"/>
                    <ValidInput label="Имя" name="имя" :rules="{ required: true, min: 3 }" v-model="editUser.firstName"/>
                    <ValidInput label="Отчество" name="отчество" :rules="{ required: false, min: 3 }" v-model="editUser.middleName" />
                    <ValidInput label="Фамилия" name="фамилия" v-model="editUser.lastName" :rules="{ required: false, min: 3 }"/>
                    <ValidSelect label="Роль" name="роль" :options="roles" :rules="{required: true}" v-model="editUser.roles" open-direction="top" label-title="name" track-by="role"/>
                    <b-button type="submit" size="sm" variant="primary">Сохранить</b-button>
                </b-form>
            </validation-observer>
        </b-modal>
        <div class="clients-list">
            <b-table striped hover bordered :fields="fields" thead-class="hidden_header" :items="users">
                <template v-slot:cell(photo)="data">
                    <img v-if="data.item.photo" alt="image" :src="data.item.photo.thumbs.smaller">
                </template>
                <template v-slot:cell(contactType)>
                    <i class="fa fa-envelope"></i>
                </template>
                <template v-slot:cell(actions)="data" class="text-right">
                    <b-btn-group>
                        <b-button v-if="data.item.id !== $auth.user().id" variant="danger" size="xs" @click="confirmDelete(data.item)"><i class="fa fa-close"></i></b-button>
                        <b-button v-if="data.item.id !== $auth.user().id" variant="primary" size="xs" @click="editAction(data.item)"><i class="fa fa-edit"></i></b-button>
                    </b-btn-group>
                </template>
                <template v-slot:cell(fullname)="data">
                    <a href="#contact-1" class="client-link">{{data.item.fullname}}</a>
                </template>
                <template v-slot:cell(roleRu)="data">
                    <b-badge class="margin-right-5" v-for="(role,index) in data.item.roles" :key="index" variant="warning">{{ constRoles[role] }}</b-badge>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import bus from '../../bus'
    import AvatarUpload from "../AvatarUpload";
    import ValidInput from "../Validation/ValidInput";
    import ValidSelect from "../Validation/ValidSelect";
    import ROLES from "./roles";
    export default {
        name: "UserInstitutionList",
        components: {ValidSelect, ValidInput, AvatarUpload},
        props: ['inst'],
        data() {
            return {
                users: [],
                editUser: null,
                userInstitution: null,
                roles: [],
                constRoles: ROLES,
                fields: [
                    {
                        key: 'photo',
                        tdClass: 'client-avatar'
                    },
                    {
                        key: 'fullname'
                    },
                    {
                        key: 'contactType',
                        tdClass: 'contact-type'
                    },
                    {
                        key: 'username'
                    },
                    {
                        key: 'roleRu'
                    },
                    {
                        key: 'actions'
                    }
                ]
            }
        },
        mounted() {
            this.load()
            bus.$on('user-added', () => {
                this.load()
            })
            Object.keys(ROLES).forEach(roleKey => {
                this.roles.push({name: ROLES[roleKey], role: roleKey});
            })
        },
        methods: {
            load() {
                this.axios.get('user_institutions', {params: {institution: this.inst.id}}).then(res => {
                    this.users = [];
                    res.data.map((item) => {
                        const data = item.user;
                        data.ui_id = item.id;
                        data.roleRu = item.roleRu;
                        data.userInstitution = item;
                        if(data.photo) {
                            this.axios.get(data.photo)
                                .then(res => {
                                    data.photo = res.data
                                    this.users.push(data)
                                })
                        } else {
                            this.users.push(data)
                        }
                    })
                })
            },
            confirmDelete(user) {
                this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить пользователя из компании?', {
                    title: 'Удаление пользователя «' + user.fullname + '»?',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(res => {
                    if(res) {
                        this.axios.delete('user_institutions/' + user.ui_id).then(() => {
                            this.load()
                        });
                    }
                })
            },
            editAction(user) {
                this.editUser = user;
                this.editUser.roles = this.editUser.roles.map((role) => {return {role:role, name: ROLES[role]}});
                this.$bvModal.show('edit-user');
            },
            onEdit() {
                this.axios.put('user_institutions/' + this.editUser.ui_id, {
                    roles: this.editUser.roles.map(role => role.role)
                }).then(() => {
                    this.axios.put('users/' + this.editUser.id, {
                        password: this.editUser.password,
                        username: this.editUser.username,
                        firstName: this.editUser.firstName,
                        lastName: this.editUser.lastName,
                        middleName: this.editUser.middleName
                    }).then(() => {
                        this.$bvModal.hide('edit-user');
                        this.load()
                    })
                });
            }
        }
    }
</script>

<style>
    .hidden_header {
        display: none!important;
    }
    .margin-right-5 {
        margin-right: 5px;
    }
</style>
