<template>
    <div class="animated fadeIn">
        <BreadCrumbs title="Каталог курсов" :items="[{title: 'Главная', url: '/#/'}]"/>
        <b-row>
            <b-col class="wrapper border-bottom white-bg search-block">
                <CourseFilter v-model="filter"/>
            </b-col>
        </b-row>
        <b-row>
            <div class="ibox" style="width: 100%;">
                <div class="ibox-content">
                    <CourseList empty-text="Курсов с указанными параметрами не найдено" :filters="filter" />
                </div>
            </div>
        </b-row>
    </div>
</template>

<script>
    import BreadCrumbs from "./BreadCrumbs";
    import CourseList from "./Course/CourseList";
    import CourseFilter from "./Course/CourseFilter";
    export default {
        name: "Catalog",
        data() {
            return {
                filter: {
                    title: null,
                    codifiers: [],
                    institutions: [],
                    categories: []
                }
            }
        },
        components: {CourseFilter, CourseList, BreadCrumbs}
    }
</script>

<style scoped>
    .search-block {
        padding: 20px;
    }
</style>
