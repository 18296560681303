<template>
  <div>
    <div v-if="!loading && !channelKey" class="animated fadeInRight">
      <div class="mail-box-header">
        <h2>Сообщения</h2>
      </div>

      <div class="ibox chat-view">
        <div class="ibox-content">

          <div class="row">
            <div class="col-md-12">
              <div class="chat-users">
                <div v-if="channels.length > 0" class="users-list">
                  <div v-for="(channel) in channels"
                       v-bind:key="channel.key"
                       @click="channelKey = channel.key"
                       class="chat-user"
                  >
                    <img class="chat-avatar" src="/logo-rounded.png" alt="">
                    <div class="chat-user-name">
                      <span>{{ getParticipant(channel.participants).fullname }}</span>
                    </div>
                    <div v-if="channel.lastMessage">
                      {{ truncateMessage(channel.lastMessage.content) }}
                    </div>
                  </div>

                </div>
                <div v-if="channels.length === 0" class="text-center mt-5">Список бесед пуст.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <channel-view v-if="channelKey"
                  :channel="channelKey"
                  :initial-text="channelInitialText"
                  @close="onCloseChannel" />
  </div>
</template>
<script>
  import ChannelView from "./ChannelView";
  export default {
    name: "Messages",
    components: {ChannelView},
    data() {
      return {
        channelInitialText: null,
        channelKey: null,
        loading: true,
        channels: []
      }
    },
    mounted() {
      let channelKey = this.$route.query.sel;

      if (!channelKey) {
        this.loadDialogs();
        return;
      }

      // rewrite alias
      if (channelKey === 'system') {
        channelKey = `sys${this.$auth.user().id}`;
      }

      this.loading = false;

      const theme = this.$route.query.theme;
      if (theme) {
        this.channelInitialText = `${theme}\n\n`;
      }
      this.channelKey = channelKey;
    },
    methods: {
      onCloseChannel() {
        this.channelKey = null;
        this.channelInitialText = null;
        this.loadDialogs();
      },
      loadDialogs() {
        this.loading = true;

        this.axios.get('chats', {params: {hideLoader: true}})
                .then(response => {
                  const data = response.data;
                  this.channels = data.channels;
                  this.loading = false;
                });
      },
      getParticipant(participants) {
        const authUserId = this.$auth.user().id;

        return participants.find(p => p.id !== authUserId);
      },
      truncateMessage(text) {
        const maxlength = 40;
        return text.length > maxlength ? `${text.slice(0, maxlength - 1)}...` : text;
      }
    }
  }
</script>

<style lang="scss">
  .chat-user {
    cursor: pointer;
  }
</style>
