<template>
  <div class="animated fadeIn">
    <template v-if="stat">
      <ibox>
        <ibox-title>
          Данные по пользователям
        </ibox-title>
        <ibox-content>
          <b-row class="text-center">
            <b-col>
              <h1><i class="fa fa-line-chart"></i> {{stat.trajectories}}</h1>
              <small class="text-muted">Всего траекторий</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-users"></i> {{stat.total}}</h1>
              <small class="text-muted">Всего пользователей</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-user-plus"></i> {{stat.onTested}}</h1>
              <b-progress variant="success" animated show-progress v-if="stat.total" :max="stat.total">
                <b-progress-bar :value="stat.onTested" :label="`${((stat.onTested / stat.total) * 100).toFixed(0)}%`"></b-progress-bar>
              </b-progress>
              <small class="text-muted">Направлено на тестирование</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-check-circle"></i> {{stat.wasTested}}</h1>
              <b-progress variant="success" animated show-progress v-if="stat.total" :max="stat.total">
                <b-progress-bar :value="stat.wasTested" :label="`${((stat.wasTested / stat.total) * 100).toFixed(0)}%`"></b-progress-bar>
              </b-progress>
              <small class="text-muted">Протестировано</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-building"></i> {{stat.organizations}}</h1>
              <small class="text-muted">Всего организаций в выборке</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-building-o"></i> {{stat.organOnTested}}</h1>
              <b-progress variant="success" animated show-progress v-if="stat.organizations" :max="stat.organizations">
                <b-progress-bar :value="stat.organOnTested" :label="`${((stat.organOnTested / stat.organizations) * 100).toFixed(0)}%`"></b-progress-bar>
              </b-progress>
              <small class="text-muted">Всего организаций направлено на тестирование</small>
            </b-col>
            <b-col>
              <h1><i class="fa fa-check-square"></i> {{stat.organWasTested}}</h1>
              <b-progress variant="success" animated show-progress v-if="stat.organizations" :max="stat.organizations">
                <b-progress-bar :value="stat.organWasTested" :label="`${((stat.organWasTested / stat.organizations) * 100).toFixed(0)}%`"></b-progress-bar>
              </b-progress>
              <small class="text-muted">Всего организаций протестировано</small>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col>
              <h1><i class="fa fa-list-ul"></i> {{stat.testsCount}}</h1>
              <small class="text-muted">Наименований тестов задействовано</small>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col>
              <h1><i class="fa fa-list-ul"></i> {{stat.testsByStudent.toFixed(2)}}</h1>
              <small class="text-muted">Тестов на человека</small>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </ibox-content>
      </ibox>
    </template>
    <template v-else>
      <ibox></ibox>
      <ibox-title :hide-tools="true" class="text-center">
        <span class="text-muted">Для получения данных необходимо выбрать проект и нажать &laquo;Применить&raquo;</span>
      </ibox-title>
    </template>
  </div>
</template>

<script>
import bus from "../../bus";
import IboxTitle from "../Ibox/IboxTitle";
import IboxContent from "../Ibox/IboxContent";
import Ibox from "../Ibox/Ibox";

export default {
name: "Member",
  components: {IboxContent, IboxTitle, Ibox},
  props: ['filters'],
  data() {
    return {
      stat: null
    }
  },
  methods: {
    getData() {
      this.axios.post(`/analytics/members`, this.filters)
          .then(res => {
            if(res.status === 200) {
              this.stat = res.data;
            } else {
              this.stat = []
            }
          })
          .catch(() => {
            this.stat = []
          })
    }
  },
  mounted() {
    bus.$on('filter-changed', () => {
      this.getData();
    });
    bus.$on('filter-cleared', () => {
      this.stat = null
    });
  }
}
</script>

<style scoped>

</style>
