<template>
    <span v-if="$auth.check()">
        <div v-if="$auth.user().company && $auth.user().company.subsystemSettings && $auth.user().company.subsystemSettings.isChat || !$auth.user().company" class="ibox">
            <ibox-title>Обсуждение курса</ibox-title>
            <div class="ibox-content">
                <channel-view :channel="chatChannelKey" :hide-title="true" />
            </div>
        </div>
    </span>
</template>

<script>
    import IboxTitle from "../Ibox/IboxTitle";
    import ChannelView from "../Profile/ChannelView";
    export default {
        name: "CourseChatbox",
        components: {ChannelView, IboxTitle},
        props: ['course'],
        data() {
            return {
                chatChannelKey: `c${this.course.id}`
            }
        }
    }
</script>

<style scoped>

</style>
