<template>
  <carousel :per-page="1"
            :per-page-custom="[[800, 1], [800, 1], [1000, 2], [1100, 3], [1400, 4]]"
            :navigation-enabled="myWindow.innerWidth > 500"
            :pagination-enabled="false"
            :center-mode="true"
            :loop="true"
            :navigation-next-label="`<img src='/img/landing/slide-right.png'/>`"
            :navigation-prev-label="`<img src='/img/landing/slide-left.png'/>`">
    <slide v-for="(tutor, key) in tutors" :key="key" class="text-center">
      <div class="tutor-img" :style="$parent.getBg(tutor)"></div>
      <h2>{{tutor.User.first_name}} {{tutor.User.last_name}}</h2>
      <p>{{tutor.Institute.title}}</p>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "TutorList",
  props: ['tutors', 'myWindow'],
  components: {Carousel, Slide}
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/home";
</style>