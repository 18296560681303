<template>
    <div v-if="test" class="animated fadeIn">
        <bread-crumbs title="Конструктор тестов" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Каталог тестов', url: '/admin/test-categories'}]"/>
        <div class="wrapper wrapper-content">
            <b-row>
                <ibox class="col-md-12">
                    <ibox-title>
                        {{test.name}}
                    </ibox-title>
                    <ibox-content>
                        <div>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ passes }">
                                        <b-form @submit.stop.prevent="passes(onSubmit)">
                                            <b-row>
                                                <b-col>
                                                    <valid-input name="код" label="Код" v-model="test.code" :rules="{required: true, min: 3}" />
                                                    <valid-input name="название" label="Название" v-model="test.name" :rules="{required: true, min: 3}" />
                                                    <valid-summernote label="Описание" name="описание" v-model="test.text" :rules="{required: false}"/>
                                                </b-col>
                                                <b-col>
                                                    <valid-input v-model.number="test.yellowZonePercent" type="number" label="Нижняя граница желтой зоны" name="нижняя граница желтой зоны" :rules="{required: true, numeric: true, min_value: 1, max_value: test.greenZonePercent}"/>
                                                    <valid-input v-model.number="test.greenZonePercent" type="number" label="Нижняя граница зеленой зоны" name="нижняя граница зеленой зоны" :rules="{required: true, numeric: true, max_value:99, min_value: test.yellowZonePercent}"/>
                                                    <valid-input label="Длительность тестов в минутах" name="длительность тестов в минутах" v-model="test.subtests[0].durationMinutes"></valid-input>
                                                </b-col>
                                            </b-row>
                                            <hr class="hr-line-dashed"/>
                                            <b-btn variant="primary" type="submit" class="pull-right">Сохранить</b-btn>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </div>
                    </ibox-content>
                </ibox>
            </b-row>
            <b-row>
                <ibox class="col-md-12">
                    <ibox-title :hide-tools="true">
                        <b-btn class="btn btn-primary btn-xs pull-right" @click="addQuestion()">Добавить новый вопрос</b-btn>
                        Вопросы
                    </ibox-title>
                    <ibox-content>
                        <valid-form v-if="currentSpec" :submit="sendQuestion">
                            <valid-input v-model="currentSpec.weight" label="Удельный вес" name="удельный вес"></valid-input>
                            <valid-select label="Тип вопроса" :multiple="false" name="тип вопроса" label-title="name" track-by="id" v-model="currentSpec.questionType" :options="questionTypes"></valid-select>
                            <valid-input label="Заголовок" v-model="currentQuestion.headerText"></valid-input>
                            <valid-summernote label="Описание" v-model="currentQuestion.description"></valid-summernote>
                        </valid-form>
                        <hr class="hr-line-dashed" />
                        <div v-for="(sub, key) in test.subtests" :key="key">
                            <draggable v-model="sub.specifications" @change="onMoveCallback" draggable=".constructor-spec">
                                <div :class="!specEditor || (specEditor && specEditor.id !== spec.id) ? 'constructor-spec' : ''" v-for="(spec, key) in sub.specifications" :key="key">
                                    <div v-if="!specEditor || (specEditor && specEditor.id !== spec.id)" class="constructor-spec-header">
                                    <p>
                                        <strong>
                                            {{key + 1}})
                                            <i :class="`fa fa-${getClass(spec.questionType.name)}`"></i>
                                            {{ spec.questionType.name }}
                                        </strong>
                                    </p>
                                    <span v-for="(question, key) in spec.questions" :key="key" v-html="question.description"></span>
                                    <b-btn variant="success" @click="specEditor = spec" size="xs"><i class="fa fa-edit"></i> Редактировать</b-btn>
                                    <hr class="hr-line-dashed"/>
                                </div>
                                    <test-constructor-editor v-else :spec="specEditor" :subtest="sub.id" :elements="codifierElements" />
                                </div>
                            </draggable>
                        </div>
                    </ibox-content>
                </ibox>
            </b-row>
        </div>
    </div>
</template>

<script>
    import ValidInput from "../Validation/ValidInput";
    import draggable from 'vuedraggable';
    import _ from 'lodash';
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import ValidSummernote from "../Validation/ValidSummernote";
    import IboxTitle from "../Ibox/IboxTitle";
    import TestConstructorEditor from "./TestConstructorEditor";
    import Specification from "./model/Specification";
    import ValidForm from "../Validation/ValidForm";
    import ValidSelect from "../Validation/ValidSelect";
    import Question from "./model/Question";
    export default {
        name: "TestConstructor",
        components: {
            ValidSelect,
            ValidForm,
            TestConstructorEditor, IboxTitle, ValidSummernote, IboxContent, Ibox, BreadCrumbs, ValidInput, draggable},
        data() {
            return {
                test: null,
                codifiers: null,
                codifierElements: null,
                questionTypes: null,
                specEditor: null,
                currentSpec: null,
                currentQuestion: null
            }
        },
        mounted() {
            this.axios.get(`sdpk/get/Test/id/${this.$route.params.id}/test.construct`).then(res => {
                this.test = res.data;
                this.axios.get(`sdpk/get/QuestionType/all`).then(res => {
                    this.questionTypes = res.data;
                });
                this.axios.get(`sdpk/get/CodifierElement/tree`).then(res => {
                    this.codifierElements = res.data;
                });
            })
        },
        methods: {
            addQuestion () {
                let iterSubtest = this.test.subtests[0]
                let newSpecification = new Specification()
                newSpecification.orderCode = 1;
                this.currentSpec = newSpecification
                this.currentSpec.subtest = iterSubtest.id
                this.currentQuestion = new Question();
            },
            sendQuestion() {
                this.axios.post(`sdpk/save/Specification/new`, this.currentSpec).then((res) => {
                    this.currentQuestion.specification = res.data.id;
                    this.axios.post(`sdpk/save/Question/new`, this.currentQuestion).then(() => {
                        this.currentSpec = null;
                        this.currentQuestion = null;
                        this.axios.get(`sdpk/get/Test/id/${this.$route.params.id}/test.construct`).then(res => {
                            this.test = res.data;
                        })
                    });
                })
            },
            onSubmit() {
                this.axios.post(`sdpk/save/Test/${this.test.id}`, this.test).then(() => {
                    this.$toasted.global.appSuccess('Тест успешно сохранен');
                    this.specEditor = null;
                })
            },
            orderedQuestions(questions) {
                return _.orderBy(questions, 'orderCode')
            },
            getClass(type) {
                let icon;
                switch (type) {
                    case 'Выбор нескольких ответов':
                        icon = 'check-square-o';
                        break;
                    case 'Единственный ответ':
                        icon = 'dot-circle-o';
                        break;
                    default:
                        icon = 'list-alt';
                        break;
                }
                return icon;
            },
            onMoveCallback() {
                this.test.subtests = this.test.subtests.map(sub => {
                    sub.specifications = sub.specifications.map((spec, index) => {
                        spec.orderCode = index + 1;
                        return spec;
                    })
                    return sub;
                })
            }
        }
    }
</script>

<style scoped>
    .sortable-chosen {
        opacity: 1;
        background: #fff;
        padding: 10px;
    }
</style>
