<template>
  <div class="table-responsive">
    <table class="table table-condensed">
      <thead>
      <th>№</th>
      <th v-for="(column, k) in task.columns" :key="k">{{ column }}</th>
      </thead>
      <tbody>
      <tr v-for="(row, r) in task.cells" :key="r">
        <td>{{ r + 1 }}</td>
        <td v-for="(column, k) in task.columns" :key="k">
          <div v-if="row[k].isStaticText" v-html="row[k].text"></div>
          <div v-if="row[k] && row[k].isStaticText && Array.isArray(givenAnswer.grid) && Array.isArray(givenAnswer.grid[r])"
               style="display: none">
            {{ givenAnswer.grid[r][k] = '' }}
          </div>
          <div v-if="!row[k].isStaticText">
            <p-check v-if="givenAnswer && Array.isArray(givenAnswer.grid) && Array.isArray(givenAnswer.grid[r])"
                     class="p-icon p-bigger p-smooth"
                     style="font-size: 200%"
                     v-model="givenAnswer.grid[r][k]">
              <i slot="extra" class="icon fa fa-check"></i>
            </p-check>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";
    export default {
        name: "QuestionMatchCheckbox",
        extends: QuestionBase,
        mounted () {
          if (this.task && Array.isArray(this.task.cells) && this.givenAnswer && Array.isArray(this.givenAnswer.grid) && this.givenAnswer.grid.length === 0) {
            for (let r = 0; r < this.task.cells.length; r++) {
              this.givenAnswer.grid.push([])
              for (let k = 0; k < this.task.columns.length; k++) {
                this.givenAnswer.grid[r][k] = (k === 0) ? '' : false
              }
            }
          }
        }
    }
</script>

<style scoped>

</style>
