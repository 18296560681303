import QuestionType from './QuestionType';
export default class Specification {
    constructor() {
        this.id = '';
        this.subtest = '';
        this.orderCode = null;
        this.weight = 1;
        this.hidden = false;
        this.redZonePercent = null;
        this.yellowZonePercent = null;
        this.greenZonePercent = null;
        this.questionType = new QuestionType();
        this.questions = [];
        this.specCodifierIds = [];
        this.specCodifiers = [];
    }
}
