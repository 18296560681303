<template>
    <div>
        <b-spinner v-if="loading" label="Loading..."></b-spinner>
        <div v-if="!loading">
            <summernote :disabled="['submitted', 'checked'].indexOf(status) > -1" v-model="noteContent"></summernote>
            <div>
                <h4>
                    Вложения
                    <span class="font-weight-lighter small">(Вы можете прикрепить до 3х файлов)</span>
                </h4>

                <ul v-if="noteAttachments.length > 0" class="mb-2">
                    <li v-for="file in noteAttachments" :key="file.id">
                        <a :href="axios.defaults.baseURL + file.path" target="_blank">{{ file.name }}</a>

                        <div v-if="['submitted', 'checked'].indexOf(status) > -1">
                            <b-button v-if="!file.deleted" size="xs" variant="danger" @click="dropAttachment(file)">Удалить</b-button>
                            <b-button v-if="file.deleted" size="xs" variant="primary" @click="restoreAttachment(file)">Восстановить</b-button>
                        </div>
                    </li>
                </ul>

                <file-upload
                    v-if="3 > validAttachmentsCount && status !== 'submitted'"
                    :actionForUpload="this.axios.defaults.baseURL + 'files/upload'"
                    :disableAccept="true"
                    @input="onFileUpload($event)"
                >
                    <b-btn size="lg" class="btn-block" variant="primary">
                        Выберите файл для загрузки <i class="fa fa-cloud-upload"></i>
                    </b-btn>
                </file-upload>
            </div>
            <div class="mt-4">
                <div v-if="status === 'submitted'" class="font-weight-bold">Отправлено на проверку</div>
                <div v-if="status === 'checked'" class="font-weight-bold">Прошло проверку</div>
                <b-button-group v-if="['not_started', 'draft'].indexOf(status) > -1">
                    <b-button @click="$emit('draft', prepareData())">Сохранить как черновик</b-button>
                    <b-button @click="$emit('submit', prepareData())" variant="primary">Отправить на проверку</b-button>
                </b-button-group>
            </div>
        </div>
    </div>
</template>

<script>
    import Summernote from "../Summernote";
    import FileUpload from "../FileUpload";
    export default {
        name: "ElementHomework",
        components: {
            FileUpload,
            Summernote,
        },
        props: {
            loading: {
                default: false,
                type: Boolean
            },
            status: String,
            content: String,
            attachments: Array,
        },
        data() {
            return {
                noteAttachments: this.attachments
                    ? this.attachments.map(a => {
                        return {...a, deleted: false}
                    })
                    : [],
                noteContent: this.content
            }
        },
        computed: {
            validAttachmentsCount() {
                return this.noteAttachments.filter(a => !a.deleted).length;
            }
        },
        methods: {
            prepareData() {
                return {
                    content: this.noteContent,
                    attachments: this.noteAttachments.filter(a => !a.deleted).map(a => a.id)
                }
            },
            onFileUpload(fileInfo) {
                this.noteAttachments.push({...fileInfo, deleted: false});
            },
            dropAttachment(attachment) {
                attachment.deleted = true;
            },
            restoreAttachment(attachment) {
                attachment.deleted = false;
            }
        }
    }
</script>

<style scoped>

</style>
