<template>
    <div class="col-xl-12">
        <div class="row">
            <div class="col-xl-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Данные профиля</h5>
                    </div>
                    <div class="ibox-content">
                        <validation-observer ref="observer" v-slot="{ passes }">
                            <b-form @submit.stop.prevent="passes(onSubmit)">
                                <ValidInput name="имя" label="Имя" v-model="user.firstName" :rules="{required: true, min: 3}" />
                                <ValidInput name="отчество" label="Отчество" v-model="user.middleName" :rules="{required: true, min: 3}" />
                                <ValidInput name="фамилия" label="Фамилия" v-model="user.lastName" :rules="{required: true, min: 3}" />
                                <ValidInput name="возраст" label="Возраст" v-model="user.age" :rules="{numeric: true}" />
                                <hr class="hr-line-dashed"/>
                                <ValidInput name="организация" label="Организация (университет, школа)" v-model="user.company" :rules="{min: 3}" />
                                <ValidInput name="должность" label="Должность" v-model="user.post" :rules="{min: 3}" />
                                <hr class="hr-line-dashed"/>
                                <ValidInput name="номер телефона" label="Номер телефона" v-model="user.phone" :rules="{min: 7, numeric: true}" />
                                <ValidInput name="страна" label="Страна" v-model="user.address" :rules="{required: true, min: 3}" />
                                <hr class="hr-line-dashed"/>
                                <b-row>
                                    <b-col md="8"></b-col>
                                    <b-col md="4">
                                        <b-btn-group class="btn-block">
                                            <b-btn type="submit" variant="primary"><i class="fa fa-save"></i> Сохранить</b-btn>
                                        </b-btn-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Подписка на рассылки уведомления</h5>
                    </div>
                    <div class="ibox-content">
                        <b-row>
                            <b-col>
                                <h4><i class="fa fa-envelope"></i> Рассылки:</h4>
                                <br/>
                                <valid-checkbox name="Новости проекта" vid="isNews" v-model="subscribe.isNews" />
                                <valid-checkbox name="Обновления по курсам" vid="isCourseNews" v-model="subscribe.isCourseNews" />
                                <valid-checkbox name="Обновления в чатах" vid="isChatMessaging" v-model="subscribe.isChatMessaging" />
                            </b-col>
                            <b-col>
                                <h4><i class="fa fa-bell"></i> Уведомления:</h4>
                                <br/>
                                <valid-checkbox name="Старт курса" vid="courseStart" v-model="subscribe.isCourseStart"  />
                                <valid-checkbox name="Старт модуля" vid="moduleStart" v-model="subscribe.isModuleStart" />
                                <valid-checkbox name="Начало тестирования" vid="startTests" v-model="subscribe.isTestStart" />
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Смена пароля</h5>
                    </div>
                    <div class="ibox-content">
                        <validation-observer ref="observer" v-slot="{ passes }">
                            <b-form @submit.stop.prevent="passes(onChangePassword)">
                                <ValidInput name="старый пароль" type="password" label="Старый пароль" vid="passwordOld" v-model="oldPassword" rules="required"/>
                                <hr class="hr-line-dashed" />
                                <ValidInput name="пароль" type="password" label="Новый пароль" vid="password" v-model="password" rules="required"/>
                                <ValidInput name="повторите пароль" type="password" label="Повторите новый пароль" v-model="plainPassword" rules="required|confirmed:password"/>
                                <hr class="hr-line-dashed" />
                                <b-row>
                                    <b-col md="8"></b-col>
                                    <b-col md="4">
                                        <b-btn-group class="btn-block">
                                            <b-btn type="submit" variant="primary"><i class="fa fa-save"></i> Сохранить</b-btn>
                                        </b-btn-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </div>
                </div>
                <div class="ibox">
                    <div class="ibox-title">
                        <h5>Смена email</h5>
                    </div>
                    <div class="ibox-content">
                        <validation-observer ref="observer" v-slot="{ passes }">
                            <b-form @submit.stop.prevent="passes(onChangeEmail)">
                                <ValidInput name="email" type="email" label="Новый email" v-model="newEmail" rules="required|email"/>
                                <hr class="hr-line-dashed" />
                                <b-row>
                                    <b-col md="8"></b-col>
                                    <b-col md="4">
                                        <b-btn-group class="btn-block">
                                            <b-btn type="submit" variant="primary"><i class="fa fa-save"></i> Сохранить</b-btn>
                                        </b-btn-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from "../../bus";
    import ValidInput from "../Validation/ValidInput";
    import ValidCheckbox from "../Validation/ValidCheckbox";
    import Actions from "./Actions";
    export default {
        name: "Settings",
        components: {ValidCheckbox, ValidInput},
        props: ['subscribe'],
        extends: Actions,
        data() {
            return {
                user: {
                    firstName: this.$auth.user().firstName,
                    middleName: this.$auth.user().middleName,
                    lastName: this.$auth.user().lastName,
                    phone: this.$auth.user().phone,
                    address: this.$auth.user().address,
                    post: this.$auth.user().post
                },
                password: null,
                plainPassword: null,
                oldPassword: null,
                newEmail: null
            }
        },
        methods: {
            onSubmit() {
                this.$http
                    .put('users/' + this.$auth.user().id, this.user)
                    .then((res) => {
                        this.user = res.data
                        this.$auth.user(this.user);
                        this.$toasted.global.appSuccess()
                        bus.$emit('settings-save');
                    })
            },
            onChangePassword() {
                this.$toasted.global.appSuccess()
            },
            onChangeEmail() {
                this.$toasted.global.appSuccess()
            },
            toBack() {
                bus.$emit('settings-save');
            }
        }
    }
</script>

<style scoped>

</style>
