<template>
    <div class="animated fadeIn">
        <bread-crumbs title="Активность" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <div class="ibox-title">
                    <h5>Активность</h5>
                </div>
                <ibox-content>
                    <div v-for="(active, key) in actions" class="stream-small" :key="key">
                        <span class="label" :class="getClassByType(active.type)"> {{ getTitleByType(active.type) }}</span>
                        <span class="text-muted" v-if="active.type !== types.ENTER_TYPE"> {{ active.title }} </span> / {{ active.date }} в {{ active.time }}, <router-link v-if="active.link && active.type !== types.ENTER_TYPE" :to="active.link">подробнее</router-link>
                    </div>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import Actions from "./Actions";
    import BreadCrumbs from "../BreadCrumbs";
    import {TYPES, TYPES_LIST} from "./actionsTypes";
    export default {
        name: "ActionsList",
        components: {BreadCrumbs, IboxContent, Ibox},
        extends: Actions,
        data() {
            return {
                tables: [],
                types: TYPES
            }
        },
        mounted() {
            this.getActions(50);
        },
        methods: {
            getClassByType(type) {
                let styleClass;
                switch (type) {
                    case TYPES.ENTER_TYPE:
                        styleClass = 'label-primary';
                        break;
                    case TYPES.VIDEO_TYPE:
                        styleClass = 'label-warning';
                        break;
                    case TYPES.COURSE_ENROLL_TYPE:
                        styleClass = 'label-success';
                        break;
                    case TYPES.COURSE_UN_ENROLL_TYPE:
                        styleClass = 'label-danger';
                        break;
                    default:
                        styleClass = 'label-default';
                        break;
                }
                return styleClass;
            },
            getTitleByType(type) {
                return TYPES_LIST[type];
            }
        }
    }
</script>

<style scoped>

</style>
