<script>
    import TYPES, {TYPES_LIST} from "./actionsTypes";

    export default {
        name: "Actions",
        render() {
            return '';
        },
        data() {
            return {
                actions: null
            }
        },
        methods: {
            addAction(type, title = null, link = this.$router.currentRoute.path) {

                if(type === TYPES.ENTER_TYPE) {
                    link = null;
                }

                if(!title) {
                    title = TYPES_LIST[type];
                }

                if(this.$auth.check() || type === TYPES.ENTER_TYPE) {
                    this.axios.post('user_activity_logs', {
                        link: link,
                        title: title,
                        type: type
                    })
                }
            },
            getActions(limit = 3) {
                if(this.$auth.check()) {
                    this.axios.get('user_activity_logs/my?limit=' + limit).then(res => {
                        this.actions = res.data.data
                    })
                }
            }
        }
    }
</script>
