<template>
    <div>
        <div>


            <p></p>
            <form class="form-inline">
                <div class="form-group">
                    <label for="constructor-union-choice"
                           style="font-size: 120%;"
                           class="control-label">Сейчас во всех колонках &nbsp;
                    </label>
                    <select id="constructor-union-choice"
                            class="form-control"
                            title="ds"
                            v-model="task.union">
                        <option :value="true" selected>общий список ответов</option>
                        <option :value="false">отдельные списки</option>
                    </select>
                </div>
            </form>
        </div>
        <table class="constructor-match-t table table-condensed"
               style="table-layout: fixed;">
            <thead>
            <th width="50">
                Заголовки<br/>колонок:
            </th>
            <th width="50"
                tabindex="-1">
                <button class="btn btn-light"
                        style="width: 100%"
                        v-if="task.cells.length === 0"
                        title="Добавить колонку (клавиша End)"
                        @click="addColumn()">
                    <i class="icofont icofont-ui-add"></i><span v-if="task.columns.length > 0"> &nbsp;</span>
                </button>
            </th>
            <th tabindex="-1" v-for="(column, k) in task.columns" :key="k">
                <input type="text"
                       :id="'con_' + id +'_colhead_' + k"
                       v-model="task.columns[k]"
                       class="form-control"
                       @keyup.end="addColumn($event, true)"
                       @keyup.enter="addRow()"
                />
                <div style="width: 100%; text-align: right">
                    <button class="btn btn-light"
                            style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                            tabindex="-1"
                            @click="deleteColumn(k)">
                        <i class="icofont icofont-delete" style="font-size: 120%;"></i> удалить колонку
                    </button>
                </div>
            </th>
            </thead>
            <tbody>
            <tr v-for="(row, r) in task.cells" :key="r">
                <td>
                    <div style="width: 100%; text-align: right">
                        <button class="btn btn-light"
                                style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                                tabindex="-1"
                                @click="deleteRow(r)">
                            <i class="icofont icofont-delete-alt" style="font-size: 120%;"></i> удалить<br/>строку
                        </button>
                    </div>
                </td>
                <td></td>
                <td v-for="(column, k) in task.columns" :key="k">
                    <div class="form-group">
                        <label :for="'is_static_col' + k + '_row' + r">Текст вопроса?</label>
                        <input type="checkbox"
                               :id="'is_static_col' + k + '_row' + r"
                               :name="'is_static_col' + k + '_row' + r"
                               v-model="task.cells[r][k].isStaticText"
                        />
                        <div class="constructor-explain">
                            <div v-if="task.cells[r][k].isStaticText">
                                <span>статический текст задания</span><br/>
                                <textarea rows="2"
                                          class="form-control"
                                          placeholder="Введите статический текст задания"
                                          v-model="task.cells[r][k].text"
                                          @keyup.enter="addRow()"
                                ></textarea>
                            </div>
                            <div v-if="!task.cells[r][k].isStaticText">
                                <quill-editor v-on:keyup.ctrl.enter="addChoice()"
                                              :options="quillOptions"
                                              :globalOptions="quillGlobalOptions"
                                              v-model="answer.grid[r][k]"></quill-editor>
                            </div>
                        </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td :colspan="2 + task.columns.length"
                    style="text-align: center">
                    <button class="btn btn-link"
                            @click="addRow()"
                            style="width: 100%;
                  font-size: 140%;">
                        <i class="icofont icofont-chart-flow-alt-2"></i> Добавить строку (Enter)
                    </button>
                    <br/><br/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import ConstructorBase from "./ConstructorBase";
    export default {
        name: "ConstructorMatchAny",
        extends: ConstructorBase,
        data() {
            return {
                newEntities: []
            }
        },
        mounted () {
            for (let i = 0; i < this.task.cells.length; i++) {
                if (!this.answer.grid[i]) {
                    this.answer.grid.push([])
                }
            }
        },
        methods: {
            addColumn () {
                if (this.task.cells.length === 0) {
                    this.task.columns.push('')
                    this.task.choices.push([])
                    this.newEntities.push('')
                }
            },
            addRow () {
                let row = []
                let answerRow = []
                for (let i = 0; i < this.task.columns.length; i++) {
                    row.push({ isStaticText: true, text: '' })
                    answerRow.push('')
                }
                this.task.cells.push(row)
                this.answer.grid.push(answerRow)
            },
            deleteRow (key) {
                if (confirm('Вы действительно хотите удалить строку?')) {
                    this.task.cells.splice(key, 1)
                }
            },
            deleteColumn (key) {
                if (confirm('Вы действительно хотите удалить колонку?')) {
                    for (let i = 0; i < this.task.cells.length; i++) {
                        this.task.cells[i].splice(key, 1)
                    }
                    this.task.columns.splice(key, 1)
                }
            },
            addChoiceToChoices (col) {
                if (this.task && this.task.choices[col]) {
                    this.task.choices[col].push(this.newEntities[col])
                    this.newEntities[col] = ''
                    this.$emit('input', this.task)
                }
            },
            deleteChoiceFromChoices (col, key) {
                if (confirm('Удалить вариант ответа?')) {
                    if (this.task && this.task.choices[col]) {
                        this.task.choices[col].splice(key, 1)
                        this.$emit('input', this.task)
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
