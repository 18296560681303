import { render, staticRenderFns } from "./NeuroList.vue?vue&type=template&id=241a91a3&scoped=true&"
import script from "./NeuroList.vue?vue&type=script&lang=js&"
export * from "./NeuroList.vue?vue&type=script&lang=js&"
import style0 from "./NeuroList.vue?vue&type=style&index=0&id=241a91a3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241a91a3",
  null
  
)

export default component.exports