<template>
    <ibox>
        <ibox-title>
            Фильтры
        </ibox-title>
        <ibox-content>
            <b-row>
                <b-col>
                    <b-form-group
                            label="Регион"
                            label-for="type-input"
                            invalid-feedback="Регион обязательно!"
                    >
                        <multiselect
                                v-model="filters.region"
                                :multiple="false"
                                :searchable="true"
                                :options="regions"
                                openDirection="bottom"
                                :loading="isRegionsLoading"
                                @search-change="findRegions"
                                @select="loadRegionCities"
                                placeholder="Введите регион"
                                track-by="id"
                                :custom-label="opt => opt.regionNameRu"
                        >
                            <template slot="noOptions">Регион не найден</template>
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Город">
                        <multiselect
                                v-model="filters.city"
                                :disabled="!filters.region"
                                :multiple="false"
                                :searchable="true"
                                :options="cities"
                                openDirection="bottom"
                                :loading="isRegionsLoading"
                                placeholder="Введите город"
                                track-by="id"
                                :custom-label="(opt) => opt.name"
                        >
                            <template slot="noOptions">Город не найден</template>
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <valid-select :disabled="!filters.city" v-model="filters.institution" :multiple="false" label="Организация" track-by="id" :options="institutions" label-title="shortName" />
                </b-col>
<!--                <b-col v-if="projects">
                    <valid-select v-model="filters.project" track-by="id" label="Проект обучения" label-title="title" :options="projects" />
                </b-col>-->
            </b-row>
            <hr class="hr-line-dashed" />
            <b-row>
                <b-col v-if="courses">
                    <valid-select label="Курс" v-model="filters.courses" track-by="id" label-title="name" :options="courses" />
                </b-col>
<!--                <b-col v-if="categories">
                    <valid-select label="Диагностика" track-by="id" v-model="filters.category" label-title="name" :options="categories" />
                </b-col>-->
                <b-col v-if="categories">
                    <valid-select label="Тест" track-by="id" v-model="filters.tests" label-title="name" :options="categories" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <valid-date-range v-model="filters.date" :rules="{}" label="Даты обучения"/>
                </b-col>
            </b-row>
            <hr class="hr-line-dashed" />
            <b-row>
                <b-col>
                    <valid-select label="Пользователи" :options="users" track-by="id" label-title="userFullname" v-model="filters.users" />
                </b-col>
                <b-col>
                    <valid-select label="Кодификаторы" :options="codifiers" track-by="id" label-title="title" v-model="filters.codifiers" />
                </b-col>
            </b-row>
            <hr class="hr-line-dashed" />
            <label class="text-muted">Анкетные данные:</label>
            <b-row>
                <b-col>
                    <valid-select label="Возраст" v-model="filters.age" :options="agesOptions" label-title="label" track-by="value" />
                </b-col>
<!--                <b-col>
                    <valid-select label="Департамент" v-model="filters.department" :options="departmentsOptions" label-title="label" track-by="value" />
                </b-col>
                <b-col>
                    <valid-select label="Отдел" v-model="filters.section" :options="['Управление', 'Начальная школа', 'Русский язык и литература', 'Обслуживающий персонал']" />
                </b-col>-->
            </b-row>
        </ibox-content>
        <div class="ibox-footer">
            <b-row>
                <b-col>
                    <b-btn-group>
                        <b-btn variant="success" @click="applyFilter()"><i class="fa fa-play"></i> Применить</b-btn>
                        <b-btn variant="warning" @click="filters.showTable = !filters.showTable" :class="!filters.showTable ? 'active' : ''"><i class="fa fa-line-chart"></i> Показать диаграммы</b-btn>
                        <b-btn variant="danger" @click="clearFilters()"><i class="fa fa-close"></i> Очистить</b-btn>
                        <b-btn class="pull-right" size="xs" v-b-modal.downloadModal variant="primary">
                            <i class="fa fa-file-excel-o"></i> Скачать
                        </b-btn>
                    </b-btn-group>
                </b-col>
            </b-row>
        </div>
    </ibox>
</template>

<script>
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import ValidSelect from "../Validation/ValidSelect";
    import ValidDateRange from "../Validation/ValidDateRange";
    import bus from "../../bus";

    export default {
        name: "StatFilterCodifier",
        components: {ValidDateRange, ValidSelect, IboxContent, IboxTitle, Ibox},
        props: ['users', 'codifiers'],
        data() {
            return {
                filters: {
                    regions: null,
                    city: null,
                    institutions: null,
                    showTable: true
                },
                isRegionsLoading: false,
                institutions: [],
                categories: null,
                regions: [],
                cities: [],
                projects: [],
                courses: [],
                agesOptions: [
                    {label: 'до 25', value: '25'},
                    {label: 'до 30', value: '30'},
                    {label: 'до 40', value: '40'},
                    {label: 'свыше 50', value: '50'},
                ],
                departmentsOptions: [
                    [
                        {label: 'Управление', value: 1},
                        {label: 'Начальная школа', value: 2},
                        {label: 'Русский язык и литература', value: 3},
                        {label: 'Обслуживающий персонал', value: 4},
                    ]
                ]
            }
        },
        mounted() {
            this.axios.get('institutions', {params: {'isPublic': true}}).then(res => {
                this.institutions = res.data;
            });
            this.axios.get('projects').then(res => {
                this.projects = res.data;
            });
            this.axios.get('courses').then(res => {
                this.courses = res.data;
            });
            this.axios.get('sdpk/get/TestCategory/all').then(res => {
                this.categories = res.data;
            });
            this.axios.get('regions').then(res => {
                this.regions = res.data;
                bus.$emit('regions:load', res.data);
            });
            this.axios.get('institutions').then(res => {
                this.institutions = res.data;
                bus.$emit('institutions:load', res.data);
            })
        },
        methods: {
            applyFilter() {
                this.$emit('apply', this.filters);
            },
            findRegions(query) {
                if(query !== '') {this.isRegionsLoading = true;
                    this.axios.get(`regions/?name=${query}`).then(res => {
                        this.isRegionsLoading = false;
                        this.regions = res.data;
                    });
                }
            },
            clearFilters() {
                this.filters = {
                    regions: null,
                    city: null,
                    institutions: null,
                    showTable: true
                };
            },
            loadRegionCities(region) {
                this.filters.city = null;
                if (!region) {
                    this.cities = [];
                    return;
                }
                this.axios.get(`cities?region=${region.id}`)
                    .then(res => {
                        this.cities = res.data
                    });
            },
        },
        watch: {
            filters: {
                handler(val) {
                    bus.$emit('filter:change', val);
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>
