<template>
    <div class="footer">
        <div class="pull-right">

        </div>
        <div>
            <strong>ОКО</strong> © {{ new Date().getFullYear() }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterVue"
    }
</script>
