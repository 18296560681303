import { render, staticRenderFns } from "./ProjectUser.vue?vue&type=template&id=e4ba883e&scoped=true&"
import script from "./ProjectUser.vue?vue&type=script&lang=js&"
export * from "./ProjectUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ba883e",
  null
  
)

export default component.exports