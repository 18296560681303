<template>
  <div class="animated fadeIn">
    <b-modal title="Функционал находится в разработке"
             :hide-footer="true"
             size="xs"
             id="underconstruct">
    </b-modal>
    <ibox>
      <ibox-title>
        Фильтры
      </ibox-title>
      <ibox-content>
        <b-row style="margin-bottom: 10px;">
          <b-col lg="12">
            <label>Проект</label>
            <multiselect
                track-by="id"
                v-model="filters.project"
                :multiple="false"
                :searchable="false"
                placeholder="Выберите проект"
                :options="projects"
                :custom-label="(opt) => opt.title"
                @input="selectProject"
            ></multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" v-if="filters.project && subjects">
            <label>Предмет</label>
            <multiselect
                track-by="id"
                v-model="filters.subjects"
                :multiple="true"
                :searchable="true"
                placeholder="Выберите предмет"
                :options="subjects"
                :custom-label="(opt) => opt.name"
            ></multiselect>
            <valid-checkbox name="Выбрать все предметы" vid="homework" v-model="subjectsAll" />
          </b-col>
          <b-col lg="4" v-if="filters.project && tests">
            <label>Компетенции (тесты)</label>
            <multiselect
                track-by="id"
                v-model="filters.tests"
                :multiple="true"
                :searchable="true"
                placeholder="Выберите предмет"
                :options="tests"
                :custom-label="(opt) => opt.name"
            ></multiselect>
          </b-col>
          <b-col lg="6">
            <label>Период</label>
            <el-date-picker
                v-model="range"
                type="datetimerange"
                format="dd.MM.yyyy H:mm"
                range-separator="до"
                start-placeholder="Начало"
                end-placeholder="Завершение">
            </el-date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" v-if="filters.project && regions">
            <label>Регион</label>
            <multiselect
                v-model="filters.region"
                track-by="id"
                :multiple="false"
                :disabled="disabled"
                :searchable="false"
                placeholder="Выберите регион"
                :options="regions"
                :custom-label="(opt) => opt.name"
                @input="selectRegion"
            ></multiselect>
          </b-col>
          <b-col lg="6" v-if="filters.region && districts">
            <label>Район</label>
            <multiselect
                v-model="filters.district"
                :multiple="false"
                :disabled="disabled"
                :searchable="false"
                placeholder="Выберите район"
                :options="districts"
                :custom-label="(opt) => opt.name"
                @input="selectDistrict"
            ></multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" v-if="filters.district && organizations">
            <label>Организация</label>
            <multiselect
                track-by="id"
                v-model="filters.organization"
                :multiple="false"
                :searchable="false"
                label="Организация"
                placeholder="Выберите организацию"
                :options="organizations"
                :custom-label="(opt) => opt.name"
            ></multiselect>
          </b-col>
        </b-row>
      </ibox-content>
      <ibox-footer class="clearfix text-center">
        <b-btn-group>
          <b-btn :disabled="filters.project === null" variant="success" @click="applyFilter()"><i class="fa fa-play"></i> Применить</b-btn>
<!--          <b-btn variant="primary" @click="$bvModal.show('underconstruct')"><i class="fa fa-file-excel-o"></i> Скачать</b-btn>-->
          <b-btn variant="danger" @click="clearFilter()"><i class="fa fa-close"></i> Очистить</b-btn>
        </b-btn-group>
      </ibox-footer>
    </ibox>
    <router-view :filters="normalizationFilter" :key="$route.fullPath"/>
  </div>
</template>

<script>
import Ibox from "../Ibox/Ibox";
import IboxTitle from "../Ibox/IboxTitle";
import IboxContent from "../Ibox/IboxContent";
import IboxFooter from "../Ibox/IboxFooter";
import bus from "../../bus";
import ValidCheckbox from "../Validation/ValidCheckbox";
export default {
name: "Anal",
  components: {ValidCheckbox, IboxFooter, IboxContent, IboxTitle, Ibox},
  data() {
    return {
      range: [],
      filters: {
        project: null,
        region: null,
        district: null,
        organization: null,
        subjects: null,
        tests: null
      },
      disabled: false,
      organization: null,
      tests: null,
      subjects: null,
      subjectsAll: false,
      regions: null,
      districts: null,
      organizations: null,
      projects: this.$auth.user().projects
    }
  },
  computed: {
    normalizationFilter() {
      return {
        startDate: this.range.length ? this.range[0] : null,
        endDate: this.range.length ? this.range[1] : null,
        project: this.filters.project ? this.filters.project.id : null,
        region: this.filters.region ? this.filters.region.id : null,
        tests: this.filters.tests ? this.filters.tests.map(sbj => sbj.id) : [],
        district: this.filters.district ? this.filters.district.id : null,
        organization: this.filters.organization ? this.filters.organization.id : null,
        subjects: this.filters.subjects ? this.filters.subjects.map(sbj => sbj.id) : []
      }
    }
  },
  mounted() {
    this.filters.project = this.projects[this.projects.length - 1];
    this.selectProject()
    this.axios.get('user_projects', {
      params: {
        'filters[user]': this.$auth.user().id,
        'filters[project]': this.filters.project.id
      }
    }).then(res => {
      if(this.$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_CUSTOMER'])) {
        return;
      }
      if(res.data.data.length) {
        this.filters.region = res.data.data[0].region
        this.filters.district = res.data.data[0].district
        this.disabled = true
        this.districts = [this.filters.district]
        this.axios.get('project_organizations', {
          params: {
            region: this.filters.region.id,
            district: this.filters.district.id,
          }
        }).then(res => {
          this.organizations = res.data
        })
      }
    })
  },
  methods: {
    selectProject() {
      this.axios.get(`projects/${this.filters.project.id}`).then(res => {
        this.axios.get(`projects/${this.filters.project.id}/regions`).then(res => {
          this.regions = res.data;
          this.axios.get(`projects/${this.filters.project.id}/subjects`).then(res => {
            this.subjects = res.data;
          });
        })
        let project = res.data
        let projectTests = [...project.enter, ...project.exit];
        if(projectTests.length) {
          this.tests = [];
          projectTests.forEach(category => {
            console.log(category)
            this.axios
                .get('sdpk/get/Test/headers/' + category.id)
                .then(res => {
                  this.tests = [...res.data];
                })
          })
        }
      })
    },
    selectRegion() {
      this.districts = this.filters.region.districts;
    },
    selectDistrict() {
      this.filters.organization = null;
      this.organizations = this.filters.district.organizations;
    },
    applyFilter() {
      bus.$emit('filter-changed');
    },
    clearFilter() {
      let project = Object.assign({}, this.filters.project);
      this.filters = {
        project: project,
        region: null,
        district: null,
        organization: null,
        subjects: null,
        limit: 30,
        offset: 0
      }
      this.range = [];
      bus.$emit('filter-cleared');
    }
  },
  watch: {
    subjectsAll(val) {
      if(val) {
        this.filters.subjects = this.subjects
      } else {
        this.filters.subjects = null
      }
    }
  }
}
</script>

<style scoped>

</style>
