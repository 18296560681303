<template>
    <div v-if="diagnostics && diagnostics.length" class="animated fadeIn">
        <bread-crumbs title="Моя диагностика" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div v-if="diagnostics.length && !loading" class="wrapper-content wrapper">
            <ibox v-for="(diagnostic, key) in diagnostics" :key="key">
                <ibox-title>
                    {{diagnostic.title}}
                </ibox-title>
                <ibox-content>
                    <div id="vertical-timeline" class="vertical-container dark-timeline center-orientation">
                        <div v-for="(enter, key) in diagnostic.enter" :key="key" class="vertical-timeline-block">
                            <div class="vertical-timeline-icon navy-bg">
                                <i class="fa fa-stethoscope"></i>
                            </div>
                            <div class="vertical-timeline-content">
                                <h2>Входная диагностика</h2>
                                <div class="table-bordered table-responsive text-center">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Количество тестов</th>
                                            <th>Тестов сдано</th>
                                            <th>Результат</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                {{ enter.testsСount }}
                                            </td>
                                            <td>
                                                {{ results[diagnostic.id].length }}
                                            </td>
                                            <td>
                                            <span v-if="getResult(results[diagnostic.id])">
                                                {{ getResult(results[diagnostic.id]) }}
                                            </span>
                                                <span v-else>
                                                -
                                            </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <router-link :to="`test-categories/${enter.id}`" class="btn btn-sm btn-primary"><i class="fa fa-list"></i> К тестам</router-link>
                                <span class="vertical-date">
                                    <small>{{ diagnostic.startDate | formatDate }}</small>
                                </span>
                            </div>
                        </div>
                        <template v-if="results[diagnostic.id].length">
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon bg-warning">
                                    <i class="fa fa-warning"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <h2>
                                        До следующего уровня не хватает: {{ getLevel(getResult(results[diagnostic.id])) }} {{ getLevel(getResult(results[diagnostic.id])) | pluralize('ru', ['балл', 'балла', 'баллов']) }}
                                    </h2>
                                    <span class="vertical-date"></span>
                                </div>
                            </div>
                        </template>
                        <template v-if="results[diagnostic.id].length">
                            <div v-for="(exit, key) in diagnostic.exit" :key="key" class="vertical-timeline-block">
                                <div class="vertical-timeline-icon bg-danger">
                                    <i class="fa fa-stethoscope"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <h2>Выходная диагностика</h2>
                                    <h3>{{ exit.name }}</h3>
                                    <p>
                                        Количество тестов: {{ exit.testsСount }}
                                    </p>
                                    <router-link :disabled="true" :to="`test-categories/${exit.id}`" class="btn btn-sm btn-danger"><i class="fa fa-list"></i> Приступить</router-link>
                                    <span class="vertical-date">
                                     <br/>
                                    <small>{{ diagnostic.endDate | formatDate }}</small>
                                </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </ibox-content>
            </ibox>
        </div>
        <div v-else class="wrapper wrapper-content">
            <ibox>
                <ibox-content>
                    <h3 style="margin:0;">Диагностика пока не назначена</h3>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";
    import Ibox from "../Ibox/Ibox";
    export default {
        name: "Diagnostic",
        components: {IboxTitle, IboxContent, BreadCrumbs, Ibox},
        data() {
            return {
                diagnostics: null,
                results: null,
                loading: true,
                trajectories: null
            }
        },
        mounted() {
            this.axios.get('projects').then(res => {
                this.diagnostics = res.data;
                this.results = {};
                this.axios.get('trajectories').then(res => {
                    this.trajectories = res.data;
                });
                res.data.forEach((diagnost, key) => {
                    if(diagnost.enter && diagnost.enter.length) {
                        diagnost.enter.forEach(category => {
                            this.results[diagnost.id] = [];
                            this.axios.get(`sdpk/get/Test/headers/${category.id}`).then(res => {
                                if(res.data.length) {
                                    res.data.forEach(test => {
                                        this.axios
                                            .get(`sdpk/test/result/by-test/${test.id}`).then(res => {
                                                if(res.data.id) {
                                                    this.results[diagnost.id].push(res.data);
                                                }
                                                console.log(this.diagnostics.length);
                                                if(this.diagnostics.length === key+1) {
                                                    this.loading = false;
                                                }
                                            })
                                    })
                                }
                            });
                        })
                    }
                })
            })
        },
        methods: {
            getResult(result) {
                let avg = 0;
                result.forEach(res => {
                    avg += res.mark;
                });
                return result.length ? 100*(avg/result.length).toFixed(2) : 0
            },
            getLevel(result) {
                if(result < 60) {
                    return Math.ceil(60-result);
                }
                if(result < 80) {
                    return Math.ceil(80-result);
                }
                return Math.ceil(100-result);
            }
        }
    }
</script>

<style scoped>

</style>
