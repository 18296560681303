<template>
  <vue-editor v-model="model" :rows="rows" :disabled="disabled"/>
</template>

<script>
export default {
  name: "Summernote",
  props: ["value", 'rows', 'disabled'],
  data() {
    return {
      model: ''
    };
  },
  mounted() {
    this.model = this.value
  },
  watch: {
    model(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>
