<template>
    <bar-chart chart-id="combo" v-if="chartData" :chart-data="chartData" :options="chartOptions" />
</template>

<script>
    import BarChart from "../Charts/BarChart";
    export default {
        name: "NeuroChart",
        components: {BarChart},
        props: ['data'],
        data() {
            return {
                chartData: null,
                chartOptions: null
            }
        },
        mounted() {
            let avg = this.data.map(data => {
                return data.avg
            })
            let userAvg = this.data.map(data => {
                return data.user_avg
            })
            let avgPadding = []
            let diff =this.data.map(data => {
                let avgDiff = data.avg - data.user_avg;
                avgPadding.push(avgDiff > 0 ? data.avg : data.avg + avgDiff);
                return parseFloat(Math.abs(avgDiff).toFixed(2))
            })
            this.chartData = {
                labels: ['Оценка стресса', 'Оценка концентрации', 'Оценка увлеченности', 'Оценка когнитивной нагрузки'],
                datasets: [
                    {
                        data: avgPadding,
                        backgroundColor: 'transparent',
                        label: ''
                    },
                {
                    type: 'line',
                    label: 'Общая средняя оценка',
                    borderColor: 'rgb(75, 192, 192)',
                    borderWidth: 2,
                    fill: false,
                    lineTension: 0.5,
                    data: avg
                },
                {
                    type: 'bar',
                    label: 'Средняя оценка пользователя',
                    backgroundColor: 'rgb(255, 99, 132)',
                    data: userAvg.map((uAvg, index) => {
                        return (uAvg - diff[index]).toFixed(2);
                    }),
                    borderColor: 'white',
                    borderWidth: 2
                }]
            }
            this.chartOptions = {
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        stacked: true,
                    }],
                    xAxes: [{
                        stacked: true,
                        barPercentage: 0.75
                    }]
                }
            }
        }
    }
</script>

<style scoped>

</style>
