<template>
    <div class="animate fadeIn">
        <bread-crumbs title="Проекты" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    <h5>Проекты</h5>
                    <div class="ibox-tools">
                        <router-link to="/admin/projects/add" v-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST'])" class="btn btn-primary btn-xs">Добавить новый поток</router-link>
                    </div>
                </div>
                <div class="ibox-content">
                    <b-card>
                        <b-table :fields="fields" :items="projects">
                            <template v-slot:cell(startDate)="data">
                                {{data.item.startDate | formatDate}}
                            </template>
                            <template v-slot:cell(endDate)="data">
                                {{data.item.endDate | formatDate}}
                            </template>
                            <template v-slot:cell(actions)="data">
                                <b-btn-group>
                                    <router-link v-if="data.item.landing && data.item.link" class="btn btn-warning" :to="`${data.item.link}`">
                                        <i class="fa fa-eye"></i>
                                    </router-link>
                                    <router-link class="btn btn-success" :to="`/admin/projects/${data.item.id}/edit`">
                                        <i class="fa fa-edit"></i>
                                    </router-link>
                                    <router-link class="btn btn-warning" :to="`/admin/projects/${data.item.id}/project-users`">
                                      <i class="fa fa-users"></i>
                                    </router-link>
                                    <b-btn @click="confirmDelete(data.item)" class="btn btn-danger"><i class="fa fa-trash"></i></b-btn>
                                    <b-btn @click="downloadBill(data.item.id)" class="btn btn-primary"><i class="fa fa-file-excel-o"></i></b-btn>
                                </b-btn-group>
                            </template>
                        </b-table>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    export default {
        name: "ProjectList",
        components: {BreadCrumbs},
        data() {
            return {
                projects: null,
                fields: [
                    {
                        key: 'title',
                        label: 'Название'
                    },
                    {
                        key: 'startDate',
                        label: 'Дата начала',
                        sortable: true
                    },
                    {
                        key: 'endDate',
                        label: 'Дата завершения',
                        sortable: false
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.axios.get(`project-list`).then(res => {
                    this.projects = res.data.data;
                })
            },
            confirmDelete(project) {
                this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить поток?', {
                    title: 'Удаление потока «' + project.title + '»',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(res => {
                        if(res) {
                            this.axios.delete('projects/' + project.id).then(() => {
                                this.load()
                            });
                        }
                    })
            },
            downloadBill(projectId) {
                window.open(`${this.axios.defaults.baseURL}pdf/project_bill/${projectId}?access_token=${this.$auth.token()}`, 'Download');
            }
        }
    }
</script>

<style scoped>

</style>
