<template>
    <div v-if="project && project.landing" class="animated fadeIn">
      <b-modal title="Заполните анкету" :hide-footer="true" v-if="project" id="questions">
        <questionnarie :project="project" />
      </b-modal>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title :hide-tools="true">
                    <h1>{{ project.title }}</h1>
                </ibox-title>
                <ibox-content v-if="project.landing">
                    <span v-if="project.landing.promo">
                        <Video :media="project.landing.promo" />
                        <hr class="hr-line-dashed"/>
                    </span>
                    <span v-if="project.landing.text" v-html="project.landing.text"></span>
                    <section v-if="project.landing.courses && project.landing.courses.length" id="team" class="team gray-section">
                        <div class="container">
                            <div class="row hot-courses d-flex justify-content-center">
                                <b-col md="3" v-for="(course, key) in project.landing.courses" :key="key">
                                    <div class="wow zoomIn bg-white course-row">
                                        <div v-if="course.cover && course.cover.thumbs" class="img-fluid" :style="{'background-image': `url(${course.cover.thumbs.preview})`}"></div>
                                        <div v-else class="img-fluid"></div>
                                        <h3><span class="navy">{{course.name}}</span></h3>
                                        <p class="text-left">{{ course.shortDescription | truncate(100, '...') }}</p>
                                        <ul class="list-inline">
                                            <li class="list-inline-item">
                                                <router-link class="btn btn-primary btn-xs" :to="`/admin/course/${course.id}`">
                                                    Смотреть
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </b-col>
                            </div>
                        </div>
                    </section>
                  <section v-if="tests" id="team-tests" class="team gray-section">
                    <div class="container">
                      <div class="row hot-courses d-flex justify-content-center">
                        <b-col md="3" v-for="(test, key) in tests" :key="key">
                          <div class="wow zoomIn bg-white course-row">
                            <h3><span class="navy">{{test.name}}</span></h3>
                            <p class="text-left">{{ getCategory(test.category.id) }}</p>
                            <ul class="list-inline">
                              <li class="list-inline-item">
                                <b-btn @click="checkAccess(test.id)" class="btn btn-primary btn-xs">
                                  Пройти тест
                                </b-btn>
                              </li>
                            </ul>
                          </div>
                        </b-col>
                      </div>
                    </div>
                  </section>
                </ibox-content>
                <div v-if="!$auth.token()" class="ibox-footer text-center">
                    <router-link to="/admin/registration" class="btn btn-lg btn-primary">Зарегистрироваться</router-link>
                </div>
            </ibox>
        </div>
    </div>
</template>

<script>
    import IboxContent from "../components/Ibox/IboxContent";
    import Ibox from "../components/Ibox/Ibox";
    import IboxTitle from "../components/Ibox/IboxTitle";
    import Video from "../components/Video";
    import Questionnarie from "../components/Course/Questionnarie";
    import bus from "../bus";
    export default {
        name: "ProjectLanding",
        components: {Questionnarie, Video, IboxTitle, Ibox, IboxContent},
        data() {
            return {
                region: null,
                project: null,
                cities: [],
                regions: [],
                tests: null,
                isRegionsLoading: true,
                isAccess: false
            }
        },
        beforeCreate() {
            this.axios.get(`/projects/${this.$route.params.id}`).then(res => {
                this.project = res.data;

                if(!this.project.landing.isActive) {
                  this.$router.push('/admin/403');
                }

                if(this.project.isClosed && !this.$route.query.token || this.$route.query.token && this.project.link === '/landing/' + this.project + '?token=' + this.$route.query.token ) {
                    this.$router.push('/admin/login');
                    this.$toasted.global.appError('Ошибка доступа');
                }

              if(this.project && this.project.landing && this.project.landing.testCategories) {
                this.tests = [];
                this.project.landing.testCategories.forEach(category => {
                  this.axios
                      .get('sdpk/get/Test/headers/' + category.id)
                      .then(res => {
                        this.tests = [...res.data];
                      })
                })
              }
              if(this.$auth.user()) {
                this.axios.get('user_projects', {
                  params: {
                    user: this.$auth.user().id,
                    project: this.project.id
                  }
                }).then((res) => {
                  if(res.status === 200 && res.data.length) {
                    this.isAccess = true;
                  }
                });
              }
            })
        },
        mounted() {
          bus.$on('enroll', () => {
            this.$bvModal.hide('questions');
          });
        },
        methods: {
            checkAccess(testId) {
              if(!this.$auth.user()) {
                return this.$router.push(`/admin/login`)
              }
              if(this.isAccess) {
                return this.$router.push(`/admin/tests-construct/${testId}`)
              }
              return this.$bvModal.show('questions');
            },
            getCategory(id) {
              if(this.project.landing.testCategories) {
                let category = [];
                category = this.project.landing.testCategories.filter(category => category.id === id)
                return category.length ? category[0].name : '';
              }
            },
            findRegions(query) {
                if(query !== '') {
                    this.isRegionsLoading = true;
                    this.axios.get(`regions/?name=${query}`).then(res => {
                        this.isRegionsLoading = false;
                        this.regions = res.data;
                    });
                }
            },
            loadRegionCities(region, fieldId) {
               if (!region) {
                    this.cities = [];
                    return;
                }
                this.axios.get(`cities?region=${region.id}`)
                    .then(res => {
                        this.user.userProject.profile[fieldId] = null;
                        this.cities = res.data
                    });
            }
        }
    }
</script>

<style scoped>
    div.img-fluid {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
    }
</style>
