<template>
    <b-modal
             id="search-access"
             ref="modal"
             button-size="sm"
             title="Предоставление доступа"
             :hide-footer="true"
    >

        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(onSubmit)">
                <validation-provider name="Роль" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Роль">
                        <b-form-select
                                v-model="form.role"
                                :options="roles"
                                :state="valid(validationContext)"
                                class="form-control"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Пользователь" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Пользователь">
                        <el-row class="demo-autocomplete">
                            <el-col :span="24">
                                <multiselect
                                        v-model="selected.username"
                                        :multiple="false"
                                        :searchable="true"
                                        :options="users.map(c => c.id)"
                                        track-by="id"
                                        placeholder="Начните вводить ФИО"
                                        label="fullname"
                                        openDirection="bottom"
                                        :loading="isUserLoading"
                                        @search-change="searchUser"
                                        @select="handleSelect"
                                        :custom-label="prepareUsers"
                                ></multiselect>
                            </el-col>
                        </el-row>
                        <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <b-button variant="success" type="submit">Отправить</b-button>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import bus from "../bus";
    export default {
        name: "SearchUser",
        props: ['institute'],
        data() {
            return {
                users: [],
                links: [],
                isUserLoading: false,
                form: {
                    institution: '/institutions/' + this.institute.id
                },
                selected: {},
                roles: [
                    {text: 'Методист', value: 'ROLE_METHODIST'},
                    {text: 'Преподаватель', value:'ROLE_TUTOR'}
                ]
            };
        },
        mounted() {
            if(this.$auth.check('ROLE_ADMIN')) {
                this.roles.push({text: 'Руководитель', value:'ROLE_CUSTOMER'})
            }
        },
        methods: {
            searchUser(query) {
                if(query !== '') {
                    this.isUserLoading = true;
                    this.axios.get(`users/?lastName=${query}&firstName=${query}&middleName=${query}&username=${query}`).then(res => {
                        this.isUserLoading = false
                        this.users = res.data;
                    });
                }
            },
            prepareUsers(opt) {
                const user = this.users.find(x => x.id == opt);
                return user ? user.fullname + ' (' + user.username + ')' : undefined;
            },
            asyncFind (query) {
                this.isUserLoading = true;
                this.axios.get(`users/?username=${query}`).then(res => {
                    this.isUserLoading = false;
                    this.users = res.data;
                });
            },
            handleSelect(item) {
                this.form.user = item;
                this.selected = {
                    username: item.username
                }
            },
            onSubmit() {
                this.axios.post('user_institutions', this.form).then(() => {
                    bus.$emit('user-added')
                    this.$bvModal.hide('search-access')
                    this.form = {
                        institution: '/institutions/' + this.institute.id
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .el-autocomplete-suggestion {
        z-index: 10000;
    }
    .el-autocomplete {
        display: block;
    }
</style>
