<template>
    <div class="animated fadeIn" v-if="test">
        <bread-crumbs title="Результаты теста" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Моя диагностика', url: '/admin/diagnostic'}, {title: test.test.name, url: `/tests-construct/${test.test.id}`}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    <h3>Результаты теста &laquo;{{ test.test.name }}&raquo;</h3>
                </ibox-title>
                <div class="ibox-content">
                  <div class="row">
                    <div class="col-md-6">
                      <b-progress :value="test.mark ? toFixed(test.mark) : 0" :variant="variant(toFixed(test.mark))" :max="100" show-progress animated></b-progress>
                    </div>
                    <div class="col-md-6">
                      <p :class="{
                        'text-danger': allDuration === 0,
                        'text-warning': allDuration < 16 && allDuration > 0,
                        'text-success': allDuration >= 16
                      }">
                        <i class="fa fa-clock-o"></i> выбрано часов для обучения: {{allDuration}} из 16
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <ibox>
                        <ibox-title>
                          <h4>Результаты</h4>
                        </ibox-title>
                        <ibox-content :no-padding="true">
                          <b-table class="row-clicked responsive" flex striped hover bordered @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" :fields="fields" :items="results">
                            <template v-slot:cell(avgMark)="data">
                              <div class="text-center">
                                <b-badge v-if="toFixed(data.item.avgMark) === 0" variant="danger">0</b-badge>
                                <b-progress v-else :value="data.item.avgMark ? toFixed(data.item.avgMark) : 0" :variant="variant(toFixed(data.item.avgMark))" :max="100" show-progress animated></b-progress>
                              </div>
                            </template>
                            <template v-slot:row-details="row">
                              <b-table :items="row.item.questions" :fields="questionFields">
                                <template v-slot:cell(isRight)="data">
                                  <b-badge :variant="data.item.isRight ? 'primary' : 'danger'">{{data.item.isRight ? 'Да' : 'Нет'}}</b-badge>
                                </template>
                                <template v-slot:cell(mark)="data">
                                  <b-progress :value="data.item.mark ? toFixed(data.item.mark) : 0" :variant="variant(toFixed(data.item.mark))" :max="100" show-progress animated></b-progress>
                                </template>
                              </b-table>
                            </template>
                          </b-table>
                        </ibox-content>
                      </ibox>
                    </div>
                    <div class="col-md-6">
                      <ibox>
                        <ibox-title>
                          <h4>Рекомендованные курсы</h4>
                        </ibox-title>
                        <ibox-content :no-padding="true">
                          <b-table striped bordered :fields="[{key: 'name', label: 'Курс'}, {key: 'duration', label: 'Длительность'}, {key: 'actions', label: 'Действия'}]" :items="removeDuplicates(courses, 'id')">
                            <template v-slot:cell(actions)="data">
                              <b-btn v-if="selectedCourses.indexOf(data.item) === -1" size="sm" variant="primary" @click="chooseCourse(data.item)">
                                <i class="fa fa-check"></i> Выбрать
                              </b-btn>
                              <b-btn v-else @click="chooseCourse(data.item)">
                                <i class="fa fa-minus"></i> Убрать
                              </b-btn>
                            </template>
                          </b-table>
                        </ibox-content>
                        <ibox-footer>
                          <router-link class="btn btn-primary" :to="`/admin/my-courses`">
                            <i class="fa fa-rocket"></i> В мои курсы
                          </router-link>
                        </ibox-footer>
                      </ibox>
                    </div>
                  </div>
                </div>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "TestResult",
        components: {IboxFooter, IboxContent, IboxTitle, Ibox, BreadCrumbs},
        data() {
            return {
              selectedCourses: [],
                results: null,
                questionResults: null,
                courses: null,
                fields: [
                    {
                        key: 'elementName',
                        label: 'Компетенция',
                        width: 50
                    },
                    /*{
                        key: 'question',
                        label: 'Подробнее'
                    },*/
                    {
                        key: 'avgMark',
                        label: 'Средняя оценка'
                    }
                ],
                questionFields: [
                    {
                        key: 'orderCode',
                        label: 'Номер вопроса'
                    },
                    {
                        key: 'questionTypeName',
                        label: 'Тип вопроса'
                    },
                    {
                        key: 'isRight',
                        label: 'Правильный ответ'
                    },
                    {
                        key: 'mark',
                        label: 'Оценка'
                    },
                ],
                codifiers: [],
                test: null
            }
        },
        mounted() {
            this.courses = [];
            this.axios.get(`sdpk/get/TestResult/id/${this.$route.params.id}/testresult.endoftest`).then(res => {
                this.test = res.data;
                this.axios.get(`sdpk/test/result/by-codifier/${this.$route.params.id}`).then(res => {
                    this.results = res.data;
                    this.axios.get(`sdpk/test/result/by-question/${this.$route.params.id}`).then(res => {
                        this.questionResults = res.data
                        this.results.map(result => {
                            result.questions = [];
                            this.questionResults.forEach(question => {
                                if(question.codifierElementId === result.elementId) {
                                  if(parseFloat(result.avgMark) < 1) {
                                    this.codifiers.push(question.codifierElementId);
                                  }
                                  result.questions.push(question);
                                }
                            })
                        })
                        if(this.codifiers.length) {
                          this.axios.get('courses/search', {
                            params: {
                              codifiers: this.codifiers.filter(this.onlyUnique)
                            }
                          }).then(res => {
                            this.courses = res.data;
                            this.courses.forEach((course) => {
                              if(course.enroll && course.enroll.isActive) {
                                this.selectedCourses.push(course)
                              }
                            });
                          })
                        }
                    })
                })
            })
        },
        methods: {
          chooseCourse(course) {
            let index = this.selectedCourses.indexOf(course)
            if(index === -1) {
              this.selectedCourses.push(course)
              this.axios.post(`enrollments/${course.id}?hideLoader=true`, {
                'isActive': true
              })
            } else {
              this.selectedCourses.splice(index, 1)
              this.axios.post(`enrollments/${course.id}?hideLoader=true`, {
                'isActive': false
              })
            }
          },
            toFixed(val) {
                return parseFloat(val).toFixed(2)*100;
            },
            variant(val) {
                if(val < 40) {
                    return 'danger';
                }
                if(val < 60) {
                    return 'warning';
                }
                return 'primary';
            },
            removeDuplicates(myArr, prop) {
                return myArr.filter((obj, pos, arr) => {
                    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
                });
            },
            getLevel(result) {
                if(result < 60) {
                    return Math.ceil(60-result);
                }
                if(result < 80) {
                    return Math.ceil(80-result);
                }
                return Math.ceil(100-result);
            },
          onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
          }
        },
      computed: {
        allDuration() {
          let duration = 0;
          this.selectedCourses.forEach((course) => {
            duration += course.duration
          })
          return duration;
        }
      }
    }
</script>

<style scoped>
    .row-clicked > table > tbody > tr {
        cursor: pointer;
    }
    table {
      margin: 0;
    }
</style>
