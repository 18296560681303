<template>
    <div class="ibox">
        <div class="ibox-content">
            <form ref="form" @submit.stop.prevent="handleSubmit(true)">
                <b-tabs content-class="mt-3">
                    <b-tab title="Основное" active>
                        <div class="">
                            <b-form-group
                                    label="Название"
                                    label-for="name-input"
                                    invalid-feedback="Имя обязательно"
                            >
                                <b-form-input
                                        id="name-input"
                                        v-model="test.name"
                                        required
                                ></b-form-input>
                            </b-form-group>
                            <validation-provider name="Описание" :rules="{ required: true }" v-slot="validationContext">
                                <div class="form-group">
                                    <label class="col-form-label">Описание:</label>
                                    <div>
                                        <Summernote placeholder="Введите описание теста" v-model="test.description" :state="valid(validationContext)"/>
                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </div>
                                </div>
                            </validation-provider>
                            <b-form-group
                                    label="Разрешено просматривать верные ответы"
                                    label-for="showCorrectAnswers-input"
                                    invalid-feedback="showCorrectAnswers is required"
                            >
                                <b-form-checkbox
                                        v-model="test.showCorrectAnswers"
                                        :value="true"
                                        :unchecked-value="false"
                                >
                                    Да
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                    label="Допустимое количество попыток"
                                    label-for="maxAttemptsCount-input"
                                    invalid-feedback="MaxAttemptsCount is required"
                            >
                                <b-form-input
                                        id="maxAttemptsCount-input"
                                        type="number"
                                        :formatter="numberFormatter"
                                        v-model.number="test.maxAttemptsCount"
                                        required
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    label="Максимальное время теста (минут)"
                                    label-for="duration-input"
                                    invalid-feedback="Duration is required"
                            >
                                <b-form-input
                                        id="duration-input"
                                        type="number"
                                        :formatter="numberFormatter"
                                        v-model.number="test.duration"
                                        required
                                ></b-form-input>
                            </b-form-group>
                        </div>
                    </b-tab>
                    <b-tab title="Вопросы">
                        <div v-for="(question, index) in test.questions" v-bind:key="index">
                            <test-form-question
                                    :allow-codifier="test.type !== TYPE_ALLOWED.module"
                                    v-bind:questionPreset.sync="question"
                                    v-bind:index="index + 1"
                                    v-on:delete="deleteQuestion(index)"
                                    v-on:update="updateQuestion(index, $event)"
                            />
                            <hr>
                        </div>
                        <b-button size="sm" variant="outline-primary" @click="addQuestion()">Добавить вопрос</b-button>
                    </b-tab>
                </b-tabs>
            </form>
        </div>
    </div>
</template>

<script>
    import bus from "../../bus";
    import TestFormQuestion from "./TestFormQuestion";
    import {TYPE_ANSWER} from "./TestFormQuestion";
    import Summernote from "../Summernote";
    import Vue from "vue";
    export const TYPE_TEST = {
        module: 'module',
        input: 'input',
        output: 'output',
    };
    export const DEFAULT_TEST_DATA = {
        showCorrectAnswers: false,
        maxAttemptsCount: 3,
        duration: 0,
        questions: [],
        type: TYPE_TEST.module
    };
    export default {
          name: "TestForm",
        components: {TestFormQuestion, Summernote},
        props: {
            type: String,
            submitting: Boolean,
            testPreset: {
                type: Object,
                default () {
                    return {
                        ...DEFAULT_TEST_DATA,
                        type: this.type || TYPE_TEST.module
                    }
                }
            },
            hideCourse: Boolean,
            hideModule: Boolean
        },
        data() {
            return {
                modules: [],
                typesList: Object.values(TYPE_TEST),
                TYPE_ALLOWED: !this.hideModule ? TYPE_TEST : {input: 'input', output: 'output'},
                test: {
                    ...this.testPreset
                }
            }
        },
        watch: {
            submitting(val) {
                if (val) {
                    this.handleSubmit();
                }
            }
        },
        mounted() {

        },
        methods: {
            checkFormValidity() {
                return this.$refs.form.checkValidity()
            },
            numberFormatter(value) {
                return Number(value);
            },
            handleSubmit(emitSubmitting = false) {
                if (emitSubmitting) {
                    this.$emit('submitting', true);
                }

                if (!this.checkFormValidity()) {
                    this.$emit('submitting', false);
                    return;
                }

                let promise = null;
                if (this.test.id) {
                    delete this.test.moduleElements
                    promise = this.axios.put(`tests/${this.test.id}`, this.test).then(r => {
                        if (r.status >= 400) {
                            throw new Error();
                        }
                        this.$toasted.global.appSuccess();
                    });
                } else {
                    promise = this.axios.post('tests', this.test).then(r => {
                        if (r.status === 400) {
                            throw new Error();
                        }
                        this.$bvModal.hide('add-test');
                        bus.$emit('add-test', r.data)
                        this.$toasted.global.appSuccess();
                    });
                }
                promise
                    .then(() => {
                        this.$emit('submitted', true);
                    })
                    .catch(() => this.$emit('submitted', false));
            },
            loadCourseModules(courseId) {
                if (!courseId) {
                    this.modules = [];
                    return;
                }
                this.axios.get(`modules?course=${courseId}`).then(res => this.modules = res.data);
            },
            addQuestion() {
                this.test.questions.push({type: TYPE_ANSWER.simple, name: '', answers: []});
            },
            deleteQuestion(index) {
                this.test.questions.splice(index, 1)
            },
            updateQuestion(index, value) {
                Vue.set(this.test.questions, index, value);
            },
            findCourses(query) {
                this.isCoursesLoading = true;
                this.axios.get(`courses/?name=${query}`).then(res => {
                    this.isCoursesLoading = false;
                    this.courses = res.data;
                });
            },
            prepareCourses(opt) {
                const course = this.courses.find(x => x.id == opt);

                return course ? course.name : undefined;
            },
            prepareModules(opt) {
                const module = this.modules.find(x => x.id == opt);

                return module ? module.name : undefined;
            }
        }
    }
</script>

<style scoped>

</style>

<i18n>
{
    "ru": {
        "type.module": "Тест модуля",
        "type.output": "Выходная диагностика",
        "type.input": "Входная диагностика"
    }
}
</i18n>
