<template>
    <div class="animated fadeIn">
        <b-modal
          id="add-user"
          ref="modal"
          size="xs"
          title="Добавление пользователя"
          o-close-on-esc
          no-close-on-backdrop
          hide-footer
      >
        <b-form class="m-t" role="form" @submit.prevent="submitUser()">
          <b-form-group>
            <b-input placeholder="Фамилия" v-model="form.lastname" required/>
          </b-form-group>
          <b-form-group>
            <b-input placeholder="Имя"
                     v-model="form.firstname"
                     required/>
          </b-form-group>
          <b-form-group>
            <b-input placeholder="Отчество" v-model="form.middlename"/>
          </b-form-group>

          <b-form-group>
            <b-input type="email" placeholder="Email" v-model="form.email" required/>
          </b-form-group>


          <b-form-group>
            <b-input placeholder="Пароль" v-model="form.password" required/>
          </b-form-group>
          <b-button type="submit" size="sm" variant="primary" class="block full-width m-b">Отправить</b-button>
        </b-form>
      </b-modal>
        <b-modal
            id="edit-user"
            ref="modal"
            size="xs"
            title="Редактировать пользователя"
            o-close-on-esc
            no-close-on-backdrop
            hide-footer
        >
          <b-form class="m-t" role="form" @submit.prevent="editUser()">
            <b-form-group>
              <b-input placeholder="Фамилия" v-model="editForm.lastName" required/>
            </b-form-group>
            <b-form-group>
              <b-input placeholder="Имя"
                       v-model="editForm.firstName"
                       required/>
            </b-form-group>
            <b-form-group>
              <b-input placeholder="Отчество" v-model="editForm.middleName"/>
            </b-form-group>
            <b-form-group>
              <b-input type="email" placeholder="Email" v-model="editForm.email" required/>
            </b-form-group>
            <valid-select label="Роль" name="роль" :options="roles" :rules="{required: true}" v-model="currentRole" open-direction="top" label-title="name" track-by="role"/>
            <b-form-group>
              <b-input type="text" placeholder="Открытый пароль" v-model="editForm.plainPassword"/>
            </b-form-group>
            <b-button type="submit" size="sm" variant="primary" class="block full-width m-b">Сохранить</b-button>
          </b-form>
        </b-modal>
        <BreadCrumbs title="Список слушателей" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]"/>
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                  <b-row>
                        <b-col>
                            <div class="input-group">
                                <input type="text" v-model="filter.fullname" placeholder="Введите ФИО" class="input form-control">
                                <span class="input-group-append">
                                    <button type="button" class="btn btn btn-info btn-sm"> <i class="fa fa-search"></i> Найти</button>
                                  <b-btn variant="primary" size="sm" v-b-modal.add-user>
                                    <i class="fa fa-user-plus"></i>
                                    Добавить
                                  </b-btn>
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </ibox-title>
                <ibox-content>
                    <div class="clients-list">
                        <b-table striped hover bordered responsive :fields="tableFields" :items="users">
                            <template v-slot:cell(fullname)="data">
                                <a href="#" class="client-link">{{data.item.fullname}}</a>
                            </template>
                            <template v-slot:cell(roleRu)="data">
                                <b-badge class="margin-right-5">{{ data.item.roleRu }}</b-badge>
                            </template>
                            <template v-slot:cell(username)="data">
                                <span class="text-muted"><i class="fa fa-envelope"></i>&nbsp; {{data.item.username}}</span>
                            </template>
                            <template v-slot:cell(userType)="data">
                                <span class="badge badge-primary" v-if="data.item.company && $auth.user().company && data.item.company.id === $auth.user().company.id">Да</span>
                                <span v-else class="badge badge-danger">Нет</span>
                            </template>
                            <template v-slot:cell(edit)="data">
                              <b-btn size="sm" variant="info" @click="onEdit(data.item.id)">
                                <i class="fa fa-edit"></i>
                              </b-btn>
                            </template>
                            <template v-slot:cell(stat)="data">
                                <b-btn-group>
                                    <router-link class="btn btn-success btn-xs" :to="`/admin/users-stats/${data.item.id}`"><i class="fa fa-line-chart"></i></router-link>
                                    <router-link v-if="data.item.neuroId" class="btn btn-warning btn-xs" :to="`/admin/neuro-stats/${data.item.id}`"><i class="fa fa-eercast"></i></router-link>
                                </b-btn-group>
                            </template>
                            <template v-slot:cell(mask)="data">
                              <b-btn size="sm" variant="primary" @click="onMask(data.item.id)">
                                <i class="fa fa-eye"></i>
                              </b-btn>
                            </template>
                        </b-table>
                    </div>
                </ibox-content>
                <ibox-footer>
                    <b-pagination v-if="pagination"
                                  v-model="currentPage"
                                  :total-rows="pagination.total"
                                  :per-page="pagination.limit"
                                  aria-controls="stats-table"
                                  @input="getUsers()"
                                  prev-text="Назад"
                                  next-text="Вперед"
                    ></b-pagination>
                </ibox-footer>
            </ibox>
        </div>
    </div>
</template>

<script>
    import ROLES from "./roles";
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxContent from "../Ibox/IboxContent";
    import IboxTitle from "../Ibox/IboxTitle";
    //import ValidCheckbox from "../Validation/ValidCheckbox";
    import {TYPES, TYPES_LIST} from "./actionsTypes";
    //import ValidSelect from "../Validation/ValidSelect";
    import IboxFooter from "../Ibox/IboxFooter";
    import ValidSelect from "../Validation/ValidSelect.vue";
    export default {
        name: "UserList",
        components: {ValidSelect, IboxFooter, IboxTitle, IboxContent, Ibox, BreadCrumbs},
        data() {
            return {
              editForm: {},
              form: {},
                filter: {
                    fullname: null,
                    courses: [],
                    organizations: []
                },
                organizations: [],
                courses: [],
                currentPage: 1,
                users: null,
                roles: [],
                fields: [],
                showMode: false,
                types: TYPES_LIST,
                pagination: null
            }
        },
        mounted() {
            Object.keys(ROLES).forEach(roleKey => {
              this.roles.push({name: ROLES[roleKey], role: roleKey});
            })
            this.getUsers();
        },
        methods: {
            onMask(userId) {
              localStorage.setItem('admin_refresh_token', localStorage.getItem('refresh_token'));
              this.axios.get(`mask/${userId}`).then(res => {
                if(res.status === 200) {
                  this.$router.push(`/admin/mask/${res.data.refresh_token}`)
                }
              })
            },
            async onEdit(userId) {
              this.editForm = (await this.axios.get('users/' + userId)).data
              this.$bvModal.show('edit-user')
            },
            getUsers(forExport = false) {

                this.axios.get(`users/list`, {
                    params: {
                        hideLoader: true,
                        offset: (this.currentPage-1)*30,
                        fullname: this.filter.fullname,
                        //courses: this.filter.courses.map(course => {return course.id}),
                        //institutions: this.filter.organizations.map(inst => {return inst.id}),
                        forExport
                    }
                }).then(res => {
                    /*if (res.data.downloadUrl) {
                        window.open(res.data.downloadUrl, '_blank');
                        return;
                    }*/
                    this.users = res.data.data;
                    this.pagination = res.data.pagination;
                });
            },
            getVariant(role) {
                let ret = 'secondary';
                switch (role) {
                    case 'ROLE_ADMIN':
                        ret = 'danger'
                        break;
                    case 'ROLE_CUSTOMER':
                        ret = 'warning'
                        break;
                    case 'ROLE_TUTOR':
                        ret = 'success'
                        break;
                    case 'ROLE_METHODIST':
                        ret = 'primary'
                        break;
                }
                return ret;
            },
            filterTable(row, filter) {

                if(filter === '') {
                    return true;
                }

                return row.fullname.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            },
            getClassByType(type) {
                let styleClass;
                switch (type) {
                    case TYPES.ENTER_TYPE:
                        styleClass = 'label-primary';
                        break;
                    case TYPES.VIDEO_TYPE:
                        styleClass = 'label-warning';
                        break;
                    case TYPES.COURSE_ENROLL_TYPE:
                        styleClass = 'label-success';
                        break;
                    case TYPES.COURSE_UN_ENROLL_TYPE:
                        styleClass = 'label-danger';
                        break;
                    default:
                        styleClass = 'label-default';
                        break;
                }
                return styleClass;
            },
            getTitleByType(type) {
                return TYPES_LIST[type];
            },
            onDownloadCsv() {
                this.getUsers(true);
            },
            submitUser() {
              this.axios.post('registration', {...this.form}).then(res => {
                if(res.status === 200) {
                  this.$bvModal.hide('add-user')
                  this.getUsers()
                  this.form = {}
                } else {
                  this.$toasted.global.appError('Пользователь уже существует!');
                }
              })
            },
            editUser() {
              const roles = []
              this.currentRole.forEach(role => {
                roles.push(role.role)
              })
              this.axios.put('users/' + this.editForm.id, {
                firstName: this.editForm.firstName,
                lastName: this.editForm.lastName,
                middleName: this.editForm.middleName,
                email: this.editForm.email,
                roles: roles
              }).then(res => {
                if(res.status === 200) {
                  this.getUsers()
                  this.$bvModal.hide('edit-user')
                  this.editForm = {}
                } else {
                  this.$toasted.global.appError('Пользователь уже существует!');
                }
              })
            },
        },
        computed: {
          currentRole: {
            // getter
            get: function () {
              if(this.editForm.roles) {
                return this.editForm.roles.map(role => {
                  if(typeof role === "string") {
                    return {
                      name: ROLES[role],
                      role: role
                    }
                  } else {
                    return role
                  }
                })
              } else {
                return [
                  {
                    name: ROLES['ROLE_USER'],
                    role: 'ROLE_USER'
                  }
                ]
              }
            },
            // setter
            set: function (newValue) {
              this.editForm.roles = newValue
            },
          },
            tableFields() {
                let fields = [];

                const baseFields = [
                    {
                        key: 'fullname',
                        label: 'ФИО',
                        sortable: true
                    },
                    {
                        key: 'username',
                        label: 'Email',
                        sortable: true
                    },
                    {
                        key: 'roleRu',
                        label: 'Роль',
                        sortable: true
                    },
                    {
                        key: 'edit',
                        label: 'Редактировать'
                    },
                    {
                      key: 'mask',
                      label: 'Замаскироваться'
                    }
                ];

                fields.push(...baseFields);

                return fields;
            }
        },
        watch: {
            filter: {
                handler() {
                    this.getUsers()
                },
                deep: true
            },
        }
}
</script>

<style scoped>

</style>
