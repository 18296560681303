<template>
    <div v-if="category" class="animated fadeIn">
        <BreadCrumbs :title="category.name" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}, {title: 'Каталог тестов', url: '/admin/test-categories'}]"/>
        <div class="wrapper wrapper-content">
            <div class="ibox">
                <div class="ibox-title">
                    Категория тестов: {{category.name}}
                    <div class="ibox-tools">
                        <a v-b-tooltip title="Добавить" v-b-modal.add-test>
                            <i class="fa fa-plus"></i> Добавить
                        </a>
                    </div>
                </div>
                <div class="ibox-content">
                    <b-table striped hover bordered :fields="fields" :items="tests">
                        <template v-slot:cell(actions)="data">
                            <b-button-group>
                                <router-link v-if="$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_METHODIST'])" v-b-tooltip title="Редактировать" :to="'/admin/constructor/' + data.item.id" class="btn btn-sm btn-warning"><i class="fa fa-edit"></i></router-link>
                                <router-link v-b-tooltip title="Пройти тест" :to="`/admin/tests-construct/${data.item.id}`" class="btn btn-primary btn-sm"><i class="fa fa-play"></i></router-link>
                            </b-button-group>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <b-modal
                id="add-test"
                ref="modal"
                button-size="sm"
                size="lg"
                title="Добавление нового теста"
                @ok="handleOk"
                v-bind:busy="modalSubmitting"
                :hide-footer="true"
                ok-title="Сохранить"
                cancel-title="Отмена"
        >
            <add-test :category="category"></add-test>
        </b-modal>
        <b-modal
                id="start-test"
                ref="modal"
                size="xl"
                title="Прохождение теста"
                o-close-on-esc
                no-close-on-backdrop
                hide-footer
        >
            <test-passage
                :test-id="selectedTestId"
            />
        </b-modal>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import TestPassage from "./TestPassage";
    import AddTest from "../TestConstructor/AddTest";
    export default {
        name: "TestList",
        components: {AddTest, TestPassage, BreadCrumbs},
        data() {
            return {
                form: {},
                modalSubmitting: false,
                selectedTestId: null,
                category: null,
                tests: [],
                fields: [
                    {
                        key: 'name',
                        label: 'Название',
                        sortable: true
                    },
                    {
                        key: 'code',
                        label: 'Код',
                        sortable: false
                    },
                    {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        mounted() {
            this.load(false);
        },
        methods: {
            load(showMsg) {
                this.axios.get(`sdpk/get/TestCategory/${this.$route.params.id}`).then(res => {
                    this.category = res.data;
                    if (showMsg) {
                        this.$toasted.global.appSuccess();
                    }
                    this.axios.get(`sdpk/get/Test/headers/${this.$route.params.id}`).then(res => {
                        this.tests = res.data;
                        if (showMsg) {
                            this.$toasted.global.appSuccess();
                        }
                    });
                });
            },
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.modalSubmitting = true;
            },
            handleSubmitting(status) {
                this.modalSubmitting = status;
            },
            handleSubmitResult(success) {
                if (success) {
                    this.$nextTick(() => {
                        this.$bvModal.hide('add-test')
                    });
                    this.load(false);
                }
                this.modalSubmitting = false;
            },
            showTestPassage(testId) {
                this.selectedTestId = testId;
                this.$bvModal.show('start-test');
            }
        }
    }
</script>

<style scoped>
    .mr-30 {
        margin-bottom: 10px;
        margin-right: 0;
    }
</style>
