<template>
    <validation-observer ref="observer" v-slot="{ passes }">
        <form @submit.stop.prevent="submit(passes)">
            <slot></slot>
            <div class="clearfix">
                <b-btn :class="button.style" variant="primary" type="submit"><i v-if="button.icon" :class="'fa fa-' + button.icon"></i> {{button.text}}</b-btn>
            </div>
        </form>
    </validation-observer>
</template>

<script>
    export default {
        name: "ValidForm",
        props: {
            submit: { type: Function },
            button: {
                type: Object,
                default () {
                    return {
                        style: null,
                        icon: null,
                        text: 'Сохранить'
                    }
                }
            }
        },
        methods: {
            getClass(type) {
                let icon;
                switch (type) {
                    case 'Выбор нескольких ответов':
                        icon = 'check-square-o';
                        break;
                    case 'Единственный ответ':
                        icon = 'dot-circle-o';
                        break;
                    default:
                        icon = 'list-alt';
                        break;
                }
                return icon;
            }
        }
    }
</script>

<style scoped>

</style>
