// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VueAuth from '@websanova/vue-auth'
import VueRouter from 'vue-router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment-timezone'
import Toasted from 'vue-toasted';
import VueVideoPlayer from 'vue-video-player';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// require videojs style
import 'video.js/dist/video-js.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import PusherService from './services/pusher-service';
import JsonExcel from 'vue-json-excel'

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);


Vue.component('downloadExcel', JsonExcel)

Vue.use(Loading);

Vue.use(VueVideoPlayer)

import {
  ValidationObserver,
  ValidationProvider,
  withValidation,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import ru from "vee-validate/dist/locale/ru.json";
import i18n from "./i18n";
import Multiselect from 'vue-multiselect'

import PrettyCheckbox from 'pretty-checkbox-vue'
import 'pretty-checkbox/src/pretty-checkbox.scss'
Vue.use(PrettyCheckbox)

ru.messages.confirmed = 'Пароли не совпадают!';
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend("decimal", {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'Only decimal values are available'
      }
    };
  },
  message: `{serverMessage}`
})


localize("ru", ru);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('withValidation', withValidation);
Vue.component('ValidationProvider', ValidationProvider);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(ElementUI, { locale });
Vue.use(VueRouter);
Vue.use(Toasted, {
  position: 'top-center',
  fitToScreen: true,
  iconPack: 'fontawesome',
  theme: 'toasted-primary',
  duration: 2000
});
Vue.component('multiselect', Multiselect);
Vue.mixin({
  methods: {
    valid: ({ dirty, validated, valid = null }) => dirty || validated ? valid : null,
    isEnroll: (course) => course.enroll && course.enroll.isActive,
    pathMedia: (media) => media.filePath.indexOf('http') >=-1 ? media.filePath : process.env.VUE_APP_API_URL + media.filePath,
    getRandomInt: () => Math.floor(Math.random() * (100 - 5 + 1)) + 5,
    srcThumb: (media, size = 'big') => media && media.thumbs ? media.thumbs[size] : '/img/no-image.png',
    hashId: () => Math.random().toString(12).substr(2, 9),
    loaderStyle: () => {
          return {
            color: '#18a689',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#2f4050',
            opacity: 0.5,
            zIndex: 999
          }
    },
    getVariant: (status) => {
      let type;
      switch (status) {
        case 'active':
          type = 'warning';
          break;
        case 'success':
          type = 'primary';
          break;
        case 'failed':
          type = 'danger';
          break;
      }
      return type;
    }
  }
});

// Lets Register a Global Error Notification Toast.
Vue.toasted.register('appError', (message) => {
  if(!Object.keys(message).length) {
    return "Ошибка"
  }
  return message;
}, {
  type : 'error',
  icon : 'exclamation-triangle'
});

Vue.toasted.register('appSuccess',
    (message) => {
      if(!Object.keys(message).length) {
        return "Успешно"
      }
      return message;
    },
    {
      type : 'success',
      icon : 'check'
    }
)

Vue.router = router;

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

let loader = null;
function hideLoader(){
  // destroy previous
  if(loader) {
    loader.hide();
    loader = null;
  }
}

let loadOptions = {
  color: '#18a689',
  loader: 'spinner',
  width: 64,
  height: 64,
  backgroundColor: '#2f4050',
  opacity: 0.5,
  zIndex: 999,
};

function showLoader() {
  hideLoader();
  loader = Vue.$loading.show(loadOptions)
}

const errorHandler = (error) => {
  hideLoader()
  console.error(error.response && error.response.data ? error.response.data.detail : 'Ошибка');
  return error.response
};

const successHandler = (response) => {
  hideLoader();
  return response
};

Vue.axios.interceptors.request.use((config) => {

  if(config.url === 'token/refresh') {
    config.data = {...config.data, refresh_token: localStorage.getItem('refresh_token')}
  }

  if(config.params && config.params.hideLoader) {
    return config;
  }

  if(config.data && config.data.hideLoader) {
    return config;
  }

  showLoader();
  return config;
});
Vue.axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

Vue.use(VueAuth, {
  auth: require('./auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  fetchData: {
    url: 'me',
    success: function (res) {

      if(res.data.isKids && window.location.pathname !== '/admin/registration') {
        window.location.href = 'https://universarium-school.ru/admin?login=true'
      }
      Vue.auth.user(res.data);
    }
  },
  redirect: '/admin/profile',
  tokenStore: ['localStorage'],
  roleKey: 'roles',
  refreshTokenName: 'refresh_token',
  refreshData: {url: 'token/refresh', method: 'POST', interval: 1440},
});


Vue.filter('truncate', function(text, length, clamp){
  clamp = clamp || '...';
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('pluralize', function(number, locale, words){
  if (number % 10 === 1 && number % 100 !== 11) {
    return words[0];
  }
  if ((number % 10 >= 2 && number % 10 <= 4 && number % 100 < 10) || number % 100 >= 20) {
    return words[1];
  }

  return words[2];
});

moment.tz.setDefault('Europe/Moscow')

Vue.filter('formatDate', function(value, format = 'DD.MM.YYYY HH:mm') {
  if (value) {
    return moment(String(value)).format(format)
  }
})



Vue.use(PusherService)

/* eslint-disable no-new */
new Vue({
  i18n,
  render: h => h(App),
  router,
  components: { App }
}).$mount('#app');
