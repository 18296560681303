<template>
  <div class="animated fadeIn">
    <bread-crumbs title="Моя диагностика" :items="[
            {title: 'Главная', url: '/#/'},
            {title: 'Профиль', url: '/admin/profile'}
          ]" />
    <div v-if="tests" class="wrapper wrapper-content">
      <section id="team-tests" class="team gray-section">
        <div class="container">
          <div class="row hot-courses d-flex justify-content-center">
            <b-col md="3" v-for="(test, key) in tests" :key="key">
              <div class="wow zoomIn bg-white course-row">
                <h3><span class="navy">{{test.name}}</span></h3>
                <p class="text-left"></p>
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <router-link :to="`/admin/tests-construct/${test.id}`" class="btn btn-primary btn-xs">
                      Пройти тест
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-col>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs";
export default {
name: "MyTests",
  components: {BreadCrumbs},
  data() {
    return {
      tests: null
    }
  },
  mounted() {
    this.axios.get('user_projects', {
      params: {
        'filters[user]': this.$auth.user().id
      }
    }).then(res => {
      this.tests = [];
      let projectUsers = res.data.data;
      projectUsers.forEach(pUser => {
        if(pUser.tests) {
          this.tests = [...pUser.tests];
        }
      })
    })
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
