<template>
    <div class="animated fadeIn">
        <bread-crumbs title="Рекомендованные курсы" :items="[{title: 'Главная', url: '/#/'}, {title: 'Профиль', url: '/admin/profile'}]" />
        <div class="wrapper wrapper-content">
            <ibox>
                <ibox-title>
                    Траектория по курсу &laquo;{{ course.name }}&raquo;
                </ibox-title>
                <ibox-content>
                    <b-table responsive flex striped hover :fields="fields" :items="trajectories" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)">
                        <template v-slot:cell(actions)="data">
                            <b-btn v-if="data.item.status === 'init'" @click="choose(data.item.id)" class="btn btn-primary btn-sm">Выбрать</b-btn>
                            <router-link :to="`/admin/course/${course.id}`" v-else-if="data.item.status === 'active'" class="btn btn-success btn-sm"><i class="fa fa-eye"></i> Перейти к курсу</router-link>
                        </template>
                        <template v-slot:row-details="data">
                            <b-table :fields="mapFields" :items="data.item.trajectoryModuleMaps"></b-table>
                        </template>
                    </b-table>
                </ibox-content>
            </ibox>
        </div>
    </div>
</template>

<script>
    import BreadCrumbs from "../BreadCrumbs";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    export default {
        name: "Trajectory",
        components: {IboxContent, IboxTitle, Ibox, BreadCrumbs},

        data() {
            return {
                trajectories: null,
                course: null,
                mapFields: [
                    {
                        key: 'module.name',
                        label: 'Модуль'
                    },
                    {
                        key: 'level',
                        label: 'Уровень'
                    },
                    {
                        key: 'point',
                        label: 'Баллы'
                    }
                ],
                fields: [
                    {
                        key: 'points',
                        label: 'Баллы',
                        sortable: true
                    },
                    {
                        key: 'duration',
                        label: 'Длительность'
                    },
                    {
                        key: 'actions',
                        label: 'Выбрать'
                    }
                ]
            }
        },
        methods: {
            choose(id) {
                this.axios.put(`/trajectories/${id}`, {
                    status: 'active'
                }).then(() => {
                    this.trajectories = this.trajectories.map(tr => {
                        if(tr.id === id) {
                           tr.status = 'active';
                        } else {
                            this.axios.put(`/trajectories/${tr.id}`, {
                                status: 'init'
                            });
                            tr.status = 'init';
                        }
                        return tr;
                    })
                })
            }
        },
        mounted() {
            this.axios.get(`/roadmap/${this.$route.params.course}`, {
                params: {
                    points: this.$route.params.points
                }
            }).then(res => {
                this.course = res.data.course;
                this.trajectories = res.data.trajectories;
            })
        }
    }
</script>

<style scoped>

</style>
