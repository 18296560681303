<template>
  <div v-if="project" class="animated fadeIn">
    <b-modal id="add-new" title="Введите название" :hide-footer="true">
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(addNew())">
          <valid-input rules="required" v-model="name" />
          <hr class="hr-line-dashed"/>
          <b-btn type="submit" variant="primary">Отправить</b-btn>
        </b-form>
      </validation-observer>
    </b-modal>
    <bread-crumbs title="Добавление пользователя" :items="[
            {title: 'Главная', url: '/#/'},
            {title: 'Проекты', url: '/admin/projects'},
            {title: project.title, url: `/projects/${project.id}/edit`},
            {title: 'Пользователи проекта', url: `/projects/${project.id}/project-users`},
          ]" />
    <div class="wrapper wrapper-content">
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.stop.prevent="passes(submit)">
        <div class="ibox">
            <div class="ibox-content">
              <div class="row">
                <div class="col-md-6">
                  <valid-input v-model="userProject.user.email" rules="required|email" label="Email" />
                  <valid-input v-model="userProject.user.lastName" rules="required" label="Фамилия" />
                  <valid-input v-model="userProject.user.firstName" rules="required" label="Имя" />
                  <valid-input v-model="userProject.user.middleName"  label="Отчество" />
                  <valid-input v-if="!userProject.user.id" v-model="userProject.user.password" label="Пароль" />
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label>Предметы</label>
                    <multiselect
                        v-model="userProject.subjects"
                        :multiple="true"
                        :searchable="true"
                        :options="subjects"
                        track-by="id"
                        placeholder="Введите название предмета"
                        label="name"
                        openDirection="bottom"
                        :loading="isSelectLoading"
                        @search-change="searchSubjects"
                        :custom-label="(opt) => opt.name"
                    ></multiselect>
                    <b-button @click="showModal('organization')" class="btn btn-primary btn-xs">Добавить новый</b-button>
                  </b-form-group>
                  <b-form-group>
                    <label>Регион</label>
                    <multiselect
                        v-model="userProject.region"
                        :multiple="false"
                        :searchable="true"
                        :options="regions"
                        track-by="id"
                        placeholder="Введите название региона"
                        label="name"
                        openDirection="bottom"
                        :loading="isSelectLoading"
                        @search-change="searchRegion"
                        :custom-label="(opt) => opt.name"
                    ></multiselect>
                    <b-button @click="showModal()" class="btn btn-primary btn-xs">Добавить новый</b-button>
                  </b-form-group>
                  <b-form-group v-if="checkProp('region')">
                    <label>Район</label>
                    <multiselect
                        v-model="userProject.district"
                        :multiple="false"
                        :searchable="true"
                        :options="districts"
                        track-by="id"
                        placeholder="Введите название района"
                        label="name"
                        openDirection="bottom"
                        :loading="isSelectLoading"
                        @search-change="searchDistrict"
                        :custom-label="(opt) => opt.name"
                    ></multiselect>
                    <b-button @click="showModal('district')" class="btn btn-primary btn-xs">Добавить новый</b-button>
                  </b-form-group>
                  <b-form-group v-if="checkProp('region') && checkProp('district')">
                    <label>Организация</label>
                    <multiselect
                        v-model="userProject.organization"
                        :multiple="false"
                        :searchable="true"
                        :options="organizations"
                        track-by="id"
                        placeholder="Введите название организации"
                        label="name"
                        openDirection="bottom"
                        :loading="isSelectLoading"
                        @search-change="searchOrganization"
                        :custom-label="(opt) => opt.name"
                    ></multiselect>
                    <b-button @click="showModal('organization')" class="btn btn-primary btn-xs">Добавить новый</b-button>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <b-btn type="submit" variant="primary">Сохранить</b-btn>
            </div>
        </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs";
import ValidInput from "../Validation/ValidInput";
export default {
  name: "AddProjectUser",
  components: {ValidInput, BreadCrumbs},
  data() {
    return {
      userProject: {
        user: {},
        region: {},
        district: {},
        organization: {},
        subjects: [],
        project: null
      },
      project: null,
      isSelectLoading: false,
      regions: [],
      districts: [],
      subjects: [],
      organizations: [],
      model: null,
      name: null
    }
  },
  mounted() {
    this.axios.get(`projects/${this.$route.params.id}`).then(res => {
      this.project = res.data;
      this.userProject.project = this.project;
    })
  },
  computed: {
    email() {
      return this.userProject.user.email;
    },
    region() {
      return this.userProject.region;
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    submit() {
      this.axios.post(`project_user_create`, {
        data: this.userProject
      }).then((res) => {
        if(res.status === 200 || res.status === 201) {
          this.$router.push(`/admin/projects/${this.project.id}/project-users`).catch(() => {});
          this.$toasted.global.appSuccess()
        } else {
          this.$toasted.global.appError()
        }
      })
    },
    searchRegion(query) {
      if(query !== '' && query.length > 3 ) {
        this.isSelectLoading = true;
        this.axios.get(`project_regions`, {
          params: {
            name: query,
            hideLoader: true
          }
        }).then(res => {
          if(res.status === 200) {
            this.isSelectLoading = false
            this.regions = res.data;
          }
        });
      }
    },
    searchDistrict(query) {
      if(query !== '' && query.length > 3 ) {
        this.isSelectLoading = true;
        this.axios.get(`project_districts`, {
          params: {
            name: query,
            region: this.userProject.region.id,
            hideLoader: true
          }
        }).then(res => {
          if(res.status === 200) {
            this.isSelectLoading = false
            this.districts = res.data;
          }
        });
      }
    },
    searchOrganization(query) {
      if(query !== '' && query.length > 3 ) {
        this.isSelectLoading = true;
        this.axios.get(`project_organizations`, {
          params: {
            name: query,
            region: this.userProject.region.id,
            district: this.userProject.district.id,
            hideLoader: true
          }
        }).then(res => {
          if(res.status === 200) {
            this.isSelectLoading = false
            this.organizations = res.data;
          }
        });
      }
    },
    searchSubjects(query) {
      if(query !== '') {
        this.isSelectLoading = true;
        this.axios.get(`project_subjects`, {
          params: {
            name: query,
            hideLoader: true
          }
        }).then(res => {
          if(res.status === 200) {
            this.isSelectLoading = false
            this.subjects = res.data;
          }
        });
      }
    },
    showModal(model = 'region') {
      this.model = model;
      this.$bvModal.show('add-new')
    },
    addNew() {
      this.axios.post('project_' + this.model + 's', {
        name: this.name,
        region: this.userProject.region.id,
        district: this.userProject.district.id
      }).then(res => {
        if(res.status === 201) {
          this.$bvModal.hide('add-new')
          this.name = '';
          this.userProject[this.model] = res.data;
        }
      })
    },
    checkProp(prop) {
      if(typeof this.userProject[prop] !== 'undefined' && this.userProject[prop] !== null) {
        return Object.keys(this.userProject[prop]).length
      }
      return null;
    }
  },
  watch: {
    region() {
      this.userProject.district = {}
      this.userProject.organization = {}
    },
    email(val, oldVal) {
      if(val !== oldVal && this.validateEmail(val)) {
        this.axios.get(`users?username=` + val, {
          params: {
            hideLoader: true
          }
        }).then(res => {
          if(res.status === 200 && res.data.length) {
            this.userProject.user = res.data[0];
          } else {
            this.userProject.user.id = null;
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
