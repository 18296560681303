<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element">
            <img v-if="$auth.user().photo" alt="image" class="rounded-circle" :src="$auth.user().photo.thumbs.smaller"/>
            <a href="/me">
              <span class="block m-t-xs font-bold text-white">{{ $auth.user().fullname }}</span>
              <span class="text-muted text-xs block">{{ $auth.user().roleRu }}</span>
            </a>
            <ul class="dropdown-menu animated fadeInRight m-t-xs">
              <li><a class="dropdown-item" href="/profile"></a></li>
              <li><router-link class="dropdown-item" to="/admin/profile">Профиль</router-link></li>
              <li class="dropdown-divider"></li>
              <li>
                <a class="dropdown-item" @click="$auth.logout()">Выход</a>
              </li>
            </ul>
          </div>
          <div class="logo-element">
            <div class="logo-wrapper">
              <img class="m-t-xs img-fluid" src="/logo.png">
            </div>
          </div>
        </li>
        <template v-for="(item, index) in menuItems">
          <router-link v-if="!item.children && item.show" tag="li" :key="index" :to="item.route">
            <a v-if="item.show" :href="item.route">
              <i :class="item.icon" v-if="item.icon"></i>
              <span class="nav-label">{{ item.title }}</span></a>
          </router-link>
          <li v-else-if="item.show" :key="index" :class="{'active': subIsActive(item)}">
            <a href="#">
              <i :class="item.icon" v-if="item.icon"></i>
              <span class="nav-label">{{ item.title }}</span>
            </a>
            <ul class="nav nav-second-level collapse" aria-expanded="true">
              <router-link tag="li" v-for="(child, chIndex) in item.children" :key="chIndex" :to="child.route">
                <a v-if="child.show" :href="child.route">
                  <i :class="child.icon" v-if="child.icon"></i>
                  <span class="nav-label">{{child.title}}</span>
                </a>
              </router-link>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
  /**
   *                                     <b-col><valid-checkbox name="Диагностика" vid="diagnost" v-model="institute.subsystemSettings.isDiagnost" /></b-col>
   <b-col><valid-checkbox name="Проекты обучения (проекты)" vid="projects" v-model="institute.subsystemSettings.isProject" /></b-col>
   <b-col><valid-checkbox name="Компетенции" vid="codifiers" v-model="institute.subsystemSettings.isCodifier" /></b-col>
   <b-col><valid-checkbox name="Тесты" vid="tests" v-model="institute.subsystemSettings.isTest" /></b-col>
   */
  import $ from "jquery";
  import 'metismenu';
  export default {
    name: "NavBar",
    props: ['logo', 'url', 'cor'],
    data() {
      let menuItems = [
        {route: '/admin/catalog', title: 'Каталог курсов', icon: 'fa fa-th-large', show: this.$auth.user().id !== 99874 && this.$auth.check([ 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])},
        { route: '/admin/homeworks', title: 'Проверка ДЗ', icon: 'fa fa-home', show: this.$auth.check(['ROLE_TUTOR'])},
        {
          title: 'Статистика',
          icon: 'fa fa-bar-chart',
          show: this.$auth.check(['ROLE_SUPER_ADMIN']) || !this.$auth.user().projects && (this.$auth.check([ 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_TUTOR']) && this.$auth.user().company && this.$auth.user().company.subsystemSettings && (this.$auth.user().company.subsystemSettings.isStatUser || this.$auth.user().company.subsystemSettings.isStatCourse || this.$auth.user().company.subsystemSettings.isStatCody || this.$auth.user().company.subsystemSettings.isStatReport || this.$auth.user().company.subsystemSettings.isStatNeuro)),
          //show: this.$auth.check([ 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_TUTOR']),
          children: [
            {route: '/admin/stats', title: 'По курсам', icon: 'fa fa-th-large', show: this.$auth.check([ 'ROLE_SUPER_ADMIN']) || (this.$auth.check([ 'ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_TUTOR']) && this.$auth.user().company && this.$auth.user().company.subsystemSettings && this.$auth.user().company.subsystemSettings.isStatCourse)},
          ]
        },
        {
          title: 'Списки',
          icon: 'fa fa-th-list',
          show: this.$auth.check(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) && this.$auth.user().id !== 99874,
          children: [
            {route: '/admin/users', title: 'Пользователи', icon: 'fa fa-users', show: true},
            {route: '/admin/courses-list', title: 'Курсы', icon: 'fa fa-th-large', show: true},
            {route: '/admin/attempts-list', title: 'Результаты тестов', icon: 'fa fa-eercast', show: true},
          ]
        },
        {
          title: 'Текст',
          icon: 'fa fa-file-o',
          show: true,
          children: [
            {route: '/admin/documents/1', title: 'О проекте', icon: 'fa fa-file', show: this.$auth.check(['ROLE_SUPER_ADMIN'])},
            {route: '/admin/documents/2', title: 'Новости', icon: 'fa fa-file', show: this.$auth.check(['ROLE_SUPER_ADMIN'])}
          ]
        },
        {route: '/admin/mails', title: 'Рассылки', icon: 'fa fa-envelope', show: this.$auth.check(['ROLE_ADMIN', 'ROLE_METHODIST', 'ROLE_TUTOR']) && this.checkProperty('isMail') || this.$auth.check(['ROLE_SUPER_ADMIN'])},
      ];
      return {
        menuItems
      }
    },
    mounted() {
      $(function() {
        $('#side-menu').metisMenu();
      })
    },
    methods: {
      subIsActive(item) {
        let active = false;
         item.children.forEach(child => {
           if(this.$router.currentRoute.path === child.route) {
             active = true;
           }
         })
        return active;
      },
      checkProperty(prop) {
        return this.$auth.user().company && this.$auth.user().company.subsystemSettings && this.$auth.user().company.subsystemSettings[prop];
      }
    },
    watch: {
      /*$route() {
        $(function() {
          $('#side-menu').metisMenu();
        })
      }*/
    }
  }
</script>

<style lang="scss">
  .logo-wrapper {
    padding: 7px;
    display: inline-block;
    border-radius: 3px;
    background: #1ab394;
    margin: 10px 10px 10px 5px;
    img {
      margin-top: 0;
    }
  }
</style>
