<template>
    <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="axios.defaults.baseURL + 'media_objects'"
            :headers="{Authorization: 'Bearer ' + this.$auth.token()}"
            :on-success="handleUploadSuccess">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</template>

<script>
    import bus from '../bus';
    export default {
        name: "AvatarUpload",
        props: ['media'],
        data() {
            return {
                imageUrl: null
            }
        },
        mounted() {
            if(this.media) {
                this.imageUrl = this.media.thumbs.big
            }
        },
        methods: {
            handleUploadSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                bus.$emit('avatar-upload', res);
            }
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 200px;
        height: 161px;
        display: block;
        margin: 0 auto;
    }
</style>
