<template>
    <div class="animated fadeIn">
        <div>
            <p></p>
        </div>
        <table class="constructor-match-t table table-condensed"
               style="table-layout: fixed;">
            <thead>
            <th width="50">
                Заголовки<br/>колонок:
            </th>
            <th width="50"
                tabindex="-1">
                <button class="btn btn-light"
                        style="width: 100%"
                        v-if="task.cells.length === 0"
                        title="Добавить колонку (клавиша End)"
                        @click="addColumn()">
                    <i class="fa fa-ui-add"></i><span v-if="task.columns.length > 0"> &nbsp;</span>
                </button>
            </th>
            <th tabindex="-1" v-for="(column, k) in task.columns" :key="k">
                <input type="text"
                       :id="'con_' + id +'_colhead_' + k"
                       v-model="task.columns[k]"
                       class="form-control"
                       @keyup.end="addColumn($event, true)"
                       @keyup.enter="addRow()"
                />
                <div style="width: 100%; text-align: right">
                    <button class="btn btn-light"
                            style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                            tabindex="-1"
                            @click="deleteColumn(k)">
                        <i class="fa fa-delete" style="font-size: 120%;"></i> удалить колонку
                    </button>
                </div>
            </th>
            </thead>
            <tbody>
            <tr v-for="(row, r) in task.cells" :key="r">
                <td>
                    <div style="width: 100%; text-align: right">
                        <button class="btn btn-light"
                                style="font-size: 70%; margin-top: 2px !important; color: darkred;"
                                tabindex="-1"
                                @click="deleteRow(r)">
                            <i class="fa fa-delete-alt" style="font-size: 120%;"></i> удалить<br/>строку
                        </button>
                    </div>
                </td>
                <td></td>
                <td v-for="(column, k) in task.columns" :key="k">
                    <div class="form-group">
                        <label :for="'is_static_col' + k + '_row' + r">Текст вопроса?</label>
                        <input type="checkbox"
                               :id="'is_static_col' + k + '_row' + r"
                               :name="'is_static_col' + k + '_row' + r"
                               v-model="task.cells[r][k].isStaticText"
                        />
                        <div class="constructor-explain">
                            <div v-if="task.cells[r][k].isStaticText">
                                <span>статический текст задания</span><br/>
                                <valid-summernote v-model="task.cells[r][k].text"></valid-summernote>
                            </div>
                            <div v-if="!task.cells[r][k].isStaticText" style="text-align: center; padding: 4px;">
                                <p-check class="p-icon p-bigger p-smooth" style="font-size: 200%" v-model="answer.grid[r][k]">
                                    <i slot="extra" class="icon fa fa-check"></i>
                                </p-check>
                            </div>
                        </div>

                    </div>
                </td>
            </tr>
            <tr>
                <td :colspan="2 + task.columns.length"
                    style="text-align: center">
                    <button class="btn btn-link"
                            @click="addRow()"
                            style="width: 100%;
                  font-size: 140%;">
                        <i class="fa fa-plus"></i> Добавить строку (Enter)
                    </button>
                    <br/><br/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
    import ConstructorMatch from "./types/ConstructorMatch";
    import ValidSummernote from "../Validation/ValidSummernote";

    export default {
        name: "ConstructorMatchCheckbox",
        components: {ValidSummernote},
        extends: ConstructorMatch,
        methods: {
            addRow () {
                let row = []
                let answerRow = []
                for (let i = 0; i < this.task.columns.length; i++) {
                    row.push({ isStaticText: i === 0, text: '', text2: '' })
                    answerRow.push(false)
                }
                this.task.cells.push(row)
                this.answer.grid.push(answerRow)
            }
        }
    }
</script>

<style scoped>

</style>
