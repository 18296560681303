<template>
    <div v-if="mediaObject" v-b-tooltip :title="getTitle">
        <b-progress v-if="progress < 100 && mediaObject.encoderTasks.length" :value="progress" variant="primary" :max="100" show-progress animated></b-progress>
        <b-btn-group v-else>
            <b-btn tag="a" :href="mediaObject.thumbs.cover" target="_blank" size="sm" variant="secondary">
                <i class="fa fa-image"></i> img
            </b-btn>
            <b-btn tag="a" :href="mediaObject.thumbs['360p']" target="_blank" size="sm" variant="primary">
                <i class="fa fa-video-camera"></i> 360p
            </b-btn>
            <b-btn tag="a" :href="mediaObject.thumbs['480p']" target="_blank" size="sm" variant="primary">
                <i class="fa fa-video-camera"></i> 480p
            </b-btn>
            <b-btn tag="a" :href="mediaObject.thumbs['720p']" target="_blank" size="sm" variant="primary">
                <i class="fa fa-video-camera"></i> 720p
            </b-btn>
        </b-btn-group>
    </div>
</template>

<script>
    export default {
        name: "EncoderProcess",
        props: ['media'],
        data() {
          return {
              progress: 0,
              timer: null,
              data: null,
              loading: false,
              mediaObject: null
          }
        },
        mounted() {
            if(this.media) {
              this.axios.get(`media_objects/${this.media.id}`).then(res => {
                this.mediaObject = res.data;
                if(this.mediaObject.encoderTasks && this.mediaObject.encoderTasks.length) {
                  this.data = this.media
                  this.fetchEventsList();
                  this.timer = setInterval(this.fetchEventsList, 10000)
                }
              })
            }
        },
        methods: {
            getTitle() {
                if(this.progress > 0 && this.progress < 100) {
                    return 'В процессе'
                }
                if(this.progress === 100) {
                    return 'Завершено'
                }
                return 'В очереди'
            },
            fetchEventsList () {
                this.axios.get(`media_objects/${this.data.id}`, {params: {hideLoader: true}}).then(res => {
                    let count = this.data.encoderTasks.length;
                    let progress = 0;
                    this.data = res.data
                    this.data.encoderTasks.forEach(task => {
                        progress += task.progress
                    })
                    this.progress = count ? Math.ceil(progress/count) : 0
                    if(this.progress === 100) {
                        this.cancelAutoUpdate()
                    }
                });
            },
            cancelAutoUpdate () { clearInterval(this.timer) }
        },
        beforeDestroy () {
            clearInterval(this.timer)
        }
    }
</script>

<style scoped>

</style>
