<template>
  <ibox-footer v-if="element && element.elementResult && element.elementResult.homework && element.elementResult.homework.status === 'checked'">
    <template v-if="results && results.length">
      <h4 class="font-weight-bold">Критерии оценки</h4>
      <b-table responsive bordered :fields="headerFields" :items="results">
        <template fixed v-slot:cell(results)="data">
          <b-table :fields="fields" :items="data.item.results">
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'title' || field.key === 'mark' ? '25%' : '50%' }"
              >
            </template>
          </b-table>
        </template>
      </b-table>
    </template>
    <template v-if="element.elementResult.homework && element.elementResult.homework.checkComment && element.elementResult.homework.checkComment.comment">
      <strong>Комментарий преподавателя:</strong>
      <p>{{element.elementResult.homework.checkComment.comment }}</p>
    </template>
  </ibox-footer>
</template>

<script>
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "HomeworkUserResult",
        components: {IboxFooter},
        props: ['element'],
        data() {
            return {
                results: null,
                fields: [
                  {
                    key: 'title',
                    label: 'Критерий'
                  },
                  {
                    key: 'mark',
                    label: 'Оценка'
                  },
                  {
                    key: 'text',
                    label: 'Комментарий'
                  }
                ],
                headerFields: [
                  {
                    key: 'index',
                    label: '№ п/п'
                  },
                  {
                    key: 'results',
                    label: 'Результаты'
                  }
                ]
            }
        },
        mounted() {
            this.loadByElementResult(this.element.elementResult.id)
        },
        methods: {
            loadByElementResult(id) {
                this.axios
                    .get('/homeworks-check/' + id)
                    .then((res) => {
                        this.results = res.data;
                    })
            }
        }
    }
</script>
<style>
  .ibox-content img {
    max-width: 100%;
  }
  .btn-outline-info {
    margin-right: 2px;
  }
  .ibox-content {
    margin-bottom: 0;
  }
  .ibox-footer {
    text-align: right;
    border-bottom: 1px solid #e7eaec;
    border-left: 1px solid #e7eaec;
    border-right: 1px solid #e7eaec;
  }
</style>
