<template>
  <div  v-if="task && Array.isArray(task.choice)">
    <!-- todo fix -->
    <div
         v-for="(k, i) in task.choice" :key="i">
        <p><strong>Ответ<span v-if="task.choice.length > 1">&nbsp;{{ i + 1 }}</span>:</strong></p>
        <textarea title="Правильный ответ"
                  v-if="givenAnswer && Array.isArray(givenAnswer.key)"
                  class="form-control"
                  v-model="givenAnswer.key[i]"></textarea>
    </div>
  </div>

</template>

<script>
    import QuestionBase from "./QuestionBase";
    export default {
        name: "QuestionOpen",
        extends: QuestionBase
    }
</script>

<style scoped>

</style>
