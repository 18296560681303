<template>
    <div class="vertical-timeline-block">
        <div class="vertical-timeline-icon bg-warning">
            <i class="fa fa-graduation-cap"></i>
        </div>
        <div class="vertical-timeline-content">
            <h5>Запись на курс</h5>
            <p>{{ action.title }}</p>
            <router-link :to="action.link" class="btn btn-sm btn-warning"> Подробнее</router-link>
            <span class="vertical-date">
                {{ action.date }}
                <small>{{ action.time }}</small>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionsSubscribe",
        props: ['action']
    }
</script>

<style scoped>

</style>
