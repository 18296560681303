<template>
  <div class="fadeInDown text-left">
    <template v-if="results.length">
      <span v-for="(result, key) in results" :key="key">
        <b-form @submit.stop.prevent="saveResult(result)">
          <ibox>
            <ibox-title>
              <b-badge style="margin-right: 20px;" class="pull-right" v-if="result.isSend" variant="primary">Результаты уже были отправлены</b-badge>
              Проверка домашней работы № {{key + 1}}
            </ibox-title>
            <ibox-content>
              <h3 class="font-weight-bold">Ответ:</h3>
              <div v-if="result.content"><strong>Текст:</strong></div>
              <div v-if="result.content" class="mb-3" v-html="result.content"></div>
              <div v-if="result.attachments && result.attachments.length > 0">
                <div><strong>Файлы:</strong></div>
                <a :href="axios.defaults.baseURL + file.path" target="_blank" class="btn d-inline-block btn-sm btn-outline-info" v-for="(file, index) of result.attachments" :key="index">
                    <i class="fa fa-download"></i> {{ file.name|truncate(10, '...') }}
                </a>
              </div>
              <hr class="hr-line-dashed"/>
              <h3 class="font-weight-bold">Критерии оценки:</h3>
              <span v-for="checkResult in result.checkResults" :key="checkResult.id">
                <b-row>
                  <b-col sm="12">
                    <div>
                      <h4 class="font-weight-bold d-inline-block">
                        {{ checkResult.title }}
                      </h4>
                      <b-badge style="margin-left: 10px;" class="d-inline-block" variant="warning">{{checkResult.coefficient}}</b-badge>
                    </div>
                    <div v-html="checkResult.description"></div>
                  </b-col>
                  <b-col sm="12">
                    <valid-input
                        v-model.number="checkResult.mark"
                        type="number"
                        name="оценка"
                        :disabled="result.isSend"
                        label="Оценка"
                        :step="1"
                        :rules="{required: true, numeric: true, min_value: 0, max_value: 5}"
                    />
                  </b-col>
                  <b-col sm="12">
                    <valid-textarea :disabled="result.isSend" name="комметарий" label="Комментарий" v-model="checkResult.text" />
                  </b-col>
                </b-row>
              </span>
            </ibox-content>
            <ibox-footer>
              <b-btn v-if="!result.isSend"
                     variant="primary"
                     type="submit">
                    <i class="fa fa-send-o"></i> Отправить результат
              </b-btn>
              <a v-if="result.isSend"
                 style="color: #fff;"
                 class="btn btn-info btn-sm"
                 @click="result.isSend = false;"
                 type="button">
                <i class="fa fa-edit"></i> Исправить
              </a>
            </ibox-footer>
          </ibox>
        </b-form>
    </span>
    </template>
    <template v-else>
      <ibox>
        <ibox-title>
          Домашних работ на проверку нет
        </ibox-title>
        <ibox-content>
          <p>Вам будет выставлен балл, который не повлияет общую оценку</p>
        </ibox-content>
      </ibox>
    </template>
  </div>
</template>

<script>
    import ValidInput from "../Validation/ValidInput";
    import ValidTextarea from "../Validation/ValidTextarea";
    import Ibox from "../Ibox/Ibox";
    import IboxTitle from "../Ibox/IboxTitle";
    import IboxContent from "../Ibox/IboxContent";
    import IboxFooter from "../Ibox/IboxFooter";
    export default {
        name: "HomeworkUserCheck",
        components: {IboxFooter, IboxContent, IboxTitle, Ibox, ValidTextarea, ValidInput},
        props: ['element'],
        data() {
            return {
                task: null,
                results: []
            }
        },
        mounted() {
            this.loadByElementResult(this.element.elementResult.id)
        },
        methods: {
            loadByElementResult(id) {
                this.axios
                    .get('/homeworks-touch/' + id)
                    .then((res) => {
                        this.task = res.data.task;
                        this.results = res.data.results;
                    })
            },
            saveResult(result) {
              this.axios
                  .post(`/homeworks-user-result/${this.element.elementResult.id}`, result)
                  .then(() => {
                    this.loadByElementResult(this.element.elementResult.id);
                  })
            }
        }
    }
</script>
<style>
  .ibox-content img {
    max-width: 100%;
  }
  .btn-outline-info {
    margin-right: 2px;
  }
  .ibox-content {
    margin-bottom: 0;
  }
  .ibox-footer {
    text-align: right;
    border-bottom: 1px solid #e7eaec;
    border-left: 1px solid #e7eaec;
    border-right: 1px solid #e7eaec;
  }
</style>
