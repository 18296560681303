<template>
    <span>
        <div class="vertical-timeline-icon navy-bg">
            <i class="fa fa-sign-in"></i>
        </div>
        <div class="vertical-timeline-content">
            <h5>Вход в систему</h5>
            <span class="vertical-date">
                {{action.date}}
                <small>{{action.time}}</small>
            </span>
        </div>
    </span>
</template>

<script>
    export default {
        name: "ActionsEnter",
        props: ['action']
    }
</script>

<style scoped>

</style>
