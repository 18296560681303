<template>
  <div class="animated fadeIn">
    <bread-crumbs title="Мои курсы" :items="[{title: 'Главная', url: '/#/'}]"/>
    <div class="wrapper wrapper-content animated fadeIn">
      <div v-if="courses.length > 0" class="row">
      <div v-for="(course, k) in courses" v-bind:key="k" class="col-md-4">
        <div class="ibox">
          <div class="ibox-content product-box">
            <div class="product-imitation" :style="{'background-image': course.cover && course.cover.thumbs ? 'url(' + course.cover.thumbs.big + ')' : ''}">
              <div class="color-overlay"></div>
              <span>{{course.name}}</span>
            </div>
            <div class="product-desc">
              <span :class="'product-price ' + course.status">
                  {{course.statusRu}}
              </span>
              <small class="badge badge-info" v-if="course.institution">
                <i class="fa fa-building"></i> {{course.institution.shortName}}
              </small>
              <div class="small m-t-xs">
                {{ course.shortDescription | truncate(100, '...') }}
              </div>
              <div v-if="course.status === 'started'" class="small m-t-xs">
                <i class="fa fa-clock-o"></i> Даты: {{ course.startDateTime|formatDate }} - {{ course.endDateTime|formatDate }}
              </div>
              <div class="small m-t-xs">
                <i class="fa fa-users"></i> На курсе: {{course.enrollCount}}
              </div>
            </div>
            <div class="ibox-footer">
              <b-btn-group class="centered-btn-group">
                <router-link :to="`course/${course.id}`" class="btn btn-primary"><span class="md-hidden">Смотреть</span> <i class="fa fa-long-arrow-right"></i></router-link>
              </b-btn-group>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div v-else-if="courses.length === 0" class="row">
      <b-col md="12">
        <div class="ibox" v-if="emptyText">
          <div class="ibox-content">
                      <span class="text-muted">
                          <span class="course-none bg-secondary"><i class="fa fa-graduation-cap"></i></span> {{emptyText}}
                      </span>
          </div>
        </div>
        <div class="ibox" v-else-if="$auth.check(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN' , 'ROLE_METHODIST', 'ROLE_CUSTOMER'])">
          <div class="ibox-content">
                      <span class="text-muted">
                          <span class="course-none bg-secondary"><i class="fa fa-graduation-cap"></i></span>  Вы пока не создали ни одного курса
                      </span>
          </div>
        </div>
        <div class="ibox" v-else>
          <div class="ibox-content">
                      <span class="text-muted">
                          Вы пока не записались ни на один курс
                      </span>
          </div>
          <div class="ibox-footer">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <router-link to="/admin/catalog" class="btn btn-primary btn-block">Перейти в каталог</router-link>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </b-col>
    </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../BreadCrumbs";
export default {
  name: "MyCourseList",
  components: {BreadCrumbs},
  data() {
    return {
      courses: []
    }
  },
  mounted() {
    this.axios.get('courses', {
      params: {
        my: true
      }
    }).then(res => {
      this.courses = res.data;
    });
  }
}
</script>

<style scoped lang="scss">
.product-desc {
  height: 150px;
}
.product-imitation {
  min-height: 180px;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  span {
    color: #fff;
    z-index: 100;
  }
  .color-overlay {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .6;
    position: absolute;
  }
}
.product-price {
  top: -33px;
  &.finished {
    background-color: #007bff;
  }
  &.started {
    background-color: #dc3545;
  }
  &.open {
    background-color: #17a2b8;
  }
  &.soon {
    background-color: #E6A23C;
  }
  &.finished {
    background-color: #67C23A;
  }
  &.closed {
    background-color: #909399;
  }
}
.course-none {
  border-radius: 20px;
  font-size: 16px;
  padding: 6px;
  line-height: 22px;
  height: 33px;
  width: 33px;
  color: #fff;
  display: inline-block;
  text-align: center;
}
</style>
